import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Form, SubmitType } from "@/features/Form/Form"
import { t } from "i18next"
import { useParams } from "react-router-dom"
import { clearErrors, sendTaskDelayedNotifications } from "../tasksSlice"

interface TaskSendDelayedNotificationsFormProps {
    eligible: boolean
    closeModal: () => void
}

export const TaskSendDelayedNotificationsForm: React.FC<
    TaskSendDelayedNotificationsFormProps
> = ({ eligible, closeModal }) => {
    const status = useAppSelector((state) => state.tasks.status)
    const dispatch = useAppDispatch()
    const taskId = useParams<{ taskId: string }>().taskId
    const projectId = useParams<{ projectId: string }>().projectId
    const handleSubmit = async () => {
        const { type } = await dispatch(
            sendTaskDelayedNotifications({ projectId, taskId }),
        )
        if (type === sendTaskDelayedNotifications.fulfilled.type) {
            closeModal()
        }
    }
    return (
        <div>
            {eligible ? (
                <Form
                    onCancel={closeModal}
                    onSubmit={handleSubmit}
                    statuses={[status.update]}
                    loadingTitle={t("sending_notifications")}
                    submitType={SubmitType.Submit}
                    clearErrors={clearErrors}
                >
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-bold text-lg">
                            {t("send_delay_notifications_notice")}
                        </p>
                        <p className="font-bold text-lg text-red-400">
                            {t("send_delay_notifications_notice_warning")}
                        </p>
                    </div>
                </Form>
            ) : (
                <div>
                    <p className="font-bold text-lg">
                        {t("send_delay_notifications_notice_not_eligible")}
                    </p>
                    <Button
                        name={t("cancel")}
                        icon="times"
                        className="ml-auto mt-2 !bg-transparent-0 !text-primary-100 !border !border-primary-100 hover:!bg-primary-100 hover:!text-secondary-100 hover:!border-primary-100 transition"
                        onClick={closeModal}
                        type="button"
                    ></Button>
                </div>
            )}
        </div>
    )
}
