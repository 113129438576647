import React from "react"
import { NoImage } from "../Errors/NoImage"

interface ImageCarouselProps {
    images: string[]
    title?: string
    children?: React.ReactNode
    childContainerClassName?: string
    className?: string
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
    images,
    title,
    className,
    children,
    childContainerClassName,
}) => {
    const [currentImage, setCurrentImage] = React.useState(0)
    return (
        <div className={`relative ${className}`}>
            {images.length > 1 && (
                <>
                    <div className="absolute top-0 left-0 p-2 w-full flex flex-col h-full">
                        <div className="w-full flex justify-center">
                            {Array.from(Array(images.length).keys()).map(
                                (idx) => (
                                    <div
                                        key={idx}
                                        className={`rounded-full w-2 h-2 bg-secondary-100 ${
                                            currentImage === idx
                                                ? "opacity-100"
                                                : "opacity-50"
                                        } transition`}
                                    ></div>
                                ),
                            )}
                        </div>
                        {/* <div className="text-secondary-100 text-6xl flex justify-between my-auto">
                            <FontAwesomeIcon
                                icon="chevron-left"
                                className="cursor-pointer opacity-60 hover:opacity-100 transition"
                            />
                            <FontAwesomeIcon
                                icon="chevron-right"
                                className="cursor-pointer opacity-60 hover:opacity-100 transition"
                            />
                        </div> */}
                    </div>
                </>
            )}
            {images.map((image, idx) => (
                <img
                    key={idx}
                    src={image}
                    alt=""
                    className={`w-full object-cover h-full ${
                        idx === currentImage ? "" : "hidden"
                    } transition}`} // @FIXME: This is a hack
                />
            ))}
            {images.length === 0 && (
                <div className="w-full h-full bg-secondary-100 flex items-center justify-center">
                    <NoImage size="6x" />
                </div>
            )}
            {children && (
                <div
                    className={
                        "absolute w-full flex items-center justify-center " +
                        childContainerClassName
                    }
                >
                    {children}
                </div>
            )}
        </div>
    )
}
