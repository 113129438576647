import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { ProjectFile } from "@/models/File"
import { IfcModel } from "@/models/Ifc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { IfcModelForm } from "./Forms/IfcModelForm"
import { IfcContainer } from "./IfcContainer"
import { IfcModelComponent } from "./IfcModel"
import {
    getProjectIfcFiles,
    getProjectIfcModels,
    selectIfcFiles,
    selectIfcModels,
} from "./ifcSlice"

export const Ifc = () => {
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const [queryParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const ifcFiles = useAppSelector(selectIfcFiles)
    const ifcModels = useAppSelector(selectIfcModels)
    const [selectedFile, setSelectedFile] = useState<ProjectFile | null>(null)
    const [selectedModel, setSelectedModel] = useState<IfcModel | null>(null)
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [showCreateModel, setShowCreateModel] = useState<boolean>(false)
    useEffect(() => {
        dispatch(getProjectIfcFiles(projectId))
        dispatch(getProjectIfcModels(projectId))
    }, [])
    useEffect(() => {
        const selectedFileId = queryParams.get("fileId")
        const fileToSelect = ifcFiles.find((file) => file.id === selectedFileId)
        if (fileToSelect) {
            setSelectedFile(
                ifcFiles.find((file) => file.id === selectedFileId) ?? null,
            )
        }
    }, [ifcFiles])

    return (
        <>
            <div className="h-full relative w-full">
                <IfcContainer
                    fullScreen={fullScreen}
                    selectedFile={selectedFile}
                    selectedModel={selectedModel}
                ></IfcContainer>
                {!selectedFile && (
                    <>
                        <div className="absolute w-full h-full blur-sm opacity-50 bg-primary-300 z-10 top-0 left-0"></div>
                        <div className="absolute w-full h-full flex justify-center items-center z-20 top-0 left-0">
                            <div className="bg-secondary-100 p-4 min-w-80 rounded-lg shadow-lg">
                                <div className="flex justify-between items-center">
                                    <h1 className="font-bold text-xl">
                                        {t("models")}:
                                    </h1>
                                    <Button
                                        name={t("create_model")}
                                        icon="plus"
                                        onClick={() => setShowCreateModel(true)}
                                    />
                                </div>
                                <div className="mt-4">
                                    {ifcModels.map((model) => (
                                        <IfcModelComponent
                                            key={model.id}
                                            ifcModel={model}
                                            setSelectedFile={setSelectedFile}
                                            setSelectedModel={setSelectedModel}
                                        />
                                    ))}
                                    {ifcModels.length === 0 && (
                                        <div className="flex justify-center items-center opacity-70 text-sm gap-2 my-4">
                                            <FontAwesomeIcon
                                                icon="folder-open"
                                                className="text-xl"
                                            />
                                            <p className="">
                                                {t("no_models_available")}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <NewModal
                title={t("create_model")}
                isShown={showCreateModel}
                closeModal={() => setShowCreateModel(false)}
            >
                <IfcModelForm closeModal={() => setShowCreateModel(false)} />
            </NewModal>
        </>
    )
}
