import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getTaskFollowersById, selectTask } from "./tasksSlice"

interface TaskFollowersProps {}

export const TaskFollowers: React.FC<TaskFollowersProps> = ({}) => {
    const task = useAppSelector(selectTask)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"

    useEffect(() => {
        dispatch(getTaskFollowersById({ projectId, taskId }))
    }, [])

    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col h-full">
            <div className="flex items-center justify-between gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("followers")}
                </h1>
            </div>
            <div className="w-full mt-4">
                {task.followers.length > 0 || task.groupFollowers.length > 0 ? (
                    <Scrollable height="400px">
                        {task.followers.length > 0 && (
                            <div className="flex flex-col gap-4">
                                <h1 className="text-lg font-bold">
                                    {t("users")}
                                </h1>
                                {task.followers.map((follower) => (
                                    <div
                                        key={follower.id}
                                        className="flex justify-between items-center bg-secondary-100 p-4 border-b border-r border-primary-100 border-opacity-25 rounded-br-lg"
                                    >
                                        <div className="flex items-center gap-3 ">
                                            <img
                                                src={
                                                    follower.profilePicture.path
                                                }
                                                alt=""
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <h4 className="text-lg font-bold">
                                                {follower.fullName}
                                            </h4>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {task.groupFollowers.length > 0 && (
                            <div className="flex flex-col gap-4">
                                <h1 className="text-lg font-bold">
                                    {t("groups")}
                                </h1>
                                {task.groupFollowers.map((group) => (
                                    <div
                                        key={group.id}
                                        className="flex justify-between items-center bg-secondary-100 p-4 border-b border-r border-primary-100 border-opacity-25 rounded-br-lg"
                                    >
                                        <div className="flex justify-center items-center gap-4">
                                            <div className="flex w-8 h-8 justify-center items-center border-2 border-primary-300 rounded-full">
                                                <FontAwesomeIcon
                                                    icon="user-group"
                                                    className="text-xs"
                                                />
                                            </div>
                                            <h4 className="text-lg font-bold">
                                                {group.name}
                                            </h4>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Scrollable>
                ) : (
                    <div className="w-full h-full flex justify-center items-center opacity-20">
                        <h1 className="text-xl flex flex-col items-center">
                            <FontAwesomeIcon
                                icon="user-minus"
                                className="mr-2 text-6xl"
                            />
                            <p>{t("no_followers_yet")}...</p>
                        </h1>
                    </div>
                )}
            </div>
        </div>
    )
}
