import { TaskDelayStatus } from "@/models/Task"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React from "react"

interface TaskDelayStatusBarProps {
    taskDelayStatus: TaskDelayStatus
}

export const TaskDelayStatusBar: React.FC<TaskDelayStatusBarProps> = ({
    taskDelayStatus,
}) => {
    const statusMapping: {
        [key in TaskDelayStatus]: {
            className: string
            icon: string
        }
    } = {
        [TaskDelayStatus.ON_TIME]: {
            className: "bg-green-400 text-secondary-100",
            icon: "check",
        },
        [TaskDelayStatus.DELAYED_START]: {
            className: "bg-orange-400 text-secondary-100",
            icon: "clock",
        },
        [TaskDelayStatus.DELAYED_END]: {
            className: "bg-orange-400 text-secondary-100",
            icon: "clock",
        },
        [TaskDelayStatus.DELAYED_BOTH]: {
            className: "bg-red-400 text-secondary-100",
            icon: "clock",
        },
    }

    return (
        taskDelayStatus && (
            <div
                className={`${statusMapping[taskDelayStatus].className} flex items-center gap-2 rounded-full p-2 h-fit text-sm w-fit`}
            >
                <FontAwesomeIcon
                    icon={statusMapping[taskDelayStatus].icon as IconProp}
                />
                <p className="inline text-nowrap">{t(taskDelayStatus)}</p>
            </div>
        )
    )
}
