import { SliceStatus } from "@/common/types"
import { Form, SubmitType } from "@/features/Form/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../Inputs/Button"

interface FormWithStepsProps {
    children: React.ReactNode[]
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    statuses: SliceStatus[]
    loadingTitle: string
    submitType: SubmitType
    clearErrors: ActionCreatorWithoutPayload
    onCancel?: () => void
    className?: string
    customSubmitButton?: React.ReactNode
}

export const FormWithSteps: React.FC<FormWithStepsProps> = ({
    children,
    onSubmit,
    statuses,
    loadingTitle,
    onCancel,
    className = "",
    submitType,
    clearErrors,
    customSubmitButton,
}) => {
    const [currentStep, setCurrentStep] = React.useState<number>(0)
    const [isLastStep, setIsLastStep] = React.useState<boolean>(false)
    const [isFirstStep, setIsFirstStep] = React.useState<boolean>(true)
    const { t } = useTranslation()
    const nextStep = () => {
        setCurrentStep((prevState) => prevState + 1)
    }
    const prevStep = () => {
        setCurrentStep((prevState) => prevState - 1)
    }
    useEffect(() => {
        setIsFirstStep(currentStep === 0)
        setIsLastStep(currentStep === children.length - 1)
    }, [currentStep, children.length])
    return (
        <Form
            onSubmit={onSubmit}
            statuses={statuses}
            loadingTitle={loadingTitle}
            onCancel={onCancel}
            className={className}
            submitType={submitType}
            customSubmitButton={customSubmitButton}
            clearErrors={clearErrors}
        >
            {children.map(
                (child, idx) =>
                    idx === currentStep && (
                        <div key={idx} className="w-full">
                            {!isFirstStep && (
                                <span
                                    className="font-medium flex items-center cursor-pointer hover:font-bold transition"
                                    onClick={prevStep}
                                >
                                    <FontAwesomeIcon
                                        icon={"chevron-left"}
                                        className="text-sm"
                                    />
                                    <p className="ml-1">{t("back")}</p>
                                </span>
                            )}
                            <div key={idx}>{child}</div>
                            <div className="mt-5 w-full flex justify-end">
                                {!isLastStep && (
                                    <Button
                                        name={t("next")}
                                        icon={"chevron-right"}
                                        type="button"
                                        onClick={nextStep}
                                    ></Button>
                                )}
                                {isLastStep && (
                                    <Button
                                        name={t("submit")}
                                        type="submit"
                                    ></Button>
                                )}
                            </div>
                        </div>
                    ),
            )}
        </Form>
    )
}
