import { Article, ArticleInterface } from "./Article"
import { BaseModel, BaseModelInterface } from "./BaseModel"
import { Project, ProjectJsonInterface } from "./Project"
import { Task, TaskJsonInterface } from "./Task"
import { User, UserInterface } from "./User"

export enum CURRENCY {
    USD = "USD",
    EUR = "EUR",
    GBP = "GBP",
    TND = "TND",
}

export interface BudgetPayload {
    name: string
    description: string
    total: number
    currency: CURRENCY
    parentBudgetId?: string
}

export interface GeneralBudgetInfo {
    totalBudget: number
    engagedBudget: number
    currency: CURRENCY
    totalArticles: number
    totalItems: number
}

export interface BudgetInterface extends BaseModelInterface {
    name: string
    description: string
    engaged: number
    total: number
    currency: CURRENCY
    parent: BudgetInterface | null
    children: BudgetInterface[]
    articles: ArticleInterface[]
    createdBy: UserInterface
    project: ProjectJsonInterface
    tasks: TaskJsonInterface[]
}

export class Budget extends BaseModel {
    public name: string
    public description: string
    public engaged: number
    public total: number
    public currency: CURRENCY
    public parent: Budget | null
    public children: Budget[]
    public articles: Article[]
    public createdBy: User
    public project: Project
    public tasks: Task[]

    constructor(data?: BudgetInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        //  round to 2 decimal places
        this.engaged = data?.engaged ? Math.round(data?.engaged * 100) / 100 : 0
        this.total = data?.total ? Math.round(data?.total * 100) / 100 : 0
        this.currency = data?.currency ?? CURRENCY.USD
        this.parent = data?.parent ? new Budget(data.parent) : null
        this.children = data?.children
            ? data.children.map((child) => new Budget(child))
            : []
        this.articles = data?.articles
            ? data.articles.map((article) => new Article(article))
            : []
        this.createdBy = data?.createdBy ? new User(data.createdBy) : new User()
        this.project = data?.project ? new Project(data.project) : new Project()
        this.tasks = data?.tasks ? data.tasks.map((task) => new Task(task)) : []
    }

    get remaining(): number {
        return this.total - this.engaged
    }

    public createPayload(parentId?: string): BudgetPayload {
        return {
            name: this.name,
            description: this.description,
            total: this.total,
            currency: this.currency,
            ...(parentId && { parentBudgetId: parentId }),
        }
    }

    public toJson(): BudgetInterface {
        return {
            ...super.toJson(),
            name: this.name,
            description: this.description,
            engaged: this.engaged,
            total: this.total,
            currency: this.currency,
            parent: this.parent ? this.parent.toJson() : null,
            children: this.children.map((child) => child.toJson()),
            articles: this.articles.map((article) => article.toJson()),
            createdBy: this.createdBy.toJson(),
            project: this.project.toJson(),
            tasks: this.tasks.map((task) => task.toJson()),
        }
    }
}
