import { ThreeDots } from "react-loader-spinner"
interface ThreeDotsLoaderProps {
    text?: string
    color?: string
    height?: number
    width?: number
}

export const ThreeDotsLoader: React.FC<ThreeDotsLoaderProps> = ({
    text,
    color,
    height,
    width,
}) => {
    return (
        <div className="flex flex-col items-center">
            <ThreeDots
                color={color ?? "#03192F"}
                height={height ?? 80}
                width={width ?? 80}
            />
            {text && <p>{text}...</p>}
        </div>
    )
}
