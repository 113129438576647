import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import {
    BIMFileNominationFields,
    FileJsonInterface,
    ProjectBIMFileNominations,
    ProjectFile,
    ProjectFileTypeEnum,
} from "@/models/File"
import { Folder, FolderJson } from "@/models/Folder"
import { ResourcePermissions } from "@/models/Permission"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    BimFilesFilterFields,
    CommonFilterFields,
    FilterOperators,
    FilterValuesType,
} from "../Plannings/Gantt/Filters/Filters"
import {
    createFolderApi,
    createRootFolderApi,
    deleteFileApi,
    deleteFolderApi,
    getBimNominationFieldsApi,
    getFolderContentFilesApi,
    getFolderContentFoldersApi,
    getProjectBimFilesApi,
    getProjectRootFoldersApi,
    getProjectTypeFilesApi,
    getResourcePermissionsApi,
    getUserSharedProjectFilesApi,
    getUserUploadedProjectFilesApi,
    renameFileApi,
    renameFolderApi,
    searchDescendantFilesApi,
    searchDescendantFoldersApi,
    updateResourcePermissionsApi,
    uploadFileToFolderApi,
} from "./fileManagerApi"

export const getProjectRootFolders = createAsyncThunkWithNotification(
    "fileManager/getProjectRootFolders",
    async (projectId: string) => {
        const response = await getProjectRootFoldersApi(projectId)
        return response
    },
)

export const getFolderContentFolders = createAsyncThunkWithNotification(
    "fileManager/getFolderContentFolders",
    async ({
        projectId,
        folderId,
    }: {
        projectId: string
        folderId: string
    }) => {
        const response = await getFolderContentFoldersApi(projectId, folderId)
        return response
    },
)

export const getFolderContentFiles = createAsyncThunkWithNotification(
    "fileManager/getFolderContentFiles",
    async ({
        projectId,
        folderId,
    }: {
        projectId: string
        folderId: string
    }) => {
        const response = await getFolderContentFilesApi(projectId, folderId)
        return response
    },
)

export const expandFolderTree = createAsyncThunkWithNotification(
    "fileManager/expandFolderTree",
    async ({
        projectId,
        folderId,
    }: {
        projectId: string
        folderId: string
    }) => {
        const response = await getFolderContentFoldersApi(projectId, folderId)
        return response
    },
)

export const createRootFolder = createAsyncThunkWithNotification(
    "fileManager/createRootFolder",
    async ({
        name,
        projectId,
        permissions,
        isGlobal,
    }: {
        name: string
        projectId: string
        permissions: ResourcePermissions
        isGlobal: boolean
    }) => {
        const response = await createRootFolderApi(
            name,
            projectId,
            permissions,
            isGlobal,
        )
        return response
    },
)

export const createFolder = createAsyncThunkWithNotification(
    "fileManager/createFolder",
    async ({
        name,
        projectId,
        parentFolderId,
        permissions,
        isGlobal,
    }: {
        name: string
        projectId: string
        parentFolderId: string
        permissions: ResourcePermissions
        isGlobal: boolean
    }) => {
        const response = await createFolderApi(
            name,
            projectId,
            parentFolderId,
            permissions,
            isGlobal,
        )
        return response
    },
)

export const uploadFileToFolder = createAsyncThunkWithNotification(
    "fileManager/uploadFileToFolder",
    async (
        {
            projectId,
            folderId,
            payload,
        }: {
            projectId: string
            folderId: string
            payload: FormData
        },
        dispatch: any,
    ) => {
        const response = await uploadFileToFolderApi(
            projectId,
            folderId,
            payload,
            dispatch,
        )
        return response
    },
)

export const searchDescendantFolders = createAsyncThunkWithNotification(
    "fileManager/searchDescendantFolders",
    async ({
        projectId,
        query,
    }: {
        projectId: string
        folderId: string
        query: string
    }) => {
        const response = await searchDescendantFoldersApi(projectId, query)
        return response
    },
)

export const searchDescendantFiles = createAsyncThunkWithNotification(
    "fileManager/searchDescendantFiles",
    async ({ projectId, query }: { projectId: string; query: string }) => {
        const response = await searchDescendantFilesApi(projectId, query)
        return response
    },
)

export const deleteFolder = createAsyncThunkWithNotification(
    "fileManager/deleteFolder",
    async ({
        projectId,
        folderId,
    }: {
        projectId: string
        folderId: string
    }) => {
        const response = await deleteFolderApi(projectId, folderId)
        return response
    },
)

export const renameFolder = createAsyncThunkWithNotification(
    "fileManager/renameFolder",
    async ({
        projectId,
        folderId,
        name,
    }: {
        projectId: string
        folderId: string
        name: string
    }) => {
        const response = await renameFolderApi(projectId, folderId, name)
        return response
    },
)

export const updateFolderPermissions = createAsyncThunkWithNotification(
    "fileManager/updateFolderPermissions",
    async ({
        projectId,
        resourceId,
        permissions,
        resourceType,
        isGlobal,
    }: {
        projectId: string
        resourceId: string
        permissions: ResourcePermissions
        resourceType: "folder" | "file"
        isGlobal: boolean
    }) => {
        const response = await updateResourcePermissionsApi(
            projectId,
            resourceId,
            permissions,
            resourceType,
            isGlobal,
        )
        return response
    },
)

export const getResourcePermissions = createAsyncThunkWithNotification(
    "fileManager/getResourcePermissions",
    async ({
        projectId,
        resourceId,
        resourceType,
    }: {
        projectId: string
        resourceId: string
        resourceType: "folder" | "file"
    }) => {
        const response = await getResourcePermissionsApi(
            projectId,
            resourceId,
            resourceType,
        )
        return response
    },
)

export const getProjectTypeFiles = createAsyncThunkWithNotification(
    "fileManager/getProjectTypeFiles",
    async ({
        projectId,
        fileType,
    }: {
        projectId: string
        fileType: ProjectFileTypeEnum
    }) => {
        const response = await getProjectTypeFilesApi(projectId, fileType)
        return response
    },
)

export const getUserUploadedProjectFiles = createAsyncThunkWithNotification(
    "fileManager/getUserUploadedProjectFiles",
    async (projectId: string) => {
        const response = await getUserUploadedProjectFilesApi(projectId)
        return response
    },
)

export const getUserSharedProjectFiles = createAsyncThunkWithNotification(
    "fileManager/getUserSharedProjectFiles",
    async (projectId: string) => {
        const response = await getUserSharedProjectFilesApi(projectId)
        return response
    },
)

export const deleteFile = createAsyncThunkWithNotification(
    "fileManager/deleteFile",
    async ({ fileId, projectId }: { fileId: string; projectId: string }) => {
        const response = await deleteFileApi(fileId, projectId)
        return response
    },
)

export const renameFile = createAsyncThunkWithNotification(
    "fileManager/renameFile",
    async ({
        projectId,
        fileId,
        name,
    }: {
        projectId: string
        fileId: string
        name: string
    }) => {
        const response = await renameFileApi(projectId, fileId, name)
        return response
    },
)

export const getBimNominationFields = createAsyncThunkWithNotification(
    "fileManager/getBimNominationFields",
    async (projectId: string) => {
        const response = await getBimNominationFieldsApi(projectId)
        return response
    },
)

export const getProjectBimFiles = createAsyncThunkWithNotification(
    "fileManager/getProjectBimFiles",
    async (projectId: string) => {
        const response = await getProjectBimFilesApi(projectId)
        return response
    },
)

const filterInitialState = {
    [CommonFilterFields.Name]: {
        value: "",
        operator: FilterOperators.StringOperators.Equals,
        isShown: false,
    },
    [BimFilesFilterFields.Task]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Project]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Originator]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.FunctionalBreakdown]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.SpatialBreakdown]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Form]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Discipline]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Number]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Phase]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Assignee]: {
        value: null,
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.Reviewers]: {
        value: null,
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [BimFilesFilterFields.BimFileStatus]: {
        value: null,
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
}

export interface FileManagerState {
    folder: FolderJson
    folderTree: FolderJson
    files: FileJsonInterface[]
    filesBIMNominations: ProjectBIMFileNominations
    resourcePermission: ResourcePermissions
    oneStatus: ReducerStatus
    manyStatus: ReducerStatus
    progress: number
    bimFileFilters: FilterValuesType<BimFilesFilterFields>
    bimTableColumns: (BimFilesFilterFields | CommonFilterFields)[]
    errors: FormErrors
}

const initialState: FileManagerState = {
    folder: new Folder().toJson(),
    folderTree: new Folder().toJson(),
    files: [],
    filesBIMNominations: {
        [BIMFileNominationFields.PROJECT]: [],
        [BIMFileNominationFields.ORIGINATOR]: [],
        [BIMFileNominationFields.FUNCTIONAL_BREAKDOWN]: [],
        [BIMFileNominationFields.SPATIAL_BREAKDOWN]: [],
        [BIMFileNominationFields.FORM]: [],
        [BIMFileNominationFields.DISCIPLINE]: [],
        [BIMFileNominationFields.NUMBER]: [],
    },
    resourcePermission: {
        read: {
            groups: [],
            users: [],
        },
        write: {
            groups: [],
            users: [],
        },
        notifications: {
            groups: [],
            users: [],
        },
    },
    oneStatus: DEFAULT_REDUCER_STATUS,
    manyStatus: DEFAULT_REDUCER_STATUS,
    progress: 0,
    bimFileFilters: filterInitialState,
    bimTableColumns: [
        CommonFilterFields.Name,
        BimFilesFilterFields.Task,
        BimFilesFilterFields.Originator,
        BimFilesFilterFields.Form,
        BimFilesFilterFields.Discipline,
        BimFilesFilterFields.Number,
        BimFilesFilterFields.Phase,
        BimFilesFilterFields.Assignee,
        BimFilesFilterFields.Reviewers,
        BimFilesFilterFields.BimFileStatus,
    ],
    errors: {},
}

export const fileManagerSlice = createSlice({
    name: "fileManager",
    initialState,
    reducers: {
        resetFolder: (state) => {
            state.folder = new Folder().toJson()
        },
        setProgress: (state, action) => {
            state.progress = action.payload
        },
        clearErrors: (state) => {
            state.errors = {}
        },
        setFilters: (state, action) => {
            state.bimFileFilters = action.payload
        },
        resetFilters: (state, action) => {
            state.bimFileFilters = filterInitialState
        },
        toggleFilter: (state, action: { payload: BimFilesFilterFields }) => {
            Object.keys(state.bimFileFilters).forEach((key) => {
                if (key !== action.payload) {
                    state.bimFileFilters[key as BimFilesFilterFields].isShown =
                        false
                }
            })
            state.bimFileFilters[action.payload].isShown =
                !state.bimFileFilters[action.payload].isShown
        },
        closeAllFilters: (state) => {
            Object.keys(state.bimFileFilters).forEach((key) => {
                state.bimFileFilters[key as BimFilesFilterFields].isShown =
                    false
            })
        },
        closeFilter: (state, action: { payload: BimFilesFilterFields }) => {
            state.bimFileFilters[action.payload].isShown = false
        },
        setBimTableColumns: (state, action) => {
            const columns = action.payload
            const columnOrder = [
                CommonFilterFields.Name,
                BimFilesFilterFields.Task,
                BimFilesFilterFields.Project,
                BimFilesFilterFields.Originator,
                BimFilesFilterFields.FunctionalBreakdown,
                BimFilesFilterFields.SpatialBreakdown,
                BimFilesFilterFields.Form,
                BimFilesFilterFields.Discipline,
                BimFilesFilterFields.Number,
                BimFilesFilterFields.Phase,
                BimFilesFilterFields.Assignee,
                BimFilesFilterFields.Reviewers,
                BimFilesFilterFields.BimFileStatus,
            ]
            state.bimTableColumns = columnOrder.filter((column) =>
                columns.includes(column),
            )
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectRootFolders.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getProjectRootFolders.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.folderTree.children = action.payload.data.data
            })
            .addCase(getProjectRootFolders.rejected, (state, action) => {
                state.manyStatus.read = SliceStatus.FAILED
                state.folder = new Folder().toJson()
            })
            .addCase(getFolderContentFolders.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getFolderContentFolders.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.folder.children = action.payload.data.data
            })
            .addCase(getFolderContentFolders.rejected, (state) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(getFolderContentFiles.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getFolderContentFiles.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.folder.files = action.payload.data.data
            })
            .addCase(getFolderContentFiles.rejected, (state) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(createRootFolder.pending, (state) => {
                state.oneStatus.create = SliceStatus.LOADING
            })
            .addCase(createRootFolder.fulfilled, (state, action) => {
                state.oneStatus.create = SliceStatus.IDLE
                state.folderTree.children = [
                    action.payload.data.data,
                    ...state.folderTree.children,
                ]
            })
            .addCase(createRootFolder.rejected, (state, action) => {
                state.oneStatus.create = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(createFolder.pending, (state) => {
                state.oneStatus.create = SliceStatus.LOADING
            })
            .addCase(createFolder.fulfilled, (state, action) => {
                state.oneStatus.create = SliceStatus.IDLE
                state.folder.children = [
                    action.payload.data.data,
                    ...state.folder.children,
                ]
                const addFolderToTree = (folder: FolderJson) => {
                    if (!action.payload.data.data.parent) {
                        state.folderTree.children = [
                            action.payload.data.data,
                            ...state.folderTree.children,
                        ]
                        return
                    }
                    if (folder.id === action.payload.data.data.parent.id) {
                        folder.children = [
                            action.payload.data.data,
                            ...(folder.children ? folder.children : []),
                        ]
                    }
                    if (folder.children) {
                        folder.children.forEach((child) =>
                            addFolderToTree(child),
                        )
                    }
                }
                addFolderToTree(state.folderTree)
            })
            .addCase(createFolder.rejected, (state, action) => {
                state.oneStatus.create = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(uploadFileToFolder.pending, (state) => {
                state.oneStatus.create = SliceStatus.LOADING
            })
            .addCase(uploadFileToFolder.fulfilled, (state, action) => {
                state.oneStatus.create = SliceStatus.IDLE
                if (Array.isArray(action.payload.data.data))
                    state.folder.files = [
                        ...(Array.isArray(action.payload.data.data)
                            ? action.payload.data.data
                            : [action.payload.data.data]),
                        ...state.folder.files,
                    ]
            })
            .addCase(uploadFileToFolder.rejected, (state, action) => {
                state.oneStatus.create = SliceStatus.FAILED
            })
            .addCase(searchDescendantFolders.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(searchDescendantFolders.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.folder.children = action.payload.data.data
            })
            .addCase(searchDescendantFolders.rejected, (state, action) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(searchDescendantFiles.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(searchDescendantFiles.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.folder.files = action.payload.data.data
            })
            .addCase(searchDescendantFiles.rejected, (state, action) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(deleteFolder.pending, (state) => {
                state.oneStatus.delete = SliceStatus.LOADING
            })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.oneStatus.delete = SliceStatus.IDLE
                state.folder.children = state.folder.children.filter(
                    (folder) => folder.id !== action.payload.data.data,
                )
                const deleteFolderInTree = (folder: FolderJson) => {
                    if (folder.children) {
                        folder.children = folder.children.filter(
                            (child) => child.id !== action.payload.data.data,
                        )
                        folder.children = folder.children.map((child) =>
                            deleteFolderInTree(child),
                        )
                    }
                    return folder
                }
                deleteFolderInTree(state.folderTree)
            })
            .addCase(deleteFolder.rejected, (state, action) => {
                state.oneStatus.delete = SliceStatus.FAILED
            })
            .addCase(renameFolder.pending, (state) => {
                state.oneStatus.update = SliceStatus.LOADING
            })
            .addCase(renameFolder.fulfilled, (state, action) => {
                state.oneStatus.update = SliceStatus.IDLE
                state.folder.children = state.folder.children.map((folder) => {
                    if (folder.id === action.payload.data.data.id) {
                        return action.payload.data.data
                    }
                    return folder
                })
                const renameFolderInTree = (folder: FolderJson) => {
                    if (folder.id === action.payload.data.data.id) {
                        return action.payload.data.data
                    }
                    if (folder.children) {
                        folder.children = folder.children.map((child) =>
                            renameFolderInTree(child),
                        )
                    }
                    return folder
                }
                renameFolderInTree(state.folderTree)
            })
            .addCase(renameFolder.rejected, (state, action) => {
                state.oneStatus.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(expandFolderTree.pending, (state) => {
                // state.oneStatus.multiple = SliceStatus.LOADING @FIXME: add action
            })
            .addCase(expandFolderTree.fulfilled, (state, action) => {
                // state.oneStatus.multiple = SliceStatus.IDLE
                // FIXME: add action
            })
            .addCase(expandFolderTree.rejected, (state, action) => {
                // state.oneStatus.multiple = SliceStatus.FAILED
            })
            // @FIXME: add action
            .addCase(updateFolderPermissions.pending, (state) => {
                state.oneStatus.update = SliceStatus.LOADING
            })
            .addCase(updateFolderPermissions.fulfilled, (state, action) => {
                state.oneStatus.update = SliceStatus.IDLE
            })
            .addCase(updateFolderPermissions.rejected, (state, action) => {
                state.oneStatus.update = SliceStatus.FAILED
            })

            .addCase(getResourcePermissions.pending, (state) => {
                state.oneStatus.read = SliceStatus.LOADING
            })
            .addCase(getResourcePermissions.fulfilled, (state, action) => {
                state.oneStatus.read = SliceStatus.IDLE
                state.resourcePermission = action.payload.data.data
            })
            .addCase(getResourcePermissions.rejected, (state, action) => {
                state.oneStatus.read = SliceStatus.FAILED
            })
            .addCase(getProjectTypeFiles.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getProjectTypeFiles.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.files = action.payload.data.data
            })
            .addCase(getProjectTypeFiles.rejected, (state) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(getUserUploadedProjectFiles.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getUserUploadedProjectFiles.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.files = action.payload.data.data
            })
            .addCase(getUserUploadedProjectFiles.rejected, (state) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(getUserSharedProjectFiles.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getUserSharedProjectFiles.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.files = action.payload.data.data
            })
            .addCase(getUserSharedProjectFiles.rejected, (state) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
            .addCase(deleteFile.pending, (state) => {
                state.oneStatus.delete = SliceStatus.LOADING
            })
            .addCase(deleteFile.fulfilled, (state, action) => {
                state.oneStatus.delete = SliceStatus.IDLE
                state.files = state.files.filter(
                    (file) => file.id !== action.payload.data.data,
                )
                state.folder.files = state.folder.files.filter(
                    (file) => file.id !== action.payload.data.data,
                )
            })
            .addCase(deleteFile.rejected, (state) => {
                state.oneStatus.delete = SliceStatus.FAILED
            })
            .addCase(renameFile.pending, (state) => {
                state.oneStatus.update = SliceStatus.LOADING
            })
            .addCase(renameFile.fulfilled, (state, action) => {
                state.oneStatus.update = SliceStatus.IDLE
                state.files = state.files.map((file) => {
                    if (file.id === action.payload.data.data.id) {
                        return action.payload.data.data
                    }
                    return file
                })
                state.folder.files = state.folder.files.map((file) => {
                    if (file.id === action.payload.data.data.id) {
                        return action.payload.data.data
                    }
                    return file
                })
            })
            .addCase(renameFile.rejected, (state, action) => {
                state.oneStatus.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(getBimNominationFields.pending, (state) => {
                state.oneStatus.read = SliceStatus.LOADING
            })
            .addCase(getBimNominationFields.fulfilled, (state, action) => {
                state.oneStatus.read = SliceStatus.IDLE
                state.filesBIMNominations = action.payload.data.data
            })
            .addCase(getBimNominationFields.rejected, (state) => {
                state.oneStatus.read = SliceStatus.FAILED
            })
            .addCase(getProjectBimFiles.pending, (state) => {
                state.manyStatus.read = SliceStatus.LOADING
            })
            .addCase(getProjectBimFiles.fulfilled, (state, action) => {
                state.manyStatus.read = SliceStatus.IDLE
                state.files = action.payload.data.data
            })
            .addCase(getProjectBimFiles.rejected, (state) => {
                state.manyStatus.read = SliceStatus.FAILED
            })
    },
})

const selectFolderRaw = (state: RootState) => state.fileManager.folder
const selectFolderTreeRaw = (state: RootState) => state.fileManager.folderTree
const selectFilesRaw = (state: RootState) => state.fileManager.files

export const selectFiles = createSelector([selectFilesRaw], (files) =>
    files.map((file) => new ProjectFile(file)),
)

export const selectFolder = createSelector(
    [selectFolderRaw],
    (folder) => new Folder(folder),
)

export const selectFolderTree = createSelector(
    [selectFolderTreeRaw],
    (folderTree) => new Folder(folderTree),
)

export default fileManagerSlice.reducer
export const {
    resetFolder,
    setProgress,
    clearErrors,
    toggleFilter,
    closeAllFilters,
    setFilters,
    closeFilter,
    resetFilters,
    setBimTableColumns,
} = fileManagerSlice.actions
