import PlaceholderAvatar from "@/assets/avatar-placeholder.png"
import { UserNotification } from "@/models/Notification"
import {
    NotificationTypeAdditionalDataMap,
    UserNotificationType,
} from "@/types/UserNotificationsTypes"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface NotificationTaskRepliedCommentProps {
    notification: UserNotification
    additionalData: NotificationTypeAdditionalDataMap[UserNotificationType.TASK_REPLY_COMMENT]
    seen: boolean
    longFormat?: boolean
}

export const NotificationTaskRepliedComment: React.FC<
    NotificationTaskRepliedCommentProps
> = ({ notification, additionalData, seen, longFormat = false }) => {
    const { t } = useTranslation()
    const redirectLink = `/projects/${additionalData.project.id}/tasks/${additionalData.task.id}?comment=${additionalData.reply.id}`
    return (
        <Link
            className={`flex items-center justify-between  p-2 bg-secondary-100 rounded-lg  ${
                seen ? "text-primary-100" : "text-primary-300"
            }`}
            to={redirectLink}
        >
            <div className="flex items-center gap-2">
                <img
                    className={`w-10 h-10 rounded-full object-cover ${
                        seen ? "filter grayscale" : ""
                    }`}
                    onError={(e) => {
                        e.currentTarget.src = PlaceholderAvatar
                    }}
                    src={additionalData.commenter.profilePicture.path}
                    alt="profile"
                />
                <div>
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.commenter.firstName}{" "}
                        {additionalData.commenter.lastName}
                    </span>{" "}
                    {t("replied_on_your_comment_from_the_task")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.task.name}
                    </span>{" "}
                    {t("from_the_project")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.project.name}
                    </span>{" "}
                    {!longFormat && (
                        <p className="text-xs">
                            {notification.timeSinceCreation}{" "}
                        </p>
                    )}
                </div>
            </div>
            {longFormat && (
                <p className="text-xs">{notification.timeSinceCreation} </p>
            )}
        </Link>
    )
}
