import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { CalendarInput } from "@/components/Inputs/CalendarInput"
import { Input } from "@/components/Inputs/Input"
import { Form, SubmitType } from "@/features/Form/Form"
import { Task, TaskJsonInterface } from "@/models/Task"
import Slider from "rc-slider"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { clearErrors, createTask } from "./tasksSlice"

interface TaskCreateFormProps {
    parentTask?: Task
    createCallback?: (task: TaskJsonInterface) => void
}

export const TaskCreateForm: React.FC<TaskCreateFormProps> = ({
    parentTask,
    createCallback,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [newTask, setNewTask] = useState<Task>(new Task())
    const status = useAppSelector((state) => state.tasks.status)
    const projectId = useParams<{ projectId: string }>().projectId
    const planningId = useParams<{ planningId: string }>().planningId

    const handleSubmit = async () => {
        const res = await dispatch(
            createTask({
                projectId,
                planningId,
                payload: newTask.createUpdatePayload(
                    parentTask?.id ?? undefined,
                ),
            }),
        )
        if (createCallback) {
            createCallback(res.payload.data.data)
        }
    }
    return (
        <Form
            clearErrors={clearErrors}
            loadingTitle={t("creating_task")}
            statuses={[status.create]}
            submitType={SubmitType.Create}
            onSubmit={handleSubmit}
        >
            {parentTask && (
                <div className="w-full">
                    <Input
                        type="text"
                        name="parentTask"
                        label={t("parent_task")}
                        value={parentTask.name}
                        disabled
                        onChange={() => {}}
                    />
                </div>
            )}
            <div className="w-full">
                <Input
                    type="text"
                    name="name"
                    label={t("task_name")}
                    value={newTask.name}
                    onChange={(e) =>
                        setNewTask(
                            new Task({
                                ...newTask.toJson(),
                                name: e.target.value,
                            }),
                        )
                    }
                />

                <div className="my-4 flex flex-col items-center">
                    <div className="flex justify-center items-center gap-2">
                        <p className="font-bold">{t("progress")}</p>
                        <p>{newTask.progress}%</p>
                    </div>
                    <div className="w-[90%]">
                        <Slider
                            value={newTask.progress}
                            onChange={(value) => {
                                setNewTask(
                                    new Task({
                                        ...newTask.toJson(),
                                        progress: value as number,
                                    }),
                                )
                            }}
                        />
                    </div>
                </div>

                <div className="flex justify-between mt-4 border-y  py-4 flex-wrap">
                    <h1 className="w-full font-bold text-lg mb-4">
                        {t("dates")}:
                    </h1>
                    <div className="w-2/5">
                        <CalendarInput
                            label={t("start_date")}
                            name="startDate"
                            value={newTask.startDateInput}
                            onChange={(e) => {
                                setNewTask(
                                    new Task({
                                        ...newTask.toJson(),
                                        startDate: e.target.value,
                                    }),
                                )
                            }}
                        />
                    </div>
                    <div className="w-2/5">
                        <CalendarInput
                            label={t("end_date")}
                            name="endDate"
                            value={newTask.endDateInput}
                            onChange={(e) =>
                                setNewTask(
                                    new Task({
                                        ...newTask.toJson(),
                                        endDate: e.target.value,
                                    }),
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
}
