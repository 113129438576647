import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Task } from "@/models/Task"
import { DetailsTimelineTaskBar } from "./DetailsTimelineTaskBar"

interface DetailsTasksProps {
    tasks: Task[]
    timelineLength: number
}

export const DetailsTimeline: React.FC<DetailsTasksProps> = ({
    tasks,
    timelineLength,
}) => {
    const { t } = useTranslation()
    const today = new Date()
    const [firstTaskSet, setFirstTaskSet] = React.useState<Task[]>([])
    const [secondTaskSet, setSecondTaskSet] = React.useState<Task[]>([])

    const [timelineStartDate, setTimelineStartDate] = React.useState<Date>(
        new Date(
            new Date().setDate(
                new Date().getDate() - Math.round(timelineLength / 2),
            ),
        ),
    )
    const [timelineEndDate, setTimelineEndDate] = React.useState<Date>(
        new Date(
            new Date().setDate(
                new Date().getDate() + Math.round(timelineLength / 2),
            ),
        ),
    )

    useEffect(() => {
        const maxTasks =
            tasks.length > 4 ? tasks.slice(0, 4) : tasks.slice(0, tasks.length)
        setFirstTaskSet(maxTasks.slice(0, Math.round(maxTasks.length / 2)))
        setSecondTaskSet(
            maxTasks.slice(Math.round(maxTasks.length / 2), maxTasks.length),
        )
    }, [tasks])

    return (
        <div>
            <div className="w-full">
                {firstTaskSet.map(
                    (task) =>
                        ((task.endDate > timelineStartDate &&
                            task.endDate < timelineEndDate) ||
                            (task.startDate < timelineEndDate &&
                                task.startDate > timelineStartDate)) && (
                            <DetailsTimelineTaskBar
                                key={task.id}
                                task={task}
                                timelineStartDate={timelineStartDate}
                                timelineEndDate={timelineEndDate}
                                below={false}
                            />
                        ),
                )}
            </div>
            <div className="h-[0.15rem] w-full bg-primary-300 flex justify-between mb-12">
                <div className="">
                    <div className="h-4 w-[0.20rem] -mt-[.4rem] bg-primary-300"></div>
                    <div className="">
                        {t("intlDateTime", {
                            val: timelineStartDate,
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </div>
                </div>
                <div className="">
                    <div className="h-4 w-[0.20rem] -mt-[.4rem] bg-primary-300 mx-auto"></div>
                    <div className="">
                        {t("intlDateTime", {
                            val: today,
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </div>
                </div>
                <div className="">
                    <div className="h-4 w-[0.20rem] -mt-[.4rem] bg-primary-300 ml-auto"></div>
                    <div className="">
                        {t("intlDateTime", {
                            val: timelineEndDate,
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </div>
                </div>
            </div>
            <div className="w-full">
                {secondTaskSet.map(
                    (task) =>
                        ((task.endDate > timelineStartDate &&
                            task.endDate < timelineEndDate) ||
                            (task.startDate < timelineEndDate &&
                                task.startDate > timelineStartDate)) && (
                            <DetailsTimelineTaskBar
                                key={task.id}
                                task={task}
                                timelineStartDate={timelineStartDate}
                                timelineEndDate={timelineEndDate}
                            />
                        ),
                )}
            </div>
        </div>
    )
}
