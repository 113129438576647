import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { PermissionGroup } from "@/models/Permission"
import {
    Task,
    TaskComment,
    TaskCommentJsonInterface,
    TaskDelayStatus,
    TaskDependenciesPayload,
    TaskDependency,
    TaskDependencyJsonInterface,
    TaskJsonInterface,
    TaskPayload,
    TaskResourcesJsonInterface,
    TaskReview,
    TaskReviewJsonInterface,
    TaskReviewStatus,
    TaskStatus,
    TaskStatusDetail,
} from "@/models/Task"
import { User } from "@/models/User"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import { updateTaskById } from "../Plannings/planningSlice"
import { getProjectTasksByIdApi } from "../projectsApi"
import { getProjectTasksTreesById } from "../projectsSlice"
import {
    addCommentApi,
    addTaskAssigneesByIdApi,
    addTaskDependenciesApi,
    addTaskFollowersByIdApi,
    addTaskReviewsByIdApi,
    addTaskTagsApi,
    createTaskApi,
    deleteAttachmentApi,
    deleteTaskApi,
    getCommentRepliesApi,
    getMyAssignedTasksApi,
    getMyFollowedTasksApi,
    getTaskActivitiesApi,
    getTaskAssigneesByIdApi,
    getTaskAttachmentsByIdApi,
    getTaskByIdApi,
    getTaskCommentsByIdApi,
    getTaskDependenciesApi,
    getTaskDependenciesByIdApi,
    getTaskFollowersByIdApi,
    getTaskResourcesApi,
    getTaskReviewsByIdApi,
    getTasksForReviewApi,
    getTaskTagsApi,
    getUserTaskReviewApi,
    removeTaskAssigneesByIdApi,
    removeTaskDependenciesApi,
    removeTaskDependencyApi,
    removeTaskFollowersByIdApi,
    removeTaskReviewsByIdApi,
    removeTaskTagsApi,
    resetTaskStatusApi,
    sendTaskDelayedNotificationsApi,
    setTaskTagsApi,
    updateTaskDependencyApi,
    updateTaskReviewApi,
    uploadAttachmentApi,
} from "./tasksApi"

export const getTaskById = createAsyncThunkWithNotification(
    "tasks/getTaskById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskByIdApi(projectId, taskId)
        return response
    },
)

export const createTask = createAsyncThunkWithNotification(
    "tasks/createTask",
    async ({
        projectId,
        planningId,
        payload,
    }: {
        projectId: string
        planningId: string
        payload: TaskPayload
    }) => {
        const response = await createTaskApi(projectId, planningId, payload)
        return response
    },
)

export const getTaskAssigneesById = createAsyncThunkWithNotification(
    "tasks/getTaskAssigneesById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskAssigneesByIdApi(projectId, taskId)
        return response
    },
)

export const addTaskAssigneesById = createAsyncThunkWithNotification(
    "tasks/addTaskAssigneesById",
    async ({
        projectId,
        taskId,
        assignees,
        groups,
    }: {
        projectId: string
        taskId: string
        assignees: string[]
        groups: string[]
    }) => {
        const response = await addTaskAssigneesByIdApi(
            projectId,
            taskId,
            assignees,
            groups,
        )
        return response
    },
)

export const removeTaskAssigneesById = createAsyncThunkWithNotification(
    "tasks/removeTaskAssigneesById",
    async ({
        projectId,
        taskId,
        assignees,
        groups,
    }: {
        projectId: string
        taskId: string
        assignees: string[]
        groups: string[]
    }) => {
        const response = await removeTaskAssigneesByIdApi(
            projectId,
            taskId,
            assignees,
            groups,
        )
        return response
    },
)

export const getTaskFollowersById = createAsyncThunkWithNotification(
    "tasks/getTaskFollowersById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskFollowersByIdApi(projectId, taskId)
        return response
    },
)

export const addTaskFollowersById = createAsyncThunkWithNotification(
    "tasks/addTaskFollowersById",
    async ({
        projectId,
        taskId,
        followers,
        groupFollowers,
    }: {
        projectId: string
        taskId: string
        followers: string[]
        groupFollowers: string[]
    }) => {
        const response = await addTaskFollowersByIdApi(
            projectId,
            taskId,
            followers,
            groupFollowers,
        )
        return response
    },
)
export const removeTaskFollowersById = createAsyncThunkWithNotification(
    "tasks/removeTaskFollowersById",
    async ({
        projectId,
        taskId,
        followers,
        groupFollowers,
    }: {
        projectId: string
        taskId: string
        followers: string[]
        groupFollowers: string[]
    }) => {
        const response = await removeTaskFollowersByIdApi(
            projectId,
            taskId,
            followers,
            groupFollowers,
        )
        return response
    },
)

export const getTaskCommentsById = createAsyncThunkWithNotification(
    "tasks/getTaskCommentsById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskCommentsByIdApi(projectId, taskId)
        return response
    },
)

export const getTaskReviewsById = createAsyncThunkWithNotification(
    "tasks/getTaskReviewsById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskReviewsByIdApi(projectId, taskId)
        return response
    },
)

export const addTaskReviewsById = createAsyncThunkWithNotification(
    "tasks/addTaskReviewsById",
    async ({
        projectId,
        taskId,
        reviewers,
        groups,
    }: {
        projectId: string
        taskId: string
        reviewers: string[]
        groups: string[]
    }) => {
        const response = await addTaskReviewsByIdApi(
            projectId,
            taskId,
            reviewers,
            groups,
        )
        return response
    },
)

export const removeTaskReviewsById = createAsyncThunkWithNotification(
    "tasks/removeTaskReviewsById",
    async ({
        projectId,
        taskId,
        reviewers,
        groups,
    }: {
        projectId: string
        taskId: string
        reviewers: string[]
        groups: string[]
    }) => {
        const response = await removeTaskReviewsByIdApi(
            projectId,
            taskId,
            reviewers,
            groups,
        )
        return response
    },
)

export const getTaskDependenciesById = createAsyncThunkWithNotification(
    "tasks/getTaskDependenciesById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskDependenciesByIdApi(projectId, taskId)
        return response
    },
)

export const getTaskAttachmentsById = createAsyncThunkWithNotification(
    "tasks/getTaskAttachmentsById",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskAttachmentsByIdApi(projectId, taskId)
        return response
    },
)

export const getCommentReplies = createAsyncThunkWithNotification(
    "tasks/getCommentReplies",
    async ({
        projectId,
        taskId,
        commentId,
    }: {
        projectId: string
        taskId: string
        commentId: string
    }) => {
        const response = await getCommentRepliesApi(
            projectId,
            taskId,
            commentId,
        )
        return response
    },
)

export const addComment = createAsyncThunkWithNotification(
    "tasks/replyToComment",
    async ({
        projectId,
        taskId,
        payload,
        commentId,
    }: {
        projectId: string
        taskId: string
        payload: FormData
        commentId?: string
    }) => {
        const response = await addCommentApi(
            projectId,
            taskId,
            payload,
            commentId,
        )
        return response
    },
)

export const uploadAttachment = createAsyncThunkWithNotification(
    "tasks/uploadAttachment",
    async ({
        projectId,
        taskId,
        payload,
    }: {
        projectId: string
        taskId: string
        payload: FormData
    }) => {
        const response = await uploadAttachmentApi(projectId, taskId, payload)
        return response
    },
)

export const deleteAttachment = createAsyncThunkWithNotification(
    "tasks/deleteAttachment",
    async ({
        projectId,
        taskId,
        fileId,
    }: {
        projectId: string
        taskId: string
        fileId: string
    }) => {
        const response = await deleteAttachmentApi(projectId, taskId, fileId)
        return response
    },
)

export const addTaskDependencies = createAsyncThunkWithNotification(
    "tasks/addTaskDependencies",
    async ({
        projectId,
        taskId,
        dependencies,
    }: {
        projectId: string
        taskId: string
        dependencies: TaskDependenciesPayload[]
    }) => {
        const response = await addTaskDependenciesApi(
            projectId,
            taskId,
            dependencies,
        )
        return response
    },
)

export const removeTaskDependencies = createAsyncThunkWithNotification(
    "tasks/removeTaskDependencies",
    async ({
        projectId,
        taskId,
        dependencies,
    }: {
        projectId: string
        taskId: string
        dependencies: string[]
    }) => {
        const response = await removeTaskDependenciesApi(
            projectId,
            taskId,
            dependencies,
        )
        return response
    },
)

export const getUserTaskReview = createAsyncThunkWithNotification(
    "tasks/getUserTaskReview",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getUserTaskReviewApi(projectId, taskId)
        return response
    },
)

export const updateTaskReview = createAsyncThunkWithNotification(
    "tasks/updateTaskReview",
    async ({
        projectId,
        taskId,
        payload,
    }: {
        projectId: string
        taskId: string
        payload: { status: TaskReviewStatus; comment: string }
    }) => {
        const response = await updateTaskReviewApi(projectId, taskId, payload)
        return response
    },
)

export const resetTaskStatus = createAsyncThunkWithNotification(
    "tasks/resetTaskStatus",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await resetTaskStatusApi(projectId, taskId)
        return response
    },
)

export const deleteTask = createAsyncThunkWithNotification(
    "tasks/deleteTask",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await deleteTaskApi(projectId, taskId)
        return response
    },
)

export const getTaskTags = createAsyncThunkWithNotification(
    "tasks/getTaskTags",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskTagsApi(projectId, taskId)
        return response
    },
)

export const setTaskTags = createAsyncThunkWithNotification(
    "tasks/setTaskTags",
    async ({
        projectId,
        taskId,
        tags,
    }: {
        projectId: string
        taskId: string
        tags: string[]
    }) => {
        const response = await setTaskTagsApi(projectId, taskId, tags)
        return response
    },
)

export const addTaskTags = createAsyncThunkWithNotification(
    "tasks/addTaskTags",
    async ({
        projectId,
        taskId,
        tags,
    }: {
        projectId: string
        taskId: string
        tags: string[]
    }) => {
        const response = await addTaskTagsApi(projectId, taskId, tags)
        return response
    },
)

export const removeTaskTags = createAsyncThunkWithNotification(
    "tasks/removeTaskTags",
    async ({
        projectId,
        taskId,
        tags,
    }: {
        projectId: string
        taskId: string
        tags: string[]
    }) => {
        const response = await removeTaskTagsApi(projectId, taskId, tags)
        return response
    },
)

export const getTaskActivities = createAsyncThunkWithNotification(
    "tasks/getTaskActivities",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskActivitiesApi(projectId, taskId)
        return response
    },
)

export const sendTaskDelayedNotifications = createAsyncThunkWithNotification(
    "tasks/sendTaskDelayedNotifications",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await sendTaskDelayedNotificationsApi(
            projectId,
            taskId,
        )
        return response
    },
)

export const getAllTasks = createAsyncThunkWithNotification(
    "tasks/getAllTasks",
    async ({
        projectId,
        pageSize,
        pageNumber,
    }: {
        projectId: string
        pageSize: number
        pageNumber: number
    }) => {
        const response = await getProjectTasksByIdApi(
            projectId,
            pageSize,
            pageNumber,
        )
        return response
    },
)

export const getMyAssignedTasks = createAsyncThunkWithNotification(
    "tasks/getMyAssignedTasks",
    async (projectId: string) => {
        const response = await getMyAssignedTasksApi(projectId)
        return response
    },
)

export const getTasksForReview = createAsyncThunkWithNotification(
    "tasks/getTasksForReview",
    async (projectId: string) => {
        const response = await getTasksForReviewApi(projectId)
        return response
    },
)

export const getMyFollowedTasks = createAsyncThunkWithNotification(
    "tasks/getMyFollowedTasks",
    async (projectId: string) => {
        const response = await getMyFollowedTasksApi(projectId)
        return response
    },
)

export const updateTaskDependency = createAsyncThunkWithNotification(
    "tasks/updateTaskDependency",
    async ({
        projectId,
        taskId,
        dependencyId,
        payload,
    }: {
        projectId: string
        taskId: string
        dependencyId: string
        payload: TaskDependenciesPayload
    }) => {
        const response = await updateTaskDependencyApi(
            projectId,
            taskId,
            dependencyId,
            payload,
        )
        return response
    },
)

export const removeTaskDependency = createAsyncThunkWithNotification(
    "tasks/removeTaskDependency",
    async ({
        projectId,
        taskId,
        dependencyId,
    }: {
        projectId: string
        taskId: string
        dependencyId: string
    }) => {
        const response = await removeTaskDependencyApi(
            projectId,
            taskId,
            dependencyId,
        )
        return response
    },
)

export const getTaskResources = createAsyncThunkWithNotification(
    "tasks/getTaskResources",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskResourcesApi(projectId, taskId)
        return response
    },
)

export const getTaskDependencies = createAsyncThunkWithNotification(
    "tasks/getTaskDependencies",
    async ({ projectId, taskId }: { projectId: string; taskId: string }) => {
        const response = await getTaskDependenciesApi(projectId, taskId)
        return response
    },
)

const mapRepliesHelper = (
    stateReplies: { [key: string]: TaskCommentJsonInterface[] },
    commentTrees: TaskCommentJsonInterface[] = [],
) => {
    commentTrees.forEach((comment) => {
        stateReplies[comment.id] = comment.replies
        mapRepliesHelper(stateReplies, comment.replies)
    })
}

interface TaskState {
    task: TaskJsonInterface
    taskResources: TaskResourcesJsonInterface
    taskDependencies: TaskDependencyJsonInterface[]
    tasks: TaskJsonInterface[]
    tasksTotalCount: number
    review: TaskReviewJsonInterface
    replies: { [key: string]: TaskCommentJsonInterface[] }
    status: ReducerStatus
    statusFilterValues: TaskStatus[]
    statusDetailFilterValues: TaskStatusDetail[]
    delayStatusFilterValues: TaskDelayStatus[]
    errors: FormErrors
}

const initialState: TaskState = {
    task: new Task().toJson(),
    tasks: [],
    tasksTotalCount: 0,
    taskResources: {
        assignees: { users: [], groups: [] },
        followers: { users: [], groups: [] },
        reviewers: [],
    },
    taskDependencies: [],
    review: new TaskReview().toJson(),
    replies: {},
    status: DEFAULT_REDUCER_STATUS,
    statusFilterValues: [],
    statusDetailFilterValues: [],
    delayStatusFilterValues: [],
    errors: {},
}

export const taskSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {}
        },
        setStatusFilterValues: (state, action) => {
            state.statusFilterValues = action.payload
        },
        setStatusDetailFilterValues: (state, action) => {
            state.statusDetailFilterValues = action.payload
        },
        setDelayStatusFilterValues: (state, action) => {
            state.delayStatusFilterValues = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTaskById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task = { ...state.task, ...action.payload.data.data }
        })
        builder.addCase(getTaskById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task = new Task().toJson()
        })

        builder.addCase(createTask.pending, (state) => {
            state.status.create = SliceStatus.LOADING
        })
        builder.addCase(createTask.fulfilled, (state, action) => {
            state.status.create = SliceStatus.IDLE
            state.task = action.payload.data.data
        })
        builder.addCase(createTask.rejected, (state, action) => {
            state.status.create = SliceStatus.FAILED
            state.task = new Task().toJson()
            state.errors = (action.payload as any).data
        })

        builder.addCase(getCommentReplies.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getCommentReplies.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.replies = {
                ...state.replies,
                [action.meta.arg.commentId]: action.payload.data.data,
            }
        })
        builder.addCase(getCommentReplies.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.replies = {}
        })
        builder.addCase(addComment.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(addComment.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            const commentId = action.meta.arg.commentId ?? ""
            if (commentId) {
                state.replies = {
                    ...state.replies,
                    [commentId]: [
                        ...(state.replies[commentId]
                            ? state.replies[commentId]
                            : []),
                        action.payload.data.data,
                    ],
                }
            } else {
                state.task.comments = [
                    ...state.task.comments,
                    action.payload.data.data,
                ]
            }
        })
        builder.addCase(addComment.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            state.replies = {}
            state.errors = (action.payload as any).data
        })
        builder.addCase(uploadAttachment.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(uploadAttachment.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.files = [
                ...state.task.files,
                ...action.payload.data.data,
            ]
        })
        builder.addCase(uploadAttachment.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.replies = {}
        })
        builder.addCase(deleteAttachment.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(deleteAttachment.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.files = state.task.files.filter(
                (file) => file.id !== action.payload.data.data,
            )
        })
        builder.addCase(deleteAttachment.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.replies = {}
        })
        builder.addCase(getUserTaskReview.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getUserTaskReview.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.review = action.payload.data.data
        })
        builder.addCase(getUserTaskReview.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.review = new TaskReview().toJson()
        })
        builder.addCase(updateTaskReview.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(updateTaskReview.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.reviews = state.task.reviews.map((review) =>
                review.id === action.payload.data.data.id
                    ? action.payload.data.data
                    : review,
            )
        })
        builder.addCase(updateTaskReview.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            // state.review = new TaskReview().toJson()
        })
        builder.addCase(resetTaskStatus.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(resetTaskStatus.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
        })
        builder.addCase(resetTaskStatus.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(deleteTask.pending, (state) => {
            state.status.delete = SliceStatus.LOADING
        })
        builder.addCase(deleteTask.fulfilled, (state) => {
            state.status.delete = SliceStatus.IDLE
            // @FIXME: remove task from store
        })
        builder.addCase(deleteTask.rejected, (state) => {
            state.status.delete = SliceStatus.FAILED
        })

        builder.addCase(getTaskAssigneesById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskAssigneesById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.assignees = action.payload.data.data.assignees
            state.task.groupsAssigned = action.payload.data.data.groupsAssigned
        })
        builder.addCase(getTaskAssigneesById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.assignees = []
            state.task.groupsAssigned = []
        })

        builder.addCase(getTaskFollowersById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskFollowersById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.followers = action.payload.data.data.followers
            state.task.groupFollowers = action.payload.data.data.groupFollowers
        })
        builder.addCase(getTaskFollowersById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.followers = []
        })
        builder.addCase(addTaskFollowersById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(addTaskFollowersById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.followers = action.payload.data.data.followers
            state.task.groupFollowers = action.payload.data.data.groupFollowers
        })
        builder.addCase(addTaskFollowersById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.followers = []
        })
        builder.addCase(removeTaskFollowersById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(removeTaskFollowersById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.followers = action.payload.data.data.followers
            state.task.groupFollowers = action.payload.data.data.groupFollowers
        })
        builder.addCase(removeTaskFollowersById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.followers = []
        })
        builder.addCase(getTaskCommentsById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskCommentsById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.comments = action.payload.data.data
            mapRepliesHelper(state.replies, action.payload.data.data)
        })
        builder.addCase(getTaskCommentsById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.comments = []
            state.replies = {}
        })

        builder.addCase(getTaskReviewsById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskReviewsById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.reviews = action.payload.data.data
        })
        builder.addCase(getTaskReviewsById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.reviews = []
        })

        builder.addCase(getTaskDependenciesById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskDependenciesById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.dependencies = action.payload.data.data
        })
        builder.addCase(getTaskDependenciesById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.dependencies = []
        })

        builder.addCase(getTaskAttachmentsById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskAttachmentsById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.files = action.payload.data.data
        })
        builder.addCase(getTaskAttachmentsById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.files = []
        })
        builder.addCase(addTaskReviewsById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(addTaskReviewsById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.reviews = action.payload.data.data.reviews
        })
        builder.addCase(addTaskReviewsById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.reviews = []
        })
        builder.addCase(removeTaskReviewsById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(removeTaskReviewsById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.reviews = action.payload.data.data.reviews
        })
        builder.addCase(removeTaskReviewsById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.reviews = []
        })
        builder.addCase(addTaskAssigneesById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(addTaskAssigneesById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.assignees = action.payload.data.data.assignees
            state.task.groupsAssigned = action.payload.data.data.groupsAssigned
        })
        builder.addCase(addTaskAssigneesById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.assignees = []
            state.task.groupsAssigned = []
        })
        builder.addCase(removeTaskAssigneesById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(removeTaskAssigneesById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.assignees = action.payload.data.data.assignees
            state.task.groupsAssigned = action.payload.data.data.groupAssignees
        })
        builder.addCase(removeTaskAssigneesById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.assignees = []
            state.task.groupsAssigned = []
        })
        builder.addCase(getTaskTags.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskTags.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.tags = action.payload.data.data
        })
        builder.addCase(getTaskTags.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.tags = []
        })
        builder.addCase(addTaskTags.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(addTaskTags.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.tags = action.payload.data.data.tags
        })
        builder.addCase(addTaskTags.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.tags = []
        })
        builder.addCase(removeTaskTags.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(removeTaskTags.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.tags = action.payload.data.data.tags
        })
        builder.addCase(removeTaskTags.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            state.task.tags = []
        })
        builder.addCase(addTaskDependencies.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(addTaskDependencies.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.dependencies = [
                ...state.task.dependencies,
                ...action.payload.data.data,
            ]
        })
        builder.addCase(addTaskDependencies.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            state.errors = (action.payload as any).data
        })
        builder.addCase(removeTaskDependencies.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(removeTaskDependencies.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.dependencies = state.task.dependencies.filter(
                (dependency) =>
                    !action.payload.data.data.includes(dependency.id),
            )
        })
        builder.addCase(removeTaskDependencies.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(setTaskTags.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(setTaskTags.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            // state.task.tags = action.payload.data.data
        })
        builder.addCase(setTaskTags.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
            // state.task.tags = []
        })
        builder.addCase(getTaskActivities.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskActivities.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.task.activities = action.payload.data.data
        })
        builder.addCase(getTaskActivities.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.task.activities = []
        })
        builder.addCase(sendTaskDelayedNotifications.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(
            sendTaskDelayedNotifications.fulfilled,
            (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.task = { ...state.task, ...action.payload.data.data }
            },
        )
        builder.addCase(sendTaskDelayedNotifications.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(getMyAssignedTasks.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getMyAssignedTasks.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.tasks = action.payload.data.data
        })
        builder.addCase(getMyAssignedTasks.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.tasks = []
        })
        builder.addCase(getTasksForReview.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTasksForReview.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.tasks = action.payload.data.data
        })
        builder.addCase(getTasksForReview.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.tasks = []
        })
        builder.addCase(getMyFollowedTasks.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getMyFollowedTasks.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.tasks = action.payload.data.data
        })
        builder.addCase(getMyFollowedTasks.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.tasks = []
        })
        builder.addCase(getAllTasks.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getAllTasks.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.tasks = action.payload.data.data
            state.tasksTotalCount = action.payload.data.totalCount
        })
        builder.addCase(getAllTasks.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.tasks = []
        })
        builder.addCase(updateTaskDependency.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(updateTaskDependency.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.task.dependencies = state.task.dependencies.map(
                (dependency) =>
                    dependency.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : dependency,
            )
        })
        builder.addCase(updateTaskDependency.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(removeTaskDependency.pending, (state) => {
            state.status.delete = SliceStatus.LOADING
        })
        builder.addCase(removeTaskDependency.fulfilled, (state, action) => {
            state.status.delete = SliceStatus.IDLE
            state.task.dependencies = state.task.dependencies.filter(
                (dependency) => dependency.id !== action.payload.data.data.id,
            )
        })
        builder.addCase(removeTaskDependency.rejected, (state) => {
            state.status.delete = SliceStatus.FAILED
        })
        builder.addCase(getProjectTasksTreesById.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getProjectTasksTreesById.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
        })
        builder.addCase(getProjectTasksTreesById.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
        })
        builder.addCase(updateTaskById.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(updateTaskById.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
        })
        builder.addCase(updateTaskById.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(getTaskResources.pending, (state) => {
            state.status.delete = SliceStatus.LOADING
        })
        builder.addCase(getTaskResources.fulfilled, (state, action) => {
            state.status.delete = SliceStatus.IDLE
            state.taskResources = action.payload.data.data
        })
        builder.addCase(getTaskResources.rejected, (state) => {
            state.status.delete = SliceStatus.FAILED
            state.taskResources = {
                assignees: { users: [], groups: [] },
                followers: { users: [], groups: [] },
                reviewers: [],
            }
        })
        builder.addCase(getTaskDependencies.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getTaskDependencies.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.taskDependencies = action.payload.data.data
        })
        builder.addCase(getTaskDependencies.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.taskDependencies = []
        })
    },
})

const selectTaskRaw = (state: RootState) => state.tasks.task
const selectRepliesRaw = (state: RootState) => state.tasks.replies
const selectTaskReviewRaw = (state: RootState) => state.tasks.review
const selectTasksRaw = (state: RootState) => state.tasks.tasks
const selectTaskDependenciesRaw = (state: RootState) =>
    state.tasks.taskDependencies

export const selectTask = createSelector(
    [selectTaskRaw],
    (task) => new Task(task),
)

export const selectTaskReview = createSelector(
    [selectTaskReviewRaw],
    (review) => new TaskReview(review),
)

export const selectReplies = (id: string) =>
    createSelector([selectRepliesRaw], (replies) => {
        try {
            return replies[id].map(
                (reply: TaskCommentJsonInterface) => new TaskComment(reply),
            )
        } catch (error) {
            return []
        }
    })

export const selectTasks = createSelector([selectTasksRaw], (tasks) =>
    tasks.map((task) => new Task(task)),
)

const selectResourcesRaw = (state: RootState) => state.tasks.taskResources
export const selectResources = createSelector(
    [selectResourcesRaw],
    (resources) => ({
        assignees: {
            users: resources.assignees.users.map((user) => new User(user)),
            groups: resources.assignees.groups.map(
                (group) => new PermissionGroup(group),
            ),
        },
        followers: {
            users: resources.followers.users.map((user) => new User(user)),
            groups: resources.followers.groups.map(
                (group) => new PermissionGroup(group),
            ),
        },
        reviewers: resources.reviewers.map((reviewer) => new User(reviewer)),
    }),
)

export const selectTaskDependencies = createSelector(
    [selectTaskDependenciesRaw],
    (predecessors) =>
        predecessors.map((predecessor) => new TaskDependency(predecessor)),
)

export const {
    clearErrors,
    setDelayStatusFilterValues,
    setStatusDetailFilterValues,
    setStatusFilterValues,
} = taskSlice.actions

export default taskSlice.reducer
