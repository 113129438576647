export interface GeneralStatistics {
    totalTasks: number
    projectProgress: number
}

export interface GeneralDelayStatistics {
    tasksOnTime: number
    tasksDelayedStart: number
    tasksDelayedEnd: number
    tasksDelayedBoth: number
}

export interface GeneralQualityStatistics {
    tasksApproved: number
    tasksWithIssues: number
    tasksApprovedWithIssues: number
    tasksApprovedWithoutIssues: number
}

export interface TaskPerUserStatistics {
    userId: string
    user: string
    tasksCount: number
    tasksApproved: number
    tasksWithIssues: number
    tasksApprovedWithIssues: number
    tasksApprovedWithoutIssues: number
    tasksOnTime: number
    tasksDelayedStart: number
    tasksDelayedEnd: number
    tasksDelayedBoth: number
}

export interface TaskPerGroupStatistics {
    groupId: string
    group: string
    tasksCount: number
    tasksApproved: number
    tasksWithIssues: number
    tasksApprovedWithIssues: number
    tasksApprovedWithoutIssues: number
    tasksOnTime: number
    tasksDelayedStart: number
    tasksDelayedEnd: number
    tasksDelayedBoth: number
}

export interface TaskStatistics {
    tasksCount: number
    tasksApproved: number
    tasksWithIssues: number
    tasksApprovedWithIssues: number
    tasksApprovedWithoutIssues: number
    tasksOnTime: number
    tasksDelayedStart: number
    tasksDelayedEnd: number
    tasksDelayedBoth: number
}

export enum TASK_STATISTICS_TYPES {
    APPROVED = "approved",
    WITH_ISSUES = "with_issues",
    APPROVED_WITH_ISSUES = "approved_with_issues",
    APPROVED_WITHOUT_ISSUES = "approved_without_issues",
    ON_TIME = "on_time",
    DELAYED_START = "delayed_start",
    DELAYED_END = "delayed_end",
    DELAYED_BOTH = "delayed_both",
}
