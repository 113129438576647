export enum SliceStatus {
    IDLE = "idle",
    LOADING = "loading",
    FAILED = "failed",
}

export enum CalendarZoomModes {
    Day = 1,
    Week = 2,
    Month = 3,
    Quarter = 4,
}

export interface ReducerStatus {
    read: SliceStatus
    create: SliceStatus
    update: SliceStatus
    delete: SliceStatus
}

export type FormErrors = { [key: string]: string[] }
