import { UserPricingPlan } from "@/models/Pricing"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { UserPricingTableActions } from "./UserPricingTableActions"

interface UserPricingPlanTableProps {
    usersPricingPlan: UserPricingPlan[]
}

export const UsersPricingPlanTable: React.FC<UserPricingPlanTableProps> = ({
    usersPricingPlan,
}) => {
    const { t } = useTranslation()
    const columns = useMemo<MRT_ColumnDef<UserPricingPlan>[]>(
        () => [
            {
                accessorKey: "user.profilePicture.path",
                header: "",
                enableColumnActions: false,
                enableColumnFilters: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({ renderedCellValue, row }) => (
                    <img
                        src={row.original.user.profilePicture.path}
                        alt={row.original.user.fullName}
                        className="w-10 h-10 rounded-full m-auto"
                    />
                ),
            },
            {
                accessorKey: "user.fullName",
                header: t("user"),
            },
            {
                accessorKey: "user.email",
                header: t("email"),
            },
            {
                accessorKey: "pricingPlan.name",
                header: t("pricing_plan"),
            },
            {
                accessorKey: "expiresAt",
                header: t("expires_at"),
                filterVariant: "date-range",

                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {t("intlDateTime", {
                            val: row.original.expiresAt,
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </div>
                ),
            },
            {
                accessorKey: "numberOfUsers",
                header: t("number_of_users"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.numberOfUsers}/
                        {row.original.pricingPlan.numberOfUsers}
                    </div>
                ),
            },
            {
                accessorKey: "numberOfProjects",
                header: t("number_of_project"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.numberOfProjects}/
                        {row.original.pricingPlan.numberOfProjects}
                    </div>
                ),
            },
            {
                accessorKey: "numberOfEmails",
                header: t("number_of_emails"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.numberOfEmails}/
                        {row.original.pricingPlan.numberOfEmails}{" "}
                        {t("per_month")}
                    </div>
                ),
            },
            {
                accessorKey: "storage",
                header: t("storage"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.storage.toFixed(2)}/
                        {row.original.pricingPlan.storage} GB
                    </div>
                ),
            },
            {
                id: "actions",
                header: "",
                enableColumnActions: false,
                enableColumnFilters: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({ row }) => (
                    <UserPricingTableActions userPricingPlan={row.original} />
                ),
            },
        ],
        [],
    )
    const table = useMantineReactTable<UserPricingPlan>({
        columns,
        data: usersPricingPlan,
        positionPagination: "top",
    })
    return <MantineReactTable table={table} />
}
