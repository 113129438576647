import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React from "react"

interface ProfilePictureInputProps {
    image: File | null
    setImage: React.Dispatch<React.SetStateAction<File | null>>
}

export const ProfilePictureInput: React.FC<ProfilePictureInputProps> = ({
    image,
    setImage,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    return (
        <div className="flex flex-col justify-center items-center my-5 mb-10">
            <h2 className="font-bold my-2">{t("pick_a_profile_picture")}</h2>
            <p className="font-light mb-2">
                {t("have_a_favorite_selfie")}? {t("upload_it_now")}!
            </p>
            <div
                onClick={() => (image ? "" : inputRef.current?.click())}
                className={`w-[200px] h-[200px] ${
                    image ? "border-primary-300" : "border-2"
                } border-primary-100 rounded-3xl text-primary-100 flex justify-center items-center ${
                    image ? "" : "p-8 cursor-pointer"
                } hover:text-primary-300 hover:border-primary-300 transition`}
            >
                {image && (
                    <div className="w-full h-full relative">
                        <div
                            onClick={() => setImage(null)}
                            className="absolute -top-2 -right-2 w-[30px] h-[30px] bg-primary-300 rounded-full cursor-pointer hover:bg-primary-100 transition flex justify-center items-center"
                        >
                            <FontAwesomeIcon
                                icon={"times"}
                                className="text-secondary-100"
                            />
                        </div>
                        <img
                            src={URL.createObjectURL(image)}
                            alt=""
                            className="w-full h-full object-cover rounded-3xl border-2 border-primary-300"
                        />
                    </div>
                )}
                {!image && (
                    <div className="flex flex-col justify-center items-center">
                        <FontAwesomeIcon
                            icon={"cloud-arrow-up"}
                            className="text-8xl"
                        />
                        <p className="text-center">
                            {t("upload_profile_picture")}
                        </p>
                    </div>
                )}
                <input
                    ref={inputRef}
                    type="file"
                    className="hidden"
                    onChange={(e) => {
                        if (e.target.files) {
                            setImage(e.target.files[0])
                        }
                    }}
                />
            </div>
        </div>
    )
}
