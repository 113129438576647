import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { TaskReviewStatus } from "@/models/Task"

interface TaskReviewStatusBarProps {
    reviewStatus: TaskReviewStatus
}

export const TaskReviewStatusBar: React.FC<TaskReviewStatusBarProps> = ({
    reviewStatus,
}) => {
    const statusMapping = {
        [TaskReviewStatus.PENDING]: {
            className: "bg-yellow-500",
            icon: "play",
        },
        [TaskReviewStatus.REJECTED]: {
            className: "bg-red-500",
            icon: "times",
        },
        [TaskReviewStatus.APPROVED]: {
            className: "bg-green-500",
            icon: "check",
        },
        other: {
            className: "bg-primary-100",
            icon: "times",
        },
    }
    const className = reviewStatus
        ? statusMapping[reviewStatus].className
        : statusMapping.other.className
    const icon = reviewStatus
        ? statusMapping[reviewStatus].icon
        : statusMapping.other.icon
    return (
        <div
            className={`${className} flex items-center gap-2 px-2 py-1 rounded-full w-fit text-secondary-100 text-xs`}
        >
            <FontAwesomeIcon icon={icon as IconProp} />
            <p>{t(reviewStatus ? reviewStatus : "no_review")}</p>
        </div>
    )
}
