import { Popup, POPUP_TYPES } from "@/components/Menus/Popup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useRef, useState } from "react"
import { ProjectStats } from "./LatestProjects"

interface LatestProjectsActionsProps {
    project: ProjectStats
    setSelectedProject: React.Dispatch<
        React.SetStateAction<ProjectStats | null>
    >
    setEditProjectModal: React.Dispatch<React.SetStateAction<boolean>>
    setDeleteProjectModal: React.Dispatch<React.SetStateAction<boolean>>
    setFreezeProjectModal: React.Dispatch<React.SetStateAction<boolean>>
    setUnfreezeProjectModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const LatestProjectsActions: React.FC<LatestProjectsActionsProps> = ({
    project,
    setSelectedProject,
    setEditProjectModal,
    setDeleteProjectModal,
    setFreezeProjectModal,
    setUnfreezeProjectModal,
}) => {
    const ellipsisRef = useRef<HTMLDivElement>(null)
    const [isPopupShown, setIsPopupShown] = useState(false)
    return (
        <div className="flex items-center justify-center">
            <div
                className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                ref={ellipsisRef}
                onClick={() => {
                    setSelectedProject(project)
                    setIsPopupShown(!isPopupShown)
                }}
            >
                <FontAwesomeIcon icon="ellipsis-v" className="cursor-pointer" />
            </div>
            <Popup
                parentRef={ellipsisRef}
                type={POPUP_TYPES.LEFT_CLICK}
                isShown={isPopupShown}
                closePopup={() => setIsPopupShown(false)}
            >
                <div className="">
                    <div
                        onClick={() => {
                            setEditProjectModal(true)
                            setIsPopupShown(false)
                        }}
                        className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                    >
                        <FontAwesomeIcon icon="edit" />
                        <p>{t("edit_project")}</p>
                    </div>
                    {!project.project.isFrozen && (
                        <div
                            onClick={() => {
                                setFreezeProjectModal(true)
                                setIsPopupShown(false)
                            }}
                            className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                        >
                            <FontAwesomeIcon icon="snowflake" />
                            <p>{t("freeze_project")}</p>
                        </div>
                    )}
                    {project.project.isFrozen && (
                        <div
                            onClick={() => {
                                setUnfreezeProjectModal(true)
                                setIsPopupShown(false)
                            }}
                            className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                        >
                            <FontAwesomeIcon icon="fire" />
                            <p>{t("unfreeze_project")}</p>
                        </div>
                    )}
                    <div
                        onClick={() => {
                            setDeleteProjectModal(true)
                            setIsPopupShown(false)
                        }}
                        className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                    >
                        <FontAwesomeIcon icon="trash" />
                        <p>{t("delete_project")}</p>
                    </div>
                </div>
            </Popup>
        </div>
    )
}
