import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

interface SpoilerProps {
    title: string
    children: React.ReactNode
    icon?: IconProp
}

export const Spoiler: React.FC<SpoilerProps> = ({ title, children, icon }) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    return (
        <div className="flex flex-col w-full">
            <div className={`flex justify-between w-full items-center h-full bg-primary-100 text-secondary-100 py-2 px-4 ${expanded ? "rounded-t-lg" : "rounded-lg"}`} onClick={() => setExpanded(!expanded)}>
                <div className="flex items-center gap-2 h-full">
                    {icon && <FontAwesomeIcon icon={icon} size="sm" />}
                    <h1 className="text-lg font-bold">{title}</h1>
                </div>
                <FontAwesomeIcon
                    icon={expanded ? "chevron-up" : "chevron-down"}
                    onClick={() => setExpanded(!expanded)}
                />
            </div>
            <div className="flex flex-col gap-2 bg-secondary-100 text-primary-300 border p-2 rounded-b-lg transition" style={{
                height: expanded ? "auto" : "0px",
                overflow: expanded ? "visible" : "hidden",
                padding: expanded ? "1rem" : "0",
            }}>
                {children}
            </div>
        </div>
    )
}
