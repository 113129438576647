import { TaskStatus } from "@/models/Task"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"

interface TaskStatusBarProps {
    taskStatus: TaskStatus
}

export const TaskStatusBar: React.FC<TaskStatusBarProps> = ({ taskStatus }) => {
    const statusMapping: {
        [key in TaskStatus]: {
            className: string
            icon: string
        }
    } = {
        [TaskStatus.NOT_STARTED]: {
            className: "bg-gray-400 text-secondary-100",
            icon: "circle",
        },
        [TaskStatus.IN_PROGRESS]: {
            className: "bg-secondary-400 text-primary-100",
            icon: "magnifying-glass",
        },
        [TaskStatus.COMPLETED]: {
            className: "bg-green-400 text-secondary-100",
            icon: "check",
        },
    }

    return (
        taskStatus && (
            <div
                className={`${statusMapping[taskStatus].className} flex items-center gap-2 rounded-full p-2 h-fit text-sm w-fit`}
            >
                <FontAwesomeIcon
                    icon={statusMapping[taskStatus].icon as IconProp}
                />
                <p className="inline text-nowrap">{t(taskStatus)}</p>
            </div>
        )
    )
}
