import { CheckboxInput } from "@/components/Inputs/CheckboxInput"
import { MODULE_PERMISSIONS, PermissionType } from "@/models/Permission"
import { t } from "i18next"
import { useEffect, useState } from "react"

interface ModulePermissionProps {
    permissions: PermissionType[]
    setPermissions: (permissions: PermissionType[]) => void
}

export const ModulePermission: React.FC<ModulePermissionProps> = ({
    permissions,
    setPermissions,
}) => {
    const [allSelected, setAllSelected] = useState<boolean>(false)
    useEffect(() => {
        if (permissions.includes(MODULE_PERMISSIONS.All.All)) {
            setAllSelected(true)
        } else {
            setAllSelected(false)
        }
    }, [permissions])
    useEffect(() => {
        if (allSelected) {
            setPermissions([...Object.values(MODULE_PERMISSIONS.All)])
        }
    }, [allSelected])
    return (
        <div className="flex flex-col gap-2">
            {Object.entries(MODULE_PERMISSIONS).map(([key, module]) => (
                <div className="flex gap-2 border-b items-center" key={key}>
                    <p className="font-bold">{t(key)}:</p>
                    <CheckboxInput
                        options={Object.values(module).map((value) => ({
                            label: t(value),
                            value: value,
                        }))}
                        value={permissions}
                        onChange={
                            setPermissions as (permissions: string[]) => void
                        }
                    />
                </div>
            ))}
        </div>
    )
}
