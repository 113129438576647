import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Amount } from "@/components/Other/Amount"
import { Currency } from "@/components/Other/Currency"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getGeneralBudgetInfo } from "../financeSlice"

interface BudgetGeneralInfoProps {}

export const BudgetGeneralInfo: FC<BudgetGeneralInfoProps> = () => {
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId
    const { t } = useTranslation()
    const status = useAppSelector((state) => state.finance.status)
    const generalBudgetInfo = useAppSelector(
        (state) => state.finance.generalBudgetInfo,
    )
    useEffect(() => {
        dispatch(getGeneralBudgetInfo(projectId))
    }, [])
    return (
        <div className="w-full">
            <WithLoader
                statuses={[status.read]}
                title={t("loading_budgets")}
                className=""
            >
                <div className="flex justify-between w-full gap-4 sm:flex-wrap lg:flex-wrap">
                    {generalBudgetInfo.length > 0 ? (
                        generalBudgetInfo.map((info) => (
                            <div
                                className="bg-secondary-100 shadow-md rounded-lg p-6 w-full relative overflow-hidden"
                                key={info.currency}
                            >
                                <div className="mb-4 flex gap-2">
                                    <span className="font-semibold">
                                        {t("total_budget")}:
                                    </span>
                                    <span className="flex gap-1">
                                        <Amount
                                            amount={info.totalBudget}
                                            currency={info.currency}
                                        />
                                    </span>
                                </div>
                                <div className="mb-4 flex gap-2">
                                    <span className="font-semibold">
                                        {t("engaged_budget")}:
                                    </span>
                                    <span className="flex gap-1">
                                        <Amount
                                            amount={info.engagedBudget}
                                            currency={info.currency}
                                        />
                                    </span>
                                </div>

                                <div className="mb-4 flex gap-2">
                                    <span className="font-semibold">
                                        {t("total_articles")}:
                                    </span>
                                    <span className="flex gap-1">
                                        {info.totalArticles}
                                    </span>
                                </div>
                                <div className="mb-4 flex gap-2">
                                    <span className="font-semibold">
                                        {t("total_items")}:
                                    </span>
                                    <span className="flex gap-1">
                                        {info.totalItems}
                                    </span>
                                </div>
                                <Currency
                                    currency={info.currency}
                                    classnames="absolute top-0 right-0 opacity-30 text-[1000%] text-secondary-400 select-none z-0"
                                />
                            </div>
                        ))
                    ) : (
                        <div className="bg-secondary-100 shadow-md rounded-lg p-6 w-full relative overflow-hidden flex flex-col items-center justify-center">
                            <FontAwesomeIcon
                                icon="exclamation-triangle"
                                size="3x"
                            />
                            <h2 className="text-center font-bold">
                                {t("no_budgets_created_yet")}
                            </h2>
                        </div>
                    )}
                </div>
            </WithLoader>
        </div>
    )
}
