import { useAppDispatch } from "@/app/hooks"
import { useEffect } from "react"
import { getLatestUsers } from "../adminSlice"
import { LatestProjects } from "./LatestProjects"

interface AdminProjectsPageProps {}

export const AdminProjectsPage: React.FC<AdminProjectsPageProps> = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getLatestUsers({}))
    }, [dispatch])
    return (
        <div className="w-full h-full bg-secondary-100 rounded-lg shadow-lg p-4 overflow-hidden flex flex-col">
            <LatestProjects />
        </div>
    )
}
