import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Form, SubmitType } from "@/features/Form/Form"
import { PricingPlan } from "@/models/Pricing"
import { useTranslation } from "react-i18next"
import { clearErrors, deletePricingPlan } from "../pricingSlice"

interface DeletePricingPlanFormProps {
    pricingPlan: PricingPlan
    onCancel: () => void
}

export const DeletePricingPlanForm: React.FC<DeletePricingPlanFormProps> = ({
    pricingPlan,
    onCancel,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.pricing.status)

    return (
        <Form
            loadingTitle={t("deleting_pricing_plan")}
            onSubmit={async () => {
                const { type } = await dispatch(
                    deletePricingPlan(pricingPlan.id),
                )
                if (type === deletePricingPlan.fulfilled.type) {
                    onCancel()
                }
            }}
            clearErrors={clearErrors}
            statuses={[status.delete]}
            submitType={SubmitType.Delete}
            onCancel={onCancel}
        >
            <div className="flex flex-col gap-4">
                <h1 className="text-lg font-bold">
                    {t("deleting_pricing_plan", {
                        pricingPlan: pricingPlan?.name,
                    })}
                </h1>
                <div>
                    <p>
                        {t("delete_pricing_plan_warning", {
                            pricingPlan: pricingPlan?.name,
                        })}
                    </p>
                </div>
            </div>
        </Form>
    )
}
