import { t } from "i18next"
import { createBrowserRouter } from "react-router-dom"
import App from "./App"
import { AdminHome } from "./features/Admin/Home/"
import { Pricing } from "./features/Admin/Pricing"
import { AdminProjectsPage } from "./features/Admin/Projects"
import { AdminUsersPage } from "./features/Admin/Users"
import { Login } from "./features/Authentication/Login"
import { Register } from "./features/Authentication/Register"
import { Profile } from "./features/Profile/Profile"
import { Details } from "./features/Projects/Details/Details"
import { BrowserBimFileTable } from "./features/Projects/FileManager/Browsers/BrowserBimFileTable"
import { BrowserDefault } from "./features/Projects/FileManager/Browsers/BrowserDefault"
import { BrowserFolder } from "./features/Projects/FileManager/Browsers/BrowserFolder"
import { BrowserMyFiles } from "./features/Projects/FileManager/Browsers/BrowserMyFiles"
import { BrowserSharedFiles } from "./features/Projects/FileManager/Browsers/BrowserSharedFiles"
import { BrowserWithFilter } from "./features/Projects/FileManager/Browsers/BrowserWithFilter"
import { FileManager } from "./features/Projects/FileManager/FileManager"
import { Articles } from "./features/Projects/Finance/Articles/Articles"
import { Budget } from "./features/Projects/Finance/Budgets/Budget"
import { Budgets } from "./features/Projects/Finance/Budgets/Budgets"
import { Finance } from "./features/Projects/Finance/Finance"
import { Ifc } from "./features/Projects/Ifc/Ifc"
import { Planning } from "./features/Projects/Plannings/Planning"
import { Plannings } from "./features/Projects/Plannings/Plannings"
import { Project } from "./features/Projects/Project"
import { Projects } from "./features/Projects/Projects"
import { Statistics } from "./features/Projects/Statistics/Statistics"
import { StatisticsIndividual } from "./features/Projects/Statistics/StatisticsIndividual"
import { Task } from "./features/Projects/Tasks/Task"
import { TaskForm } from "./features/Projects/Tasks/TaskForm"
import { TaskDependenciesForm } from "./features/Projects/Tasks/TaskForms/TaskDependenciesForm"
import { TaskGeneralForm } from "./features/Projects/Tasks/TaskForms/TaskGeneralForm"
import { TaskResourcesForm } from "./features/Projects/Tasks/TaskForms/TaskResourcesForm"
import { TaskTagsForm } from "./features/Projects/Tasks/TaskForms/TaskTagsForm"
import { TasksTable } from "./features/Projects/Tasks/TaskTable"
import { Tasks } from "./features/Projects/Tasks/Tasks"
import { UserManagement } from "./features/Projects/UserManagement/UserManagement"
import { UserNotifications } from "./features/UserNotification/UserNotifications"
import { ProjectFileTypeEnum } from "./models/File"
import { Admin } from "./pages/Admin"
import Authentication from "./pages/Authentication"
import { Dashboard } from "./pages/Dashboard"
import { Invitation } from "./pages/Invitation"
import { LandingPage } from "./pages/LandingPage/LandingPage"
import { ResetPassword } from "./pages/ResetPassword"
import { VerifyEmail } from "./pages/VerifyEmail"

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <LandingPage />,
            },
            {
                path: "/invitation",
                element: <Invitation />,
            },
            {
                path: "/verify-email",
                element: <VerifyEmail />,
            },
            {
                path: "/reset-password",
                element: <ResetPassword />,
            },
            {
                path: "/auth",
                element: <Authentication />,
                children: [
                    {
                        path: "/auth/login",
                        element: <Login />,
                    },
                    {
                        path: "/auth/register",
                        element: <Register />,
                    },
                ],
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
                children: [
                    {
                        path: "/dashboard/projects",
                        element: <Projects />,
                    },
                    {
                        path: "/dashboard/projects/:projectId",
                        element: <Project />,
                        children: [
                            {
                                path: "/dashboard/projects/:projectId/details",
                                element: <Details />,
                            },
                            {
                                path: "/dashboard/projects/:projectId/plannings",
                                element: <Plannings />,
                                children: [],
                            },
                            {
                                path: "/dashboard/projects/:projectId/plannings/:planningId",
                                element: <Planning />,
                                children: [
                                    {
                                        path: "/dashboard/projects/:projectId/plannings/:planningId/tasks/:taskId/edit",
                                        element: (
                                            <TaskForm
                                                tabs={[
                                                    {
                                                        name: t("general"),
                                                        route: `general`,
                                                        icon: "info-circle",
                                                    },
                                                    {
                                                        name: t("resources"),
                                                        route: `resources`,
                                                        icon: "users",
                                                    },
                                                    {
                                                        name: t("dependencies"),
                                                        route: `dependencies`,
                                                        icon: "link",
                                                    },
                                                ]}
                                            />
                                        ),
                                        children: [
                                            {
                                                path: "/dashboard/projects/:projectId/plannings/:planningId/tasks/:taskId/edit/general",
                                                element: <TaskGeneralForm />,
                                            },
                                            {
                                                path: "/dashboard/projects/:projectId/plannings/:planningId/tasks/:taskId/edit/resources",
                                                element: <TaskResourcesForm />,
                                            },
                                            {
                                                path: "/dashboard/projects/:projectId/plannings/:planningId/tasks/:taskId/edit/dependencies",
                                                element: (
                                                    <TaskDependenciesForm />
                                                ),
                                            },
                                            {
                                                path: "/dashboard/projects/:projectId/plannings/:planningId/tasks/:taskId/edit/tags",
                                                element: <TaskTagsForm />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "/dashboard/projects/:projectId/users",
                                element: <UserManagement />,
                            },
                            {
                                path: "/dashboard/projects/:projectId/tasks",
                                element: <Tasks />,
                                children: [
                                    {
                                        path: "/dashboard/projects/:projectId/tasks/",
                                        element: <TasksTable />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/tasks/:taskId/",
                                        element: <Task />,
                                        children: [
                                            {
                                                path: "/dashboard/projects/:projectId/tasks/:taskId/edit",
                                                element: (
                                                    <TaskForm
                                                        tabs={[
                                                            {
                                                                name: t(
                                                                    "general",
                                                                ),
                                                                route: `general`,
                                                                icon: "info-circle",
                                                            },
                                                            {
                                                                name: t(
                                                                    "resources",
                                                                ),
                                                                route: `resources`,
                                                                icon: "users",
                                                            },
                                                            {
                                                                name: t(
                                                                    "dependencies",
                                                                ),
                                                                route: `dependencies`,
                                                                icon: "link",
                                                            },
                                                        ]}
                                                    />
                                                ),
                                                children: [
                                                    {
                                                        path: "/dashboard/projects/:projectId/tasks/:taskId/edit/general",
                                                        element: (
                                                            <TaskGeneralForm />
                                                        ),
                                                    },
                                                    {
                                                        path: "/dashboard/projects/:projectId/tasks/:taskId/edit/resources",
                                                        element: (
                                                            <TaskResourcesForm />
                                                        ),
                                                    },
                                                    {
                                                        path: "/dashboard/projects/:projectId/tasks/:taskId/edit/dependencies",
                                                        element: (
                                                            <TaskDependenciesForm />
                                                        ),
                                                    },
                                                    {
                                                        path: "/dashboard/projects/:projectId/tasks/:taskId/edit/tags",
                                                        element: (
                                                            <TaskTagsForm />
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "/dashboard/projects/:projectId/finance",
                                element: <Finance />,
                                children: [
                                    {
                                        path: "/dashboard/projects/:projectId/finance/budgets",
                                        element: <Budgets />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/finance/budgets/:budgetId",
                                        element: <Budget />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/finance/articles",
                                        element: <Articles />,
                                    },
                                ],
                            },
                            {
                                path: "/dashboard/projects/:projectId/3d-viewer",
                                element: <Ifc />,
                            },
                            {
                                path: "/dashboard/projects/:projectId/file-manager",
                                element: <FileManager />,
                                children: [
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager",
                                        element: <BrowserDefault />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/:folderId",
                                        element: <BrowserFolder />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/my-files",
                                        element: <BrowserMyFiles />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/shared-files",
                                        element: <BrowserSharedFiles />,
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/images",
                                        element: (
                                            <BrowserWithFilter
                                                fileType={
                                                    ProjectFileTypeEnum.PHOTO
                                                }
                                                header="images"
                                                loadingMessage="loading_images"
                                            />
                                        ),
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/ifc-files",
                                        element: (
                                            <BrowserWithFilter
                                                fileType={
                                                    ProjectFileTypeEnum.IFC
                                                }
                                                header="ifc_files"
                                                loadingMessage="loading_ifc_files"
                                            />
                                        ),
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/pdf-files",
                                        element: (
                                            <BrowserWithFilter
                                                fileType={
                                                    ProjectFileTypeEnum.PDF
                                                }
                                                header="pdf_files"
                                                loadingMessage="loading_pdf_files"
                                            />
                                        ),
                                    },
                                    {
                                        path: "/dashboard/projects/:projectId/file-manager/bim-file-table",
                                        element: <BrowserBimFileTable />,
                                    },
                                ],
                            },
                            {
                                path: "/dashboard/projects/:projectId/statistics",
                                element: <Statistics />,
                            },
                            {
                                path: "/dashboard/projects/:projectId/statistics/users/:userId",
                                element: <StatisticsIndividual group={false} />,
                            },
                            {
                                path: "/dashboard/projects/:projectId/statistics/groups/:groupId",
                                element: <StatisticsIndividual group={true} />,
                            },
                        ],
                    },
                    {
                        path: "/dashboard/notifications",
                        element: <UserNotifications />,
                    },
                    {
                        path: "/dashboard/profile",
                        element: <Profile />,
                    },
                    {
                        path: "/dashboard/settings",
                        element: <div />,
                    },
                ],
            },
            {
                path: "/admin",
                element: <Admin />,
                children: [
                    {
                        path: "/admin/home",
                        element: <AdminHome />,
                    },
                    {
                        path: "/admin/users",
                        element: <AdminUsersPage />,
                    },
                    {
                        path: "/admin/projects",
                        element: <AdminProjectsPage />,
                    },
                    {
                        path: "/admin/pricing",
                        element: <Pricing />,
                    },
                    {
                        path: "/admin/tickets",
                        element: <div />,
                    },
                ],
            },
        ],
    },
])
