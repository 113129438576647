import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { selectAuthUser } from "../Authentication/authSlice"

interface ProfileProps {}

export const Profile: React.FC<ProfileProps> = () => {
    const user = useAppSelector(selectAuthUser)
    const { t } = useTranslation()
    return (
        <div className="w-full max-h-full flex flex-col gap-4">
            <div className="w-full flex h-[300px] gap-5 items-center bg-secondary-100 shadow-lg rounded-lg p-4">
                <div className="w-1/5 min-w-[200px]">
                    <img
                        src={user.profilePicture.path}
                        alt="profile"
                        className="rounded-full border-4 border-primary-100"
                    />
                </div>
                <div className="w-fit">
                    <h1 className="font-bold text-3xl">{user.fullName}</h1>
                    <div>
                        <p className="text-lg">Software Engineer</p>
                        <div className="flex items-center gap-2 text-sm">
                            <FontAwesomeIcon icon="envelope" />
                            <p>{user.email}</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm">
                            <FontAwesomeIcon icon="phone" />
                            <p>{user.phone}</p>
                        </div>
                    </div>
                </div>
                <div className="border-l border-primary-300 px-4 h-full flex flex-col justify-center w-fit ">
                    <div>
                        <h1 className="text-2xl font-bold">{t("about")}:</h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Unde odio non facere consequatur architecto
                            vitae. Quae inventore nostrum temporibus deserunt,
                            reprehenderit sed necessitatibus aperiam vitae
                            accusamus sint. Dolorum, numquam distinctio!
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-full bg-secondary-100 shadow-lg rounded-lg p-4 flex flex-col gap-5 min-h-[120px]">
                <div className="flex justify-between text-xl">
                    <div className="p-3 flex flex-col items-center">
                        <h2 className="font-bold">{t("projects")}</h2>
                        <div className="flex gap-2 items-center mt-2">
                            <p>12</p>
                            <FontAwesomeIcon icon="folder" />
                        </div>
                    </div>
                    <div className="p-3 flex flex-col items-center">
                        <h2 className="font-bold">{t("assignments_solved")}</h2>
                        <div className="flex gap-2 items-center mt-2">
                            <p>143</p>
                            <FontAwesomeIcon icon="clipboard-check" />
                        </div>
                    </div>
                    <div className="p-3 flex flex-col items-center">
                        <h2 className="font-bold">
                            {t("assignments_reviewed")}
                        </h2>
                        <div className="flex gap-2 items-center mt-2">
                            <p>38</p>
                            <FontAwesomeIcon icon="clipboard-user" />
                        </div>
                    </div>
                    <div className="p-3 flex flex-col items-center">
                        <h2 className="font-bold">{t("delays")}</h2>
                        <div className="flex gap-2 items-center mt-2">
                            <p>5</p>
                            <FontAwesomeIcon icon="clock" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-secondary-100 shadow-lg rounded-lg p-4 flex flex-col gap-5">
                <h1 className="font-bold text-3xl">{t("recommendations")}:</h1>
                <Scrollable height="260px">
                    <ul>
                        {[1, 2, 3, 4, 5].map((item) => (
                            <li
                                key={item}
                                className="p-4 border rounded-lg mb-4"
                            >
                                <div className="flex gap-2 flex-col">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={user.profilePicture.path}
                                            className="w-16 h-16 rounded-full border-4 border-primary-100"
                                            alt=""
                                        />
                                        <div>
                                            <p className="text-lg font-bold">
                                                {user.fullName}
                                                <span className="ml-2 font-thin text-sm">
                                                    4 days ago
                                                </span>
                                            </p>
                                            {[1, 2, 3, 4, 5].map((item) => (
                                                <FontAwesomeIcon
                                                    key={item}
                                                    icon="star"
                                                    className="text-yellow-400"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipisicing elit.
                                            Nostrum minima suscipit enim ea
                                            praesentium pariatur reprehenderit
                                            laudantium tempora quae eos quod
                                            itaque ipsam amet voluptatibus
                                            libero, quidem dolorum iste veniam.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Scrollable>
            </div>
        </div>
    )
}
