import { useAppSelector } from "@/app/hooks"
import { compareWBS, GanttFormatterMapping } from "@/common/helpers"
import { Popup, POPUP_TYPES } from "@/components/Menus/Popup"
import { Task, TaskDependenciesType } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { selectTaskMappingById, selectTaskTree } from "../planningSlice"
import { FilterFields, FilterGroup, FilterValuesType } from "./Filters/Filters"
import { applyFilterToTasksAndChildren } from "./ganttHelpers"
import { selectGanttCalendar } from "./ganttSlice"
import { GanttTaskCalendarRowDependency } from "./GanttTaskCalendarRowDependency"

interface TaskItemProps {
    task: Task
}

export const GanttTaskCalendarRow: React.FC<TaskItemProps> = ({ task }) => {
    const calendar = useAppSelector(selectGanttCalendar)
    const fullTaskMapping = useAppSelector(
        (state) => state.plannings.taskMapping,
    )

    const taskTree = useAppSelector(selectTaskTree)
    const getTaskPositionInTaskTree = (
        taskTree: Task,
        task: Task,
        position: { current: number },
    ): number => {
        // Check if the current task is the one we are looking for
        if (taskTree.id === task.id) {
            return position.current
        }

        // Increment the current position as we visit this task
        position.current += 1

        // Ensure taskMapping is defined and expanded
        const taskMapping = fullTaskMapping[taskTree.id]

        if (taskTree.children.length > 0 && taskMapping.isExpanded) {
            // Loop through children to find the task
            for (let i = 0; i < taskTree.children.length; i++) {
                const result = getTaskPositionInTaskTree(
                    taskTree.children[i],
                    task,
                    position,
                )
                if (result !== -1) {
                    return result
                }
            }
        }

        // Return -1 if the task is not found
        return -1
    }
    const [width, setWidth] = useState<number>(0)
    const [margin, setMargin] = useState<number>(0)
    const [childInFilter, setChildInFilter] = useState<boolean>(true)
    const { t } = useTranslation()

    const [isShown, setIsShown] = useState<boolean>(false)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const parentRef = useRef<HTMLDivElement>(null)

    const [inFilter, setInFilter] = useState<boolean>(true)
    const taskMapping = useAppSelector(selectTaskMappingById(task.id))
    const filters = useAppSelector((state) => state.gantt.filters)
    const filterGroup = new FilterGroup(filters)
    const startDateOffset = GanttFormatterMapping[
        calendar.zoom
    ].startDateOffsetCalc(task.startDate)
    const endDateOffset = GanttFormatterMapping[
        calendar.zoom
    ].endDateOffsetCalc(task.endDate)

    useEffect(() => {
        const width =
            calendar.unit *
                GanttFormatterMapping[calendar.zoom].intervalFn(
                    task.endDate,
                    task.startDate,
                ) +
            calendar.unit -
            (startDateOffset + endDateOffset)
        const margin =
            calendar.unit *
                GanttFormatterMapping[calendar.zoom].intervalFn(
                    task.startDate,
                    calendar.startDate,
                ) +
            startDateOffset
        setWidth(width)
        setMargin(margin)
    }, [taskMapping, calendar.zoom])
    useEffect(() => {
        setChildInFilter(
            applyFilterToTasksAndChildren(task, filters, filterGroup),
        )
        setInFilter(
            filterGroup.applyFilters(
                task,
                filters as FilterValuesType<FilterFields>,
            ),
        )
    }, [filters])
    useEffect(() => {
        setIsExpanded(taskMapping?.isExpanded ?? true)
    }, [taskMapping, task])
    return childInFilter ? (
        <>
            <div
                className={`relative h-12 pt-4 w-max ${inFilter ? "" : "opacity-20"}`}
            >
                <div
                    className={`h-3 transition cursor-pointer group  relative ${task.hasChildren ? "bg-yellow-400 hover:bg-yellow-600" : "bg-green-400 hover:bg-green-600"}`}
                    style={{
                        width: width > 20 ? width : 20 + "px",
                        marginLeft: margin + "px",
                        height: width > 20 ? 12 : 20 + "px",
                        ...(width < 20 && { borderRadius: "0.3rem" }),
                    }}
                >
                    {width > 20 && (
                        <div
                            className={`absolute top-2 w-0 h-0 bg-transparent-0  border-l-transparent border-t-[15px] transition border-r-[10px] border-r-transparent-0 ${
                                task.hasChildren
                                    ? "border-t-yellow-400 group-hover:border-t-yellow-600"
                                    : "border-t-green-400 group-hover:border-t-green-600"
                            }`}
                        ></div>
                    )}
                    <div
                        className={`absolute -mt-1 top-0 right-full mr-4 text-nowrap ${task.hasChildren ? "font-bold" : ""}`}
                    >
                        {task.name}
                    </div>
                    {width > 20 && (
                        <div
                            className={`absolute top-2 right-0 w-0 h-0  transition border-t-[15px]  border-l-[10px] border-l-transparent-0 ${
                                task.hasChildren
                                    ? "border-t-yellow-400 group-hover:border-t-yellow-600"
                                    : "border-t-green-400 group-hover:border-t-green-600"
                            }`}
                        ></div>
                    )}
                    {task.dependencies.filter(
                        (dependency) =>
                            dependency.dependencyType ===
                            TaskDependenciesType.SUCCESSOR,
                    ).length > 0 && (
                        <div
                            className="relative"
                            ref={parentRef}
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                        >
                            <FontAwesomeIcon
                                icon="circle"
                                className="absolute top-2.5 left-full transform -translate-x-1/2 -translate-y-1/2 text-primary-100 text-xs"
                            />

                            <Popup
                                type={POPUP_TYPES.HOVER}
                                parentRef={parentRef}
                                isShown={isShown}
                                closePopup={() => setIsShown(false)}
                                className="mt-4"
                            >
                                <div>
                                    <p className="font-bold">
                                        {t("task_dependencies", {
                                            task: task.name,
                                        })}
                                    </p>
                                    {task.dependencies
                                        .filter(
                                            (dependency) =>
                                                dependency.dependencyType ===
                                                TaskDependenciesType.SUCCESSOR,
                                        )
                                        .map((dependency) => (
                                            <div
                                                key={dependency.id}
                                                className="border-b my-4"
                                            >
                                                <div className="text-primary-300 flex items-center gap-1">
                                                    <p className="font-bold">
                                                        {t("dependency_task")}:
                                                    </p>
                                                    <p>
                                                        {
                                                            dependency
                                                                .dependencyTask
                                                                .wbs
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            dependency
                                                                .dependencyTask
                                                                .name
                                                        }
                                                    </p>
                                                </div>
                                                <div className="text-primary-300 flex items-center gap-1">
                                                    <p className="font-bold">
                                                        {t("relation_type")}:
                                                    </p>
                                                    <p>
                                                        {t(
                                                            dependency.relationType.toLowerCase(),
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="text-primary-300 flex items-center gap-1">
                                                    <p className="font-bold">
                                                        {t("lag")}:
                                                    </p>
                                                    <p>
                                                        {dependency.lag}{" "}
                                                        {dependency.lag > 1
                                                            ? t("days")
                                                            : t("day")}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </Popup>
                        </div>
                    )}
                </div>
                {task.dependencies
                    .filter(
                        (dependency) =>
                            dependency.dependencyType ===
                            TaskDependenciesType.SUCCESSOR,
                    )
                    .map((dependency) => (
                        <GanttTaskCalendarRowDependency
                            key={dependency.id}
                            dependency={dependency}
                            mainTask={task}
                        />
                    ))}
            </div>

            {isExpanded &&
                task.children
                    .sort((a, b) => compareWBS(a.wbs, b.wbs))
                    .map((child, index) => (
                        <GanttTaskCalendarRow key={child.id} task={child} />
                    ))}
        </>
    ) : null
}
