import { axiosAuthenticated } from "@/common/axios"
import { ProjectFileTypeEnum } from "@/models/File"
import { FolderJson } from "@/models/Folder"
import { ResourcePermissions } from "@/models/Permission"
import { setProgress } from "./fileManagerSlice"

export const getProjectRootFoldersApi = (projectId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/file-manager/root`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getFolderContentFoldersApi = (
    projectId: string,
    folderId: string,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/folders/${folderId}/folders`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getFolderContentFilesApi = (
    projectId: string,
    folderId: string,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/folders/${folderId}/files`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createRootFolderApi = (
    name: string,
    projectId: string,
    permissions: ResourcePermissions,
    isGlobal: boolean,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/file-manager/root/create`, {
                name,
                permissions,
                isGlobal,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createFolderApi = (
    name: string,
    projectId: string,
    parentFolderId: string,
    permissions: ResourcePermissions,
    isGlobal: boolean,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/file-manager/folders/${parentFolderId}/create`,
                {
                    name,
                    permissions,
                    isGlobal,
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const uploadFileToFolderApi = (
    projectId: string,
    folderId: string,
    payload: FormData,
    dispatch: any,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/file-manager/folders/${folderId}/upload`,
                payload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            const progress = Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total,
                            )
                            dispatch(setProgress(progress))
                        }
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const searchDescendantFoldersApi = (
    projectId: string,
    query: string,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/folders/search?query=${query}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const searchDescendantFilesApi = (projectId: string, query: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/files/search?query=${query}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteFolderApi = (projectId: string, folderId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(
                `/projects/${projectId}/file-manager/folders/${folderId}/delete`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const renameFolderApi = (
    projectId: string,
    folderId: string,
    name: string,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .put(
                `/projects/${projectId}/file-manager/folders/${folderId}/rename`,
                {
                    name,
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateResourcePermissionsApi = (
    projectId: string,
    resourceId: string,
    payload: ResourcePermissions,
    resourceType: "folder" | "file",
    isGlobal: boolean,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .put(
                `/projects/${projectId}/file-manager/${resourceType}s/${resourceId}/permissions`,
                {
                    payload,
                    isGlobal,
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getResourcePermissionsApi = (
    projectId: string,
    resourceId: string,
    resourceType: "folder" | "file",
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/${resourceType}s/${resourceId}/permissions`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectTypeFilesApi = (
    projectId: string,
    fileType: ProjectFileTypeEnum,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/files?fileType=${fileType}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserUploadedProjectFilesApi = (projectId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/file-manager/files/my-files`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserSharedProjectFilesApi = (projectId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/file-manager/files/shared-files`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteFileApi = (fileId: string, projectId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(
                `/projects/${projectId}/file-manager/files/${fileId}/delete`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const renameFileApi = (
    projectId: string,
    fileId: string,
    name: string,
) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/file-manager/files/${fileId}/rename`, {
                name,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getBimNominationFieldsApi = (projectId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/file-manager/files/bim-nomination`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectBimFilesApi = (projectId: string) => {
    return new Promise<{ data: FolderJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/file-manager/files/bim-files`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
