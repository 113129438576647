import { axiosAuthenticated } from "@/common/axios"
import {
    PricingPlanInterface,
    PricingPlanUpdatePayload,
    UserPricingPlanInterface,
} from "@/models/Pricing"
import { UserInterface } from "@/models/User"

export const getPricingPlansApi = () => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/pricing/plans`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createPricingPlanApi = (data: PricingPlanInterface) => {
    return new Promise<{ data: PricingPlanInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/pricing/plans`, data)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUsersPricingPlansApi = (pricingPlanId?: string) => {
    return new Promise<{ data: UserPricingPlanInterface[] }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .get(
                    `/pricing/user-plans/${pricingPlanId ? pricingPlanId : ""}`,
                )
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const updatePricingPlanApi = (
    pricingPlanId: string,
    data: PricingPlanUpdatePayload,
) => {
    return new Promise<{ data: PricingPlanInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/pricing/plans/${pricingPlanId}`, data)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const assignUsersToPricingPlanApi = (
    userIds: string[],
    pricingPlanId: string,
    expiresAt: Date,
) => {
    return new Promise<{ data: UserPricingPlanInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .post(`/pricing/user-plans`, {
                    pricingPlanId,
                    userIds,
                    expiresAt,
                })
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const deletePricingPlanApi = (pricingPlanId: string) => {
    return new Promise<{ data: PricingPlanInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/pricing/plans/${pricingPlanId}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const extendUserPricingPlanApi = (
    userPricingPlanId: string,
    expiresAt: Date,
) => {
    return new Promise<{ data: UserPricingPlanInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .put(`/pricing/user-plans/${userPricingPlanId}/extend`, {
                    expiresAt,
                })
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const expireUserPricingPlanApi = (userPricingPlanId: string) => {
    return new Promise<{ data: UserPricingPlanInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .put(`/pricing/user-plans/${userPricingPlanId}/expire`)
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const getUsersPricingPlansStatsApi = (
    startDate: Date,
    endDate: Date,
) => {
    return new Promise<{ data: UserPricingPlanInterface[] }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .get(
                    `/pricing/stats/user-plans?startDate=${startDate.toUTCString()}&endDate=${endDate.toUTCString()}`,
                )
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const getUserPricingPlanApi = (userId: string) => {
    return new Promise<{ data: UserPricingPlanInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .get(`/pricing/user-plans/${userId}`)
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}
