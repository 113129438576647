import PlaceholderAvatar from "@/assets/avatar-placeholder.png"
import { UserNotification } from "@/models/Notification"
import {
    NotificationTypeAdditionalDataMap,
    UserNotificationType,
} from "@/types/UserNotificationsTypes"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface NotificationFolderRenamedProps {
    notification: UserNotification
    additionalData: NotificationTypeAdditionalDataMap[UserNotificationType.FOLDER_RENAMED]

    seen: boolean
    longFormat?: boolean
}

export const NotificationFolderRenamed: React.FC<
    NotificationFolderRenamedProps
> = ({ notification, additionalData, seen, longFormat = false }) => {
    const { t } = useTranslation()
    const redirectLink = `/dashboard/projects/${additionalData.project.id}/file-manager${additionalData.parent !== null ? "/" + additionalData.parent.id : ""}?resource=${additionalData.folder.id}`
    return (
        <Link
            className={`flex items-center justify-between  p-2 bg-secondary-100 rounded-lg  ${
                seen ? "text-primary-100" : "text-primary-300"
            }`}
            to={redirectLink}
        >
            <div className="flex items-center gap-2">
                <img
                    className={`w-10 h-10 rounded-full object-cover ${
                        seen ? "filter grayscale" : ""
                    }`}
                    onError={(e) => {
                        e.currentTarget.src = PlaceholderAvatar
                    }}
                    src={additionalData.renamedBy.profilePicture.path}
                    alt="profile"
                />
                <div>
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.renamedBy.firstName}{" "}
                        {additionalData.renamedBy.lastName}
                    </span>{" "}
                    {t("renamed_the_folder")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.folder.oldName}
                    </span>{" "}
                    {t("to")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.folder.name}
                    </span>{" "}
                    {t("in_the_project")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.project.name}
                    </span>{" "}
                    {!longFormat && (
                        <p className="text-xs">
                            {notification.timeSinceCreation}{" "}
                        </p>
                    )}
                </div>
            </div>
            {longFormat && (
                <p className="text-xs">{notification.timeSinceCreation} </p>
            )}
        </Link>
    )
}
