import { useAppDispatch } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { IfcFilterSet } from "@/models/Ifc"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { IfcHandler } from "../IfcHandler"
import { setIsLoading } from "../ifcSlice"
import { IfcFiltersSet } from "./IfcFiltersSet"

interface IfcFiltersFormProps {
    ifcHandler: IfcHandler
}

export const IfcFiltersForm: React.FC<IfcFiltersFormProps> = ({
    ifcHandler,
}) => {
    const dispatch = useAppDispatch()
    const [filterSets, setFilterSets] = useState<IfcFilterSet>({
        inclusive: false,
        rules: [
            {
                type: "property",
                name: "",
                value: "",
            },
        ],
        sets: [],
    })
    const { t } = useTranslation()
    return (
        <div className="bg-secondary-100 text-primary-300 p-4 rounded-lg w-full">
            <h3 className="text-lg">{t("filter_rules")}</h3>
            <IfcFiltersSet
                ifcHandler={ifcHandler}
                ifcFilterSet={filterSets}
                setIfcFilterSet={setFilterSets}
            />
            <div className="flex gap-2 justify-end items-center mt-4">
                <Button
                    name={t("clear")}
                    onClick={() => {
                        setFilterSets({
                            inclusive: false,
                            rules: [
                                {
                                    type: "property",
                                    name: "",
                                    value: "",
                                },
                            ],
                            sets: [],
                        })
                    }}
                />
                <Button
                    name={t("select")}
                    onClick={() => {
                        dispatch(setIsLoading(true))
                        ifcHandler.applyFilter(filterSets).then(() => {
                            dispatch(setIsLoading(false))
                        })
                    }}
                />
            </div>
        </div>
    )
}
