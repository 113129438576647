import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface ButtonProps {
    name: string
    isDisabled?: boolean
    icon?: IconProp
    className?: string
    type?: "button" | "submit" | "reset"
    formValidator?: { [key: string]: boolean }
    onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({
    name,
    isDisabled,
    className,
    icon,
    type = "submit",
    formValidator = { key: true },
    onClick = () => null,
}) => {
    const disabledStyle = "opacity-50 cursor-not-allowed "
    const disabled = !Object.values(formValidator).every(
        (item) => item === true,
    )
    let buttonStyle = disabled || isDisabled ? disabledStyle : ""
    buttonStyle =
        buttonStyle +
        "bg-primary-100 text-secondary-100 px-4 py-2 flex items-center justify-center rounded-lg hover:bg-primary-300 transition " +
        className

    return (
        <button
            disabled={disabled}
            className={buttonStyle}
            onClick={() => {
                if (isDisabled) return
                onClick()
            }}
            type={type}
        >
            {icon && (
                <FontAwesomeIcon className="mr-2" icon={icon}></FontAwesomeIcon>
            )}
            <p className="text-nowrap">{name}</p>
        </button>
    )
}
