import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface SharedFilesIconProps {
    className?: string
}

export const SharedFilesIcon: React.FC<SharedFilesIconProps> = ({
    className,
}) => {
    return (
        <div className={`${className} relative w-16 h-16`}>
            <FontAwesomeIcon
                icon="folder"
                className="text-6xl text-yellow-500 absolute"
            />
            <FontAwesomeIcon
                icon="share-nodes"
                className="text-secondary-100 absolute text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
        </div>
    )
}
