import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { Planning, PlanningJsonInterface } from "@/models/Planning"
import {
    Task,
    TaskDependenciesPayload,
    TaskJsonInterface,
    TaskPayload,
} from "@/models/Task"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    createPlanningApi,
    deletePlanningApi,
    editPlanningApi,
    getPlanningTaskTreeApi,
    getPlanningsByProjectIdApi,
    getTaskByIdApi,
    getTaskSiblingsByIdApi,
    setPlanningAsMainApi,
    setTaskDependenciesApi,
    updateTaskByIdApi,
} from "./planningApi"

export const getPlanningsByProjectId = createAsyncThunkWithNotification(
    "plannings/getPlanningsByProjectId",
    async (projectId: string) => {
        const response = await getPlanningsByProjectIdApi(projectId)
        return response
    },
)

export const editPlanning = createAsyncThunkWithNotification(
    "plannings/editPlanning",
    async ({
        projectId,
        planningId,
        updatePayload,
    }: {
        projectId: string
        planningId: string
        updatePayload: any
    }) => {
        const response = await editPlanningApi(
            projectId,
            planningId,
            updatePayload,
        )
        return response
    },
)

export const deletePlanning = createAsyncThunkWithNotification(
    "plannings/deletePlanning",
    async ({
        projectId,
        planningId,
    }: {
        projectId: string
        planningId: string
    }) => {
        const response = await deletePlanningApi(projectId, planningId)
        return response
    },
)

export const getPlanningTaskTree = createAsyncThunkWithNotification(
    "plannings/getTasks",
    async ({
        projectId,
        planningId,
    }: {
        projectId: string
        planningId: string
    }) => {
        const response = await getPlanningTaskTreeApi(projectId, planningId)
        return response
    },
)

export const getTaskById = createAsyncThunkWithNotification(
    "plannings/getTaskById",
    async ({
        projectId,
        planningId,
        taskId,
    }: {
        projectId: string
        planningId: string
        taskId: string
    }) => {
        const response = await getTaskByIdApi(projectId, planningId, taskId)
        return response
    },
)

export const getTaskSiblingsById = createAsyncThunkWithNotification(
    "plannings/getTaskSiblingsById",
    async ({
        projectId,
        planningId,
        taskId,
    }: {
        projectId: string
        planningId: string
        taskId: string
    }) => {
        const response = await getTaskSiblingsByIdApi(
            projectId,
            planningId,
            taskId,
        )
        return response
    },
)

export const updateTaskById = createAsyncThunkWithNotification(
    "plannings/updateTaskById",
    async ({
        projectId,
        taskId,
        updatedPayload,
    }: {
        projectId: string
        taskId: string
        updatedPayload: TaskPayload
    }) => {
        const response = await updateTaskByIdApi(
            projectId,
            taskId,
            updatedPayload,
        )
        return response
    },
)

export const setTaskDependencies = createAsyncThunkWithNotification(
    "plannings/setTaskDependencies",
    async ({
        projectId,
        planningId,
        taskId,
        updatedPayload,
    }: {
        projectId: string
        planningId: string
        taskId: string
        updatedPayload: TaskDependenciesPayload[]
    }) => {
        const response = await setTaskDependenciesApi(
            projectId,
            planningId,
            taskId,
            updatedPayload,
        )
        return response
    },
)

export const createPlanning = createAsyncThunkWithNotification(
    "plannings/createPlanning",
    async ({
        createPlanningPayload,
        projectId,
    }: {
        createPlanningPayload: FormData
        projectId: string
    }) => {
        const response = await createPlanningApi(
            createPlanningPayload,
            projectId,
        )
        return response
    },
)

export const setPlanningAsMain = createAsyncThunkWithNotification(
    "plannings/setPlanningAsMain",
    async ({
        projectId,
        planningId,
    }: {
        projectId: string
        planningId: string
    }) => {
        const response = await setPlanningAsMainApi(projectId, planningId)
        return response
    },
)

interface TaskMapping {
    [key: string]: TaskJsonInterface
}

const generateTaskMappingFromTree = (taskTree: TaskJsonInterface) => {
    const taskMapping: TaskMapping = {}
    const traverse = (task: TaskJsonInterface) => {
        taskMapping[task.id] = task
        if (task.children) {
            task.children.forEach((child) => traverse(child))
        }
    }
    traverse(taskTree)
    return taskMapping
}

const addTaskToParentHelper = (
    taskTree: TaskJsonInterface,
    task: TaskJsonInterface,
) => {
    const traverse = (node: TaskJsonInterface) => {
        if (!task.parent) return
        if (node.id === task.parent.id) {
            node.children.push(task)
        } else {
            node.children.forEach((child) => traverse(child))
        }
    }
    traverse(taskTree)
}

const removeTaskFromParentHelper = (
    taskTree: TaskJsonInterface,
    taskId: string,
) => {
    const traverse = (node: TaskJsonInterface) => {
        const index = node.children.findIndex((child) => child.id === taskId)
        if (index !== -1) {
            node.children.splice(index, 1)
        } else {
            node.children.forEach((child) => traverse(child))
        }
    }
    traverse(taskTree)
}

const updateTaskInTreeHelper = (
    taskTree: TaskJsonInterface,
    task: TaskJsonInterface,
) => {
    const traverse = (node: TaskJsonInterface) => {
        if (node.id === task.id) {
            Object.assign(node, task)
        } else {
            node.children.forEach((child) => traverse(child))
        }
    }
    traverse(taskTree)
}

export interface PlanningsState {
    plannings: PlanningJsonInterface[]
    planning: PlanningJsonInterface
    taskTree: TaskJsonInterface
    taskMapping: { [key: string]: TaskJsonInterface }
    task: TaskJsonInterface
    taskSiblings: TaskJsonInterface[]
    highlightedTaskId: string
    status: ReducerStatus
    errors: FormErrors
}

const initialState: PlanningsState = {
    plannings: [],
    planning: new Planning().toJson(),
    taskTree: new Task().toJson(),
    taskMapping: {},
    task: new Task().toJson(),
    taskSiblings: [],
    highlightedTaskId: "",
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

export const planningSlice = createSlice({
    name: "plannings",
    initialState,
    reducers: {
        shrinkTask: (state, action) => {
            state.taskMapping = {
                ...state.taskMapping,
                [action.payload]: {
                    ...state.taskMapping[action.payload],
                    isExpanded: false,
                },
            }
        },
        shrinkAllTasks: (state) => {
            state.taskMapping = Object.keys(state.taskMapping).reduce(
                (acc: TaskMapping, key) => {
                    acc[key] = { ...state.taskMapping[key], isExpanded: false }
                    return acc
                },
                {},
            )
        },
        expandTask: (state, action) => {
            state.taskMapping = {
                ...state.taskMapping,
                [action.payload]: {
                    ...state.taskMapping[action.payload],
                    isExpanded: true,
                },
            }
        },
        expandAllTasks: (state) => {
            state.taskMapping = Object.keys(state.taskMapping).reduce(
                (acc: TaskMapping, key) => {
                    acc[key] = { ...state.taskMapping[key], isExpanded: true }
                    return acc
                },
                {},
            )
        },
        addTaskToParent: (state, action) => {
            const task: TaskJsonInterface = action.payload.task
            addTaskToParentHelper(state.taskTree, task)
            state.taskMapping[task.id] = task
        },
        removeTaskFromParent: (state, action) => {
            const task: string = action.payload
            removeTaskFromParentHelper(state.taskTree, task)
            state.taskMapping = Object.keys(state.taskMapping).reduce(
                (acc: TaskMapping, key) => {
                    if (key !== task) {
                        acc[key] = state.taskMapping[key]
                    }
                    return acc
                },
                {},
            )
        },
        updateTaskInTree: (state, action) => {
            const task: TaskJsonInterface = action.payload.task
            updateTaskInTreeHelper(state.taskTree, task)
            state.taskMapping[task.id] = {
                ...state.taskMapping[task.id],
                ...task,
            }
        },
        highlightTask: (state, action) => {
            state.highlightedTaskId = action.payload
        },
        clearErrors: (state) => {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPlanningsByProjectId.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getPlanningsByProjectId.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.plannings = action.payload.data.data
            })
            .addCase(getPlanningsByProjectId.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.plannings = []
            })
            .addCase(editPlanning.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(editPlanning.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.plannings = state.plannings.map((planning) =>
                    planning.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : planning,
                )
            })
            .addCase(editPlanning.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(deletePlanning.pending, (state) => {
                state.status.delete = SliceStatus.LOADING
            })
            .addCase(deletePlanning.fulfilled, (state, action) => {
                state.status.delete = SliceStatus.IDLE
                state.plannings = state.plannings.filter(
                    (planning) => planning.id !== action.payload.data.data,
                )
            })
            .addCase(deletePlanning.rejected, (state) => {
                state.status.delete = SliceStatus.FAILED
            })
            .addCase(getPlanningTaskTree.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getPlanningTaskTree.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.taskTree = {
                    ...state.taskTree,
                    ...action.payload.data.data,
                }
                if (Object.keys(state.taskMapping).length === 0) {
                    state.taskMapping = generateTaskMappingFromTree(
                        state.taskTree,
                    )
                }
            })
            .addCase(getPlanningTaskTree.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.taskTree = new Task().toJson()
                state.taskMapping = {}
            })
            .addCase(getTaskById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getTaskById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.task = action.payload.data.data
            })
            .addCase(getTaskById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.task = new Task().toJson()
            })
            .addCase(updateTaskById.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(updateTaskById.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.task = action.payload.data.data
                updateTaskInTreeHelper(state.taskTree, action.payload.data.data)
            })
            .addCase(updateTaskById.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.task = new Task().toJson()
                state.errors = (action.payload as any).data
            })
            .addCase(getTaskSiblingsById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getTaskSiblingsById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.taskSiblings = action.payload.data.data
            })
            .addCase(getTaskSiblingsById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.taskSiblings = []
            })
            .addCase(setTaskDependencies.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(setTaskDependencies.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                // state.taskSiblings = action.payload.data.data;
            })
            .addCase(setTaskDependencies.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data
                // state.taskSiblings = [];
            })
            .addCase(createPlanning.pending, (state) => {
                state.status.create = SliceStatus.LOADING
            })
            .addCase(createPlanning.fulfilled, (state, action) => {
                state.status.create = SliceStatus.IDLE
                state.planning = action.payload.data.data
                state.plannings = [...state.plannings, action.payload.data.data]
            })
            .addCase(createPlanning.rejected, (state, action) => {
                state.status.create = SliceStatus.FAILED
                state.planning = new Planning().toJson()
                state.errors = (action.payload as any).data
            })
            .addCase(setPlanningAsMain.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(setPlanningAsMain.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.plannings = action.payload.data.data
            })
            .addCase(setPlanningAsMain.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
                state.plannings = []
            })
    },
})

const selectRawPlannings = (state: RootState) => state.plannings.plannings
const selectRawTaskTree = (state: RootState) => state.plannings.taskTree
const selectRawTask = (state: RootState) => state.plannings.task
const selectRawTaskSiblings = (state: RootState) => state.plannings.taskSiblings
export const selectTaskMappingById = (taskId: string) => (state: RootState) =>
    state.plannings.taskMapping[taskId]

export const selectPlannings = createSelector(
    [selectRawPlannings],
    (plannings) => plannings.map((planning) => new Planning(planning)),
)
export const selectTaskTree = createSelector(
    [selectRawTaskTree],
    (taskTree) => new Task(taskTree),
)
export const selectTask = createSelector(
    [selectRawTask],
    (task) => new Task(task),
)
export const selectTaskSiblings = createSelector(
    [selectRawTaskSiblings],
    (taskSiblings) =>
        taskSiblings.map((sibling: TaskJsonInterface) => new Task(sibling)),
)

export const {
    shrinkTask,
    expandTask,
    expandAllTasks,
    shrinkAllTasks,
    addTaskToParent,
    highlightTask,
    updateTaskInTree,
    removeTaskFromParent,
    clearErrors,
} = planningSlice.actions

export default planningSlice.reducer
