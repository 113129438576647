import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { ProjectFileTypeEnum } from "@/models/File"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { FileIcon } from "../FileIcon"
import { getProjectTypeFiles, selectFiles } from "../fileManagerSlice"

interface BrowserWithFilterProps {
    fileType: ProjectFileTypeEnum
    header: string
    loadingMessage: string
}

export const BrowserWithFilter: React.FC<BrowserWithFilterProps> = ({
    fileType,
    header,
    loadingMessage,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const files = useAppSelector(selectFiles)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const status = useAppSelector((state) => state.fileManager.manyStatus)
    useEffect(() => {
        dispatch(
            getProjectTypeFiles({
                projectId: projectId,
                fileType: fileType,
            }),
        )
    }, [dispatch])
    return (
        <div className="w-full h-full">
            <div className="p-2">
                <h1 className="text-2xl font-bold">{t(header)}:</h1>
            </div>
            <WithLoader
                statuses={[status.read]}
                title={t(loadingMessage)}
                className="w-full h-full flex flex-wrap overflow-y-auto gap-2 sm:justify-evenly"
            >
                {files.map((file) => (
                    <FileIcon key={file.id} file={file} />
                ))}
                {files.length === 0 && (
                    <div className="p-2 opacity-60 text-primary-300 w-full h-full flex flex-col gap-3 justify-center items-center">
                        <FontAwesomeIcon icon="search" size="3x" />
                        {t("no_files_found")}
                    </div>
                )}
            </WithLoader>
        </div>
    )
}
