import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import {
    FilterFields,
    FilterGroup,
    FilterValuesType,
} from "../Plannings/Gantt/Filters/Filters"
import { Calendar } from "./Calendar/Calendar"
import { Cards } from "./Cards/Cards"
import { List } from "./List/List"
import { TaskFilters } from "./TaskFilters"
import {
    getAllTasks,
    getMyAssignedTasks,
    getMyFollowedTasks,
    getTasksForReview,
    selectTasks,
} from "./tasksSlice"

interface TasksTableProps {}

enum Mode {
    ALL = "all",
    ASSIGNED_TASKS = "assigned_tasks",
    TASKS_FOR_REVIEW = "tasks_for_review",
    FOLLOWED_TASKS = "followed_tasks",
}

const iconMapping: {
    [key in Mode]: IconProp
} = {
    [Mode.ALL]: "list-check",
    [Mode.ASSIGNED_TASKS]: "user",
    [Mode.TASKS_FOR_REVIEW]: "check",
    [Mode.FOLLOWED_TASKS]: "star",
}

export const TasksTable: React.FC<TasksTableProps> = () => {
    const [mode, setMode] = useState<"list" | "cards" | "calendar">("cards")
    const [tasksMode, setTasksMode] = useState<Mode>(Mode.ALL)
    const [mobileFilters, setMobileFilters] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<number>(10)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [showMenu, setShowMenu] =
        useOutletContext<
            [
                boolean,
                Dispatch<SetStateAction<boolean>>,
                boolean,
                Dispatch<SetStateAction<boolean>>,
            ]
        >()
    const statusFilter = useAppSelector(
        (state) => state.tasks.statusFilterValues,
    )
    const statusDetailFilter = useAppSelector(
        (state) => state.tasks.statusDetailFilterValues,
    )
    const delayStatusFilter = useAppSelector(
        (state) => state.tasks.delayStatusFilterValues,
    )
    const tasks = useAppSelector(selectTasks)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const [localTasks, setLocalTasks] = useState(tasks)
    const dispatch = useAppDispatch()
    const filters = useAppSelector((state) => state.gantt.filters)
    const filterGroup = new FilterGroup(filters)
    const modeComponentMapping = {
        list: <List tasks={localTasks} />,
        cards: (
            <Cards
                tasks={localTasks}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
            />
        ),
        calendar: <Calendar tasks={localTasks} />,
    }

    useEffect(() => {
        const filteredTasks = tasks.filter((task) => {
            return (
                (statusFilter.includes(task.status) ||
                    statusFilter.length === 0) &&
                (statusDetailFilter.includes(task.statusDetail) ||
                    statusDetailFilter.length === 0) &&
                (delayStatusFilter.includes(task.delayStatus) ||
                    delayStatusFilter.length === 0) &&
                filterGroup.applyFilters(
                    task,
                    filters as FilterValuesType<FilterFields>,
                )
            )
        })
        setLocalTasks(filteredTasks)
    }, [statusFilter, statusDetailFilter, delayStatusFilter, tasks, filters])

    useEffect(() => {
        if (tasksMode === Mode.ALL) {
            dispatch(getAllTasks({ projectId, pageSize, pageNumber }))
        } else if (tasksMode === Mode.ASSIGNED_TASKS) {
            dispatch(getMyAssignedTasks(projectId))
        } else if (tasksMode === Mode.TASKS_FOR_REVIEW) {
            dispatch(getTasksForReview(projectId))
        } else if (tasksMode === Mode.FOLLOWED_TASKS) {
            dispatch(getMyFollowedTasks(projectId))
        }
    }, [tasksMode, pageNumber, pageSize])

    return (
        <>
            {showMenu && (
                <div className="flex items-center gap-2 bg-secondary-100 shadow-lg rounded-lg p-3 justify-between sm:flex-wrap  lg:flex-wrap">
                    <div className="flex gap-2 sm:flex-wrap">
                        {Object.entries(Mode).map(([key, value]) => (
                            <Button
                                key={key}
                                name={t(value)}
                                icon={iconMapping[value]}
                                className={
                                    tasksMode === value
                                        ? `!bg-primary-100 !text-secondary-100`
                                        : `!bg-secondary-100 !text-primary-300 !border !border-primary-300 transition hover:!bg-primary-100 hover:!text-secondary-100`
                                }
                                onClick={() =>
                                    setTasksMode(Mode[key as keyof typeof Mode])
                                }
                            />
                        ))}
                    </div>
                    <div className="2xl:hidden flex gap-4 flex-wrap">
                        <div className="flex items-center gap-2">
                            <div
                                className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                    mode === "list"
                                        ? "bg-primary-100 text-secondary-100"
                                        : "bg-secondary-100 text-primary-100 border border-primary-100"
                                }`}
                                onClick={() => setMode("list")}
                            >
                                <FontAwesomeIcon icon="list" />
                            </div>
                            <div
                                className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                    mode === "cards"
                                        ? "bg-primary-100 text-secondary-100"
                                        : "bg-secondary-100 text-primary-100 border border-primary-100"
                                }`}
                                onClick={() => setMode("cards")}
                            >
                                <FontAwesomeIcon icon="table-cells-large" />
                            </div>
                            <div
                                className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                    mode === "calendar"
                                        ? "bg-primary-100 text-secondary-100"
                                        : "bg-secondary-100 text-primary-100 border border-primary-100"
                                }`}
                                onClick={() => setMode("calendar")}
                            >
                                <FontAwesomeIcon icon="calendar" />
                            </div>
                        </div>
                        <Button
                            name={t("filters")}
                            onClick={() => setMobileFilters(true)}
                            icon="filter"
                            className="hidden sm:block"
                        />
                    </div>
                </div>
            )}
            <div className="h-full overflow-hidden flex mt-4 gap-4">
                <div className="sm:hidden lg:hidden  bg-secondary-100 rounded-lg shadow-lg">
                    <TaskFilters />
                </div>
                <div className="h-full shadow-lg rounded-lg bg-secondary-100 flex flex-col overflow-hidden w-full">
                    {modeComponentMapping[mode]}
                </div>
                <div className="sm:hidden">
                    <NewModal
                        title={t("filters")}
                        isShown={mobileFilters}
                        closeModal={() => setMobileFilters(false)}
                    >
                        <TaskFilters />
                    </NewModal>
                </div>
            </div>
        </>
    )
}
