import { PermissionType } from "@/models/Permission"

interface CheckboxInputProps {
    options: { label: string; value: any }[]
    value: string[]
    onChange: (value: string[]) => void
    disabled?: boolean
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
    options,
    value,
    onChange,
    disabled = false,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return
        if (e.target.checked) {
            onChange([...value, e.target.value as PermissionType])
        } else {
            onChange(value.filter((v) => v !== e.target.value))
        }
    }
    return (
        <div className={`${disabled ? "opacity-50 cursor-not-allowed" : ""}`}>
            {options.map((option, idx) => (
                <span key={idx} className="ml-3">
                    <input
                        id={option.label + idx}
                        type="checkbox"
                        name={option.label}
                        onChange={handleChange}
                        value={option.value}
                        checked={value.includes(option.value)}
                        disabled={disabled}
                    />
                    <label htmlFor={option.label + idx} className="ml-1">
                        {option.label}
                    </label>
                </span>
            ))}
        </div>
    )
}
