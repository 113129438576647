import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Scrollable } from "./Scrollable"

interface NewModalProps {
    isShown: boolean
    closeModal: () => void
    children: React.ReactNode
    title: string
    className?: string
}

export const NewModal: React.FC<NewModalProps> = ({
    isShown,
    closeModal,
    children,
    title,
    className,
}) => {
    const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }
    return isShown ? (
        <div
            className={`fixed top-0 left-0 w-screen h-screen bg-primary-300 bg-opacity-60 backdrop-blur-sm z-20 ${className}`}
        >
            <div className="w-full h-full max-h-screen flex justify-center items-center">
                <div
                    className="sm:min-w-full sm:w-full sm:h-full sm:flex sm:justify-center sm:items-center min-w-[500px] max-h-[80vh] sm:max-h-full flex justify-center items-center max-w-[80%] sm:max-w-full"
                    onClick={stopPropagation}
                >
                    <div
                        className="bg-secondary-100 rounded-lg shadow-lg overflow-hidden flex flex-col w-full h-full justify-center "
                        onClick={stopPropagation}
                    >
                        <div className="flex justify-between items-center bg-primary-100 p-5">
                            <h1 className="text-2xl font-bold text-secondary-100 mr-4">
                                {title}
                            </h1>
                            <div className="flex">
                                <button
                                    className="text-secondary-100 hover:text-secondary-400 transition hover:scale-105"
                                    onClick={closeModal}
                                >
                                    <FontAwesomeIcon icon="times" />
                                </button>
                            </div>
                        </div>
                        <Scrollable
                            height="100%"
                            width="100%"
                            className="flex flex-col !max-h-[80vh] sm:h-full"
                        >
                            <div className="w-full h-full flex flex-col justify-center items-center p-5">
                                {children}
                            </div>
                        </Scrollable>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}
