import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { NewModal } from "@/components/Layouts/NewModal"
import { Popup, POPUP_TYPES } from "@/components/Menus/Popup"
import { Form, SubmitType } from "@/features/Form/Form"
import { clearErrors } from "@/features/Projects/projectsSlice"
import { Article } from "@/models/Article"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ArticleForm } from "../../Articles/ArticleForm"
import { deleteArticle, getBudgetArticles } from "../../Articles/articlesSlice"

interface BudgetArticleTableActionsProps {
    article: Article
}

export const BudgetArticleTableActions: React.FC<
    BudgetArticleTableActionsProps
> = ({ article }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const budgetId = useParams<{ budgetId: string }>().budgetId ?? "-1"
    const ellipsisRef = useRef<HTMLDivElement>(null)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const status = useAppSelector((state) => state.article.status)

    const menuItems = [
        {
            title: t("edit_article"),
            icon: "edit" as IconProp,
            onClick: () => {
                setShowEditModal(true)
            },
        },
        {
            title: t("delete_article"),
            icon: "trash" as IconProp,
            onClick: () => {
                setShowDeleteModal(true)
            },
        },
    ]

    return (
        <div className="">
            <div
                className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                ref={ellipsisRef}
                onClick={() => {
                    setShowMenu(!showMenu)
                }}
            >
                <FontAwesomeIcon icon="ellipsis-v" className="cursor-pointer" />
            </div>
            <Popup
                parentRef={ellipsisRef}
                type={POPUP_TYPES.LEFT_CLICK}
                isShown={showMenu}
                closePopup={() => setShowMenu(false)}
            >
                <ul className="flex flex-col gap-2">
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className="flex gap-2 items-center hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b pl-2 pr-8 py-2"
                            onClick={() => {
                                item.onClick()
                                setShowMenu(false)
                            }}
                        >
                            <FontAwesomeIcon icon={item.icon} />
                            <p className="text-nowrap">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popup>
            <NewModal
                title={t("edit_article")}
                isShown={showEditModal}
                closeModal={() => setShowEditModal(false)}
            >
                <ArticleForm
                    article={article}
                    closeModal={() => {
                        setShowEditModal(false)
                    }}
                />
            </NewModal>
            <NewModal
                title={t("delete_article")}
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <Form
                    statuses={[status.delete]}
                    loadingTitle={t("deleting_article", {
                        article: article.name,
                    })}
                    className="w-full"
                    submitType={SubmitType.Delete}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            deleteArticle({
                                projectId,
                                articleId: article.id,
                            }),
                        )
                        if (type === "article/deleteArticle/fulfilled") {
                            setShowDeleteModal(false)
                            dispatch(getBudgetArticles({ projectId, budgetId }))
                        }
                    }}
                    clearErrors={clearErrors}
                >
                    <div className="w-full">
                        <h1 className="text-lg font-bold">
                            {t("deleting_article", {
                                article: article.name,
                            })}
                        </h1>
                        <p className="text-red-400 mb-4 font-bold">
                            {t("delete_article_notice")}
                        </p>
                    </div>
                </Form>
            </NewModal>
        </div>
    )
}
