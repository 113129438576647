import { useAppDispatch } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FragmentIdMap } from "@thatopen/fragments"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getProjectArticles } from "../../Finance/Articles/articlesSlice"
import { IfcHandler } from "../IfcHandler"
import { SelectionDetailsRow } from "./SelectionDetailsRow"

interface SelectionDetailsProps {
    ifcHandler: IfcHandler
    selection: FragmentIdMap
    close: () => void
}
interface element {
    uuid: string
    expressId: number
}

export const SelectionDetails: React.FC<SelectionDetailsProps> = ({
    ifcHandler,
    selection,
    close,
}) => {
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId

    const [elements, setElements] = useState<element[]>([])
    useEffect(() => {
        const elements: element[] = []
        Object.keys(selection).forEach((key) => {
            selection[key].forEach((id) => {
                elements.push({ uuid: key, expressId: id })
            })
        })
        setElements(elements)
    }, [])
    useEffect(() => {
        dispatch(getProjectArticles(projectId))
    }, [])
    return (
        <div className="sm:w-full">
            <div className="flex justify-end p-1">
                <div onClick={close}>
                    <FontAwesomeIcon
                        icon="times"
                        className="hover:text-red-600 transition cursor-pointer"
                    />
                </div>
            </div>
            <Scrollable height="400px">
                <div className="flex flex-col gap-2">
                    {elements.map((element) => (
                        <SelectionDetailsRow
                            key={element.uuid + element.expressId}
                            ifcHandler={ifcHandler}
                            element={element}
                        />
                    ))}
                </div>
            </Scrollable>
        </div>
    )
}
