import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { ProjectFile } from "@/models/File"
import { IfcModel } from "@/models/Ifc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { IfcHandler } from "../IfcHandler"
import { IfcModelFiles } from "../IfcModelFiles"
import {
    getIfcFileCompletedEntities,
    getIfcFileInProgressEntities,
    getIfcFileNotStartedEntities,
    selectSelectedEntities,
    setIsLoading,
} from "../ifcSlice"
import { IfcFiltersForm } from "./IfcFiltersForm"
import { PlansList } from "./PlansList"
import { SpatialStructureTree } from "./SpatialStructureTree"

interface IfcToolBarProps {
    ifcHandler: IfcHandler
    selectedModel: IfcModel
    selectedFile: ProjectFile
}

export enum CAMERA_MODES {
    PERSPECTIVE = "perspective",
    ORTHOGRAPHIC = "orthographic",
    FIRST_PERSON = "first_person",
}

enum MODALS {
    addModel = "addModel",
    filter = "filter",
    plans = "plans",
    spatialStructure = "spatialStructure",
    filterProgress = "filterProgress",
}

enum ENTITIES_FILTER_MODES {
    ALL = "all",
    NOT_STARTED = "not_started",
    IN_PROGRESS = "in_progress",
    COMPLETED = "completed",
}

export const IfcToolBar: React.FC<IfcToolBarProps> = ({
    ifcHandler,
    selectedModel,
    selectedFile,
}) => {
    const [clipperEnabled, setClipperEnabled] = useState<boolean>(false)
    const [selectionMode, setSelectionMode] = useState<boolean>(true)
    const [showModals, setShowModals] = useState<{ [key in MODALS]: boolean }>({
        addModel: false,
        filter: false,
        plans: false,
        spatialStructure: false,
        filterProgress: false,
    })
    const [isExploded, setIsExploded] = useState<boolean>(false)
    const [distanceMeasurement, setDistanceMeasurement] =
        useState<boolean>(false)
    const [sectionPlane, setSectionPlane] = useState<boolean>(false)
    const [firstPerson, setFirstPerson] = useState<boolean>(false)
    const [cameraMode, setCameraMode] = useState<CAMERA_MODES>(
        CAMERA_MODES.PERSPECTIVE,
    )

    const entitiesToShow = useAppSelector(selectSelectedEntities)
    const [entitiesFilterMode, setEntitiesFilterMode] =
        useState<ENTITIES_FILTER_MODES>(ENTITIES_FILTER_MODES.ALL)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId
    const { t } = useTranslation()

    const toggleModal = (modal: MODALS) => {
        // set all to false and modal to true
        const newModals: { [key in MODALS]: boolean } = {
            addModel: false,
            filter: false,
            plans: false,
            spatialStructure: false,
            filterProgress: false,
        }
        newModals[modal] = !showModals[modal]
        setShowModals(newModals)
    }
    const hideModal = (modal: MODALS) => {
        setShowModals({ ...showModals, [modal]: false })
    }

    useEffect(() => {
        if (ifcHandler) {
            if (entitiesFilterMode === ENTITIES_FILTER_MODES.ALL) {
                ifcHandler.showAllEntities()
            } else if (
                entitiesFilterMode === ENTITIES_FILTER_MODES.NOT_STARTED
            ) {
                dispatch(
                    getIfcFileNotStartedEntities({
                        projectId,
                        ifcFileId: selectedFile?.id ?? "",
                    }),
                )
            } else if (
                entitiesFilterMode === ENTITIES_FILTER_MODES.IN_PROGRESS
            ) {
                dispatch(
                    getIfcFileInProgressEntities({
                        projectId,
                        ifcFileId: selectedFile?.id ?? "",
                    }),
                )
            } else if (entitiesFilterMode === ENTITIES_FILTER_MODES.COMPLETED) {
                dispatch(
                    getIfcFileCompletedEntities({
                        projectId,
                        ifcFileId: selectedFile?.id ?? "",
                    }),
                )
            }
        }
    }, [entitiesFilterMode])

    useEffect(() => {
        if (ifcHandler && entitiesToShow) {
            dispatch(setIsLoading(true))
            ifcHandler
                .isolateEntities(
                    entitiesToShow.map((entity) => entity.globalId),
                )
                .then(() => {
                    dispatch(setIsLoading(false))
                })
        }
    }, [entitiesToShow])

    return (
        <>
            <div className="flex relative flex-col">
                <div className="flex gap-1 border-b py-2 flex-col">
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${showModals.addModel ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="plus"
                            onClick={() => {
                                if (ifcHandler) {
                                    toggleModal(MODALS.addModel)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1 border-b py-2 flex-col">
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${selectionMode ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="arrow-pointer"
                            onClick={() => {
                                if (ifcHandler) {
                                    hideModal(MODALS.filter)
                                    ifcHandler.setIsSelecting(!selectionMode)
                                    setSelectionMode(!selectionMode)
                                }
                            }}
                        />
                    </div>
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${showModals.filter ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition `}
                    >
                        <FontAwesomeIcon
                            icon="filter"
                            onClick={() => {
                                if (ifcHandler) {
                                    setSelectionMode(false)
                                    ifcHandler.setIsSelecting(false)
                                    toggleModal(MODALS.filter)
                                }
                            }}
                        />
                    </div>
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${showModals.filterProgress ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition `}
                    >
                        <FontAwesomeIcon
                            icon="spinner"
                            onClick={() => {
                                if (ifcHandler) {
                                    toggleModal(MODALS.filterProgress)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1 border-b sm:border-0  py-2  flex-col">
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${clipperEnabled ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="scissors"
                            onClick={() => {
                                if (ifcHandler) {
                                    ifcHandler.setClipping(!clipperEnabled)
                                    setClipperEnabled(!clipperEnabled)
                                }
                            }}
                        />
                    </div>
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${isExploded ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="layer-group"
                            onClick={() => {
                                if (ifcHandler) {
                                    ifcHandler.setExplode(!isExploded)
                                    setIsExploded(!isExploded)
                                }
                            }}
                        />
                    </div>
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${sectionPlane ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="solar-panel"
                            onClick={async () => {
                                if (ifcHandler) {
                                    await ifcHandler.setPlans(!sectionPlane)
                                    setSectionPlane(!sectionPlane)
                                    toggleModal(MODALS.plans)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1 border-b flex-col  py-2">
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${distanceMeasurement ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="ruler"
                            onClick={() => {
                                if (ifcHandler) {
                                    ifcHandler.setDistanceMeasurement(
                                        !distanceMeasurement,
                                    )
                                    setDistanceMeasurement(!distanceMeasurement)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1 border-b flex-col py-2 ">
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${showModals.spatialStructure ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="folder-tree"
                            onClick={() => {
                                toggleModal(MODALS.spatialStructure)
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1 flex-col py-2">
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${firstPerson ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition `}
                    >
                        <FontAwesomeIcon
                            icon="person"
                            onClick={() => {
                                ifcHandler.setCameraMode(
                                    CAMERA_MODES.PERSPECTIVE,
                                )
                                setCameraMode(CAMERA_MODES.PERSPECTIVE)
                                ifcHandler.setFirstPerson(!firstPerson)
                                setFirstPerson(!firstPerson)
                            }}
                        />
                    </div>
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${cameraMode === CAMERA_MODES.ORTHOGRAPHIC ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="cube"
                            onClick={() => {
                                ifcHandler.setFirstPerson(false)
                                setFirstPerson(false)
                                ifcHandler.setCameraMode(
                                    CAMERA_MODES.ORTHOGRAPHIC,
                                )
                                setCameraMode(CAMERA_MODES.ORTHOGRAPHIC)
                            }}
                        />
                    </div>
                    <div
                        className={`w-6 h-6 flex items-center justify-center rounded-lg ${cameraMode === CAMERA_MODES.PERSPECTIVE ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                    >
                        <FontAwesomeIcon
                            icon="cubes"
                            onClick={() => {
                                ifcHandler.setFirstPerson(false)
                                setFirstPerson(false)
                                ifcHandler.setCameraMode(
                                    CAMERA_MODES.PERSPECTIVE,
                                )
                                setCameraMode(CAMERA_MODES.PERSPECTIVE)
                            }}
                        />
                    </div>
                </div>
                {showModals.plans && (
                    <div className="absolute bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-11 sm:max-w-[100px]">
                        <PlansList ifcHandler={ifcHandler} />
                    </div>
                )}
                {showModals.spatialStructure && (
                    <div className="absolute bg-primary-100 y py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-11 sm:max-w-[250px]">
                        <SpatialStructureTree ifcHandler={ifcHandler} />
                    </div>
                )}
                {showModals.filter && (
                    <div className="absolute bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-11">
                        <IfcFiltersForm ifcHandler={ifcHandler} />
                    </div>
                )}
                {showModals.addModel && (
                    <div className="absolute bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-11">
                        <IfcModelFiles
                            ifcHandler={ifcHandler}
                            selectedModel={selectedModel}
                            selectedFile={selectedFile}
                        />
                    </div>
                )}
                {showModals.filterProgress && (
                    <div className="absolute bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-14">
                        <div className="p-2">
                            <div className="bg-secondary-100 w-full rounded-lg shadow-lg p-2 flex items-center gap-2 mt-2 sm:flex-wrap">
                                <p className="text-lg font-bold text-primary-300 text-nowrap">
                                    {t("show_entities")}:{" "}
                                </p>
                                <Button
                                    name={t("all")}
                                    onClick={() => {
                                        setEntitiesFilterMode(
                                            ENTITIES_FILTER_MODES.ALL,
                                        )
                                    }}
                                    className={`hover:!text-secondary-100 hover:!bg-primary-100 transition ${entitiesFilterMode === ENTITIES_FILTER_MODES.ALL ? "" : "!text-primary-300 !bg-secondary-100 !border border-primary-100"}`}
                                />
                                <Button
                                    name={t("not_started")}
                                    onClick={() => {
                                        setEntitiesFilterMode(
                                            ENTITIES_FILTER_MODES.NOT_STARTED,
                                        )
                                    }}
                                    className={`hover:!text-secondary-100 hover:!bg-primary-100 transition ${entitiesFilterMode === ENTITIES_FILTER_MODES.NOT_STARTED ? "" : "!text-primary-300 !bg-secondary-100 !border border-primary-100"}`}
                                />
                                <Button
                                    name={t("in_progress")}
                                    onClick={() => {
                                        setEntitiesFilterMode(
                                            ENTITIES_FILTER_MODES.IN_PROGRESS,
                                        )
                                    }}
                                    className={`hover:!text-secondary-100 hover:!bg-primary-100 transition ${entitiesFilterMode === ENTITIES_FILTER_MODES.IN_PROGRESS ? "" : "!text-primary-300 !bg-secondary-100 !border border-primary-100"}`}
                                />
                                <Button
                                    name={t("completed")}
                                    onClick={() => {
                                        setEntitiesFilterMode(
                                            ENTITIES_FILTER_MODES.COMPLETED,
                                        )
                                    }}
                                    className={`hover:!text-secondary-100 hover:!bg-primary-100 transition ${entitiesFilterMode === ENTITIES_FILTER_MODES.COMPLETED ? "" : "!text-primary-300 !bg-secondary-100 !border border-primary-100"}`}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* {advancedSelection && !showPlans && (
                <div className="absolute top-[200%] bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-[50%] -translate-x-1/2 w-fit w-max-full">
                    <AdvancedSelectionTool ifcHandler={ifcHandler} />
                </div>
            )} */}
                {}
            </div>
        </>
    )
}
