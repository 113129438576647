import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { selectIsLogged } from "@/features/Authentication/authSlice"
import { Register } from "@/features/Authentication/Register"
import { InvitationStatus } from "@/models/Auth"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { updateInvitation, validateInvitationToken } from "./invitationSlice"

interface InvitationProps {}

export enum InvitationResponse {
    ACCEPT = "accept",
    REJECT = "reject",
}

export const Invitation: React.FC<InvitationProps> = () => {
    const [queryParams] = useSearchParams()
    const invitation = useAppSelector((state) => state.invitation.invitation)
    const status = useAppSelector((state) => state.invitation.status)
    const dispatch = useAppDispatch()
    const token = queryParams.get("token")
    const response = queryParams.get("response")
    const [isValid, setIsValid] = useState<boolean>(true)
    const { t } = useTranslation()
    const isLogged = useAppSelector(selectIsLogged)
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (token && response) {
            if (
                response !== InvitationResponse.ACCEPT &&
                response !== InvitationResponse.REJECT
            ) {
                setIsValid(false)
            } else {
                setIsValid(true)
            }
        } else {
            setIsValid(false)
        }
    }, [queryParams])
    useEffect(() => {
        if (isValid && !isLogged) {
            dispatch(validateInvitationToken(token))
            if (
                response === InvitationResponse.REJECT &&
                invitation.status === InvitationStatus.PENDING
            ) {
                dispatch(
                    updateInvitation({
                        token,
                        response: InvitationResponse.REJECT,
                    }),
                )
            }
        }
    }, [isValid])
    useEffect(() => {
        if (status.read === SliceStatus.FAILED) {
            setIsValid(false)
        }
    }, [status])
    useEffect(() => {
        if (isLogged) {
            navigate("/dashboard/")
        }
    }, [isLogged, navigate])

    return (
        <div className="bg-secondary-100 text-primary-300 p-4 rounded-lg shadow-lg min-w-[300px] z-10 relative">
            <WithLoader
                statuses={[status.read]}
                title={t("validating_invitation")}
            >
                <div className="flex justify-center items-center">
                    {isValid &&
                        invitation.status !== InvitationStatus.PENDING && (
                            <div>
                                <p>{t("invitation_already_rejected")}</p>
                            </div>
                        )}
                    {isValid &&
                        response === InvitationResponse.ACCEPT &&
                        invitation.status === InvitationStatus.PENDING && (
                            <div>
                                <Register />
                            </div>
                        )}
                    {!isValid && <p>{t("invalid_invitation")}</p>}
                </div>
            </WithLoader>
        </div>
    )
}
