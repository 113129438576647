import { MyFilesIcon } from "@/components/Icons/MyFilesIcon"
import { SharedFilesIcon } from "@/components/Icons/SharedFilesIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { Link, useParams } from "react-router-dom"

interface QuickAccessProps {}

export const QuickAccess: React.FC<QuickAccessProps> = () => {
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    return (
        <div className="p-2">
            <h1 className="text-2xl font-bold">{t("quick_access")}:</h1>
            <div>
                <div className="flex mt-2 sm:flex-wrap sm:gap-y-4 gap-x-2 sm:justify-evenly">
                    <div className="flex flex-col  items-center cursor-pointer w-28 text-sm h-36">
                        <Link
                            to={`/dashboard/projects/${projectId}/file-manager/my-files`}
                            className="flex flex-col  items-center"
                        >
                            <MyFilesIcon />
                            <p className="text-primary-300 text-center">
                                {t("my_files")}
                            </p>
                        </Link>
                    </div>
                    <div className="flex flex-col  items-center cursor-pointer w-28 text-sm h-36">
                        <Link
                            to={`/dashboard/projects/${projectId}/file-manager/shared-files`}
                            className="flex flex-col  items-center"
                        >
                            <SharedFilesIcon />
                            <p className="text-primary-300 text-center">
                                {t("shared_files")}
                            </p>
                        </Link>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer w-28 text-sm h-36">
                        <Link
                            to={`/dashboard/projects/${projectId}/file-manager/images`}
                            className="flex flex-col  items-center"
                        >
                            <FontAwesomeIcon
                                icon="image"
                                className=" text-primary-100 w-16 h-16"
                            />
                            <p className="text-primary-300 text-center">
                                {t("images")}
                            </p>
                        </Link>
                    </div>
                    <div className="flex flex-col  items-center cursor-pointer w-28 text-sm h-36">
                        <Link
                            to={`/dashboard/projects/${projectId}/file-manager/ifc-files`}
                            className="flex flex-col  items-center"
                        >
                            <FontAwesomeIcon
                                icon="cubes"
                                className=" text-primary-100 w-16 h-16"
                            />
                            <p className="text-primary-300 text-center">
                                {t("ifc_files")}
                            </p>
                        </Link>
                    </div>
                    <div className=" cursor-pointer w-28 text-sm h-36">
                        <Link
                            to={`/dashboard/projects/${projectId}/file-manager/pdf-files`}
                            className="flex flex-col  items-center"
                        >
                            <FontAwesomeIcon
                                icon="file-pdf"
                                className=" text-primary-100 w-16 h-16"
                            />
                            <p className="text-primary-300 text-center">
                                {t("pdf_files")}
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
