import { Task, TaskStatus } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

interface CalendarTaskRowProps {
    task: Task
}

export const CalendarTaskRow: React.FC<CalendarTaskRowProps> = ({ task }) => {
    const statusClassMapping = {
        [TaskStatus.NOT_STARTED]: "bg-gray-400 text-secondary-100",
        [TaskStatus.IN_PROGRESS]: "bg-yellow-500 text-secondary-100",
        [TaskStatus.COMPLETED]: "bg-green-500 text-secondary-100",
    }
    const { t } = useTranslation()
    const location = useLocation()
    return (
        <Link to={`${location.pathname}/${task.id}`}>
            <div
                className={`${
                    statusClassMapping[task.status]
                } p-2 rounded-lg text-ellipsis cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100`}
            >
                <div className="flex items-center gap-2 justify-between">
                    <p>{task.name}</p>
                    <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon="calendar" />
                        <p>
                            {t("intlDateTime", {
                                val: task.endDate,
                                formatParams: {
                                    val: {
                                        weekday: "short",
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    },
                                },
                            })}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    )
}
