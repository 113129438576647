import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { SliceStatus } from "@/common/types"
import {
    Budget,
    BudgetInterface,
    BudgetPayload,
    GeneralBudgetInfo,
} from "@/models/Finance"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    createBudgetApi,
    deleteBudgetApi,
    getBudgetsApi,
    getBudgetStatisticsApi,
    getBudgetsTreesApi,
    getGeneralBudgetInfoApi,
    updateBudgetApi,
} from "./financeApi"

export const getBudgets = createAsyncThunkWithNotification(
    "projects/getBudgets",
    async (projectId: string) => {
        const response = await getBudgetsApi(projectId)
        return response
    },
)

export const getBudgetsTrees = createAsyncThunkWithNotification(
    "projects/getBudgetsTrees",
    async (projectId: string) => {
        const response = await getBudgetsTreesApi(projectId)
        return response
    },
)

export const getGeneralBudgetInfo = createAsyncThunkWithNotification(
    "projects/getGeneralBudgetInfo",
    async (projectId: string) => {
        const response = await getGeneralBudgetInfoApi(projectId)
        return response
    },
)

export const createBudget = createAsyncThunkWithNotification(
    "projects/createBudget",
    async ({
        projectId,
        payload,
    }: {
        projectId: string
        payload: BudgetPayload
    }) => {
        const response = await createBudgetApi(projectId, payload)
        return response
    },
)

export const updateBudget = createAsyncThunkWithNotification(
    "projects/updateBudget",
    async ({
        projectId,
        budgetId,
        payload,
    }: {
        projectId: string
        budgetId: string
        payload: BudgetPayload
    }) => {
        const response = await updateBudgetApi(projectId, budgetId, payload)
        return response
    },
)

export const deleteBudget = createAsyncThunkWithNotification(
    "projects/deleteBudget",
    async ({
        projectId,
        budgetId,
    }: {
        projectId: string
        budgetId: string
    }) => {
        const response = await deleteBudgetApi(projectId, budgetId)
        return response
    },
)

export const getBudgetStatistics = createAsyncThunkWithNotification(
    "projects/getBudgetStatistics",
    async ({
        projectId,
        budgetId,
    }: {
        projectId: string
        budgetId: string
    }) => {
        const response = await getBudgetStatisticsApi(projectId, budgetId)
        return response
    },
)

interface FinanceState {
    generalBudgetInfo: GeneralBudgetInfo[]
    budgetStatistics: BudgetInterface
    budgets: BudgetInterface[]
    budgetsTrees: BudgetInterface[]
    status: {
        read: SliceStatus
        write: SliceStatus
    }
}

const initialState: FinanceState = {
    generalBudgetInfo: [],
    budgetStatistics: new Budget().toJson(),
    budgets: [],
    budgetsTrees: [],
    status: {
        read: SliceStatus.IDLE,
        write: SliceStatus.IDLE,
    },
}

const updateBudgetsTreeHelper = (
    budgetTree: BudgetInterface,
    budget: BudgetInterface,
): BudgetInterface => {
    if (budgetTree.id === budget.id) {
        return budget
    }
    if (budgetTree.children) {
        return {
            ...budgetTree,
            children: budgetTree.children.map((child) =>
                updateBudgetsTreeHelper(child, budget),
            ),
        }
    }
    return budgetTree
}

const addBudgetToTreeHelper = (
    budgetTree: BudgetInterface,
    budget: BudgetInterface,
): BudgetInterface => {
    if (budgetTree.id === budget.parent?.id) {
        return {
            ...budgetTree,
            children: [...(budgetTree.children ?? []), budget],
        }
    }
    if (budgetTree.children) {
        return {
            ...budgetTree,
            children: budgetTree.children.map((child) =>
                addBudgetToTreeHelper(child, budget),
            ),
        }
    }
    return budgetTree
}

const deleteBudgetFromTreeHelper = (
    budgetTree: BudgetInterface,
    budgetId: string,
): BudgetInterface => {
    if (
        budgetTree.children &&
        budgetTree.children.map((child) => child.id).includes(budgetId)
    ) {
        return {
            ...budgetTree,
            children: budgetTree.children.filter(
                (child) => child.id !== budgetId,
            ),
        }
    }
    if (budgetTree.children) {
        return {
            ...budgetTree,
            children: budgetTree.children.map((child) =>
                deleteBudgetFromTreeHelper(child, budgetId),
            ),
        }
    }
    return budgetTree
}

const financeSlice = createSlice({
    name: "finance",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBudgets.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getBudgets.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.budgets = action.payload.data.data
        })
        builder.addCase(getBudgets.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
        })
        builder.addCase(getGeneralBudgetInfo.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getGeneralBudgetInfo.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.generalBudgetInfo = action.payload.data.data
        })
        builder.addCase(getGeneralBudgetInfo.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
        })
        builder.addCase(createBudget.pending, (state) => {
            state.status.write = SliceStatus.LOADING
        })
        builder.addCase(createBudget.fulfilled, (state, action) => {
            state.status.write = SliceStatus.IDLE
            state.budgets.push(action.payload.data.data)
            if (!action.payload.data.data.parent) {
                state.budgetsTrees = [
                    ...state.budgetsTrees,
                    action.payload.data.data,
                ]
            } else {
                state.budgetsTrees = state.budgetsTrees.map((budget) =>
                    addBudgetToTreeHelper(budget, action.payload.data.data),
                )
            }
        })
        builder.addCase(createBudget.rejected, (state) => {
            state.status.write = SliceStatus.FAILED
        })
        builder.addCase(getBudgetsTrees.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getBudgetsTrees.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.budgetsTrees = action.payload.data.data
        })
        builder.addCase(getBudgetsTrees.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
        })
        builder.addCase(updateBudget.pending, (state) => {
            state.status.write = SliceStatus.LOADING
        })
        builder.addCase(updateBudget.fulfilled, (state, action) => {
            state.status.write = SliceStatus.IDLE
            state.budgets = state.budgets.map((budget) =>
                budget.id === action.payload.data.data.id
                    ? action.payload.data.data
                    : budget,
            )
            state.budgetsTrees = state.budgetsTrees.map((budget) =>
                updateBudgetsTreeHelper(budget, action.payload.data.data),
            )
        })
        builder.addCase(updateBudget.rejected, (state) => {
            state.status.write = SliceStatus.FAILED
        })
        builder.addCase(deleteBudget.pending, (state) => {
            state.status.write = SliceStatus.LOADING
        })
        builder.addCase(deleteBudget.fulfilled, (state, action) => {
            state.status.write = SliceStatus.IDLE
            state.budgets = state.budgets.filter(
                (budget) => budget.id !== action.payload.data.data,
            )
            if (
                state.budgetsTrees
                    .map((budget) => budget.id)
                    .includes(action.payload.data.data)
            ) {
                state.budgetsTrees = state.budgetsTrees.filter(
                    (budget) => budget.id !== action.payload.data.data,
                )
            } else {
                state.budgetsTrees = state.budgetsTrees.map((budget) =>
                    deleteBudgetFromTreeHelper(
                        budget,
                        action.payload.data.data,
                    ),
                )
            }
        })
        builder.addCase(deleteBudget.rejected, (state) => {
            state.status.write = SliceStatus.FAILED
        })
        builder.addCase(getBudgetStatistics.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getBudgetStatistics.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.budgetStatistics = action.payload.data.data
        })
        builder.addCase(getBudgetStatistics.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
        })
    },
})

const selectBudgetsRaw = (state: RootState) => state.finance.budgets
const selectBudgetsTreesRaw = (state: RootState) => state.finance.budgetsTrees
const selectBudgetStatisticsRaw = (state: RootState) =>
    state.finance.budgetStatistics

export const selectBudgetsTrees = createSelector(
    [selectBudgetsTreesRaw],
    (budgets) => budgets.map((budget) => new Budget(budget)),
)
export const selectBudgets = createSelector([selectBudgetsRaw], (budgets) =>
    budgets.map((budget) => new Budget(budget)),
)

export const selectBudgetStatistics = createSelector(
    [selectBudgetStatisticsRaw],
    (budgetStatistics) => new Budget(budgetStatistics),
)

export default financeSlice.reducer
