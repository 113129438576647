import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MouseEventHandler, useEffect, useRef, useState } from "react"

export interface ContextMenuItem {
    name: string
    icon: IconProp
    onClick: MouseEventHandler<HTMLLIElement>
}

interface WithContextMenuProps {
    children: React.ReactNode
    items: ContextMenuItem[]
    isShown?: boolean
    menuCords?: [number, number]
    onMouseEnter?: () => void
    onClick?: () => void
    className?: string
    contextMenuClassName?: string
    disableContextMenu?: boolean
}

export const WithContextMenu: React.FC<WithContextMenuProps> = ({
    children,
    items,
    isShown = false,
    menuCords = [0, 0],
    className,
    contextMenuClassName,
    onMouseEnter = () => {},
    onClick = () => {},
    disableContextMenu = false,
}) => {
    const [showContextMenu, setShowContextMenu] = useState<boolean>(isShown)
    const divRef = useRef<HTMLDivElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const [cords, setCords] = useState<{ x: number; y: number }>({
        x: menuCords[0],
        y: menuCords[1],
    })

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (divRef.current?.contains(e.target as Node)) {
            setShowContextMenu(!showContextMenu)
            const { width: menuWidth, height: menuHeight } =
                menuRef.current?.getBoundingClientRect() || {
                    width: 0,
                    height: 0,
                }
            const x =
                e.clientX + menuWidth > window.innerWidth
                    ? e.clientX - menuWidth
                    : e.clientX
            const y =
                e.clientY + menuHeight > window.innerHeight
                    ? e.clientY - menuHeight
                    : e.clientY
            setCords({ x, y })
        } else {
            setShowContextMenu(false)
        }
    }

    useEffect(() => {
        const handleLeftClick = (e: MouseEvent) => {
            setShowContextMenu(false)
        }
        const handleRightClick = (e: any) => {
            handleClick(e)
        }
        document.addEventListener("click", handleLeftClick)
        document.addEventListener("contextmenu", handleRightClick)
        return () => {
            document.removeEventListener("click", handleLeftClick)
            document.removeEventListener("contextmenu", handleRightClick)
        }
    }, [])
    return (
        <div className="relative" onContextMenu={handleClick} ref={divRef}>
            <div
                className={className}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            >
                {children}
            </div>
            {showContextMenu && items.length > 0 && (
                <div
                    ref={menuRef}
                    className="fixed z-50 min-w-[200px] bg-secondary-100 shadow-lg rounded-lg flex flex-col gap-2 font-normal h-fit"
                    style={{
                        top: `${cords.y}px`,
                        left: `${cords.x}px`,
                    }}
                >
                    <ul>
                        {items.map((item, idx) => (
                            <li
                                key={idx}
                                onMouseDown={item.onClick}
                                className="flex items-center gap-2 border-b py-2 px-4 hover:bg-primary-100 hover:text-secondary-100 cursor-pointer"
                            >
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    className="w-8"
                                />
                                <p>{item.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
