import { useAppDispatch } from "@/app/hooks"
import { RootState } from "@/app/store"
import { SliceStatus } from "@/common/types"
import { TaskPerUserStatistics } from "@/models/Statistics"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getProjectTaskStatisticsPerUser } from "./statisticsSlice"

interface StatisticsTaskPerUserProps {}

export const StatisticsTaskPerUserTable: React.FC<
    StatisticsTaskPerUserProps
> = () => {
    const projectId = useParams<{ projectId: string }>().projectId
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const taskPerUser = useSelector(
        (state: RootState) => state.statistics.tasksPerUser,
    )
    const status = useSelector((state: RootState) => state.statistics.status)
    useEffect(() => {
        dispatch(getProjectTaskStatisticsPerUser(projectId))
    }, [])
    const columns = useMemo<MRT_ColumnDef<TaskPerUserStatistics>[]>(
        () => [
            {
                accessorKey: "user",
                header: t("user"),
            },
            {
                accessorKey: "tasksCount",
                header: t("tasks_count"),
            },
            {
                accessorKey: "tasksApproved",
                header: t("tasks_approved"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksApproved} (
                        {(
                            (row.original.tasksApproved /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksWithIssues",
                header: t("tasks_with_issues"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksWithIssues} (
                        {(
                            (row.original.tasksWithIssues /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksApprovedWithIssues",
                header: t("tasks_approved_with_issues"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksApprovedWithIssues} (
                        {(
                            (row.original.tasksApprovedWithIssues /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksApprovedWithoutIssues",
                header: t("tasks_approved_without_issues"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksApprovedWithoutIssues} (
                        {(
                            (row.original.tasksApprovedWithoutIssues /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksOnTime",
                header: t("tasks_on_time"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksOnTime} (
                        {(
                            (row.original.tasksOnTime /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksDelayedStart",
                header: t("tasks_delayed_start"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksDelayedStart} (
                        {(
                            (row.original.tasksDelayedStart /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksDelayedEnd",
                header: t("tasks_delayed_end"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksDelayedEnd} (
                        {(
                            (row.original.tasksDelayedEnd /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
            {
                accessorKey: "tasksDelayedBoth",
                header: t("tasks_delayed_both"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {row.original.tasksDelayedBoth} (
                        {(
                            (row.original.tasksDelayedBoth /
                                row.original.tasksCount) *
                            100
                        ).toFixed(2)}
                        %)
                    </p>
                ),
            },
        ],
        [],
    )
    const table = useMantineReactTable<TaskPerUserStatistics>({
        columns,
        data: taskPerUser,
        positionPagination: "top",
        state: { isLoading: status.read === SliceStatus.LOADING },
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                navigate(
                    `/dashboard/projects/${projectId}/statistics/users/${row.original.userId}/`,
                )
            },
            sx: {
                cursor: "pointer", //you might want to change the cursor too when adding an onClick
            },
        }),
    })
    return <MantineReactTable table={table} />
}
