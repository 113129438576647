import { ResourcesIds } from "@/app/common"
import { PermissionGroup } from "@/models/Permission"
import { User } from "@/models/User"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Scrollable } from "../Layouts/Scrollable"

interface UsersOrGroupsInputProps {
    value: ResourcesIds
    setValue: (value: ResourcesIds) => void
    users: User[]
    groups: PermissionGroup[]
    placeholder?: string
    zIndex?: string
    disableGroups?: boolean
}

export const UsersOrGroupsInput: React.FC<UsersOrGroupsInputProps> = ({
    value,
    setValue,
    users,
    groups,
    placeholder,
    zIndex,
    disableGroups = false,
}) => {
    const { t } = useTranslation()

    const [mode, setMode] = useState<"users" | "groups">(
        disableGroups ? "users" : "groups",
    )
    const [expanded, setExpanded] = useState<boolean>(false)
    const [selectedUsers, setSelectedUsers] = useState<User[]>([])
    const [selectedGroups, setSelectedGroups] = useState<PermissionGroup[]>([])
    const dropDownRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [dropDownCords, setDropDownCords] = useState({ x: 0, y: 0, width: 0 })
    const [reversed, setReversed] = useState<boolean>(false)

    useEffect(() => {
        setSelectedUsers(users.filter((user) => value.users.includes(user.id)))
        setSelectedGroups(
            groups.filter((group) => value.groups.includes(group.id)),
        )
    }, [value, groups, users])
    useEffect(() => {
        const closeDropDown = (e: any) => {
            if (
                e.target !== dropDownRef.current &&
                !dropDownRef.current?.contains(e.target) &&
                !inputRef.current?.contains(e.target)
            ) {
                setExpanded(false)
            } else if (
                inputRef.current?.contains(e.target) &&
                e.target !== dropDownRef.current &&
                !dropDownRef.current?.contains(e.target)
            ) {
                const cords = inputRef.current?.getBoundingClientRect()
                if (cords) {
                    if (cords.y + cords.height + 200 > window.innerHeight) {
                        setDropDownCords({
                            x: cords.x,
                            y: cords.y - (200 + cords.height + 20),
                            width: cords.width,
                        })
                        setReversed(true)
                    } else {
                        setDropDownCords({
                            x: cords.x,
                            y: cords.y + cords.height,
                            width: cords.width,
                        })
                        setReversed(false)
                    }
                }
                setExpanded(!expanded)
            }
        }
        document.addEventListener("mousedown", closeDropDown)
        return () => {
            document.removeEventListener("mousedown", closeDropDown)
        }
    })
    return (
        <div className="w-[400px]">
            <div className="border border-primary-100 border-opacity-45 rounded-lg mb-2 p-1 w-full">
                <Scrollable height="70px" className="overflow-x-hidden">
                    {selectedUsers.length > 0 && (
                        <div className="flex flex-wrap gap-x-1">
                            <p className="font-bold">{t("selected_users")}: </p>
                            <div className="flex flex-wrap gap-1">
                                {selectedUsers.map((user, index) => (
                                    <p key={user.id}>
                                        {user.fullName}
                                        {index !== selectedUsers.length - 1
                                            ? ","
                                            : ""}
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}
                    {!disableGroups && selectedGroups.length > 0 && (
                        <div className="flex flex-wrap gap-x-1">
                            <p className="font-bold">{t("selected_groups")}:</p>
                            <p className="text-wrap">
                                {selectedGroups
                                    .map((group) => group.name)
                                    .join(", ")}
                            </p>
                        </div>
                    )}
                    {selectedGroups.length === 0 &&
                        selectedUsers.length === 0 && (
                            <div className="flex items-center justify-center h-full gap-2 opacity-50">
                                <FontAwesomeIcon icon="exclamation-triangle" />
                                <p>{t("nothing_is_selected")}</p>
                            </div>
                        )}
                </Scrollable>
            </div>
            <div
                className="border border-primary-100 border-opacity-45 rounded-lg h-8 w-full flex justify-between items-center p-1 cursor-pointer"
                ref={inputRef}
            >
                <p className="opacity-50 text-sm p-1">
                    {placeholder ? placeholder : t("click_to_select")}
                </p>
                <FontAwesomeIcon
                    icon={expanded ? "chevron-up" : "chevron-down"}
                />
                {expanded && (
                    <div
                        className={`absolute w-[400px] bg-secondary-100 top-8 shadow-lg rounded-lg z-20 ${zIndex} flex flex-col`}
                        onClick={(e) => e.stopPropagation()}
                        ref={dropDownRef}
                        style={{
                            left: dropDownCords.x,
                            top: dropDownCords.y,
                            width: dropDownCords.width,
                            flexDirection: reversed
                                ? "column-reverse"
                                : "column",
                            boxShadow: reversed
                                ? "0px -4px 4px rgba(0, 0, 0, 0.1)"
                                : "0px 4px 4px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div className="flex w-full shadow-lg">
                            {!disableGroups && (
                                <div
                                    className={`w-full flex gap-2 items-center justify-center rounded-tl-sm p-2 ${mode === "groups" ? "bg-primary-100 text-secondary-100" : ""} hover:bg-primary-100 hover:text-secondary-100 transition`}
                                    onClick={() => setMode("groups")}
                                >
                                    <FontAwesomeIcon icon="users" />
                                    {t("groups")}
                                </div>
                            )}
                            <div
                                className={`w-full flex gap-2 items-center justify-center rounded-tr-sm p-2 ${mode === "users" ? "bg-primary-100 text-secondary-100" : ""} hover:bg-primary-100 hover:text-secondary-100 transition`}
                                onClick={() => setMode("users")}
                            >
                                <FontAwesomeIcon icon="user" />
                                {t("users")}
                            </div>
                        </div>
                        <div className="p-1">
                            <Scrollable height="200px">
                                {mode === "groups" && (
                                    <div>
                                        <div className="flex w-full justify-evenly">
                                            <div
                                                className="flex items-center gap-1 hover:text-primary-100 transition"
                                                onClick={() => {
                                                    setValue({
                                                        ...value,
                                                        groups: groups.map(
                                                            (group) => group.id,
                                                        ),
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon icon="check" />
                                                <p>{t("select_all")}</p>
                                            </div>
                                            <div
                                                className="flex items-center gap-1 hover:text-red-400 transition"
                                                onClick={() =>
                                                    setValue({
                                                        ...value,
                                                        groups: [],
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon="times" />
                                                <p>{t("clear")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {groups.length > 0 &&
                                                groups.map((group, index) => (
                                                    <div
                                                        key={index}
                                                        className={`flex items-center justify-between p-2 hover:bg-primary-100 hover:text-secondary-100 transition 
                                            ${value.groups.includes(group.id) && "bg-primary-100 text-secondary-100"}`}
                                                        onClick={() => {
                                                            if (
                                                                value.groups.includes(
                                                                    group.id,
                                                                )
                                                            ) {
                                                                setValue({
                                                                    ...value,
                                                                    groups: value.groups.filter(
                                                                        (id) =>
                                                                            id !==
                                                                            group.id,
                                                                    ),
                                                                })
                                                            } else {
                                                                setValue({
                                                                    ...value,
                                                                    groups: [
                                                                        ...value.groups,
                                                                        group.id,
                                                                    ],
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <p>{group.name}</p>
                                                        {value.groups.includes(
                                                            group.id,
                                                        ) && (
                                                            <FontAwesomeIcon icon="check" />
                                                        )}
                                                    </div>
                                                ))}
                                            {groups.length === 0 && (
                                                <div className="flex items-center justify-center h-10 opacity-50 gap-2">
                                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                                    <p>
                                                        {t("no_groups_found")}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {mode === "users" && (
                                    <div>
                                        <div className="flex w-full justify-evenly">
                                            <div
                                                className="flex items-center gap-1 hover:text-primary-100 transition"
                                                onClick={() => {
                                                    setValue({
                                                        ...value,
                                                        users: users.map(
                                                            (user) => user.id,
                                                        ),
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon icon="check" />
                                                <p>{t("select_all")}</p>
                                            </div>
                                            <div
                                                className="flex items-center gap-1 hover:text-red-400 transition"
                                                onClick={() =>
                                                    setValue({
                                                        ...value,
                                                        users: [],
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon="times" />
                                                <p>{t("clear")}</p>
                                            </div>
                                        </div>

                                        <div>
                                            {users.length > 0 &&
                                                users.map((user, index) => (
                                                    <div
                                                        key={index}
                                                        className={`flex items-center justify-between p-2 hover:bg-primary-100 hover:text-secondary-100 transition 
                                            ${value.users.includes(user.id) && "bg-primary-100 text-secondary-100"}`}
                                                        onClick={() => {
                                                            if (
                                                                value.users.includes(
                                                                    user.id,
                                                                )
                                                            ) {
                                                                setValue({
                                                                    ...value,
                                                                    users: value.users.filter(
                                                                        (id) =>
                                                                            id !==
                                                                            user.id,
                                                                    ),
                                                                })
                                                            } else {
                                                                setValue({
                                                                    ...value,
                                                                    users: [
                                                                        ...value.users,
                                                                        user.id,
                                                                    ],
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <p>{user.email}</p>
                                                        {value.users.includes(
                                                            user.id,
                                                        ) && (
                                                            <FontAwesomeIcon icon="check" />
                                                        )}
                                                    </div>
                                                ))}
                                            {users.length === 0 && (
                                                <div className="flex items-center justify-center h-10 opacity-50 gap-2">
                                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                                    <p>{t("no_users_found")}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Scrollable>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
