import { t } from "i18next"

export interface BaseModelInterface {
    id: string
    isArchived: boolean
    createdAt?: Date | string
    updatedAt?: Date | string
}

export class BaseModel {
    public id: string
    public isArchived: boolean
    public createdAt: Date
    public updatedAt: Date

    constructor(data?: BaseModelInterface) {
        this.id = data?.id ?? "-1"
        this.isArchived = data?.isArchived ?? false
        this.createdAt = data?.createdAt ? new Date(data.createdAt) : new Date()
        this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : new Date()
    }

    get timeSinceCreation(): string {
        const diff = new Date().getTime() - this.createdAt.getTime()
        const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
        const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
        const days = Math.floor(diff / (1000 * 60 * 60 * 24))
        const hours = Math.floor(diff / (1000 * 60 * 60))
        const minutes = Math.floor(diff / (1000 * 60))
        const seconds = Math.floor(diff / 1000)

        if (years > 0) {
            return `${years} ${years !== 1 ? t("years_ago") : t("year_ago")}`
        } else if (months > 0) {
            return `${months} ${
                months !== 1 ? t("months_ago") : t("month_ago")
            }`
        } else if (days > 0) {
            return `${days} ${days !== 1 ? t("days_ago") : t("day_ago")}`
        } else if (hours > 0) {
            return `${hours} ${hours !== 1 ? t("hours_ago") : t("hour_ago")}`
        } else if (minutes > 0) {
            return `${minutes} ${
                minutes !== 1 ? t("minutes_ago") : t("minute_ago")
            }`
        } else {
            return `${seconds} ${
                seconds !== 1 ? t("seconds_ago") : t("second_ago")
            }`
        }
    }

    get timeSinceUpdate(): string {
        const diff = new Date().getTime() - this.updatedAt.getTime()
        const days = Math.floor(diff / (1000 * 60 * 60 * 24))
        const hours = Math.floor(diff / (1000 * 60 * 60))
        const minutes = Math.floor(diff / (1000 * 60))
        const seconds = Math.floor(diff / 1000)

        if (days > 0) {
            return `${days} days ago`
        } else if (hours > 0) {
            return `${hours} hours ago`
        } else if (minutes > 0) {
            return `${minutes} minutes ago`
        } else {
            return `${seconds} seconds ago`
        }
    }

    public toJson(): BaseModelInterface {
        return {
            id: this.id,
            isArchived: this.isArchived,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        }
    }
}
