import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpring } from "@react-spring/web"
import { useEffect, useRef, useState } from "react"

interface HorizontalSmoothScrollProps {
    children: React.ReactNode
}

export const HorizontalSmoothScroll: React.FC<HorizontalSmoothScrollProps> = ({
    children,
}) => {
    const horizontalRef = useRef<HTMLDivElement>(null)
    const [scrollSpring, scrollSpringApi] = useSpring(() => ({ x: 0 }))
    const [enableScroll, setEnableScroll] = useState<boolean>(true)
    const [currentElement, setCurrentElement] = useState<number>(0)
    const [numOfElements, setNumOfElements] = useState<number>(0)
    const [elementWidth, setElementWidth] = useState<number>(0)
    const showNextElement = () => {
        if (horizontalRef.current && enableScroll) {
            const elementWidth =
                horizontalRef.current.getBoundingClientRect().width
            if (
                !(
                    scrollSpring.x.get() - elementWidth <=
                    -elementWidth * horizontalRef.current.children.length
                )
            ) {
                scrollSpringApi.start({
                    from: { x: scrollSpring.x.get() },
                    to: { x: scrollSpring.x.get() - elementWidth },
                    onStart: () => {
                        setEnableScroll(false)
                        setCurrentElement(currentElement + 1)
                    },
                    onRest: () => {
                        setEnableScroll(true)
                    },
                })
            }
        }
    }
    const showPreviousElement = () => {
        if (horizontalRef.current && enableScroll) {
            if (!(scrollSpring.x.get() + elementWidth > 0)) {
                scrollSpringApi.start({
                    from: { x: scrollSpring.x.get() },
                    to: { x: scrollSpring.x.get() + elementWidth },
                    onStart: () => {
                        setEnableScroll(false)
                        setCurrentElement(currentElement - 1)
                    },
                    onRest: () => {
                        setEnableScroll(true)
                    },
                })
            }
        }
    }

    useEffect(() => {
        if (horizontalRef.current) {
            const elementWidth =
                horizontalRef.current.getBoundingClientRect().width
            setElementWidth(elementWidth)
            setNumOfElements(horizontalRef.current.children.length)
        }
    }, [horizontalRef])

    return (
        <div
            className="h-full w-full relative overflow-hidden"
        >
            {currentElement > 0 && (
                <div
                    onClick={showPreviousElement}
                    className="cursor-pointer hover:opacity-100 transition absolute top-1/2 -translate-y-1/2 z-20 left-2 opacity-50 bg-primary-100 w-8 h-8 flex items-center justify-center rounded-full text-secondary-100"
                >
                    <FontAwesomeIcon icon="chevron-left" className="text-xl" />
                </div>
            )}
            <animated.div
                className="flex w-full h-full no-scrollbar absolute"
                ref={horizontalRef}
                style={scrollSpring}
            >
                {children}
            </animated.div>
            {currentElement < numOfElements - 1 && (
                <div
                    onClick={showNextElement}
                    className="cursor-pointer hover:opacity-100 transition absolute top-1/2 -translate-y-1/2 z-20 right-2 opacity-50 bg-primary-100 w-8 h-8 flex items-center justify-center rounded-full text-secondary-100"
                >
                    <FontAwesomeIcon icon="chevron-right" className="text-xl" />
                </div>
            )}
        </div>
    )
}
