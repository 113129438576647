import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { IfcHandler } from "../IfcHandler"
import { SpatialStructureElementsRows } from "./SpatialStructureElementsRows"

interface SpatialStructureTreeRowProps {
    ifcHandler: IfcHandler
    name: string
    value: { [key: string]: Set<number> }
    search?: string
}

export const SpatialStructureTreeRow: React.FC<
    SpatialStructureTreeRowProps
> = ({ ifcHandler, name, value, search }) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [isHidden, setIsHidden] = useState<boolean>(false)
    return (
        <div className="bg-secondary-100 text-primary-300 rounded-lg shadow-lg">
            <div
                onClick={() => setIsExpanded(!isExpanded)}
                className="w-full flex gap-4 justify-between items-center p-2 transition hover:bg-primary-100 hover:text-secondary-100 cursor-pointer px-4 rounded-lg  "
            >
                <p className="text-nowrap">{name}</p>
                <div
                    className="flex items-center gap-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    <FontAwesomeIcon
                        icon={isHidden ? "eye-slash" : "eye"}
                        className="text-xs cursor-pointer hover:scale-110 w-4 h-4"
                        onClick={() => {
                            ifcHandler.setSelectionVisibility(isHidden, value)
                            setIsHidden(!isHidden)
                        }}
                    />
                    <FontAwesomeIcon
                        icon="mouse-pointer"
                        className="text-xs cursor-pointer hover:scale-110 w-4 h-4"
                        onClick={() => {
                            ifcHandler.select(value)
                        }}
                    />
                    <FontAwesomeIcon
                        className="text-sm cursor-pointer hover:scale-110 w-4 h-4"
                        icon={isExpanded ? "chevron-down" : "chevron-right"}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </div>
            </div>
            {isExpanded && (
                <SpatialStructureElementsRows
                    ifcHandler={ifcHandler}
                    value={value}
                    search={search}
                />
            )}
        </div>
    )
}
