import { ProjectFile } from "@/models/File"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { InputLabel } from "./InputLabel"

interface ImageInputProps {
    images: ProjectFile[]
    setImagesToUpload: (images: File[]) => void
    setImagesToDelete: (images: string[]) => void
    label?: string
    required?: boolean
}

export const ImageInput: React.FC<ImageInputProps> = ({
    images = [],
    setImagesToUpload,
    setImagesToDelete,
    label,
    required = false,
}) => {
    const [localImages, setLocalImages] = useState<ProjectFile[]>(images)
    const [uploadedImage, setUploadedImage] = useState<File>()
    const [uploadedImages, setUploadedImages] = useState<File[]>([])
    const [deletedImages, setDeletedImages] = useState<string[]>([])
    const inputRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    useEffect(() => {
        if (uploadedImage) {
            setUploadedImages([...uploadedImages, uploadedImage])
        }
    }, [uploadedImage])
    useEffect(() => {
        setImagesToUpload(uploadedImages)
    }, [uploadedImages])
    useEffect(() => {
        setImagesToDelete(deletedImages)
    }, [deletedImages])
    return (
        <div>
            <InputLabel label={label} required={required} />
            <div className="flex gap-2 items-center flex-wrap w-full">
                {localImages
                    .filter((image) => image.id != "")
                    .map((image) => (
                        <div className="relative" key={image.id}>
                            <div>
                                <FontAwesomeIcon
                                    icon={"times"}
                                    className="absolute top-1 right-1 cursor-pointer text-red-400 bg-secondary-100 p-1 w-3 h-3 rounded-full transition hover:bg-red-600 hover:text-secondary-100"
                                    onClick={() => {
                                        setDeletedImages([
                                            ...deletedImages,
                                            image.id,
                                        ])
                                        setLocalImages(
                                            localImages.filter(
                                                (localImage) =>
                                                    localImage.id !== image.id,
                                            ),
                                        )
                                    }}
                                />
                            </div>
                            <img
                                src={image.path}
                                alt={image.name}
                                className="w-28 h-28 object-cover"
                            />
                        </div>
                    ))}
                {uploadedImages.map((image, idx) => (
                    <div className="relative" key={idx}>
                        <div>
                            <FontAwesomeIcon
                                icon={"times"}
                                className="absolute top-1 right-1 cursor-pointer text-red-400 bg-secondary-100 p-1 w-3 h-3 rounded-full transition hover:bg-red-600 hover:text-secondary-100"
                                onClick={() =>
                                    setUploadedImages(
                                        uploadedImages.filter(
                                            (uploadedImage) =>
                                                uploadedImage !== image,
                                        ),
                                    )
                                }
                            />
                        </div>
                        <img
                            src={URL.createObjectURL(image)}
                            alt={image.name}
                            className="w-28 h-28 object-cover"
                        />
                    </div>
                ))}
                <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    ref={inputRef}
                    onChange={(e) =>
                        e.target.files && setUploadedImage(e.target.files[0])
                    }
                />
                <div className="w-28 h-28 border border-secondary-400 rounded-lg group hover:bg-primary-100 transition">
                    <div
                        onClick={() => inputRef.current?.click()}
                        className="w-full h-full flex flex-col justify-center items-center cursor-pointer"
                    >
                        <p className="text-center text-sm text-secondary-400 group-hover:text-secondary-100 transition">
                            {t("add_new_image")}
                        </p>
                        <FontAwesomeIcon
                            icon={"plus"}
                            className="text-secondary-400 text-2xl group-hover:text-secondary-100 transition"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
