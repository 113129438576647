import { TaskStatusDetail, TaskStatusDetailNameSpace } from "@/models/Task"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React from "react"

interface TaskStatusDetailBarProps {
    taskStatusDetail: TaskStatusDetail
}

export const TaskStatusDetailBar: React.FC<TaskStatusDetailBarProps> = ({
    taskStatusDetail,
}) => {
    const statusMapping: {
        [key in TaskStatusDetail]: {
            className: string
            icon: string
        }
    } = {
        [TaskStatusDetailNameSpace.NOT_STARTED.AWAITING_START]: {
            className: "bg-gray-400 text-secondary-100",
            icon: "circle",
        },
        [TaskStatusDetailNameSpace.NOT_STARTED.DEPENDENCY_BLOCKED]: {
            className: "bg-secondary-400 text-primary-100",
            icon: "magnifying-glass",
        },
        [TaskStatusDetailNameSpace.IN_PROGRESS.HAS_ISSUES]: {
            className: "bg-red-400 text-secondary-100",
            icon: "exclamation-circle",
        },
        [TaskStatusDetailNameSpace.IN_PROGRESS.PENDING_REVIEW]: {
            className: "bg-yellow-400 text-secondary-100",
            icon: "clock",
        },
        [TaskStatusDetailNameSpace.IN_PROGRESS.APPROVED]: {
            className: "bg-green-400 text-secondary-100",
            icon: "check",
        },
        [TaskStatusDetailNameSpace.COMPLETED.SUCCESSFULLY_COMPLETED]: {
            className: "bg-green-400 text-secondary-100",
            icon: "check",
        },
        [TaskStatusDetailNameSpace.COMPLETED.WITH_ISSUES]: {
            className: "bg-yellow-400 text-secondary-100",
            icon: "exclamation-triangle",
        },
    }

    return (
        taskStatusDetail && (
            <div
                className={`${statusMapping[taskStatusDetail].className} flex items-center gap-2 rounded-full p-2 h-fit text-sm w-fit`}
            >
                <FontAwesomeIcon
                    icon={statusMapping[taskStatusDetail].icon as IconProp}
                />
                <p className="inline text-nowrap">{t(taskStatusDetail)}</p>
            </div>
        )
    )
}
