import { Notification as NotificationModel } from "@/models/Notification"
import Notification from "./AppNotification"

export const Notifications = ({
    notifications,
}: {
    notifications: NotificationModel[]
}) => {
    return (
        <div className="fixed bottom-0  right-0 p-8 z-10">
            {[...notifications]
                .slice(0, 5)
                .reverse()
                .map((notification) => (
                    <Notification
                        key={notification.id}
                        notification={notification}
                    />
                ))}
        </div>
    )
}
