import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Scrollable } from "../Layouts/Scrollable"
import { Input } from "./Input"

export interface HierarchyOptionProps {
    value: string | number
    label: string
    expanded: boolean
    item: any
}

interface HierarchyItemProps extends HierarchyOptionProps {
    onChange: (value: { value: string; label: string }) => void
    search: string
}

interface HierarchyDropDownProps {
    className?: string
    options: HierarchyOptionProps[]
    name: string
    placeholder?: string
    disabled?: boolean
    value: { value: string; label: string }
    onChange: (value: { value: string; label: string }) => void
    translate?: boolean
}

const HierarchyDropDownOption: React.FC<HierarchyItemProps> = ({
    value,
    label,
    expanded,
    onChange,
    item,
    search,
}) => {
    const [showChildren, setShowChildren] = useState<boolean>(false)
    const filter = item.name.toLowerCase().includes(search.toLowerCase())
    const isSearching = search.length > 0
    return (
        <div>
            {filter && (
                <div className="flex items-center gap-1">
                    <div className="w-5 h-5 flex justify-center items-center">
                        {item.children.length > 0 && (
                            <FontAwesomeIcon
                                icon={
                                    showChildren
                                        ? "chevron-down"
                                        : "chevron-right"
                                }
                                className="text-primary-100 cursor-pointer transition hover:text-primary-300"
                                onClick={() => setShowChildren(!showChildren)}
                            />
                        )}
                    </div>
                    <div
                        className="w-fit hover:bg-primary-100 hover:text-secondary-100 cursor-pointer transition pr-2 pl-1 rounded-lg"
                        onClick={() => {
                            onChange({
                                value: item.id,
                                label: item.wbs + " - " + item.name,
                            })
                        }}
                    >
                        <p>
                            <span className="text-xs">{item.wbs}</span> -{" "}
                            {label}
                        </p>
                    </div>
                </div>
            )}
            <div className={isSearching ? "" : "ml-2"}>
                {(showChildren || isSearching) &&
                    item.children.map((child: any, i: number) => (
                        <HierarchyDropDownOption
                            key={i}
                            value={child.id}
                            label={child.name}
                            expanded={false}
                            item={child}
                            search={search}
                            onChange={onChange}
                        />
                    ))}
            </div>
        </div>
    )
}

export const HierarchyDropDown: React.FC<HierarchyDropDownProps> = ({
    value,
    options,
    placeholder,
    disabled = false,
    className,
    name,
    onChange,
    translate = false,
}) => {
    const { t } = useTranslation()
    const [search, setSearch] = useState<string>("")
    const [expanded, setExpanded] = useState<boolean>(false)
    const inputRef = useRef<HTMLDivElement>(null)
    const [menuCords, setMenuCords] = useState<{
        x: number
        y: number
        width: number
    }>({
        x: 0,
        y: 0,
        width: 0,
    })
    // const [localValue, setLocalValue] = useState<{
    //     value: number | string
    //     label: string
    // }>({
    //     value: value.value,
    //     label: value.label,
    // })
    // useEffect(() => {
    //     onChange(localValue)
    // }, [localValue, onChange])

    // useEffect(() => {
    //     if (value.value !== localValue.value) {
    //         setLocalValue(value)
    //     }
    // }, [value])

    return (
        <div
            className={`relative ${disabled ? "opacity-50 cursor-not-allowed" : ""} ${className}`}
            ref={inputRef}
        >
            <div
                className="w-full h-8 border-b-2 border-b-primary-100 flex justify-between items-center overflow-x-hidden"
                onClick={(e) => {
                    if (!disabled) {
                        setExpanded(!expanded)
                        setMenuCords({
                            x:
                                inputRef.current?.getBoundingClientRect()
                                    .left || 0,
                            y:
                                inputRef.current?.getBoundingClientRect()
                                    .bottom || 0,
                            width:
                                inputRef.current?.getBoundingClientRect()
                                    .width || 0,
                        })
                    }
                }}
            >
                {value.label.length > 0
                    ? value.label
                    : placeholder && (
                          <p className="opacity-50">{t(placeholder)}...</p>
                      )}
                <FontAwesomeIcon icon="chevron-down" className="ml-auto" />
            </div>
            {expanded && !disabled && (
                <div
                    className="bg-secondary-100 fixed z-50 top-8 left-0 p-3 w-full shadow-lg rounded-lg border border-primary-100"
                    style={{
                        top: menuCords.y,
                        left: menuCords.x,
                        width: menuCords.width,
                    }}
                >
                    <div className="flex items-center w-full p-3 mb-2">
                        <FontAwesomeIcon icon="search" />
                        <Input
                            value={search}
                            type="text"
                            name={t("search")}
                            onChange={(e) => setSearch(e.target.value)}
                            className="!mt-0 w-full"
                            placeholder={`${t("search")}...`}
                        />
                    </div>
                    <Scrollable
                        height="200px"
                        className="!w-[400px]"
                        yScroll={true}
                    >
                        {options.map((option, i) => (
                            <HierarchyDropDownOption
                                key={i}
                                value={option.value}
                                label={option.label}
                                expanded={option.expanded}
                                item={option.item}
                                search={search}
                                onChange={onChange}
                            />
                        ))}
                    </Scrollable>
                </div>
            )}
        </div>
    )
}
