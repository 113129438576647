import { Popup } from "@/components/Layouts/Popup"
import { Task, TaskStatus } from "@/models/Task"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

interface DetailsTimelineTaskBarProps {
    timelineStartDate: Date
    timelineEndDate: Date
    task: Task
    below?: boolean
}

type NamePosition = "start" | "center" | "end"

export const DetailsTimelineTaskBar: React.FC<DetailsTimelineTaskBarProps> = ({
    timelineStartDate,
    timelineEndDate,
    task,
    below = true,
}) => {
    const [showTask, setShowTask] = React.useState<boolean>(false)
    const [popupCords, setPopupCords] = React.useState<[number, number]>([0, 0])
    const [taskPercentageFromStart, setTaskPercentageFromStart] =
        React.useState<number>(0)
    const [taskPercentageFromEnd, setTaskPercentageFromEnd] =
        React.useState<number>(0)
    const [taskPercentageCenter, setTaskPercentageCenter] =
        React.useState<number>(0)
    const [namePosition, setNamePosition] =
        React.useState<NamePosition>("center")
    const [isLongTask, setIsLongTask] = React.useState<boolean>(false)
    const projectId = useParams<{ projectId: string }>().projectId
    const { t } = useTranslation()
    const getFromStartPercentage = (taskDate: Date) => {
        if (taskDate < timelineStartDate) return 0
        const diff = taskDate.getTime() - timelineStartDate.getTime()
        const percentage =
            (diff / (timelineEndDate.getTime() - timelineStartDate.getTime())) *
            100
        return Math.round(percentage)
    }
    const getFromEndPercentage = (taskDate: Date) => {
        if (taskDate > timelineEndDate) return 0
        const diff = timelineEndDate.getTime() - taskDate.getTime()
        const percentage =
            (diff / (timelineEndDate.getTime() - timelineStartDate.getTime())) *
            100
        return Math.round(percentage)
    }
    const handleMouseEnter = (e: React.MouseEvent) => {
        const x = e.clientX
        const y = e.clientY
        setPopupCords([x, y])
        setShowTask(true)
    }

    const taskColorMap = {
        [TaskStatus.NOT_STARTED]: "bg-gray-400",
        [TaskStatus.IN_PROGRESS]: "bg-secondary-400",
        [TaskStatus.COMPLETED]: "bg-green-400",
    }

    useEffect(() => {
        setTaskPercentageFromStart(getFromStartPercentage(task.startDate))
        setTaskPercentageFromEnd(getFromEndPercentage(task.endDate))
        setTaskPercentageCenter(
            100 -
                (getFromStartPercentage(task.startDate) +
                    getFromEndPercentage(task.endDate)),
        )
    }, [task])

    useEffect(() => {
        if (
            taskPercentageFromStart >= taskPercentageFromEnd &&
            taskPercentageFromStart >= taskPercentageCenter
        ) {
            setNamePosition("start")
        }
        if (
            taskPercentageFromEnd > taskPercentageFromStart &&
            taskPercentageFromEnd > taskPercentageCenter
        ) {
            setNamePosition("end")
        }
        if (
            taskPercentageCenter > taskPercentageFromStart &&
            taskPercentageCenter > taskPercentageFromEnd
        ) {
            setNamePosition("center")
        }
    }, [taskPercentageCenter, taskPercentageFromStart, taskPercentageFromEnd])

    useEffect(() => {
        if (taskPercentageCenter > 5) {
            setIsLongTask(true)
        } else {
            setIsLongTask(false)
        }
    }, [taskPercentageCenter])

    return (
        <Link to={`/dashboard/projects/${projectId}/tasks/${task.id}`}>
            <div
                className={`cursor-pointer h-4 ${
                    below ? "mt-8" : "mb-8"
                } relative transition`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => setShowTask(false)}
            >
                <div
                    key={task.id}
                    className={`h-[0.15rem] ${
                        isLongTask ? taskColorMap[task.status] : ""
                    } flex`}
                    style={{
                        marginLeft: `${taskPercentageFromStart}%`,
                        width: `${isLongTask ? taskPercentageCenter : 10}%`,
                    }}
                >
                    {taskPercentageCenter <= 5 && (
                        <div
                            className={`absolute w-4 h-4 ${
                                taskColorMap[task.status]
                            } rotate-45`}
                        ></div>
                    )}
                    {namePosition === "start" && (
                        <div
                            className="absolute text-right pr-2"
                            style={{
                                width: `${taskPercentageFromStart}%`,
                                left: `0`,
                                top: isLongTask ? "-10px" : "-4px",
                            }}
                        >
                            {task.name}
                        </div>
                    )}
                    <div className="flex justify-between w-full">
                        {task.startDate >= timelineStartDate && isLongTask && (
                            <div
                                className={`h-4 w-[0.20rem] ${
                                    taskColorMap[task.status]
                                } -mt-[.4rem]`}
                            ></div>
                        )}
                        {namePosition === "center" && (
                            <div
                                className={`ml-4 ${!below ? "-mt-6" : "mt-2"}`}
                            >
                                {task.name}
                            </div>
                        )}
                        {task.endDate <= timelineEndDate && isLongTask && (
                            <div
                                className={`h-4 w-[0.20rem] ${
                                    taskColorMap[task.status]
                                } -mt-[.4rem] ml-auto`}
                            ></div>
                        )}
                    </div>
                    {namePosition === "end" && (
                        <div
                            className={`absolute -top-[10px] pl-5`}
                            style={{
                                width: `${taskPercentageFromEnd}%`,
                                left: `${
                                    taskPercentageCenter +
                                    taskPercentageFromStart
                                }%`,
                                top: isLongTask ? "-10px" : "-4px",
                            }}
                        >
                            {task.name}
                        </div>
                    )}
                </div>
                <Popup isShown={showTask} x={popupCords[0]} y={popupCords[1]}>
                    <div className="p-2">
                        <p>{task.name}</p>
                        <p>
                            {t("intlDateTime", {
                                val: task.startDate,
                                formatParams: {
                                    val: {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    },
                                },
                            })}{" "}
                            -{" "}
                            {t("intlDateTime", {
                                val: task.endDate,
                                formatParams: {
                                    val: {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    },
                                },
                            })}
                        </p>
                    </div>
                </Popup>
            </div>
        </Link>
    )
}
