import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { useTranslation } from "react-i18next"
import { QuickNavigationFolder } from "./QuickNavigationFolder"
import { selectFolderTree } from "./fileManagerSlice"

interface QuickNavigationProps {}

export const QuickNavigation: React.FC<QuickNavigationProps> = () => {
    const folderTree = useAppSelector(selectFolderTree)
    const status = useAppSelector((state) => state.fileManager.manyStatus)
    const { t } = useTranslation()
    return (
        <div className="border border-primary-200 h-full p-3 rounded-lg">
            <Scrollable
                height="100%"
                width="100%"
                yScroll={true}
                xScroll={true}
            >
                {folderTree.children.map((child) => (
                    <QuickNavigationFolder key={child.id} folder={child} />
                ))}
            </Scrollable>
        </div>
    )
}
