import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Currency } from "@/components/Other/Currency"
import { Form, SubmitType } from "@/features/Form/Form"
import { Article } from "@/models/Article"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ArticleForm } from "./ArticleForm"
import {
    clearErrors,
    deleteArticle,
    getProjectArticles,
    selectArticles,
} from "./articlesSlice"

interface ArticlesProps {}

export const Articles: FC<ArticlesProps> = () => {
    const articles = useAppSelector(selectArticles)
    const dispatch = useAppDispatch()
    const [showCreateArticle, setShowCreateArticle] = useState<boolean>(false)
    const [showEditArticle, setShowEditArticle] = useState<boolean>(false)
    const [showDeleteArticle, setShowDeleteArticle] = useState<boolean>(false)
    const [selectedArticle, setSelectedArticle] = useState<Article>(
        new Article(),
    )
    const [search, setSearch] = useState<string>("")
    const [mode, setMode] = useState<"list" | "cards">("list")
    const { t } = useTranslation()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const status = useAppSelector((state) => state.article.status)

    useEffect(() => {
        dispatch(getProjectArticles(projectId))
    }, [dispatch, projectId])
    return (
        <div className="flex flex-col gap-5 h-full overflow-hidden">
            <div className="flex items-center justify-between">
                <h1 className="text-4xl font-bold border-b-4 w-fit border-opacity-100 border-primary-300">
                    {t("articles")}
                </h1>
                <Button
                    name={t("create_new_article")}
                    onClick={() => setShowCreateArticle(true)}
                    icon={"plus"}
                />
            </div>
            <div className="flex items-center gap-2 bg-secondary-100 shadow-lg rounded-lg p-3 justify-between">
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon="search" />
                    <Input
                        value={search}
                        name="search"
                        type="text"
                        placeholder={t("search")}
                        onChange={(e) => setSearch(e.target.value)}
                        className="!mt-0"
                    />
                </div>
                <div className="flex items-center gap-2">
                    <div
                        className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                            mode === "list"
                                ? "bg-primary-300 text-secondary-100"
                                : "bg-secondary-100 text-primary-300 border border-primary-300"
                        }`}
                        onClick={() => setMode("list")}
                    >
                        <FontAwesomeIcon icon="list" />
                        <p>{t("list")}</p>
                    </div>
                    <div
                        className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                            mode === "cards"
                                ? "bg-primary-300 text-secondary-100"
                                : "bg-secondary-100 text-primary-300 border border-primary-300"
                        }`}
                        onClick={() => setMode("cards")}
                    >
                        <FontAwesomeIcon icon="newspaper" />
                        <p>{t("cards")}</p>
                    </div>
                </div>
            </div>
            <Scrollable height="100%" className="w-full">
                <div className=" bg-secondary-100 shadow-lg rounded-lg p-3 justify-between h-full">
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <th className="w-[15%]">{t("name")}</th>
                                <th className="w-[20%]">{t("description")}</th>
                                <th className="w-[10%]">{t("unit_price")}</th>
                                <th className="w-[10%]">{t("unit")}</th>
                                <th className="w-[20%]">{t("photos")}</th>
                                <th className="w-[10%]">{t("budget")}</th>
                                <th className="w-[10%]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {articles.map((article) => (
                                <tr key={article.id} className="border-b">
                                    <td>{article.name}</td>
                                    <td>{article.description}</td>
                                    <td>
                                        <div className="flex gap-1">
                                            {article.unitPrice}
                                            <Currency
                                                currency={
                                                    article.budget.currency
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td>{t(article.unit.toLowerCase())}</td>
                                    <td>
                                        <Scrollable height="100px">
                                            {article.photos.map((photo) => (
                                                <img
                                                    key={photo.id}
                                                    src={photo.path}
                                                    alt={article.name}
                                                    className="h-28 w-28 object-cover"
                                                />
                                            ))}
                                        </Scrollable>
                                    </td>
                                    <td>{article.budget.name}</td>
                                    <td className="h-full">
                                        <div className="justify-end text-lg flex gap-2">
                                            <FontAwesomeIcon
                                                icon="edit"
                                                className="cursor-pointer text-yellow-400 hover:text-yellow-600 transition"
                                                onClick={() => {
                                                    setSelectedArticle(
                                                        new Article(
                                                            article.toJson(),
                                                        ),
                                                    )
                                                    setShowEditArticle(true)
                                                }}
                                            />
                                            <FontAwesomeIcon
                                                icon="trash"
                                                className="cursor-pointer text-red-400 hover:text-red-600 transition"
                                                onClick={() => {
                                                    setSelectedArticle(
                                                        new Article(
                                                            article.toJson(),
                                                        ),
                                                    )
                                                    setShowDeleteArticle(true)
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Scrollable>
            <NewModal
                title={t("create_new_article")}
                isShown={showCreateArticle}
                closeModal={() => setShowCreateArticle(false)}
            >
                <ArticleForm closeModal={() => setShowCreateArticle(false)} />
            </NewModal>
            <NewModal
                title={t("edit_article")}
                isShown={showEditArticle}
                closeModal={() => setShowEditArticle(false)}
            >
                <ArticleForm
                    article={selectedArticle}
                    closeModal={() => {
                        setSelectedArticle(new Article())
                        setShowEditArticle(false)
                    }}
                />
            </NewModal>
            <NewModal
                title={t("delete_article")}
                isShown={showDeleteArticle}
                closeModal={() => setShowDeleteArticle(false)}
            >
                <Form
                    statuses={[status.delete]}
                    loadingTitle={t("deleting_article", {
                        article: selectedArticle.name,
                    })}
                    className="w-full"
                    submitType={SubmitType.Delete}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            deleteArticle({
                                projectId,
                                articleId: selectedArticle.id,
                            }),
                        )
                        if (type === "article/deleteArticle/fulfilled") {
                            setSelectedArticle(new Article())
                            setShowDeleteArticle(false)
                        }
                    }}
                    clearErrors={clearErrors}
                >
                    <div className="w-full">
                        <h1 className="text-lg font-bold">
                            {t("deleting_article", {
                                article: selectedArticle.name,
                            })}
                        </h1>
                        <p className="text-red-400 mb-4 font-bold">
                            {t("delete_article_notice")}
                        </p>
                    </div>
                </Form>
            </NewModal>
        </div>
    )
}
