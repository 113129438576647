import { axiosAuthenticated } from "@/common/axios"
import { TaskJsonInterface } from "@/models/Task"

export const getUserProjectTasksApi = (projectId: string) => {
    return new Promise<{ data: TaskJsonInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/latest`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserNewNotificationsApi = () => {
    return new Promise<{ data: any[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/notifications/new`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserAllNotificationsApi = () => {
    return new Promise<{ data: any[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/notifications/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const markNotificationsAsSeenApi = (notificationsIds: number[]) => {
    return new Promise<{ data: any[] }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/notifications/seen`, { notificationsIds })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getCurrentUserPermissionsInProjectApi = (projectId: string) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .get(`/project/${projectId}/permissions/my-permissions`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserProjectModulesApi = (projectId: string) => {
    return new Promise<{ data: any[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/modules`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
