import { Popup, POPUP_TYPES } from "@/components/Menus/Popup"
import { User } from "@/models/User"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useRef, useState } from "react"

interface LatestUsersActionsProps {
    user: User
    setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>
    setBanUserModal: React.Dispatch<React.SetStateAction<boolean>>
    setEditUserModal: React.Dispatch<React.SetStateAction<boolean>>
    setResetPasswordModal: React.Dispatch<React.SetStateAction<boolean>>
    setUnbanUserModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const LatestUsersActions: React.FC<LatestUsersActionsProps> = ({
    user,
    setSelectedUser,
    setBanUserModal,
    setEditUserModal,
    setResetPasswordModal,
    setUnbanUserModal,
}) => {
    const ellipsisRef = useRef<HTMLDivElement>(null)
    const [isPopupShown, setIsPopupShown] = useState(false)
    return (
        <div className="flex items-center justify-center">
            <div
                className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                ref={ellipsisRef}
                onClick={() => {
                    setSelectedUser(user)
                    setIsPopupShown(!isPopupShown)
                }}
            >
                <FontAwesomeIcon icon="ellipsis-v" className="cursor-pointer" />
            </div>
            <Popup
                parentRef={ellipsisRef}
                type={POPUP_TYPES.LEFT_CLICK}
                isShown={isPopupShown}
                closePopup={() => setIsPopupShown(false)}
            >
                <div className="">
                    <div
                        onClick={() => {
                            setEditUserModal(true)
                            setIsPopupShown(false)
                        }}
                        className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                    >
                        <FontAwesomeIcon icon="user-cog" />
                        <p>{t("edit_user_profile")}</p>
                    </div>
                    {/* <div
                        onClick={() => {
                            setResetPasswordModal(true)
                            setIsPopupShown(false)
                        }}
                        className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                    >
                        <FontAwesomeIcon icon="key" />
                        <p>{t("send_email_reset_password")}</p>
                    </div> */}
                    <div
                        onClick={() => {
                            setUnbanUserModal(true)
                            setIsPopupShown(false)
                        }}
                        className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b"
                    >
                        <FontAwesomeIcon icon="user-check" />
                        <p>{t("unban_user")}</p>
                    </div>
                    <div
                        onClick={() => {
                            setBanUserModal(true)
                            setIsPopupShown(false)
                        }}
                        className="flex gap-2 items-center pl-2 pr-8 py-2 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg"
                    >
                        <FontAwesomeIcon icon="ban" />
                        <p>{t("ban_user")}</p>
                    </div>
                </div>
            </Popup>
        </div>
    )
}
