import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { IfcHandler } from "../IfcHandler"

interface SpatialStructureElementsRowProps {
    ifcHandler: IfcHandler
    element: { uuid: string; expressId: number }
    search?: string
}

export const SpatialStructureElementsRow: React.FC<
    SpatialStructureElementsRowProps
> = ({ ifcHandler, element, search = "" }) => {
    const [elementName, setElementName] = useState<string>("")
    const [isHidden, setIsHidden] = useState<boolean>(false)
    useEffect(() => {
        ifcHandler.getElementName(element.expressId).then((name: string) => {
            setElementName(name)
        })
    }, [])
    return (
        elementName.includes(search) && (
            <div className="flex justify-between gap-2 px-4 py-1 border-b">
                <p>{elementName}</p>
                <div
                    className="flex items-center gap-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    <FontAwesomeIcon
                        icon={isHidden ? "eye-slash" : "eye"}
                        className="text-xs cursor-pointer hover:scale-110 w-4 h-4"
                        onClick={() => {
                            ifcHandler.setSelectionVisibility(isHidden, {
                                [element.uuid]: new Set([element.expressId]),
                            })
                            setIsHidden(!isHidden)
                        }}
                    />
                    <FontAwesomeIcon
                        className="text-sm cursor-pointer hover:scale-110 w-4 h-4"
                        icon="mouse-pointer"
                        onClick={() => {
                            ifcHandler.select({
                                [element.uuid]: new Set([element.expressId]),
                            })
                        }}
                    />
                </div>
            </div>
        )
    )
}
