import { axiosAuthenticated } from "@/common/axios"
import { BanReason } from "@/models/Admin"
import { UserInterface } from "@/models/User"

export const getLatestUsersApi = () => {
    return new Promise<{ data: UserInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/back-office/admin/users`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getLatestProjectsApi = () => {
    return new Promise<{ data: UserInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/back-office/admin/projects`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getGeneralStatsApi = () => {
    return new Promise<{ data: UserInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/back-office/admin/general/stats`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserStatsInIntervalApi = (
    startDate: string,
    endDate: string,
) => {
    return new Promise<{ data: UserInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/back-office/admin/users/stats?startDate=${startDate}&endDate=${endDate}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectStatsInIntervalApi = (
    startDate: string,
    endDate: string,
) => {
    return new Promise<{ data: UserInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/back-office/admin/projects/stats?startDate=${startDate}&endDate=${endDate}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const banUserApi = (
    userId: string,
    data: {
        reason: BanReason
        description: string
        expiresAt: Date
    },
) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/back-office/admin/users/${userId}/ban`, { data })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const unbanUserApi = (userId: string) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/back-office/admin/users/${userId}/unban`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserBanStatusApi = (userId: string) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/back-office/admin/users/${userId}/ban-status`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const changeUserProfilePictureApi = (userId: string, data: FormData) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/back-office/admin/users/${userId}/profile-picture`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateUserApi = (userId: string, data: any) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/back-office/admin/users/${userId}`, { data })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const freezeProjectApi = (projectId: string) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/back-office/admin/projects/${projectId}/freeze`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const unfreezeProjectApi = (projectId: string) => {
    return new Promise<{ data: UserInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/back-office/admin/projects/${projectId}/unfreeze`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
