import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { UsersGroupsInput } from "@/components/Inputs/UserGroupsInput"
import { NewModal } from "@/components/Layouts/NewModal"
import { Popup } from "@/components/Layouts/Popup"
import { UsersShow } from "@/components/Other/UsersShow"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import {
    MODULE_PERMISSIONS,
    PermissionGroup,
    PermissionType,
} from "@/models/Permission"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ModulePermission } from "./ModulePermission"
import {
    clearErrors,
    deletePermissionGroup,
    updatePermissionGroup,
} from "./userManagementSlice"

interface GroupCardProps {
    projectGroup: PermissionGroup
}

export const GroupCard: React.FC<GroupCardProps> = ({ projectGroup }) => {
    const { t } = useTranslation()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const dispatch = useAppDispatch()
    const userPermissions = useAppSelector(selectUserPermissions)
    const [permissionsHovered, setPermissionsHovered] = useState<boolean>(false)
    const [popupCords, setPopupCords] = useState<[number, number]>([0, 0])
    const errors = useAppSelector((state) => state.userManagement.errors)

    const [localGroup, setLocalGroup] = useState<PermissionGroup>(projectGroup)
    const [groupPermissions, setGroupPermissions] = useState<PermissionType[]>(
        projectGroup.permissions,
    )
    const [selectedUsers, setSelectedUsers] = useState<string[]>(
        projectGroup.users.map((user) => user.id),
    )
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const status = useAppSelector((state) => state.userManagement.status)
    const deleteGroup = async () => {
        const { type } = await dispatch(
            deletePermissionGroup({ projectId, groupId: projectGroup.id }),
        )
        if (type === deletePermissionGroup.fulfilled.type) {
            setShowDelete(false)
        }
    }
    const updateGroup = async () => {
        const { type } = await dispatch(
            updatePermissionGroup({
                projectId,
                groupId: localGroup.id,
                createPayload: localGroup.createPayload(selectedUsers),
            }),
        )
        if (type === updatePermissionGroup.fulfilled.type) {
            setShowEdit(false)
        }
    }
    useEffect(() => {
        setLocalGroup(new PermissionGroup(projectGroup.toJson()))
    }, [])
    useEffect(() => {
        setLocalGroup(
            new PermissionGroup({
                ...localGroup.toJson(),
                permissions: groupPermissions,
            }),
        )
    }, [groupPermissions])

    return (
        <div className="flex flex-col gap-2 p-2 shadow-lg rounded-lg break-words max-w-[500px] w-full relative">
            <div className="absolute top-0 right-0 flex items-center gap-2 pr-2">
                {userPermissions.hasAllPermissions([
                    MODULE_PERMISSIONS.UserManager.GroupEdit,
                ]) && (
                    <FontAwesomeIcon
                        icon="cog"
                        className="text-yellow-200 hover:text-yellow-500 transition cursor-pointer"
                        onClick={() => setShowEdit(true)}
                    />
                )}
                {userPermissions.hasAllPermissions([
                    MODULE_PERMISSIONS.UserManager.GroupDelete,
                ]) && (
                    <FontAwesomeIcon
                        icon="trash"
                        className="text-red-200 hover:text-red-500 transition cursor-pointer"
                        onClick={() => setShowDelete(true)}
                    />
                )}
            </div>
            <h4 className="font-bold tex-lg text-xl mt-2">
                {projectGroup.name}
            </h4>
            <p>
                <span className="font-bold">{t("description")}: </span>{" "}
                {projectGroup.description}
            </p>
            <div
                onMouseEnter={(e) => {
                    setPopupCords([e.clientX, e.clientY])
                    setPermissionsHovered(true)
                }}
                onMouseLeave={() => setPermissionsHovered(false)}
            >
                <span className="font-bold">{t("permissions")}: </span>
                {projectGroup.permissions.length > 2
                    ? projectGroup.permissions.slice(0, 2).join(", ") + "..."
                    : projectGroup.permissions.join(", ")}
                <Popup
                    isShown={
                        permissionsHovered &&
                        projectGroup.permissions.length > 2
                    }
                    x={popupCords[0]}
                    y={popupCords[1]}
                >
                    <p className="w-96">
                        {projectGroup.permissions.join(", ")}
                    </p>
                </Popup>
            </div>
            <UsersShow users={projectGroup.users} />
            <NewModal
                title={t("delete_group")}
                isShown={showDelete}
                closeModal={() => setShowDelete(false)}
            >
                <Form
                    statuses={[status.delete]}
                    loadingTitle={t("deleting_group")}
                    onSubmit={deleteGroup}
                    submitType={SubmitType.Delete}
                    onCancel={() => setShowDelete(false)}
                    clearErrors={clearErrors}
                >
                    <div className="w-[400px]">
                        <h4 className="font-bold text-2xl">
                            {t("delete_group_notice", {
                                group: projectGroup.name,
                            })}
                        </h4>
                    </div>
                </Form>
            </NewModal>
            <NewModal
                title={t("edit_group")}
                isShown={showEdit}
                closeModal={() => setShowEdit(false)}
            >
                <Form
                    statuses={[status.update]}
                    loadingTitle={t("updating_group_permissions")}
                    onSubmit={updateGroup}
                    submitType={SubmitType.Save}
                    onCancel={() => setShowEdit(false)}
                    clearErrors={clearErrors}
                >
                    <div className="w-[400px]">
                        <h4 className="font-bold text-2xl mb-4">
                            {t("adjusting_group_permission_notice", {
                                group: projectGroup.name,
                            })}
                        </h4>
                        <div className="flex flex-col gap-5">
                            <Input
                                label={t("name")}
                                name={t("name")}
                                type="text"
                                placeholder={t("enter_group_name") + "..."}
                                value={localGroup.name}
                                onChange={(e) =>
                                    setLocalGroup(
                                        new PermissionGroup({
                                            ...localGroup.toJson(),
                                            name: e.target.value,
                                        }),
                                    )
                                }
                                className="!m-0"
                                errors={errors.name}
                            />
                            <Input
                                label={t("description")}
                                name={t("description")}
                                type="text"
                                placeholder={
                                    t("enter_group_description") + "..."
                                }
                                value={localGroup.description}
                                onChange={(e) =>
                                    setLocalGroup(
                                        new PermissionGroup({
                                            ...localGroup.toJson(),
                                            description: e.target.value,
                                        }),
                                    )
                                }
                                className="!m-0"
                                errors={errors.description}
                            />
                            <div>
                                <p className="font-bold">{t("permissions")}:</p>
                                <ModulePermission
                                    permissions={groupPermissions}
                                    setPermissions={setGroupPermissions}
                                />
                            </div>
                            <div>
                                <p className="font-bold">{t("users")}:</p>
                                <UsersGroupsInput
                                    selectedUsers={projectGroup.users}
                                    setValue={setSelectedUsers}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </NewModal>
        </div>
    )
}
