import { animated, useSpring } from "@react-spring/web"
import React, { useEffect, useRef } from "react"

interface PopupProps {
    children: React.ReactNode
    isShown: boolean
    onClose?: () => void
    x: number
    y: number
}

export const Popup: React.FC<PopupProps> = ({
    children,
    isShown,
    onClose,
    x,
    y,
}) => {
    const [popupCords, setPopupCords] = React.useState<[number, number]>([x, y])
    const [showPopup, setShowPopup] = React.useState<boolean>(isShown)
    const windowSize = useRef([window.innerWidth, window.innerHeight])
    const popupRef = useRef<HTMLDivElement>(null)
    const [fadeIn, fadeInApi] = useSpring(() => ({
        from: { opacity: 0},
        config: { duration: 200 },
    }))
    useEffect(() => {
        const bounds = popupRef.current?.getBoundingClientRect()
        let newX = x
        let newY = y

        if (x + bounds?.width! > windowSize.current[0]) {
            newX = x - bounds!.width
        }
        if (y + bounds?.height! > windowSize.current[1]) {
            newY = y - bounds!.height
        }
        setPopupCords([newX, newY])
    }, [isShown, x, y])

    useEffect(() => {
        if (isShown) {
            setShowPopup(isShown)
            fadeInApi.start({ opacity: 1 })
        } else {
            fadeInApi.start({ opacity: 0, onRest: () => setShowPopup(false) })
        }
    }, [showPopup, isShown, fadeInApi])

    return (
        <animated.div
            className={`fixed z-50 bg-secondary-100 shadow-lg rounded-lg p-2 ${
                showPopup ? "" : "hidden"
            }`}
            style={{ ...fadeIn, top: popupCords[1], left: popupCords[0] }}
            ref={popupRef}
        >
            {showPopup && children}
        </animated.div>
    )
}
