interface InputLabelProps {
    label?: string
    required?: boolean
    className?: string
}

export const InputLabel: React.FC<InputLabelProps> = ({
    label,
    required,
    className,
}) => {
    return (
        label && (
            <label className={className + " text-sm font-medium"}>
                {label}:{required && <span className="text-red-500"> *</span>}
            </label>
        )
    )
}
