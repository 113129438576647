import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { TaskReviewStatusBar } from "@/components/Other/TaskReviewStatusBar"
import { Task, TaskReviewStatus } from "@/models/Task"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    getTaskCommentsById,
    getUserTaskReview,
    selectTaskReview,
    updateTaskReview,
} from "../tasksSlice"

interface TaskReviewFormProps {
    task: Task
    closeModal: () => void
}

export const TaskReviewForm: React.FC<TaskReviewFormProps> = ({
    task,
    closeModal,
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [comment, setComment] = useState("")
    const taskReview = useAppSelector(selectTaskReview)
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const status = useAppSelector((state) => state.tasks.status)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    useEffect(() => {
        dispatch(getUserTaskReview({ projectId, taskId }))
    }, [])
    const updateReview = async (status: TaskReviewStatus) => {
        let close = true
        const { type } = await dispatch(
            updateTaskReview({
                projectId,
                taskId: taskId,
                payload: {
                    status,
                    comment,
                },
            }),
        )
        if (type === updateTaskReview.fulfilled.type) {
            const { type } = await dispatch(
                getTaskCommentsById({ projectId, taskId }),
            )
            if (type === getTaskCommentsById.fulfilled.type) {
                closeModal()
            }
        }
    }

    return (
        <WithLoader
            statuses={[status.update, status.read]}
            title={t("updating_review")}
            className="w-full"
        >
            <div className="flex flex-col gap-2">
                <h1 className="font-bold text-xl mb-4">
                    {t("reviewing") + " "}
                    <span className="font-normal">{task.name}</span>
                </h1>
                <div className="flex items-center gap-2">
                    <p className="font-bold">{t("latest_review")}:</p>
                    <div>
                        <TaskReviewStatusBar reviewStatus={taskReview.status} />
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <p className="font-bold">{t("submitted_on")}:</p>
                    <p>
                        {t("intlDateTime", {
                            val: taskReview.updatedAt,
                            formatParams: {
                                val: {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </p>
                </div>
                <div>
                    <TextAreaInput
                        label={t("comment")}
                        value={comment}
                        name="comment"
                        onChange={(event) => setComment(event.target.value)}
                        className="w-full"
                    />
                </div>
                <div className="w-full flex items-center justify-evenly mt-4">
                    <Button
                        name={t("reject")}
                        type="button"
                        icon="times"
                        className="h-10 !bg-red-400 hover:!bg-red-600 !border-secondary-100 text-secondary-100"
                        onClick={() => updateReview(TaskReviewStatus.REJECTED)}
                    />
                    <Button
                        name={t("approve")}
                        type="button"
                        icon="check"
                        className="h-10 !bg-green-400 hover:!bg-green-600 !border-secondary-100 text-secondary-100"
                        onClick={() => updateReview(TaskReviewStatus.APPROVED)}
                    />
                </div>
            </div>
        </WithLoader>
    )
}
