import { ARTICLE_STATUS } from "@/models/Article"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"

interface ArticleStatusBarProps {
    articleStatus: ARTICLE_STATUS
}

export const ArticleStatusBar: React.FC<ArticleStatusBarProps> = ({
    articleStatus,
}) => {
    const statusMapping: {
        [key in ARTICLE_STATUS]: {
            className: string
            icon: string
        }
    } = {
        [ARTICLE_STATUS.BUDGET_EXCEEDED]: {
            className: "bg-red-400 text-secondary-100",
            icon: "exclamation-triangle",
        },
        [ARTICLE_STATUS.BUDGET_NOT_EXCEEDED]: {
            className: "bg-green-400 text-secondary-100",
            icon: "check",
        },
    }

    return (
        articleStatus && (
            <div
                className={`${statusMapping[articleStatus].className} flex items-center gap-2 rounded-full p-2 h-fit text-sm w-fit`}
            >
                <FontAwesomeIcon
                    icon={statusMapping[articleStatus].icon as IconProp}
                />
                <p className="inline text-nowrap">
                    {t(articleStatus.toLowerCase())}
                </p>
            </div>
        )
    )
}
