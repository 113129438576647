import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { selectProjectGroups } from "@/features/Projects/UserManagement/userManagementSlice"
import { selectProjectUsers } from "@/features/Projects/projectsSlice"
import { User } from "@/models/User"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Scrollable } from "../Layouts/Scrollable"
import { Input } from "./Input"

interface UserGroupsInputProps {
    selectedUsers: User[]
    setValue: (value: string[]) => void
}

const removeDuplicates = (users: User[]) => {
    return users.filter(
        (user, index) => users.findIndex((u) => u.id === user.id) === index,
    )
}

export const UsersGroupsInput: React.FC<UserGroupsInputProps> = ({
    setValue,
    selectedUsers,
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"

    const [usersWithoutGroup, setUsersWithoutGroup] = useState<User[]>([])
    const [localSelectedUsers, setLocalSelectedUsers] =
        useState<User[]>(selectedUsers)
    const [expanded, setExpanded] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")

    const users = useAppSelector(selectProjectUsers)
    const groups = useAppSelector(selectProjectGroups)

    const selectUsers = (users: User[]) => {
        const usersToSelect = removeDuplicates(users)
        setLocalSelectedUsers(usersToSelect)
    }

    const arrayHasSearch = (array: User[], search: string) => {
        return array.some(
            (user) =>
                user.fullName.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase()),
        )
    }

    useEffect(() => {
        const usersWithoutGroup = users.filter(
            (user) =>
                !groups.some((group) =>
                    group.users.map((user) => user.id).includes(user.id),
                ),
        )
        setUsersWithoutGroup(usersWithoutGroup)
    }, [users, groups])
    useEffect(() => {
        setValue(localSelectedUsers.map((user) => user.id))
    }, [localSelectedUsers])
    return (
        <div className="min-w-[400px] text-primary-300">
            <div
                className="flex items-center justify-between relative"
                onClick={() => setExpanded(!expanded)}
            >
                {localSelectedUsers.length === 0 && <p>{t("select_users")}</p>}
                {localSelectedUsers.length !== 0 && (
                    <div className="flex gap-x-2 gap-y-1 flex-wrap w-[400px]">
                        {localSelectedUsers.map((user) => (
                            <div className="flex gap-2" key={user.id}>
                                <img
                                    src={user.profilePicture.path}
                                    className="w-6 h-6 rounded-full"
                                    alt={user.email}
                                />
                                <p key={user.id}>{user.fullName}</p>
                            </div>
                        ))}
                    </div>
                )}
                <FontAwesomeIcon icon={expanded ? "caret-up" : "caret-down"} />
                {expanded && (
                    <div
                        className="absolute bg-secondary-100 shadow-lg w-full rounded-lg top-full left-0 p-2"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex items-center gap-2 w-full">
                            <FontAwesomeIcon icon="search" />
                            <Input
                                className="!m-0 w-full"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                name={t("search")}
                                placeholder={t("search_user")}
                            />
                        </div>
                        <Scrollable height="200px">
                            <div>
                                {groups.map(
                                    (group) =>
                                        group.users.length !== 0 &&
                                        arrayHasSearch(group.users, search) && (
                                            <UserGroupRow
                                                key={group.id}
                                                groupName={group.name}
                                                users={group.users.filter(
                                                    (user) =>
                                                        arrayHasSearch(
                                                            [user],
                                                            search,
                                                        ),
                                                )}
                                                localSelectedUsers={
                                                    localSelectedUsers
                                                }
                                                selectUsers={selectUsers}
                                            />
                                        ),
                                )}
                                {usersWithoutGroup.length !== 0 &&
                                    arrayHasSearch(
                                        usersWithoutGroup,
                                        search,
                                    ) && (
                                        <UserGroupRow
                                            groupName={t("other")}
                                            users={usersWithoutGroup.filter(
                                                (user) =>
                                                    arrayHasSearch(
                                                        [user],
                                                        search,
                                                    ),
                                            )}
                                            localSelectedUsers={
                                                localSelectedUsers
                                            }
                                            selectUsers={selectUsers}
                                        />
                                    )}
                            </div>
                        </Scrollable>
                    </div>
                )}
            </div>
        </div>
    )
}

interface UserGroupRowProps {
    groupName: string
    users: User[]
    localSelectedUsers: User[]
    selectUsers: (users: User[]) => void
}

const UserGroupRow: React.FC<UserGroupRowProps> = ({
    groupName,
    users,
    localSelectedUsers,
    selectUsers,
}) => {
    const [fullySelected, setFullySelected] = useState<boolean>(false)
    useEffect(() => {
        setFullySelected(
            users.every((user) =>
                localSelectedUsers.some((u) => u.id === user.id),
            ),
        )
    }, [localSelectedUsers])
    return (
        <div>
            <div className="flex gap-2 items-center justify-between">
                <p>{groupName}</p>
                <FontAwesomeIcon
                    icon={fullySelected ? "square-check" : "square"}
                    onClick={() => {
                        if (fullySelected) {
                            const filteredUsers = localSelectedUsers.filter(
                                (user) => !users.includes(user),
                            )
                            selectUsers(filteredUsers)
                        } else {
                            selectUsers([...localSelectedUsers, ...users])
                        }
                    }}
                />
            </div>
            {users.map((user) => (
                <div className="flex gap-2 items-center pl-3" key={user.id}>
                    <FontAwesomeIcon
                        icon={
                            localSelectedUsers.some((u) => u.id === user.id)
                                ? "square-check"
                                : "square"
                        }
                        onClick={() =>
                            selectUsers(
                                localSelectedUsers.some((u) => u.id === user.id)
                                    ? localSelectedUsers.filter(
                                          (u) => u.id !== user.id,
                                      )
                                    : [...localSelectedUsers, user],
                            )
                        }
                    />
                    {user.fullName}
                </div>
            ))}
        </div>
    )
}
