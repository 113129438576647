import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpring } from "@react-spring/web"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

interface SideBarItemProps {
    name: string
    icon: IconProp
    isActive: boolean
    expanded?: boolean
    onClick: () => void
    rotateIcon?: boolean
}

export const SideBarItem: React.FC<SideBarItemProps> = ({
    name,
    icon,
    isActive,
    expanded,
    onClick,
    rotateIcon,
}) => {
    const { t } = useTranslation()
    const [isAnimating, setIsAnimating] = React.useState<boolean>(false)
    const handleClick = () => {
        onClick()
    }
    const [fadeIn, fadeInApi] = useSpring(() => ({
        from: { width: "0px" },
    }))

    const [rotate, rotateApi] = useSpring(() => ({
        from: { transform: "rotate(0deg)" },
    }))

    useEffect(() => {
        if (isAnimating) return
        if (expanded) {
            fadeInApi.start({
                from: { width: "0px" },
                to: { width: "150px" },
                onRest: () => {
                    setIsAnimating(false)
                },
                onProps: () => {
                    setIsAnimating(true)
                },
            })
            if (rotateIcon) {
                rotateApi.start({
                    from: { transform: "rotate(0deg)" },
                    to: { transform: "rotate(180deg)" },
                })
            }
        } else {
            fadeInApi.start({
                from: { width: "150px" },
                to: { width: "0px" },
                onRest: () => {
                    setIsAnimating(false)
                },
                onProps: () => {
                    setIsAnimating(true)
                },
            })
            if (rotateIcon) {
                rotateApi.start({
                    from: { transform: "rotate(180deg)" },
                    to: { transform: "rotate(0deg)" },
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded, fadeInApi])

    const style = isActive
        ? "bg-primary-200 cursor-pointer"
        : "hover:bg-primary-200 cursor-pointer"
    return (
        <div
            onClick={handleClick}
            className={style + " p-3 flex items-center h-12 text-secondary-100"}
        >
            <animated.div style={rotate}>
                <FontAwesomeIcon icon={icon} className="w-5"></FontAwesomeIcon>
            </animated.div>
            <animated.div className="w-0 overflow-hidden" style={fadeIn}>
                <p className="min-w-[150px] ml-2">{t(name)}</p>
            </animated.div>
        </div>
    )
}
