import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { selectTaskTree } from "../planningSlice"
import { FilterContainer } from "./Filters/FilterContainer"
import {
    CommonFilterFields,
    FilterFields,
    FilterValuesType,
    TaskFilterFields,
} from "./Filters/Filters"
import { GanttTaskTableRow } from "./GantTaskTableRow"
import { setFilters, toggleFilter } from "./ganttSlice"

const tableHeaderMapping = {
    [TaskFilterFields.WBS]: { label: TaskFilterFields.WBS, width: "120px" },
    [CommonFilterFields.Name]: {
        label: CommonFilterFields.Name,
        width: "370px",
    },
    [TaskFilterFields.StartDate]: {
        label: TaskFilterFields.StartDate,
        width: "200px",
    },
    [TaskFilterFields.EndDate]: {
        label: TaskFilterFields.EndDate,
        width: "200px",
    },
    [TaskFilterFields.TaskStatus]: {
        label: TaskFilterFields.TaskStatus,
        width: "170px",
    },
    [TaskFilterFields.StatusDetail]: {
        label: TaskFilterFields.StatusDetail,
        width: "200px",
    },
    [TaskFilterFields.DelayStatus]: {
        label: TaskFilterFields.DelayStatus,
        width: "200px",
    },
    [TaskFilterFields.StartDelay]: {
        label: TaskFilterFields.StartDelay,
        width: "170px",
    },
    [TaskFilterFields.EndDelay]: {
        label: TaskFilterFields.EndDelay,
        width: "170px",
    },
    [TaskFilterFields.Duration]: {
        label: TaskFilterFields.Duration,
        width: "150px",
    },
    [TaskFilterFields.Progress]: {
        label: TaskFilterFields.Progress,
        width: "150px",
    },
    [TaskFilterFields.Assignees]: {
        label: TaskFilterFields.Assignees,
        width: "170px",
    },
    [TaskFilterFields.Reviewers]: {
        label: TaskFilterFields.Reviewers,
        width: "170px",
    },
    [TaskFilterFields.Tags]: { label: TaskFilterFields.Tags, width: "170px" },
}

export const GanttTaskTable: React.FC = () => {
    const { t } = useTranslation()
    const taskTree = useAppSelector(selectTaskTree)
    const filters = useAppSelector((state) => state.gantt.filters)
    const dispatch = useAppDispatch()
    const thClasses =
        " border border-secondary-100 bg-primary-100 print:bg-white print:text-primary-300 print:border-primary-300 text-xs font-medium text-secondary-100 tracking-wider top-0 relative"
    const tableColumns = useAppSelector((state) => state.gantt.tableColumns)
    return (
        <div className="w-full h-full overflow-auto">
            <div className="min-w-full w-max flex h-16 sticky top-0 left-0 z-20">
                {Object.values(tableColumns).map((field) => (
                    <div
                        className={
                            "flex-1 flex justify-center items-center" +
                            thClasses
                        }
                        style={{
                            minWidth:
                                tableHeaderMapping[field as TaskFilterFields]
                                    .width,
                        }}
                        key={
                            tableHeaderMapping[field as TaskFilterFields].label
                        }
                    >
                        <p className="uppercase">
                            {t(
                                tableHeaderMapping[field as TaskFilterFields]
                                    .label,
                            )}
                        </p>
                        <FontAwesomeIcon
                            icon="filter"
                            className={`ml-1 hover:text-yellow-400 transition cursor-pointer ${filters[tableHeaderMapping[field as TaskFilterFields].label].isShown || filters[tableHeaderMapping[field as TaskFilterFields].label].value ? "text-yellow-400" : "text-secondary-100"}`}
                            onClick={() => {
                                dispatch(
                                    toggleFilter(
                                        tableHeaderMapping[
                                            field as TaskFilterFields
                                        ].label,
                                    ),
                                )
                            }}
                        />
                        {filters[
                            tableHeaderMapping[field as TaskFilterFields].label
                        ].value && (
                            <FontAwesomeIcon
                                icon="filter-circle-xmark"
                                className="ml-1 text-red-400 hover:text-red-600 transition cursor-pointer"
                                onClick={() => {
                                    dispatch(
                                        setFilters({
                                            ...filters,
                                            [tableHeaderMapping[
                                                field as TaskFilterFields
                                            ].label]: {
                                                ...filters[
                                                    tableHeaderMapping[
                                                        field as TaskFilterFields
                                                    ].label
                                                ],
                                                value: "",
                                            },
                                        }),
                                    )
                                }}
                            />
                        )}
                        {filters[
                            tableHeaderMapping[field as TaskFilterFields].label
                        ].isShown && (
                            <FilterContainer
                                filters={
                                    filters as FilterValuesType<FilterFields>
                                }
                                field={
                                    tableHeaderMapping[
                                        field as TaskFilterFields
                                    ].label
                                }
                                setFilters={setFilters}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="w-full">
                <GanttTaskTableRow task={taskTree} />
            </div>
        </div>
    )
}
