import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../app/hooks"
import bimIllustration from "../assets/bim_illustration.png"
import { selectIsLogged } from "../features/Authentication/authSlice"

function Authentication() {
    const isLogged = useAppSelector(selectIsLogged)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const location = useLocation()
    useEffect(() => {
        if (isLogged) {
            navigate("/dashboard/")
        } else if (location.pathname === "/auth/") {
            navigate("/auth/login")
        }
    }, [isLogged, navigate])
    return (
        <div className="flex w-full justify-center items-center h-2/3 z-10 lg:h-full sm:h-full">
            <Outlet></Outlet>
            <div className="w-[500px] h-full bg-sky-500 flex justify-evenly items-center flex-col lg:hidden sm:hidden lg">
                <img src={bimIllustration} alt="" />
                <p className="font-bold">
                    {t("bim_project_management_made_easy")}!
                </p>
            </div>
            {/* <BgMovingRectangle /> */}
        </div>
    )
}

export default Authentication
