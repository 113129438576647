import { Input } from "@/components/Inputs/Input"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { BimpackLogo } from "@/components/Other/BimpackLogo"
import { Form, SubmitType } from "@/features/Form/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpring } from "@react-spring/web"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { clearErrors } from "../Invitation/invitationSlice"

interface ContactUsProps {}

export const ContactUs: React.FC<ContactUsProps> = () => {
    const { t } = useTranslation()
    const [contactUs, setContactUs] = useState<{
        firstName: string
        lastName: string
        email: string
        subject: string
        message: string
    }>({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: "",
    })
    const floatSpring = useSpring({
        from: { y: -20 },
        to: [{ y: 20 }, { y: -20 }],
        loop: true,
        config: {
            duration: 3000,
            mass: 5,
            friction: 120,
            tension: 120,
        },
    })
    return (
        <section className="w-full flex flex-col items-center min-h-screen px-32 py-8  text-secondary-100 lg:p-4">
            <h1 className="text-5xl font-black mb-4">{t("contact_us")}</h1>
            <p className="text-xl text-center w-3/4 lg:w-full lg:mb-4">
                {t("contact_us_description")}
            </p>
            <div className="w-full flex justify-between p-14 gap-2 lg:flex-col lg:p-0">
                <Form
                    onSubmit={() => {}}
                    className="w-1/3 bg-secondary-100 rounded-lg p-4 text-primary-300 lg:w-full lg:p-0 lg:py-4 flex flex-col gap-4 items-center"
                    statuses={[]}
                    loadingTitle={t("sending_email")}
                    submitType={SubmitType.Submit}
                    clearErrors={clearErrors}
                >
                    <h1 className="text-xl font-black mb-4 w-[80%] text-center m-auto">
                        {t("get_in_touch_with_us")}
                    </h1>
                    <div className="w-full flex flex-col gap-4 items-center">
                        <Input
                            label={t("first_name")}
                            name={t("first_name")}
                            type="text"
                            className="w-[80%] !m-0"
                            placeholder={"Jhon"}
                            value={contactUs.firstName}
                            onChange={(e) =>
                                setContactUs({
                                    ...contactUs,
                                    firstName: e.target.value,
                                })
                            }
                        />
                        <Input
                            label={t("last_name")}
                            name={t("last_name")}
                            type="text"
                            className="w-[80%] !m-0"
                            placeholder={t("Smith")}
                            value={contactUs.lastName}
                            onChange={(e) =>
                                setContactUs({
                                    ...contactUs,
                                    lastName: e.target.value,
                                })
                            }
                        />
                        <Input
                            label={t("email")}
                            name={t("email")}
                            type="email"
                            className="w-[80%] !m-0"
                            placeholder={"jhon@smith.com"}
                            value={contactUs.email}
                            onChange={(e) =>
                                setContactUs({
                                    ...contactUs,
                                    email: e.target.value,
                                })
                            }
                        />
                        <Input
                            label={t("subject")}
                            name={t("subject")}
                            type="text"
                            className="w-[80%] !m-0"
                            placeholder={t("pricing_inquiry")}
                            value={contactUs.subject}
                            onChange={(e) =>
                                setContactUs({
                                    ...contactUs,
                                    subject: e.target.value,
                                })
                            }
                        />
                        <div className="w-[80%]">
                            <TextAreaInput
                                label={t("message")}
                                name={t("message")}
                                value={contactUs.message}
                                onChange={(e) =>
                                    setContactUs({
                                        ...contactUs,
                                        message: e.target.value,
                                    })
                                }
                                className="w-full resize-none h-[150px]"
                            />
                        </div>
                    </div>
                </Form>
                <div className="w-1/3 flex items-center lg:hidden">
                    <animated.div
                        className="w-full opacity-15"
                        style={{ ...floatSpring }}
                    >
                        <BimpackLogo
                            className="m-auto"
                            logoSize="w-[70%]"
                            withText={false}
                            isLink={false}
                        />
                    </animated.div>
                </div>
                <div className="w-full flex flex-col justify-evenly">
                    <div className="flex flex-col items-center">
                        <h1 className="text-2xl font-black mt-7">
                            {t("follow_us")}
                        </h1>
                        <p className="mb-4 text-center">{t("follow_us_description")}</p>
                        <div>
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon icon={["fab", "facebook"]} />
                                <a
                                    href="https://www.facebook.com/bimpack.official"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    facebook.com/bimpack.official
                                </a>
                            </div>
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon icon={["fab", "x-twitter"]} />
                                <a
                                    href="https://x.com/bimpackIo"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    x.com/bimpackIo
                                </a>
                            </div>
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon icon={["fab", "linkedin"]} />
                                <a
                                    href="https://www.linkedin.com/company/bimpackio"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    linkedin.com/company/bimpackio
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center self-center mt-6">
                        <h1 className="text-2xl font-black ">
                            {t("contact_information")}
                        </h1>
                        <p className="mb-4 text-center">
                            {t("contact_information_description")}
                        </p>
                        <div>
                            <div className="flex items-center gap-2 mb-2">
                                <FontAwesomeIcon icon="envelope" />
                                <p className="font-bold">{t("email")}: </p>
                                <a
                                    href="mailto:contact@bimpack.io"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    contact@bimpack.io
                                </a>
                            </div>
                            <div className="flex items-center gap-2 mb-2">
                                <FontAwesomeIcon icon="phone" />
                                <p className="font-bold">{t("phone")}: </p>
                                <a
                                    href="tel:+21627557513"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    +216 27 557 513
                                </a>
                                /
                                <a
                                    href="tel:+21658815090"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    +216 58 815 090
                                </a>
                            </div>
                            <div className="flex items-center gap-2 mb-2">
                                <FontAwesomeIcon icon="globe" />
                                <p className="font-bold">{t("address")}: </p>
                                <a
                                    href="https://maps.app.goo.gl/oaDcEqcpgxiDfth27"
                                    className="text-highlight-100"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Ben Arous, Tunisia
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
