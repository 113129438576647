import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Scrollable } from "../Layouts/Scrollable"
import { Popup, POPUP_TYPES } from "../Menus/Popup"

interface DropDownMultiInputProps {
    options: { label: string; value: string | number }[]
    type: "text" | "number"
    value: string[] | number[]
    onChange: (value: string[] | number[]) => void
    enableSearch?: boolean
    placeHolder?: string
    className?: string
}

export const DropDownMultiInput: React.FC<DropDownMultiInputProps> = ({
    options,
    value,
    onChange,
    className,
    type,
    placeHolder,
    enableSearch = true,
}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const dropDownRef = React.useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const [search, setSearch] = React.useState("")
    const [selectedOptions, setSelectedOptions] = React.useState<
        { label: string; value: string | number }[]
    >([])
    const [notSelectedOptions, setNotSelectedOptions] =
        React.useState<{ label: string; value: string | number }[]>(options)
    useEffect(() => {
        setSelectedOptions(
            options.filter((option) => value.includes(option.value as never)),
        )
        setNotSelectedOptions(
            options.filter((option) => !value.includes(option.value as never)),
        )
    }, [options, value])
    useEffect(() => {
        if (search) {
            const timeout = setTimeout(() => {
                setNotSelectedOptions(
                    options.filter((option) => {
                        return option.label
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    }),
                )
            }, 500)

            return () => clearTimeout(timeout)
        } else {
            setNotSelectedOptions(
                options.filter(
                    (option) => !value.includes(option.value as never),
                ),
            )
        }
    }, [search, options, value])
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            if (type === "number") {
                onChange([...value, parseInt(e.target.value)] as number[])
            } else {
                onChange([...value, e.target.value] as string[])
            }
        } else {
            if (type === "number") {
                onChange(
                    value.filter((v) => v !== parseInt(e.target.value)) as
                        | string[]
                        | number[],
                )
            } else {
                onChange(
                    value.filter((v) => v !== e.target.value) as
                        | string[]
                        | number[],
                )
            }
        }
    }
    return (
        <div className={className}>
            <div className="w-[340px]" ref={dropDownRef}>
                <div
                    className="flex items-center justify-between w-full flex-wrap border border-primary-300 rounded-lg p-2 cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedOptions.length > 0 ? (
                        <Scrollable height="250px">
                            <div className="flex gap-2 flex-wrap">
                                {selectedOptions.map((option, idx) => (
                                    <p
                                        key={idx}
                                        className="p-2 bg-secondary-400 rounded-lg"
                                    >
                                        {option.label}
                                    </p>
                                ))}
                            </div>
                        </Scrollable>
                    ) : (
                        <p className="text-primary-300 opacity-65">
                            {placeHolder}
                        </p>
                    )}
                    {selectedOptions.length === 0 && (
                        <FontAwesomeIcon
                            icon={isOpen ? "chevron-up" : "chevron-down"}
                        />
                    )}
                </div>

                <Popup
                    type={POPUP_TYPES.LEFT_CLICK}
                    isShown={isOpen}
                    closePopup={() => setIsOpen(false)}
                    parentRef={dropDownRef}
                    className="p-4"
                >
                    {enableSearch && (
                        <div>
                            <input
                                type="text"
                                placeholder={t("search")}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    )}
                    <div className="flex justify-between text-primary-100 font-bold uppercase text-sm">
                        <p
                            className="cursor-pointer mr-2"
                            onClick={() =>
                                onChange(
                                    options.map((option) => option.value) as
                                        | string[]
                                        | number[],
                                )
                            }
                        >
                            {t("select_all")}
                        </p>
                        <p
                            className="cursor-pointer"
                            onClick={() => onChange([])}
                        >
                            {t("clear")}
                        </p>
                    </div>
                    {selectedOptions.length > 0 && (
                        <div>
                            <h3 className="font-bold border-b border-b-primary-300">
                                {t("selected")}
                            </h3>
                            <div>
                                {selectedOptions.map((option, idx) => (
                                    <span key={idx} className="w-full flex">
                                        <input
                                            type="checkbox"
                                            value={option.value}
                                            onChange={handleChange}
                                            checked={value.includes(
                                                option.value as never,
                                            )}
                                            className="mr-2"
                                        />
                                        <p>{option.label}</p>
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                    {notSelectedOptions.length > 0 && (
                        <div>
                            <h3 className="font-bold border-b border-b-primary-300">
                                {t("options")}
                            </h3>
                            <div>
                                {notSelectedOptions.map((option, idx) => (
                                    <span key={idx} className="w-full flex">
                                        <input
                                            type="checkbox"
                                            value={option.value}
                                            onChange={handleChange}
                                            checked={value.includes(
                                                option.value as never,
                                            )}
                                            className="mr-2"
                                        />
                                        <p>{option.label}</p>
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                    {selectedOptions.length + notSelectedOptions.length ===
                        0 && (
                        <p className="w-full flex justify-center items-center p-2 text-primary-100">
                            <FontAwesomeIcon
                                icon="times-circle"
                                className="mr-2"
                            />
                            {t("nothing_to_select")}
                        </p>
                    )}
                </Popup>
            </div>
        </div>
    )
}
