import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface MyFilesIconProps {
    className?: string
}

export const MyFilesIcon: React.FC<MyFilesIconProps> = ({ className }) => {
    return (
        <div className={`${className} relative w-16 h-16`}>
            <FontAwesomeIcon
                icon="folder"
                className="text-6xl text-yellow-500 absolute"
            />
            <FontAwesomeIcon
                icon="user"
                className="text-secondary-100 absolute text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
        </div>
    )
}
