import { useAppSelector } from "@/app/hooks"
import { Navbar } from "@/features/Navbar/Navbar"
import { Sidebar } from "@/features/Sidebar/Sidebar"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"

interface AdminProps {}

export const Admin: React.FC<AdminProps> = () => {
    const user = useAppSelector((state) => state.auth.user)
    const isLogged = useAppSelector((state) => state.auth.isLogged)
    const navigate = useNavigate()
    const sideBarItems = [
        [
            {
                name: "home",
                icon: "home" as IconProp,
                route: "/admin/home",
            },
            {
                name: "users",
                icon: "user" as IconProp,
                route: "/admin/users/",
            },
            {
                name: "projects",
                icon: "folder" as IconProp,
                route: "/admin/projects/",
            },
        ],
        [
            {
                name: "pricing",
                icon: "dollar-sign" as IconProp,
                route: "/admin/pricing",
            },
            {
                name: "tickets",
                icon: "circle-info" as IconProp,
                route: "/admin/tickets",
            },
        ],
    ]
    useEffect(() => {
        if (!user.isSuperAdmin) navigate("/dashboard/")
    }, [])
    return (
        <div className="flex flex-col w-full h-full z-10">
            <Navbar adminDashboard></Navbar>
            <div className="flex min-h-0 h-full">
                <Sidebar items={sideBarItems}></Sidebar>
                <div className="w-full h-full p-2 sm:p-0 overflow-hidden">
                    <div className="w-full h-full bg-secondary-300 rounded-lg text-primary-300 p-2 overflow-hidden box-border">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}
