import { useAppSelector } from "@/app/hooks"
import { GanttFormatterMapping } from "@/common/helpers"
import { useState } from "react"
import { selectTaskTree } from "../planningSlice"
import { selectGanttCalendar } from "./ganttSlice"
import { GanttTaskCalendarRow } from "./GanttTaskCalendarRow"

interface GanttTaskCalendarProps {}

export const GanttTaskCalendar: React.FC<GanttTaskCalendarProps> = ({}) => {
    const calendar = useAppSelector(selectGanttCalendar)
    const formatter = GanttFormatterMapping[calendar.zoom]
    const taskTree = useAppSelector(selectTaskTree)
    const [tableLevel, setTableLevel] = useState<number>(0)
    return (
        <div className="w-full h-full overflow-auto">
            <div className="w-full h-16 sticky top-0 z-10">
                <div className="flex h-full w-full">
                    {calendar.dates.map((date, index) => (
                        <div key={index} className="shrink-0 h-full">
                            <div className="border h-1/2 bg-primary-100 text-secondary-100 flex items-center justify-center">
                                {formatter.generalFormatter(date.date)}
                            </div>
                            <div className="flex h-1/2">
                                {date.interval.map((secondDate, index) => (
                                    <div
                                        key={index}
                                        className="shrink-0 border bg-primary-100 text-secondary-100 flex items-center justify-center"
                                        style={{
                                            width: calendar.unit + "px",
                                        }}
                                    >
                                        {formatter.detailsFormatter(secondDate)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full relative">
                <div
                    className="absolute top-0 left-0 overflow-hidden h-full"
                    style={{
                        backgroundImage: `linear-gradient(#666666 1px, transparent 1px), linear-gradient(to right, #666666 1px, #ffffff 1px)`,
                        backgroundSize: `${calendar.unit}px 48px`,
                        opacity: 0.15,
                        backgroundColor: "#FFF",
                        width: `${calendar.unit * calendar.dates.flatMap((date) => date.interval).length}px`,
                        minHeight: "49px",
                    }}
                ></div>
                <GanttTaskCalendarRow task={taskTree} />
            </div>
        </div>
    )
}
