import { BaseModel, BaseModelInterface } from "./BaseModel"

export enum InvitationStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    EXPIRED = "expired",
}

export interface InvitationInterface extends BaseModelInterface {
    token: string
    email: string
    status: InvitationStatus
}

export class Invitation extends BaseModel {
    public token: string
    public email: string
    public status: InvitationStatus

    constructor(data?: InvitationInterface) {
        super(data)
        this.token = data?.token ?? ""
        this.email = data?.email ?? ""
        this.status = data?.status ?? InvitationStatus.PENDING
    }

    public toJson(): InvitationInterface {
        return {
            ...super.toJson(),
            token: this.token,
            email: this.email,
            status: this.status,
        }
    }
}
