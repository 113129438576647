import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { selectFolderTree } from "./fileManagerSlice"
import { QuickNavigation } from "./QuickNavigation"
interface FileExplorerProps {}

export const FileExplorer: React.FC<FileExplorerProps> = ({}) => {
    const folderTree = useAppSelector(selectFolderTree)
    const location = useLocation()
    const [showQuickNavigation, setShowQuickNavigation] =
        React.useState<boolean>(true)
    useEffect(() => {
        const currentPath = location.pathname.split("/").pop()
        if (
            currentPath === "file-manager" ||
            currentPath === "bim-file-table"
        ) {
            setShowQuickNavigation(false)
        } else {
            setShowQuickNavigation(true)
        }
    }, [location])
    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 flex gap-4 flex-wrap w-full h-full">
            <div className="flex w-full gap-2 h-full">
                {showQuickNavigation && folderTree.children.length > 0 && (
                    <div className="w-3/12 max-w-[280px] sm:hidden lg:hidden">
                        <QuickNavigation />
                    </div>
                )}
                <Scrollable width="100%" height="100%" className="w-9/12">
                    <Outlet />
                </Scrollable>
            </div>
        </div>
    )
}
