import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface FeatureCardProps {
    title: string
    description: string
    icon: IconProp
    onClick?: () => void
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
    title,
    description,
    icon,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className="w-80 bg-primary-100 px-4 py-2 rounded-lg flex flex-col items-center justify-center text-center transition hover:scale-110 cursor-pointer select-none"
        >
            <div className="w-16 h-16 bg-secondary-100 rounded-full flex items-center justify-center">
                <FontAwesomeIcon
                    icon={icon}
                    className="text-3xl text-primary-100"
                />
            </div>
            <div>
                <h2 className="text-xl font-bold mt-2">{title}</h2>
                <p className="text-lg font-light text-center ">{description}</p>
            </div>
        </div>
    )
}
