import { User } from "@/models/User"
import { useState } from "react"
import { Popup } from "@/components/Layouts/Popup"
import { ProfilePicture } from "./ProfilePicture"

interface UsersShowProps {
    users: User[]
    className?: string
    othersClassName?: string
}

export const UsersShow: React.FC<UsersShowProps> = ({
    users,
    className,
    othersClassName,
}) => {
    const [hovered, setHovered] = useState<boolean>(false)
    const [popUpCords, setPopUpCords] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    })
    const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setPopUpCords({ x: e.clientX, y: e.clientY })
        setHovered(true)
    }
    return (
        <div
            className={`flex relative ${className} cursor-default`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={() => setHovered(false)}
        >
            {users.slice(0, 2).map((user, idx) => (
                <ProfilePicture
                    key={user.id}
                    className={`w-8 h-8 rounded-full  ${idx !== 0 && "-ml-6"}`}
                    picture={user.profilePicture}
                    alt={`${user.email} profile`}
                />
            ))}
            {users.length > 2 && (
                <div
                    className={`w-8 h-8 rounded-full bg-primary-100  -ml-6 flex justify-center items-center text-secondary-100 ${othersClassName}`}
                >
                    <p className="text-xs ">+{users.length - 2}</p>
                </div>
            )}
            {users.length === 0 && (
                <div
                    className={`w-8 h-8 rounded-full bg-primary-100  flex justify-center items-center text-secondary-100 ${othersClassName}`}
                >
                    <p className="text-xs ">0</p>
                </div>
            )}
            <Popup
                isShown={hovered && users.length > 0}
                x={popUpCords.x}
                y={popUpCords.y}
            >
                <ul className="p-2 flex flex-col gap-2">
                    {users.map((user) => (
                        <li key={user.id} className="flex gap-2">
                            <ProfilePicture
                                picture={user.profilePicture}
                                className="w-6 h-6 rounded-full"
                            />
                            <p>
                                {user.fullName} ({user.email})
                            </p>
                        </li>
                    ))}
                </ul>
            </Popup>
        </div>
    )
}
