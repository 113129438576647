import { useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Task } from "@/models/Task"
import { t } from "i18next"
import { TaskCard } from "./Card"

interface CardsBoardProps {
    tasks: Task[]
    pageSize: number
    pageNumber: number
    setPageNumber: (pageNumber: number) => void
    setPageSize: (pageSize: number) => void
    mobile?: boolean
}

export const CardsBoard: React.FC<CardsBoardProps> = ({
    tasks,
    mobile = false,
    pageSize,
    pageNumber,
    setPageNumber,
    setPageSize,
}) => {
    const status = useAppSelector((state) => state.tasks.status)
    const totalCount = useAppSelector((state) => state.tasks.tasksTotalCount)
    return (
        <div className="w-full p-3">
            <WithLoader statuses={[status.read]} title={t("loading_tasks")}>
                <Scrollable>
                    <>
                        <div className="flex flex-wrap">
                            {tasks.map((task) => (
                                <div
                                    key={task.id}
                                    className="p-4 w-1/4 sm:w-full lg:w-1/2"
                                >
                                    <TaskCard task={task} />
                                </div>
                            ))}
                        </div>
                        {tasks.length < totalCount && (
                            <Button
                                type="button"
                                name={t("load_more")}
                                onClick={() => {
                                    setPageNumber(pageNumber + 1)
                                    setPageSize(pageSize + 10)
                                }}
                                className="w-full mt-4"
                            />
                        )}
                    </>
                </Scrollable>
            </WithLoader>
        </div>
    )
}
