import { HorizontalSmoothScroll } from "@/components/Layouts/HorizontalSmoothScroll"
import { Task } from "@/models/Task"
import { CardsBoard } from "./CardsBoard"

interface CardsProps {
    tasks: Task[]
    pageSize: number
    pageNumber: number
    setPageNumber: (pageNumber: number) => void
    setPageSize: (pageSize: number) => void
}

export const Cards: React.FC<CardsProps> = ({
    tasks,
    pageSize,
    pageNumber,
    setPageNumber,
    setPageSize,
}) => {
    return (
        <div className="w-full h-full">
            <div className="sm:hidden flex overflow-hidden h-full">
                <CardsBoard
                    tasks={tasks}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                />
            </div>
            <div className="hidden h-full w-full sm:block">
                <HorizontalSmoothScroll>
                    <CardsBoard
                        tasks={tasks}
                        mobile={true}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        setPageSize={setPageSize}
                        setPageNumber={setPageNumber}
                    />
                </HorizontalSmoothScroll>
            </div>
        </div>
    )
}
