interface CalendarInputProps {
    label?: string
    value: string
    name: string
    className?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CalendarInput: React.FC<CalendarInputProps> = ({
    label,
    value,
    name,
    className = "",
    onChange,
}) => {
    return (
        <div className={className}>
            {label && <p className={`font-bold`}>{label}</p>}
            <input
                className="w-full"
                name={name}
                type="date"
                onChange={onChange}
                value={value ?? ""}
            />
        </div>
    )
}
