import { Task } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

interface DetailsTimelineCalendarProps {
    tasks: Task[]
    month: number
}

export const DetailsTimelineCalendar: React.FC<
    DetailsTimelineCalendarProps
> = ({ tasks, month }) => {
    const { t } = useTranslation()
    const getDay = (day: number) => {
        return (t("daysMapping", { returnObjects: true }) as string[])[day]
    }
    const [showTasks, setShowTasks] = React.useState<boolean>(false)
    const [popupCords, setPopupCords] = React.useState<[number, number]>([0, 0])
    const [calendarMonth, setCalendarMonth] = React.useState<number>(month)

    const firstDay = new Date(new Date().getFullYear(), calendarMonth, 1)
    const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0)

    const lastSunday = new Date(
        new Date(firstDay).setDate(firstDay.getDate() - firstDay.getDay()),
    )
    const iteratorDate = new Date(lastSunday)

    const isDateInInterval = (date: Date, startDate: Date, endDate: Date) => {
        const compareDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        )
        const compareStartDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
        )
        const compareEndDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
        )
        return compareDate >= compareStartDate && compareDate <= compareEndDate
    }

    const datesTasks = [...Array(6)].map((_, i) =>
        [...Array(7)].map((_, j) => ({
            date:
                i === 0 && j === 0
                    ? new Date(iteratorDate)
                    : new Date(
                          iteratorDate.setDate(iteratorDate.getDate() + 1),
                      ),
            tasks: tasks.filter((task) =>
                isDateInInterval(iteratorDate, task.startDate, task.endDate),
            ),
        })),
    )

    const taskColors = tasks.map((task) => ({
        id: task.id,
        color: Math.floor(Math.random() * 16777215).toString(16),
    }))

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setPopupCords([e.clientX, e.clientY])
        setShowTasks(true)
    }

    const compareDates = (a: Date, b: Date) => {
        return (
            a.getFullYear() === b.getFullYear() &&
            a.getMonth() === b.getMonth() &&
            a.getDate() === b.getDate()
        )
    }

    return (
        <div className="w-full">
            <div className="text-lg font-bold bg-primary-100 py-2 flex items-center justify-center">
                <FontAwesomeIcon
                    icon={["fas", "chevron-left"]}
                    className="mr-2 cursor-pointer hover:scale-125 transition"
                    onClick={() => setCalendarMonth((calendarMonth - 1) % 12)}
                />
                <h1>
                    {
                        (t("months", { returnObjects: true }) as string[])[
                            calendarMonth
                        ]
                    }{" "}
                    {new Date().getFullYear()}
                </h1>
                <FontAwesomeIcon
                    icon={["fas", "chevron-right"]}
                    className="ml-2 cursor-pointer hover:scale-125 transition"
                    onClick={() => setCalendarMonth((calendarMonth + 1) % 12)}
                />
            </div>
            <table
                className="w-full border-collapse"
                cellSpacing={0}
                cellPadding={0}
                border={0}
            >
                <thead className="border-b ">
                    <tr>
                        <th>{getDay(0)}</th>
                        <th>{getDay(1)}</th>
                        <th>{getDay(2)}</th>
                        <th>{getDay(3)}</th>
                        <th>{getDay(4)}</th>
                        <th>{getDay(5)}</th>
                        <th>{getDay(6)}</th>
                    </tr>
                </thead>
                <tbody>
                    {datesTasks.map((week, index) => (
                        <tr key={index} className="text-center w-full h-8">
                            {week.map((day, index) => (
                                <td key={index} className="">
                                    <div
                                        className={`w-full h-8 ${
                                            day.date >= firstDay &&
                                            day.date <= lastDay
                                                ? "text-primary-300"
                                                : "text-primary-100"
                                        }`}
                                    >
                                        {day.tasks.length > 0 && (
                                            <div className="w-full relative h-8 flex flex-col gap-1 justify-center">
                                                <div className="absolute left-0 top-0 h-full w-full flex justify-center items-center ">
                                                    <p className="bg-secondary-100 rounded-full w-6 h-6 ">
                                                        {day.date.getDate()}
                                                    </p>
                                                </div>
                                                {day.tasks.map((task) => (
                                                    <div
                                                        key={task.id}
                                                        className={`w-full h-1/3${
                                                            compareDates(
                                                                day.date,
                                                                task.startDate,
                                                            )
                                                                ? " rounded-tl-2xl rounded-bl-2xl"
                                                                : ""
                                                        } ${
                                                            compareDates(
                                                                day.date,
                                                                task.endDate,
                                                            )
                                                                ? " rounded-tr-2xl rounded-br-2xl"
                                                                : ""
                                                        }`}
                                                        style={{
                                                            backgroundColor: `#${
                                                                taskColors.find(
                                                                    (
                                                                        taskColor,
                                                                    ) =>
                                                                        taskColor.id ===
                                                                        task.id,
                                                                )?.color
                                                            }`,
                                                        }}
                                                        onMouseEnter={
                                                            handleMouseEnter
                                                        }
                                                        onMouseLeave={() =>
                                                            setShowTasks(false)
                                                        }
                                                    >
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {day.tasks.length === 0 && (
                                            <div className="flex w-full h-full justify-center items-center">
                                                <p>{day.date.getDate()}</p>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
