import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import {
    getCurrentUserPermissionsInProject,
    selectUserPermissions,
} from "@/features/User/userSlice"
import { MODULE_PERMISSIONS } from "@/models/Permission"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getProjectUsersById, selectProjectUsers } from "../projectsSlice"
import { UserCard } from "./UserCard"

import { Spoiler } from "@/components/Layouts/Spoiler"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Form, SubmitType } from "@/features/Form/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InvitationCard } from "./InvitationCard"
import {
    clearErrors,
    getProjectInvitations,
    inviteUsersToProject,
    selectProjectInvitations,
} from "./userManagementSlice"

interface UsersProps {}

const isEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
}

export const Users: React.FC<UsersProps> = () => {
    const dispatch = useAppDispatch()
    const users = useAppSelector(selectProjectUsers)
    const invitations = useAppSelector(selectProjectInvitations)
    const userPermissions = useAppSelector(selectUserPermissions)
    const status = useAppSelector((state) => state.projects.status)
    const projectStatus = useAppSelector((state) => state.projects.status)
    const userManagementStatus = useAppSelector(
        (state) => state.userManagement.status,
    )
    const [userEmail, setUserEmail] = useState<string>("")
    const [usersToAdd, setUsersToAdd] = useState<string[]>([])
    const [canAdd, setCanAdd] = useState<boolean>(false)
    const [addUser, setAddUser] = useState<boolean>(false)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const { t } = useTranslation()
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getCurrentUserPermissionsInProject(projectId))
        dispatch(getProjectInvitations(projectId))
    }, [])
    useEffect(() => {
        if (isEmail(userEmail)) {
            setCanAdd(true)
        } else {
            setCanAdd(false)
        }
    }, [userEmail])
    return (
        <div className="bg-secondary-100 rounded-lg shadow-lg p-4 flex flex-col gap-2">
            <div>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="font-bold text-2xl">{t("users")}:</h2>
                    {userPermissions.hasAllPermissions([
                        MODULE_PERMISSIONS.UserManager.Invite,
                    ]) && (
                        <Button
                            name={t("invite_users")}
                            icon="user-plus"
                            onClick={() => setAddUser(true)}
                        />
                    )}
                </div>
                <Scrollable height="100%">
                    <WithLoader
                        statuses={[status.read]}
                        title={t("loading_users")}
                    >
                        <div className="flex flex-wrap gap-4 justify-normal pb-5 w-full">
                            {users.length > 0 &&
                                users.map((user) => (
                                    <UserCard key={user.id} user={user} />
                                ))}
                            {users.length === 0 && (
                                <div className="w-full h-full flex justify-center items-center gap-2 opacity-50">
                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                    <p>{t("no_users_found")}</p>
                                </div>
                            )}
                        </div>
                        <Spoiler title={t("invites")}>
                            <div className="flex flex-wrap gap-4 justify-normal pb-5">
                                {invitations.length > 0 &&
                                    invitations.map((invitation) => (
                                        <InvitationCard
                                            invitation={invitation}
                                            key={invitation.id}
                                        />
                                    ))}
                                {invitations.length === 0 && (
                                    <div className="w-full h-full flex justify-center items-center gap-2 opacity-50">
                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                        <p>{t("no_invitations_found")}</p>
                                    </div>
                                )}
                            </div>
                        </Spoiler>
                    </WithLoader>
                </Scrollable>
            </div>
            <NewModal
                title={t("invite_users_to_project")}
                isShown={addUser}
                closeModal={() => setAddUser(false)}
            >
                <Form
                    statuses={[userManagementStatus.update, projectStatus.read]}
                    loadingTitle={t("inviting_users")}
                    clearErrors={clearErrors}
                    onSubmit={async () => {
                        if (usersToAdd.length === 0) return
                        const { type } = await dispatch(
                            inviteUsersToProject({
                                projectId,
                                emails: usersToAdd,
                            }),
                        )
                        if (type === inviteUsersToProject.fulfilled.type) {
                            setUsersToAdd([])
                            setAddUser(false)
                        }
                        await dispatch(getProjectInvitations(projectId))
                    }}
                    onCancel={() => setAddUser(false)}
                    submitType={SubmitType.Submit}
                >
                    <div className="flex flex-col gap-2 w-[400px]">
                        <h1 className="font-bold text-lg">
                            {t("invite_users_to_project")}
                        </h1>
                        {usersToAdd.length > 0 && (
                            <div className="border p-2 rounded-lg border-primary-300 border-opacity-20">
                                <p>{usersToAdd.join(", ")}</p>
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <Input
                                type="text"
                                name={t("email")}
                                placeholder={t("email")}
                                className="!m-0 w-full"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && canAdd) {
                                        setUsersToAdd([
                                            ...usersToAdd,
                                            userEmail,
                                        ])
                                        setUserEmail("")
                                    }
                                }}
                                onBlur={() => {
                                    if (canAdd) {
                                        setUsersToAdd([
                                            ...usersToAdd,
                                            userEmail,
                                        ])
                                        setUserEmail("")
                                    }
                                }}
                            />
                            <Button
                                icon="plus"
                                className={`!py-2 ${canAdd ? "!bg-green-200 !opacity-100 !border-green-600 !text-green-600 hover:!bg-green-600 hover:!text-secondary-100 hover:!border-secondary-100 cursor-pointer" : "!opacity-50 !cursor-not-allowed"}`}
                                name={t("add")}
                                isDisabled={!canAdd}
                                onClick={() => {
                                    if (canAdd) {
                                        setUsersToAdd([
                                            ...usersToAdd,
                                            userEmail,
                                        ])
                                        setUserEmail("")
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Form>
            </NewModal>
        </div>
    )
}
