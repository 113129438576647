import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { UsersShow } from "@/components/Other/UsersShow"
import { Task } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

interface ListProps {
    tasks: Task[]
}

export const List: React.FC<ListProps> = ({ tasks }) => {
    const { t } = useTranslation()
    const status = useAppSelector((state) => state.projects.status)
    const location = useLocation()
    const navigate = useNavigate() // @FIXME make it link
    const navigateToTaskDetails = (id: string) => {
        navigate(`${location.pathname}/${id}`)
    }

    return (
        <Scrollable height="100%" className="h-full">
            <WithLoader statuses={[status.read]} title={t("loading_tasks")}>
                <table className="w-full">
                    <thead>
                        <tr className="text-left">
                            <th className="p-2">{t("name")}</th>
                            <th className="p-2 lg:hidden">
                                {t("description")}
                            </th>
                            <th className="p-2">{t("end_date")}</th>
                            <th className="p-2 sm:hidden">{t("assignees")}</th>
                            <th className="p-2 sm:hidden">{t("reviewers")}</th>
                            <th className="p-2 text-center">{t("status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.length > 0 &&
                            tasks.map((task) => (
                                <tr
                                    key={task.id}
                                    className="h-14 hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                                    onClick={() =>
                                        navigateToTaskDetails(task.id)
                                    }
                                >
                                    <td className="p-2">
                                        <p>{task.name}</p>
                                    </td>
                                    <td className="p-2 lg:hidden">
                                        <p>{task.description}</p>
                                    </td>
                                    <td className="p-2">
                                        <p>
                                            {t("intlDateTime", {
                                                val: task.endDate,
                                                formatParams: {
                                                    val: {
                                                        weekday: "short",
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                    },
                                                },
                                            })}
                                        </p>
                                    </td>
                                    <td className="p-2 sm:hidden">
                                        <UsersShow users={task.assignees} />
                                    </td>
                                    <td className="p-2 sm:hidden">
                                        <UsersShow
                                            users={task.reviews
                                                .map(
                                                    (review) => review.reviewer,
                                                )
                                                .flat()}
                                        />
                                    </td>
                                    <td className="p-2 flex justify-center items-center">
                                        <TaskStatusBar
                                            taskStatus={task.status}
                                        />
                                    </td>
                                </tr>
                            ))}
                        {tasks.length === 0 && (
                            <tr>
                                <td colSpan={6} className="text-center p-2">
                                    <div className="w-full flex justify-center items-center gap-2 opacity-50">
                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                        <p>{t("no_tasks_found")}</p>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </WithLoader>
        </Scrollable>
    )
}
