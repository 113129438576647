import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Task } from "@/models/Task"
import { useTranslation } from "react-i18next"
import { TaskRow } from "./TaskRow"

interface DetailsTasksWidgetProps {
    tasks: Task[]
}

export const DetailsTasksWidget: React.FC<DetailsTasksWidgetProps> = ({
    tasks,
}) => {
    const { t } = useTranslation()
    const status = useAppSelector((state) => state.projects.status)
    return (
        <div className="p-5">
            <h1 className="text-lg font-bold">{t("my_urgent_tasks")}</h1>
            <WithLoader
                statuses={[status.read]}
                className="w-full h-72"
                title={t("loading_urgent_tasks")}
            >
                {tasks.length > 0 && (
                    <Scrollable height="320px">
                        <table className="w-full mt-5">
                            <thead className="text-left text-lg text-primary-300">
                                <tr>
                                    <th></th>
                                    <th className="px-4">{t("name")}</th>
                                    <th className="text-center sm:hidden">
                                        {t("status")}
                                    </th>
                                    <th className="text-center">
                                        {t("deadline")}
                                    </th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>
                            {tasks.length > 0 && (
                                <tbody>
                                    {tasks.map((task) => (
                                        <TaskRow task={task} key={task.id} />
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </Scrollable>
                )}
            </WithLoader>
        </div>
    )
}
