import { BaseModel, BaseModelInterface } from "./BaseModel"

export interface TagJson extends BaseModelInterface {
    name: string
    icon: string
    color: string
    backgroundColor: string
}

export class Tag extends BaseModel {
    public name: string
    public icon: string
    public color: string
    public backgroundColor: string

    constructor(data?: TagJson) {
        super(data)
        this.name = data?.name ?? ""
        this.icon = data?.icon ?? ""
        this.color = data?.color ?? ""
        this.backgroundColor = data?.backgroundColor ?? ""
    }

    public createPayload(): {
        name: string
        icon: string
        color: string
        backgroundColor: string
    } {
        return {
            name: this.name,
            icon: this.icon,
            color: this.color,
            backgroundColor: this.backgroundColor,
        }
    }

    public toJson(): TagJson {
        return {
            ...super.toJson(),
            name: this.name,
            icon: this.icon,
            color: this.color,
            backgroundColor: this.backgroundColor,
        }
    }
}
