import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { DropDown } from "@/components/Inputs/DropDown"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Form, SubmitType } from "@/features/Form/Form"
import { Article } from "@/models/Article"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FragmentIdMap } from "@thatopen/fragments"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ArticleForm } from "../../Finance/Articles/ArticleForm"
import {
    getProjectArticles,
    selectArticles,
} from "../../Finance/Articles/articlesSlice"
import { IfcHandler } from "../IfcHandler"
import {
    clearErrors,
    selectIfcEntities,
    setIfcEntitiesArticle,
} from "../ifcSlice"

interface SetEntityArticleFormProps {
    ifcHandler: IfcHandler | null
    selection: FragmentIdMap | null
    ifcFileId: string
    closeModal: () => void
}

export const SetEntityArticleForm: FC<SetEntityArticleFormProps> = ({
    ifcHandler,
    selection,
    ifcFileId,
    closeModal,
}) => {
    const { t } = useTranslation()
    const articles = useAppSelector(selectArticles)
    const status = useAppSelector((state) => state.ifcViewer.status)
    const [loading, setLoading] = useState<SliceStatus>(SliceStatus.IDLE)
    const dispatch = useAppDispatch()

    const [showCreateArticleModal, setShowCreateArticleModal] = useState(false)
    const [selectedEntities, setSelectedEntities] = useState<
        {
            name: string
            guid: string
            expressId: number
        }[]
    >([])
    const [selectedArticle, setSelectedArticle] = useState<string>("")
    const ifcEntities = useAppSelector(selectIfcEntities)

    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    useEffect(() => {
        dispatch(getProjectArticles(projectId))
    }, [])

    useEffect(() => {
        if (ifcHandler && selection) {
            setLoading(SliceStatus.LOADING)
            ifcHandler.getSelectionEntities(selection).then((entities) => {
                setSelectedEntities(
                    entities.filter(
                        (entity, index, self) =>
                            entity &&
                            index ===
                                self.findIndex((t) => t.guid === entity.guid),
                    ),
                )
                setLoading(SliceStatus.IDLE)
            })
        }
    }, [selection])

    const setArticle = async () => {
        const selectedEntitiesGuid = selectedEntities.map(
            (entity) => entity.guid,
        )
        const entitiesIds = ifcEntities
            .filter((entity) => selectedEntitiesGuid.includes(entity.globalId))
            .map((entity) => entity.id)
        const { type } = await dispatch(
            setIfcEntitiesArticle({
                projectId,
                articleId: selectedArticle,
                entities: entitiesIds,
            }),
        )
        if (type === setIfcEntitiesArticle.fulfilled.type) {
            closeModal()
        }
    }
    return (
        <WithLoader statuses={[loading]} title={t("loading_entities")}>
            <Form
                statuses={[status.update]}
                loadingTitle={t("setting_article")}
                onSubmit={setArticle}
                clearErrors={clearErrors}
                submitType={SubmitType.Save}
                onCancel={closeModal}
            >
                <div className="flex flex-col gap-4">
                    <h1 className="font-bold text-xl">
                        {t("set_article_notice")}
                    </h1>
                    <div className="flex items-start gap-2">
                        <p className="font-bold text-lg">{t("elements")}: </p>
                        <div className="p-2 rounded-lg border w-full border-primary-100">
                            <Scrollable height="300px">
                                <ul className="w-full">
                                    {selectedEntities.map((entity) => (
                                        <li
                                            key={entity.guid}
                                            className="flex items-center gap-2"
                                        >
                                            {entity.name}
                                            <FontAwesomeIcon
                                                icon="times"
                                                className="cursor-pointer text-red-400 hover:text-red-600 transition"
                                                onClick={() => {
                                                    if (
                                                        ifcHandler &&
                                                        selection
                                                    ) {
                                                        ifcHandler.removeElementFromSelection(
                                                            selection,
                                                            entity.expressId,
                                                        )
                                                    }
                                                }}
                                            ></FontAwesomeIcon>
                                        </li>
                                    ))}
                                </ul>
                            </Scrollable>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 w-full flex-wrap">
                        <p className="font-bold text-lg">{t("article")}: </p>
                        <DropDown
                            name={t("article")}
                            value={selectedArticle}
                            options={articles.map((article: Article) => ({
                                label: article.name,
                                value: article.id,
                            }))}
                            onChange={(e) => {
                                setSelectedArticle(e.target.value)
                            }}
                        />
                        <div className="flex gap-2 items-center text-green-400 hover:text-green-600 transition cursor-pointer">
                            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                            <p onClick={() => setShowCreateArticleModal(true)}>
                                {t("create_new_article")}
                            </p>
                        </div>
                    </div>
                </div>
            </Form>
            <NewModal
                title={t("create_new_article")}
                isShown={showCreateArticleModal}
                closeModal={() => setShowCreateArticleModal(false)}
            >
                <ArticleForm
                    closeModal={() => setShowCreateArticleModal(false)}
                />
            </NewModal>
        </WithLoader>
    )
}
