import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { Task, TaskStatus } from "@/models/Task"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

interface TaskRowProps {
    task: Task
}

export const TaskRow: React.FC<TaskRowProps> = ({ task }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const taskStatusColorMap = {
        [TaskStatus.NOT_STARTED]: {
            normal: "bg-gray-400 text-secondary-100 hover:bg-gray-400",
            progressBarColor: "#D1D5DB",
            progressBarStroke: "#D1D5DB",
        },
        [TaskStatus.IN_PROGRESS]: {
            normal: "bg-secondary-400 !text-primary-300 hover:bg-secondary-400",
            progressBarColor: "#03192F",
            progressBarStroke: "#d4e9fc",
        },
        [TaskStatus.COMPLETED]: {
            normal: "bg-green-400 hover:bg-green-400",
            progressBarColor: "#10B981",
            progressBarStroke: "#10B981",
        },
    }

    const handleTaskClick = () => {
        navigate(`/dashboard/projects/${projectId}/tasks/${task.id}`)
    }
    return (
        <>
            <tr
                className={`h-12 cursor-pointer transition group relative bg-opacity-50 ${
                    taskStatusColorMap[task.status].normal
                }`}
                onClick={handleTaskClick}
            >
                <td
                    className={`absolute top-0 left-0 -z-10 ${
                        taskStatusColorMap[task.status].normal
                    } h-full`}
                    style={{
                        width: `${task.progress}%`,
                        ...(task.progress === 0 && {
                            opacity: `0`,
                        }),
                    }}
                ></td>
                <td className="w-[30%] px-4 sm:w-[60%]">{task.name}</td>
                <td className="w-[20%] sm:hidden">
                    <div className="w-full h-full flex justify-center items-center">
                        <TaskStatusBar taskStatus={task.status} />
                    </div>
                </td>
                <td className="w-[20%] text-center ">
                    {t("intlDateTime", {
                        val: task.endDate,
                        formatParams: {
                            val: {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            },
                        },
                    })}
                </td>
                <td>
                    <p className="font-bold text-2xl opacity-20 text-right text-primary-100">
                        {task.progress + "%"}
                    </p>
                </td>
            </tr>
        </>
    )
}
