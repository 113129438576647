import { useAppDispatch } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import {
    TaskDelayStatus,
    TaskStatus,
    TaskStatusDetail,
    TaskStatusDetailMap,
} from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TaskAdvancedFilters } from "./TaskAdvancedFilters"
import {
    setDelayStatusFilterValues,
    setStatusDetailFilterValues,
    setStatusFilterValues,
} from "./tasksSlice"

interface TaskFiltersProps {}

export const TaskFilters: React.FC<TaskFiltersProps> = () => {
    const [localStatusFilterValues, setLocalStatusFilterValues] = useState<
        TaskStatus[]
    >([])
    const [localStatusDetailFilterValues, setLocalStatusDetailFilterValues] =
        useState<TaskStatusDetail[]>([])
    const [localDelayStatusFilterValues, setLocalDelayStatusFilterValues] =
        useState<TaskDelayStatus[]>([])
    const [showAdvancedFiltersModal, setShowAdvancedFiltersModal] =
        useState<boolean>(false)

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    useEffect(() => {
        dispatch(setStatusFilterValues(localStatusFilterValues))
    }, [localStatusFilterValues])

    useEffect(() => {
        dispatch(setStatusDetailFilterValues(localStatusDetailFilterValues))
    }, [localStatusDetailFilterValues])

    useEffect(() => {
        dispatch(setDelayStatusFilterValues(localDelayStatusFilterValues))
    }, [localDelayStatusFilterValues])
    return (
        <div className="w-[250px]  p-4 flex flex-col gap-4">
            <div>
                <h1 className="font-bold text-lg">{t("filter_by_status")}</h1>
                <div className="flex flex-col gap-4">
                    {Object.values(TaskStatus).map((status, idx) => (
                        <div key={idx}>
                            <div
                                className="flex gap-2 cursor-pointer"
                                onClick={() => {
                                    setLocalStatusFilterValues(
                                        localStatusFilterValues.includes(status)
                                            ? localStatusFilterValues.filter(
                                                  (s) => s !== status,
                                              )
                                            : [
                                                  ...localStatusFilterValues,
                                                  status,
                                              ],
                                    )
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        localStatusFilterValues.includes(status)
                                            ? "check-square"
                                            : "square"
                                    }
                                    className="mt-1"
                                />
                                <p>{t(status)}</p>
                            </div>
                            <div>
                                {Object.values(TaskStatusDetailMap[status]).map(
                                    (statusDetail, idx) => (
                                        <div
                                            key={idx}
                                            className="flex gap-2 cursor-pointer"
                                            onClick={() => {
                                                if (
                                                    !localStatusFilterValues.includes(
                                                        status,
                                                    ) &&
                                                    !localStatusDetailFilterValues.includes(
                                                        statusDetail,
                                                    )
                                                ) {
                                                    setLocalStatusFilterValues([
                                                        ...localStatusFilterValues,
                                                        status,
                                                    ])
                                                }
                                                setLocalStatusDetailFilterValues(
                                                    localStatusDetailFilterValues.includes(
                                                        statusDetail,
                                                    )
                                                        ? localStatusDetailFilterValues.filter(
                                                              (s) =>
                                                                  s !==
                                                                  statusDetail,
                                                          )
                                                        : [
                                                              ...localStatusDetailFilterValues,
                                                              statusDetail,
                                                          ],
                                                )
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    localStatusDetailFilterValues.includes(
                                                        statusDetail,
                                                    )
                                                        ? "check-square"
                                                        : "square"
                                                }
                                                className="mt-1"
                                            />
                                            <p>{t(statusDetail)}</p>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h1 className="font-bold text-lg">{t("filter_by_delay")}</h1>
                <div className="flex flex-col">
                    {Object.values(TaskDelayStatus).map((status, idx) => (
                        <div
                            key={idx}
                            className="flex gap-2 cursor-pointer"
                            onClick={() => {
                                setLocalDelayStatusFilterValues(
                                    localDelayStatusFilterValues.includes(
                                        status,
                                    )
                                        ? localDelayStatusFilterValues.filter(
                                              (s) => s !== status,
                                          )
                                        : [
                                              ...localDelayStatusFilterValues,
                                              status,
                                          ],
                                )
                            }}
                        >
                            <FontAwesomeIcon
                                icon={
                                    localDelayStatusFilterValues.includes(
                                        status,
                                    )
                                        ? "check-square"
                                        : "square"
                                }
                                className="mt-1"
                            />
                            <p>{t(status)}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex items-center justify-center">
                <Button
                    icon={"filter"}
                    name={t("advanced_filters")}
                    onClick={() => setShowAdvancedFiltersModal(true)}
                ></Button>
            </div>
            <NewModal
                title={t("advanced_filters")}
                isShown={showAdvancedFiltersModal}
                closeModal={() => setShowAdvancedFiltersModal(false)}
            >
                <TaskAdvancedFilters
                    setShowAdvancedFiltersModal={setShowAdvancedFiltersModal}
                ></TaskAdvancedFilters>
            </NewModal>
        </div>
    )
}
