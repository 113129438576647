import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { RootState } from "@/app/store"
import { Form, SubmitType } from "@/features/Form/Form"
import { ProjectFile } from "@/models/File"
import { Folder } from "@/models/Folder"
import { ResourcePermissions } from "@/models/Permission"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    clearErrors,
    getResourcePermissions,
    updateFolderPermissions,
} from "../fileManagerSlice"
import { FileManagerPermissionInputs } from "./FileManagerPermissionInputs"

interface FileManagerPermissionFormProps {
    resource: ProjectFile | Folder
    closeModal?: () => void
}

export const FileManagerPermissionForm: React.FC<
    FileManagerPermissionFormProps
> = ({ resource, closeModal }) => {
    const { t } = useTranslation()
    const status = useAppSelector(
        (state: RootState) => state.fileManager.oneStatus,
    )
    const dispatch = useAppDispatch()
    const resourcePermissions = useAppSelector(
        (state: RootState) => state.fileManager.resourcePermission,
    )
    const [isGlobal, setIsGlobal] = useState(resource.isGlobal)
    const [localResourcePermissions, setLocalResourcePermissions] =
        useState<ResourcePermissions>({
            read: {
                groups: [],
                users: [],
            },
            write: {
                groups: [],
                users: [],
            },
            notifications: {
                groups: [],
                users: [],
            },
        })
    const projectId = useParams<{ projectId: string }>().projectId
    useEffect(() => {
        dispatch(
            getResourcePermissions({
                projectId,
                resourceId: resource.id,
                resourceType: resource instanceof Folder ? "folder" : "file",
            }),
        )
    }, [])
    useEffect(() => {
        setLocalResourcePermissions(resourcePermissions)
    }, [resourcePermissions])
    useEffect(() => {
        setIsGlobal(resource.isGlobal)
    }, [resource])
    const updatePermissions = async () => {
        const { type } = await dispatch(
            updateFolderPermissions({
                projectId,
                resourceId: resource.id,
                permissions: localResourcePermissions,
                resourceType: resource instanceof Folder ? "folder" : "file",
                isGlobal,
            }),
        )
        if (type === updateFolderPermissions.fulfilled.type) {
            closeModal?.()
        }
    }
    const cutText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "..."
        }
        return text
    }
    return (
        <Form
            onSubmit={updatePermissions}
            className="flex flex-col w-full"
            statuses={[status.update, status.read]}
            loadingTitle={t("loading_permissions")}
            submitType={SubmitType.Save}
            onCancel={closeModal}
            clearErrors={clearErrors}
        >
            <h1 className="text-xl font-bold mb-4 w-full text-wrap max-w-[400px]">
                {t("editing_permissions", {
                    name: cutText(resource.name, 38),
                })}
            </h1>
            <div className="mb-4">
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        checked={isGlobal}
                        onChange={(e) => setIsGlobal(e.target.checked)}
                        className="mr-2"
                    />
                    {t("set_resource_as_global", {
                        resource:
                            resource instanceof Folder
                                ? t("folder")
                                : t("file"),
                    })}
                </label>
            </div>
            <div className="w-full">
                <FileManagerPermissionInputs
                    value={localResourcePermissions}
                    setValue={setLocalResourcePermissions}
                />
            </div>
        </Form>
    )
}
