import { ProjectFile } from "@/models/File"
import {
    Task,
    TaskDelayStatus,
    TaskReview,
    TaskStatus,
    TaskStatusDetail,
} from "@/models/Task"
import { User } from "@/models/User"
import { isAfter, isBefore, isEqual } from "date-fns"
import { isNull } from "lodash"

export namespace FilterOperators {
    export enum StringOperators {
        Equals = "equals",
        NotEquals = "not_equals",
        Contains = "contains",
        NotContains = "not_contains",
        StartsWith = "starts_with",
        EndsWith = "ends_with",
    }
    export enum NumberOperators {
        NumberEquals = "number_equals",
        NumberNotEquals = "number_not_equals",
        LessThan = "less_than",
        LessThanOrEquals = "less_than_or_equals",
        GreaterThan = "greater_than",
        GreaterThanOrEquals = "greater_than_or_equals",
    }
    export enum DateOperators {
        DatesEquals = "date_equals",
        DatesNotEquals = "date_not_equals",
        Before = "before",
        After = "after",
        Between = "between",
    }
    export enum ArrayOperators {
        ArrayContains = "array_contains",
        ArrayNotContains = "array_not_contains",
    }
}
export type FilterOperatorsType =
    | FilterOperators.StringOperators
    | FilterOperators.NumberOperators
    | FilterOperators.DateOperators
    | FilterOperators.ArrayOperators

export type ValueTypes =
    | string
    | number
    | Date
    | Date[]
    | string[]
    | TaskStatus
    | TaskStatusDetail
    | TaskDelayStatus
    | null

export type FilterValuesType<
    T extends TaskFilterFields | BimFilesFilterFields | CommonFilterFields,
> = Record<
    T,
    { value: ValueTypes; operator: FilterOperatorsType; isShown: boolean }
>

export enum CommonFilterFields {
    Name = "name",
}

export enum TaskFilterFields {
    WBS = "wbs",
    Duration = "duration",
    Progress = "progress",
    Tags = "tags",
    StartDate = "startDate",
    EndDate = "endDate",
    StartDelay = "startDelay",
    EndDelay = "endDelay",
    StatusDetail = "statusDetail",
    DelayStatus = "delayStatus",
    Assignees = "assignees",
    Reviewers = "reviews",
    TaskStatus = "taskStatus",
}

export enum BimFilesFilterFields {
    Task = "task",
    Project = "project",
    Originator = "originator",
    FunctionalBreakdown = "functionalBreakdown",
    SpatialBreakdown = "spatialBreakdown",
    Form = "form",
    Discipline = "discipline",
    Number = "number",
    Phase = "phase",
    Assignee = "assignee",
    Reviewers = "reviewers",
    BimFileStatus = "bimFileStatus",
}

export type FilterFields =
    | TaskFilterFields
    | BimFilesFilterFields
    | CommonFilterFields

const operatorsFunctionMapping = {
    [FilterOperators.StringOperators.Equals]: (
        value: string,
        filterValue: string,
    ) => {
        return value === filterValue
    },
    [FilterOperators.StringOperators.NotEquals]: (
        value: string,
        filterValue: string,
    ) => {
        return value !== filterValue
    },
    [FilterOperators.StringOperators.Contains]: (
        value: string,
        filterValue: string,
    ) => {
        return value.includes(filterValue as string)
    },
    [FilterOperators.StringOperators.NotContains]: (
        value: string,
        filterValue: string,
    ) => {
        return value.includes(filterValue as string)
    },
    [FilterOperators.StringOperators.StartsWith]: (
        value: string,
        filterValue: string,
    ) => {
        return value.startsWith(filterValue as string)
    },
    [FilterOperators.StringOperators.EndsWith]: (
        value: string,
        filterValue: string,
    ) => {
        return value.endsWith(filterValue as string)
    },
    [FilterOperators.NumberOperators.NumberEquals]: (
        value: number,
        filterValue: number,
    ) => {
        return value === filterValue
    },
    [FilterOperators.NumberOperators.NumberNotEquals]: (
        value: number,
        filterValue: number,
    ) => {
        return value !== filterValue
    },
    [FilterOperators.NumberOperators.LessThan]: (
        value: number,
        filterValue: number,
    ) => {
        return value < (filterValue as number)
    },
    [FilterOperators.NumberOperators.LessThanOrEquals]: (
        value: number,
        filterValue: number,
    ) => {
        return value <= (filterValue as number)
    },
    [FilterOperators.NumberOperators.GreaterThan]: (
        value: number,
        filterValue: number,
    ) => {
        return value > (filterValue as number)
    },
    [FilterOperators.NumberOperators.GreaterThanOrEquals]: (
        value: number,
        filterValue: number,
    ) => {
        return value >= (filterValue as number)
    },
    [FilterOperators.DateOperators.DatesEquals]: (
        value: Date,
        filterValue: Date,
    ) => {
        const valueDate = new Date(value)
        const filterValueDate = new Date(filterValue)
        valueDate.setHours(0, 0, 0, 0)
        filterValueDate.setHours(0, 0, 0, 0)
        return isEqual(valueDate, filterValueDate)
    },
    [FilterOperators.DateOperators.DatesNotEquals]: (
        value: Date,
        filterValue: Date,
    ) => {
        const valueDate = new Date(value)
        const filterValueDate = new Date(filterValue)
        valueDate.setHours(0, 0, 0, 0)
        filterValueDate.setHours(0, 0, 0, 0)
        return !isEqual(valueDate, filterValueDate)
    },
    [FilterOperators.DateOperators.Before]: (
        value: Date,
        filterValue: Date,
    ) => {
        return isBefore(value, filterValue)
    },
    [FilterOperators.DateOperators.After]: (value: Date, filterValue: Date) => {
        return isAfter(value, filterValue)
    },
    [FilterOperators.DateOperators.Between]: (
        value: Date,
        filterValue: Date[],
    ) => {
        if (!Array.isArray(filterValue) || filterValue.length !== 2) return true
        const valueDate = new Date(value)
        const filterValueDate = filterValue.map((date) => new Date(date))
        valueDate.setHours(0, 0, 0, 0)
        filterValueDate[0].setHours(0, 0, 0, 0)
        filterValueDate[1].setHours(0, 0, 0, 0)
        return (
            isAfter(valueDate, filterValueDate[0]) &&
            isBefore(valueDate, filterValueDate[1])
        )
    },
    [FilterOperators.ArrayOperators.ArrayContains]: (
        value: any,
        filterValue: string[],
    ) => {
        if (Array.isArray(value)) {
            const ids = value.map((value) => value.id)
            return (filterValue as string[]).some((tag) => ids.includes(tag))
        } else {
            return (
                filterValue.includes(value) ||
                (value.id && filterValue.includes(value.id))
            )
        }
    },
    [FilterOperators.ArrayOperators.ArrayNotContains]: (
        value: any[],
        filterValue: string[],
    ) => {
        if (Array.isArray(value)) {
            const ids = value.map((value) => value.id)
            return !(filterValue as string[]).some((tag) => ids.includes(tag))
        } else {
            return !filterValue.includes(value)
        }
    },
}

export class Filter {
    public field: FilterFields
    private operatorFunctionMapping = operatorsFunctionMapping
    constructor({ field }: { field: FilterFields }) {
        this.field = field
    }

    public applyFilter(
        model: Task | ProjectFile,
        filterValue: ValueTypes,
        operator: FilterOperatorsType,
    ): boolean {
        const field = this.field as FilterFields
        let value = (model as any)[field]
        if (this.field === TaskFilterFields.Reviewers) {
            value = (value as TaskReview[]).map((reviews) => reviews.reviewer)
        }
        if (this.field === TaskFilterFields.Assignees) {
            value = [
                ...new Set([
                    ...(value as User[]),
                    ...((model as Task).groupsAssigned as never as User[]),
                ]),
            ]
        }
        const filterFunction = this.operatorFunctionMapping[operator]
        return filterFunction(value as never, filterValue as never) ?? false
    }
}

export class FilterGroup {
    public filters: Filter[]

    constructor(
        filters:
            | FilterValuesType<FilterFields>
            | FilterValuesType<TaskFilterFields>
            | FilterValuesType<BimFilesFilterFields>,
    ) {
        this.filters = Object.keys(filters).map((field) => {
            return new Filter({
                field: field as FilterFields,
            })
        })
    }
    public applyFilters(
        task: Task | ProjectFile,
        filterValues: FilterValuesType<FilterFields>,
    ): boolean {
        return this.filters.every((filter) => {
            const filterValue = filterValues[filter.field as FilterFields]
            if (
                isNull(filterValue) ||
                (Array.isArray(filterValue.value) &&
                    filterValue.value.length === 0) ||
                isNull(filterValue.value) ||
                !filterValue.value ||
                !filterValue.operator ||
                (Array.isArray(filterValue.value) && !filterValue.value.length)
            )
                return true
            return filter.applyFilter(
                task,
                filterValue.value,
                filterValue.operator,
            )
        })
    }
}
