import IfcViewerToolsIllustration from "@/assets/illustrations/ifc_viewer/ifc_tools_illustration.jpeg"
import IfcViewerIllustration from "@/assets/illustrations/ifc_viewer/ifc_viewer_illustration.jpeg"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpringRef, useTransition } from "@react-spring/web"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface ThreeDVisualizationFeaturesProps {}

export const ThreeDVisualizationFeatures: React.FC<
    ThreeDVisualizationFeaturesProps
> = () => {
    const { t } = useTranslation()
    const details = [
        {
            title: t("realtime_3d_visualization"),
            description: t("realtime_3d_visualization_description"),
            icon: "cubes",
            illustration: (
                <div className="w-full h-full flex items-center justify-center">
                    <div className="w-[90%] h-fit border-4 rounded border-primary-100 border-t-[25px] relative">
                        <div className="absolute -top-[15px] left-4 w-fit h-fit flex gap-2">
                            <span className="rounded-full w-2 h-2 bg-red-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-yellow-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-green-600 z-20"></span>
                        </div>
                        <img
                            src={IfcViewerIllustration}
                            alt="project_management_illustration"
                            className="object-cover w-full"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: t("bim_integration"),
            description: t("bim_integration_description"),
            icon: "building",
            illustration: (
                <div className="w-full h-full flex items-center justify-center">
                    <div className="w-[90%] h-fit border-4 rounded border-primary-100 border-t-[25px] relative">
                        <div className="absolute -top-[15px] left-4 w-fit h-fit flex gap-2">
                            <span className="rounded-full w-2 h-2 bg-red-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-yellow-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-green-600 z-20"></span>
                        </div>
                        <img
                            src={IfcViewerToolsIllustration}
                            alt="project_management_illustration"
                            className="object-cover w-full"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: t("efficient_streaming"),
            description: t("efficient_streaming_description"),
            icon: "cloud-upload-alt",
            illustration: (
                <div className="h-fit flex justify-center">
                    <h1 className="text-4xl font-bold uppercase">
                        {t("coming_soon")}!
                    </h1>
                </div>
            ),
        },
    ]
    const [currentDetail, setCurrentDetail] = useState(0)
    const transRef = useSpringRef()
    const [transitions, api] = useTransition(currentDetail, () => ({
        ref: transRef,
        keys: null,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
        onRest: (_a, _b, item) => {
            if (currentDetail === item) {
                setCurrentDetail((state) => (state + 1) % details.length)
            }
        },
        exitBeforeEnter: true,
    }))
    useEffect(() => {
        transRef.start()
    }, [currentDetail])
    return (
        <section className="w-full h-full flex flex-col px-8 py-4 ">
            <div className="flex gap-2 h-full ">
                <div className="w-full h-full">
                    <div className="flex flex-col h-full gap-4 items-center w-full">
                        {details.map((detail, index) => (
                            <div
                                key={index}
                                className={`flex lg:p-2 lg:flex-col gap-4 items-center p-4 rounded-lg cursor-pointer transition hover:bg-primary-100 w-full flex-1 ${
                                    currentDetail === index
                                        ? "bg-primary-100"
                                        : ""
                                }`}
                                onClick={() => setCurrentDetail(index)}
                            >
                                <FontAwesomeIcon
                                    icon={detail.icon as IconProp}
                                    className="text-3xl text-secondary-100"
                                />
                                <div className="lg:text-center">
                                    <h2 className="text-xl lg:text-lg font-bold">
                                        {detail.title}
                                    </h2>
                                    <p className="text-lg lg:text-base font-light">
                                        {detail.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full h-full lg:hidden">
                    {transitions((style, item) => (
                        <animated.div
                            className="w-full h-full flex items-center justify-center"
                            style={{ ...style }}
                        >
                            {details[item].illustration}
                        </animated.div>
                    ))}
                </div>
            </div>
        </section>
    )
}
