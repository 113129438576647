import { useAppDispatch } from "@/app/hooks"
import { useEffect } from "react"
import { getLatestUsers } from "../adminSlice"
import { LatestUsers } from "./LatestUsers"

interface AdminUsersPageProps {}

export const AdminUsersPage: React.FC<AdminUsersPageProps> = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getLatestUsers({}))
    }, [dispatch])
    return (
        <div className="w-full h-full bg-secondary-100 rounded-lg shadow-lg p-4 overflow-hidden flex flex-col">
            <LatestUsers />
        </div>
    )
}
