import { Button } from "@/components/Inputs/Button"
import { useTranslation } from "react-i18next"

interface PricingProps {}

export const Pricing: React.FC<PricingProps> = () => {
    const { t } = useTranslation()
    return (
        <section className="w-full flex flex-col items-center min-h-screen px-32 py-8 bg-secondary-300 text-primary-100 lg:p-4 ">
            <h1 className="text-5xl font-black mb-4">{t("pricing")}</h1>
            <p className="text-xl text-center w-3/4 lg:w-full">
                {t("pricing_description")}
            </p>
            <div className="bg-highlight-100 w-3/4 lg:w-full mt-8 p-5 rounded-lg text-primary-300 font-bold flex items-center justify-center">
                <p className="text-center">
                    {t("pricing_beta_access_notice")}.
                </p>
            </div>
            <div className="flex w-full h-full gap-4 p-8 lg:p-0 lg:mt-4 lg:flex-col text-primary-300">
                <div className="w-full h-full bg-secondary-100 shadow-lg p-4 rounded-lg flex flex-col items-center justify-between">
                    <h2 className="font-bold text-2xl">
                        {t("free_beta_access")}
                    </h2>
                    <p className="p-4 text-center font-light text-lg lg:p-2">
                        {t("free_beta_access_description")}
                    </p>
                    <div className="flex flex-col gap-1">
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("duration")}:
                            </span>
                            {t("duration_beta_access")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("storage")}:
                            </span>
                            {t("storage_beta_access")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("projects")}:
                            </span>
                            {t("projects_beta_access")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("users")}:
                            </span>
                            {t("users_beta_access")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("support")}:
                            </span>
                            {t("support_beta_access")}.
                        </p>
                        <div className="flex gap-2 items-start">
                            <p className="text-lg font-light">
                                <span className="font-bold">
                                    {t("features")}:
                                </span>
                            </p>
                            <div>
                                <p className="font-light text-lg">
                                    - {t("features_beta_access_1")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_beta_access_2")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_beta_access_3")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_beta_access_4")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_beta_access_5")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2 ml-auto lg:ml-0 lg:mt-4">
                        <Button
                            className="!bg-green-400 hover:!bg-green-600"
                            name={t("join_beta_access")}
                        />
                    </div>
                </div>
                <div className="w-full h-full bg-secondary-100 shadow-lg p-4 rounded-lg flex flex-col items-center justify-between">
                    <h2 className="font-bold text-2xl">
                        {t("tailored_packages")}
                    </h2>
                    <p className="p-4 text-center font-light text-lg">
                        {t("tailored_packages_description")}
                    </p>
                    <div className="flex flex-col gap-1">
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("storage")}:
                            </span>
                            {t("storage_tailored_package")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("projects")}:
                            </span>
                            {t("projects_tailored_package")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("users")}:
                            </span>
                            {t("users_tailored_package")}.
                        </p>
                        <p className="text-lg font-light">
                            <span className="font-bold mr-2">
                                {t("support")}:
                            </span>
                            {t("support_tailored_package")}.
                        </p>
                        <div className="flex gap-2 items-start">
                            <p className="text-lg font-light">
                                <span className="font-bold">
                                    {t("features")}:
                                </span>
                            </p>
                            <div>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_1")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_2")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_3")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_4")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_5")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_6")}
                                </p>
                                <p className="font-light text-lg">
                                    - {t("features_tailored_package_7")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2 ml-auto lg:ml-0 lg:mt-4">
                        <Button
                            name={t("request_tailored_package")}
                            className="!bg-green-400 hover:!bg-green-600"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
