interface ScrollableProps {
    children: React.ReactNode
    height?: string
    width?: string
    className?: string
    yScroll?: boolean
    xScroll?: boolean
    innerRef?: React.RefObject<HTMLDivElement>
}

export const Scrollable: React.FC<ScrollableProps> = ({
    children,
    height = "100%",
    width = "100%",
    className,
    yScroll = true,
    xScroll = false,
    innerRef = null,
}) => {
    return (
        <div
            className={`w-full ${className ?? ""}`}
            style={{
                maxHeight: height,
                maxWidth: width,
                overflowY: yScroll ? "auto" : "hidden",
                overflowX: xScroll ? "auto" : "hidden",
            }}
            ref={innerRef}
        >
            {children}
        </div>
    )
}
