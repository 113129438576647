import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { CalendarInput } from "@/components/Inputs/CalendarInput"
import {
    HierarchyDropDown,
    HierarchyOptionProps,
} from "@/components/Inputs/HierarchyDropDown"
import { Input } from "@/components/Inputs/Input"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Form, SubmitType } from "@/features/Form/Form"
import { Task } from "@/models/Task"
import Slider from "rc-slider"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useOutletContext, useParams } from "react-router-dom"
import {
    getPlanningTaskTree,
    updateTaskById,
} from "../../Plannings/planningSlice"
import {
    getProjectTasksTreesById,
    selectProjectTasksTree,
} from "../../projectsSlice"
import { clearErrors, createTask, getTaskById, selectTask } from "../tasksSlice"

interface TaskGeneralFormProps {}

export const TaskGeneralForm: React.FC<TaskGeneralFormProps> = ({}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const taskId = useParams<{ taskId: string }>().taskId ?? ""
    const projectId = useParams<{ projectId: string }>().projectId
    const planningId = useParams<{ planningId: string }>().planningId

    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [localTask, setLocalTask] = useState<Task>(new Task())

    const [selectedParentTask, setSelectedParentTask] = useState<{
        value: string
        label: string
    }>({
        value: "",
        label: "",
    })
    const task = useAppSelector(selectTask)
    const projectTasks = useAppSelector(selectProjectTasksTree)
    const status = useAppSelector((state) => state.tasks.status)
    const errors = useAppSelector((state) => state.tasks.errors)
    const context = useOutletContext<{
        setShowModal: Dispatch<SetStateAction<boolean>>
    }>()
    const [tasksHierarchyOptions, setTasksHierarchyOptions] = useState<
        HierarchyOptionProps[]
    >([])
    useEffect(() => {
        dispatch(getProjectTasksTreesById(projectId))
    }, [])

    useEffect(() => {
        setTasksHierarchyOptions(
            projectTasks.map((task) => ({
                value: task.id,
                label: task.name,
                expanded: false,
                item: task,
            })),
        )
    }, [projectTasks])

    useEffect(() => {
        if (taskId) {
            setIsEditMode(true)
        }
    }, [taskId])
    useEffect(() => {
        if (isEditMode) {
            dispatch(getTaskById({ projectId, taskId }))
        }
    }, [isEditMode])
    useEffect(() => {
        if (isEditMode && task) {
            setLocalTask(new Task(task.toJson()))
            if (task.parent) {
                setSelectedParentTask({
                    value: task.parent.id,
                    label: `${task.parent.wbs} - ${task.parent.name}`,
                })
            }
        }
    }, [task])

    const handleSubmit = () => {
        const parentId =
            selectedParentTask.value !== "-1"
                ? selectedParentTask.value
                : undefined
        const payload = localTask.createUpdatePayload(parentId)
        if (isEditMode) {
            dispatch(
                updateTaskById({ projectId, taskId, updatedPayload: payload }),
            ).then((res) => {
                if (planningId) {
                    dispatch(getPlanningTaskTree({ planningId, projectId }))
                }
                if (context && context.setShowModal) {
                    context.setShowModal(false)
                }
            })
        } else {
            dispatch(
                createTask({
                    projectId,
                    planningId,
                    payload,
                }),
            ).then((res) => {
                if (planningId) {
                    dispatch(getPlanningTaskTree({ planningId, projectId }))
                }
                if (context && context.setShowModal) {
                    context.setShowModal(false)
                }
            })
        }
    }
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLocalTask(
            new Task({
                ...localTask.toJson(),
                [e.target.name]: e.target.value,
            }),
        )
    }

    return (
        <Form
            onSubmit={handleSubmit}
            statuses={
                isEditMode
                    ? [status.update, status.read]
                    : [status.create, status.read]
            }
            loadingTitle={
                status.read === SliceStatus.LOADING
                    ? t("loading_task")
                    : isEditMode
                      ? t("updating_task")
                      : t("creating_task")
            }
            submitType={isEditMode ? SubmitType.Save : SubmitType.Create}
            clearErrors={clearErrors}
            className="overflow-hidden p-5"
        >
            <Scrollable height="100%">
                <Input
                    label={t("name")}
                    name="name"
                    type="text"
                    value={localTask.name}
                    onChange={handleChange}
                    placeholder={t("task_name") + "..."}
                    errors={errors.name}
                />
                <div className="mt-4">
                    <TextAreaInput
                        label={t("description")}
                        name="description"
                        value={localTask.description}
                        onChange={handleChange}
                        className="w-full"
                    />
                </div>
                {(!isEditMode || task.children.length === 0) && (
                    <div className="my-4 flex flex-col items-center">
                        <div className="flex justify-center items-center gap-2">
                            <p className="font-bold">{t("progress")}</p>
                            <p>{localTask.progress}%</p>
                        </div>
                        <div className="w-[90%]">
                            <Slider
                                value={localTask.progress}
                                onChange={(value) => {
                                    setLocalTask(
                                        new Task({
                                            ...localTask.toJson(),
                                            progress: value as number,
                                        }),
                                    )
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="mt-4">
                    <p className="font-bold">{t("parent_task")}:</p>
                    <HierarchyDropDown
                        value={selectedParentTask}
                        placeholder={t("select_parent_task")}
                        onChange={
                            setSelectedParentTask as Dispatch<
                                SetStateAction<{
                                    value: string | number
                                    label: string
                                }>
                            >
                        }
                        options={tasksHierarchyOptions}
                        name={t("parent_task")}
                    ></HierarchyDropDown>
                </div>
                <div className="flex justify-between mt-4 border-y  py-4 flex-wrap">
                    <h1 className="w-full font-bold text-lg mb-4">
                        {t("dates")}:
                    </h1>
                    <div className="w-2/5">
                        <CalendarInput
                            label={t("start_date")}
                            name="startDate"
                            value={localTask.startDateInput}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="w-2/5">
                        <CalendarInput
                            label={t("end_date")}
                            name="endDate"
                            value={localTask.endDateInput}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </Scrollable>
        </Form>
    )
}
