import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { ProjectCard } from "@/components/Cards/ProjectCard"
import { NoData } from "@/components/Errors/NoData"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { ProjectForm } from "./Forms/ProjectForm"
import {
    getUserProjects,
    getUserSharedProjects,
    selectProjects,
    selectSharedProjects,
} from "./projectsSlice"

export const Projects = () => {
    const dispatch = useAppDispatch()
    const projects = useAppSelector(selectProjects)
    const status = useAppSelector((state) => state.projects.status)
    const sharedProjects = useAppSelector(selectSharedProjects)
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false)
    const { t } = useTranslation()
    const [myPageSize, setMyPageSize] = React.useState<number>(10)
    const [myPageNumber, setMyPageNumber] = React.useState<number>(1)
    const [sharedPageSize, setSharedPageSize] = React.useState<number>(10)
    const [sharedPageNumber, setSharedPageNumber] = React.useState<number>(1)
    const projectsTotalCount = useAppSelector(
        (state) => state.projects.projectsTotalCount,
    )
    const sharedProjectsTotalCount = useAppSelector(
        (state) => state.projects.sharedProjectsTotalCount,
    )
    useEffect(() => {
        dispatch(
            getUserProjects({ pageSize: myPageSize, pageNumber: myPageNumber }),
        )
    }, [myPageNumber, myPageSize])
    useEffect(() => {
        dispatch(
            getUserSharedProjects({
                pageSize: sharedPageSize,
                pageNumber: sharedPageNumber,
            }),
        )
    }, [sharedPageNumber, sharedPageSize])
    return (
        <div className="max-h-full h-full">
            <div className="shadow-lg rounded-xl h-full flex flex-col">
                <div className="flex justify-between items-center bg-primary-300 rounded-lg text-secondary-100 p-5">
                    <h1 className="text-4xl font-bold">{t("my_projects")}: </h1>
                </div>
                <div className="p-5 flex flex-col overflow-hidden">
                    <Scrollable className="overflow-x-hidden" height="100%">
                        <div>
                            <div className="w-full mb-5 rounded-lg shadow-lg">
                                <div className="flex bg-secondary-100 rounded-lg justify-between items-center h-full p-5 sm:gap-4 sm:flex-col-reverse">
                                    <div className="flex justify-center items-center gap-4 ml-auto sm:m-auto">
                                        <Button
                                            name={t("create_project")}
                                            icon="plus"
                                            onClick={() =>
                                                setShowCreateModal(true)
                                            }
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                            <h2 className="text-2xl font-bold border-b w-fit border-b-primary-300">
                                {t("my_projects")}:
                            </h2>
                            <WithLoader
                                statuses={[status.read]}
                                title={t("loading_projects")}
                            >
                                <div className="w-full">
                                    <>
                                        {projects.length > 0 ? (
                                            <div className={`flex flex-wrap`}>
                                                {projects.map((project) => (
                                                    <ProjectCard
                                                        key={project.id}
                                                        project={project}
                                                    ></ProjectCard>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="flex flex-col justify-center items-center p-5 w-full">
                                                <NoData
                                                    message={t(
                                                        "you_dont_have_any_projects_yet",
                                                    )}
                                                    className="w-56"
                                                />
                                            </div>
                                        )}
                                        {projects.length <
                                            projectsTotalCount && (
                                            <div className="w-full flex justify-center items-center">
                                                <Button
                                                    name={t("load_more")}
                                                    onClick={() => {
                                                        setMyPageNumber(
                                                            myPageNumber + 1,
                                                        )
                                                        setMyPageSize(
                                                            myPageSize + 10,
                                                        )
                                                    }}
                                                ></Button>
                                            </div>
                                        )}
                                    </>
                                </div>
                            </WithLoader>
                            <h2 className="text-2xl font-bold border-b w-fit border-b-primary-300 my-4">
                                {t("shared_projects")}:
                            </h2>
                            <WithLoader
                                statuses={[status.read]}
                                title={t("loading_projects")}
                                className={"w-full"}
                            >
                                <div className="w-full">
                                    <>
                                        {sharedProjects.length > 0 ? (
                                            <div className={`flex flex-wrap`}>
                                                {sharedProjects.map(
                                                    (project) => (
                                                        <ProjectCard
                                                            key={project.id}
                                                            project={project}
                                                        ></ProjectCard>
                                                    ),
                                                )}
                                            </div>
                                        ) : (
                                            <div className="flex flex-col justify-center items-center p-5 w-full">
                                                <NoData
                                                    message={t(
                                                        "you_dont_have_any_projects_yet",
                                                    )}
                                                    className="w-56"
                                                />
                                            </div>
                                        )}
                                        {sharedProjects.length <
                                            sharedProjectsTotalCount && (
                                            <div className="w-full flex justify-center items-center">
                                                <Button
                                                    name={t("load_more")}
                                                    onClick={() => {
                                                        setSharedPageNumber(
                                                            sharedPageNumber +
                                                                1,
                                                        )
                                                        setSharedPageSize(
                                                            sharedPageSize + 10,
                                                        )
                                                    }}
                                                ></Button>
                                            </div>
                                        )}
                                    </>
                                </div>
                            </WithLoader>
                        </div>
                    </Scrollable>
                </div>
            </div>
            <NewModal
                title={t("create_project")}
                isShown={showCreateModal}
                closeModal={() => setShowCreateModal(false)}
            >
                <ProjectForm
                    closeModal={() => setShowCreateModal(false)}
                ></ProjectForm>
            </NewModal>
            <Outlet></Outlet>
        </div>
    )
}
