import { BaseModel, BaseModelInterface } from "./BaseModel"
import { Task, TaskJsonInterface } from "./Task"
import { User, UserInterface } from "./User"

export interface PlanningJsonInterface extends BaseModelInterface {
    name: string
    description: string
    isMain: boolean
    createdBy: UserInterface
    mainTask: TaskJsonInterface
}

export interface PlanningCreateInterface {
    name: string
    description: string
    file: File | null
}

export class Planning extends BaseModel {
    public name: string
    public description: string
    public isMain: boolean
    public createdBy: User
    public mainTask: Task

    constructor(public data?: PlanningJsonInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        this.isMain = data?.isMain ?? false
        this.createdBy = new User(data?.createdBy) ?? new User()
        this.mainTask = new Task(data?.mainTask) ?? new Task()
    }

    public toJson(): PlanningJsonInterface {
        return {
            ...super.toJson(),
            name: this.name,
            description: this.description,
            isMain: this.isMain,
            createdBy: this.createdBy.toJson(),
            mainTask: this.mainTask.toJson(),
        }
    }

    public createPayloadWithCsv(file: File | null): FormData {
        const payload = new FormData()
        payload.append("name", this.name)
        payload.append("description", this.description)
        if (file) {
            payload.append("file", file)
        }
        return payload
    }

    public updatePayload() {
        return {
            name: this.name,
            description: this.description,
        }
    }
}
