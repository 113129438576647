import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { UsersShow } from "@/components/Other/UsersShow"
import {
    BIMFileStatus,
    ProjectFile,
    taskStatusBimFileMapping,
} from "@/models/File"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { getProjectBimFiles, selectFiles } from "../fileManagerSlice"

interface BrowserBimFileTableProps {}

const fileStatusColorMap = {
    [BIMFileStatus.PENDING]: "text-primary-100",
    [BIMFileStatus.APPROVED]: "text-green-600",
    [BIMFileStatus.APPROVED_WITH_ISSUES]: "text-orange-600",
    [BIMFileStatus.REJECTED]: "text-red-600",
}

export const BrowserBimFileTable: React.FC<BrowserBimFileTableProps> = () => {
    const { t } = useTranslation()
    const files = useAppSelector(selectFiles)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const dispatch = useAppDispatch()
    const [localFiles, setLocalFiles] = useState<ProjectFile[]>([])
    const [showAll, setShowAll] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getProjectBimFiles(projectId))
    }, [])
    useEffect(() => {
        if (showAll) {
            setLocalFiles(files)
        } else {
            setLocalFiles(files.filter((file) => file.hasBimNomination))
        }
    }, [files, showAll])
    const columns = useMemo<MRT_ColumnDef<ProjectFile>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("name"),
            },
            {
                id: "task",
                header: t("task"),
                Cell: ({ row }) => {
                    const file = row.original
                    return (
                        <Link
                            to={`/dashboard/projects/${projectId}/tasks/${file.task.id}`}
                        >
                            {file.task.name}
                        </Link>
                    )
                },
            },
            {
                accessorKey: "originator",
                header: t("originator"),
            },
            {
                accessorKey: "functionalBreakdown",
                header: t("functional_breakdown"),
            },
            {
                accessorKey: "spatialBreakdown",
                header: t("spatial_breakdown"),
            },
            {
                accessorKey: "form",
                header: t("form"),
            },
            {
                accessorKey: "discipline",
                header: t("discipline"),
            },
            {
                accessorKey: "number",
                header: t("number"),
            },
            {
                accessorKey: "phase",
                header: t("phase"),
            },
            {
                accessorKey: "assignee",
                header: t("assignee"),
                Cell: ({ row }) => {
                    const file = row.original
                    return <UsersShow users={[file.uploadedBy]} />
                },
            },
            {
                accessorKey: "reviewers",
                header: t("reviewers"),
                Cell: ({ row }) => {
                    const file = row.original
                    return (
                        <UsersShow
                            users={file.task.reviews
                                .map((review) => review.reviewer)
                                .flat()}
                        />
                    )
                },
            },
            {
                accessorKey: "bimFileStatus",
                header: t("bim_file_status"),
                Cell: ({ row }) => {
                    const file = row.original
                    return (
                        <span
                            className={`${
                                fileStatusColorMap[
                                    taskStatusBimFileMapping[
                                        file.task.statusDetail
                                    ]
                                ]
                            }`}
                        >
                            {t(file.bimFileStatus)}
                        </span>
                    )
                },
            },
        ],
        [],
    )
    const table = useMantineReactTable<ProjectFile>({
        columns,
        data: localFiles,
        positionPagination: "top",
    })

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">{t("files")}</h1>
                <Button
                    name={
                        showAll ? t("show_only_bim_nominated") : t("show_all")
                    }
                    onClick={() => setShowAll(!showAll)}
                />
            </div>
            <MantineReactTable table={table} />
        </div>
    )
}
