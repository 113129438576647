import { useAppSelector } from "@/app/hooks"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Task } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"
import { DetailsTimeline } from "./DetailsTimeline"
import { DetailsTimelineCalendar } from "./DetailsTimelineCalendar"

interface DetailsTasksTimelineProps {
    tasks: Task[]
}

export const DetailsTimelineWidget: React.FC<DetailsTasksTimelineProps> = ({
    tasks,
}) => {
    const { t } = useTranslation()
    const [timelineLength, setTimelineLength] = React.useState<number>(60)
    const [timelineMode, setTimelineMode] = React.useState<boolean>(true)
    const status = useAppSelector((state) => state.projects.status)
    const currentMonth = new Date().getMonth()
    return (
        <div className="p-5 min-h-full flex flex-col gap-2 w-full">
            <h1 className="text-lg font-bold">{t("my_timeline")}</h1>
            <WithLoader
                statuses={[status.read]}
                title={t("loading_tasks")}
                className="w-full"
            >
                <div className="text-lg flex gap-2 justify-end items-center w-full">
                    <FontAwesomeIcon
                        icon="calendar"
                        className={`${
                            timelineMode
                                ? "text-primary-100 hover:text-primary-300"
                                : "text-secondary-100 bg-primary-300 p-2 rounded-full w-4 h-4"
                        } cursor-pointer transition`}
                        onClick={() => setTimelineMode(false)}
                    />
                    <FontAwesomeIcon
                        icon="timeline"
                        className={`${
                            timelineMode
                                ? "text-secondary-100 bg-primary-300 p-2 rounded-full w-4 h-4"
                                : "text-primary-100 hover:text-primary-300 "
                        } cursor-pointer transition`}
                        onClick={() => setTimelineMode(true)}
                    />
                </div>
                <div className="flex flex-col w-full justify-center flex-grow">
                    {timelineMode ? (
                        <DetailsTimeline
                            tasks={tasks}
                            timelineLength={timelineLength}
                        />
                    ) : (
                        <DetailsTimelineCalendar
                            tasks={tasks}
                            month={currentMonth}
                        />
                    )}
                </div>
            </WithLoader>
        </div>
    )
}
