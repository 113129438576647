import { NotificationFileRenamed } from "@/components/Notifications/NotificationFileRenamed"
import { NotificationTaskDelayed } from "@/components/Notifications/NotificationTaskDelayed"
import React from "react"
import { NotificationFileDeleted } from "../components/Notifications/NotificationFileDeleted"
import { NotificationFileUploaded } from "../components/Notifications/NotificationFileUploaded"
import { NotificationFolderCreated } from "../components/Notifications/NotificationFolderCreated"
import { NotificationFolderDeleted } from "../components/Notifications/NotificationFolderDeleted"
import { NotificationFolderRenamed } from "../components/Notifications/NotificationFolderRenamed"
import { NotificationTaskAddedComment } from "../components/Notifications/NotificationTaskAddComment"
import { NotificationTaskAskedForReview } from "../components/Notifications/NotificationTaskAskedForReview"
import { NotificationTaskAssigned } from "../components/Notifications/NotificationTaskAssigned"
import { NotificationTaskAttachmentAdded } from "../components/Notifications/NotificationTaskAttachmentAdded"
import { NotificationTaskCompleted } from "../components/Notifications/NotificationTaskCompleted"
import { NotificationTaskDeleted } from "../components/Notifications/NotificationTaskDeleted"
import { NotificationTaskDependencySet } from "../components/Notifications/NotificationTaskDependencySet"
import { NotificationTaskHasIssue } from "../components/Notifications/NotificationTaskHasIssue"
import { NotificationTaskInProgress } from "../components/Notifications/NotificationTaskInProgress"
import { NotificationTaskPendingReview } from "../components/Notifications/NotificationTaskPendingReview"
import { NotificationTaskRepliedComment } from "../components/Notifications/NotificationTaskRepliedComment"
import { NotificationTaskReviewApproved } from "../components/Notifications/NotificationTaskReviewApproved"
import { NotificationTaskReviewRejected } from "../components/Notifications/NotificationTaskReviewRejected"
import { NotificationTaskReviewerDesignated } from "../components/Notifications/NotificationTaskReviewerDesignated"
import { NotificationTaskReviewerRemoved } from "../components/Notifications/NotificationTaskReviewerRemoved"
import { NotificationTaskUnassigned } from "../components/Notifications/NotificationTaskUnassigned"
import { NotificationTaskUpdated } from "../components/Notifications/NotificationTaskUpdated"
import {
    NotificationTypeAdditionalDataMap,
    UserNotificationType,
} from "../types/UserNotificationsTypes"
import { BaseModel, BaseModelInterface } from "./BaseModel"
import { User, UserInterface } from "./User"

export enum NotificationType {
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    INVALID = "invalid",
}
export enum NotificationLevel {
    DEBUG = "debug",
    ADMIN = "admin",
    USER = "user",
}

export interface NotificationInterface {
    id: string
    title: string
    body: string
    type: NotificationType
    level: NotificationLevel
    errors?: { [key: string]: string[] }
}

export class Notification {
    public id: string
    public title: string
    public body: string
    public type: NotificationType
    public level: NotificationLevel
    public errors?: { [key: string]: string[] }

    constructor(data: NotificationInterface) {
        this.id = data?.id ?? 0
        this.title = data?.title ?? ""
        this.body = data?.body ?? ""
        this.type = data?.type ?? NotificationType.INVALID
        this.level = data?.level ?? NotificationLevel.ADMIN
        this.errors = data?.errors ?? {}
    }

    toJson(): NotificationInterface {
        return {
            id: this.id,
            title: this.title,
            body: this.body,
            type: this.type,
            level: this.level,
            errors: this.errors,
        }
    }
}

export interface UserNotificationInterface extends BaseModelInterface {
    type: UserNotificationType
    seen: boolean
    user: UserInterface
    seenOn: Date | string
    additionalData: NotificationTypeAdditionalDataMap[UserNotificationType] | {}
}

const UserNotificationTypeComponentMap: {
    [key in UserNotificationType]: React.FC<any>
} = {
    [UserNotificationType.TASK_ASSIGNED]: NotificationTaskAssigned,
    [UserNotificationType.TASK_REVIEWER_ASSIGNED]:
        NotificationTaskReviewerDesignated,
    [UserNotificationType.TASK_UNASSIGNED]: NotificationTaskUnassigned,
    [UserNotificationType.TASK_REVIEWER_REMOVED]:
        NotificationTaskReviewerRemoved,
    [UserNotificationType.FOLDER_CREATED]: NotificationFolderCreated,
    [UserNotificationType.FILE_UPLOADED]: NotificationFileUploaded,
    [UserNotificationType.FILE_DELETED]: NotificationFileDeleted,
    [UserNotificationType.FILE_RENAMED]: NotificationFileRenamed,

    [UserNotificationType.FOLDER_DELETED]: NotificationFolderDeleted,
    [UserNotificationType.FOLDER_RENAMED]: NotificationFolderRenamed,
    [UserNotificationType.TASK_DELETED]: NotificationTaskDeleted,
    [UserNotificationType.TASK_UPDATED]: NotificationTaskUpdated,

    [UserNotificationType.TASK_ASK_REVIEW]: NotificationTaskAskedForReview, // FIXME: implement this

    [UserNotificationType.TASK_COMPLETED]: NotificationTaskCompleted, // FIXME: implement this
    [UserNotificationType.TASK_HAS_ISSUE]: NotificationTaskHasIssue, // FIXME: implement this
    [UserNotificationType.TASK_PENDING_REVIEW]: NotificationTaskPendingReview, // FIXME: implement this
    [UserNotificationType.TASK_IN_PROGRESS]: NotificationTaskInProgress, // FIXME: implement this

    [UserNotificationType.TASK_REVIEW_APPROVED]: NotificationTaskReviewApproved, // FIXME: implement this
    [UserNotificationType.TASK_REVIEW_REJECTED]: NotificationTaskReviewRejected, // FIXME: implement this

    [UserNotificationType.TASK_ADD_COMMENT]: NotificationTaskAddedComment, // FIXME: implement this
    [UserNotificationType.TASK_REPLY_COMMENT]: NotificationTaskRepliedComment, // FIXME: implement this

    [UserNotificationType.TASK_SET_DEPENDENCY]: NotificationTaskDependencySet, // FIXME: implement this
    [UserNotificationType.TASK_ADD_ATTACHMENT]: NotificationTaskAttachmentAdded, // FIXME: implement this
    [UserNotificationType.TASK_DELAYED_MANAGER]: NotificationTaskDelayed, // FIXME: implement this
    [UserNotificationType.TASK_DELAYED_START]: NotificationTaskDelayed, // FIXME: implement this
    [UserNotificationType.TASK_DELAYED_END]: NotificationTaskDelayed, // FIXME: implement this
}

export class UserNotification extends BaseModel {
    public type: UserNotificationType
    public seen: boolean
    public user: User
    public seenOn: Date
    public additionalData:
        | NotificationTypeAdditionalDataMap[UserNotificationType]
        | {}

    constructor(data?: UserNotificationInterface) {
        super(data)
        this.type = data?.type ?? UserNotificationType.TASK_ASSIGNED
        this.seen = data?.seen ?? false
        this.user = data?.user ? new User(data.user) : new User()
        this.seenOn = data?.seenOn ? new Date(data.seenOn) : new Date()
        this.additionalData = data?.additionalData ?? {}
    }

    getBody(seen: boolean, longFormat: boolean = false): React.ReactElement {
        const Component = UserNotificationTypeComponentMap[this.type]
        return (
            <Component
                notification={this}
                additionalData={this.additionalData as any}
                seen={seen}
                longFormat={longFormat}
            />
        )
    }

    toJson(): UserNotificationInterface {
        return {
            ...super.toJson(),
            type: this.type,
            seen: this.seen,
            user: this.user.toJson(),
            seenOn: this.seenOn,
            additionalData: this.additionalData,
        }
    }
}
