import { ResourcesIds } from "@/app/common"
import {
    differenceInDays,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
} from "date-fns"
import { TaskActivity, TaskActivityInterface } from "./Activity"
import { Article, ArticleInterface } from "./Article"
import { BaseModel, BaseModelInterface } from "./BaseModel"
import { FileJsonInterface, ProjectFile } from "./File"
import { PermissionGroup, PermissionGroupJsonInterface } from "./Permission"
import { Tag, TagJson } from "./Tag"
import { User, UserInterface } from "./User"

export enum TaskStatus {
    NOT_STARTED = "not_started",
    IN_PROGRESS = "in_progress",
    COMPLETED = "completed",
}

export namespace TaskStatusDetailNameSpace {
    // Not started
    export enum NOT_STARTED {
        AWAITING_START = "awaiting_start",
        DEPENDENCY_BLOCKED = "dependency_blocked",
    }

    export enum IN_PROGRESS {
        HAS_ISSUES = "has_issues",
        PENDING_REVIEW = "pending_review",
        APPROVED = "approved",
    }

    // Completed
    export enum COMPLETED {
        SUCCESSFULLY_COMPLETED = "successfully_completed",
        WITH_ISSUES = "with_issues",
    }
}

export const TaskStatusDetailMap = {
    [TaskStatus.NOT_STARTED]: [
        TaskStatusDetailNameSpace.NOT_STARTED.AWAITING_START,
        TaskStatusDetailNameSpace.NOT_STARTED.DEPENDENCY_BLOCKED,
    ],
    [TaskStatus.IN_PROGRESS]: [
        TaskStatusDetailNameSpace.IN_PROGRESS.HAS_ISSUES,
        TaskStatusDetailNameSpace.IN_PROGRESS.PENDING_REVIEW,
    ],
    [TaskStatus.COMPLETED]: [
        TaskStatusDetailNameSpace.COMPLETED.SUCCESSFULLY_COMPLETED,
        TaskStatusDetailNameSpace.COMPLETED.WITH_ISSUES,
    ],
}

export type TaskStatusDetail =
    | TaskStatusDetailNameSpace.NOT_STARTED
    | TaskStatusDetailNameSpace.IN_PROGRESS
    | TaskStatusDetailNameSpace.COMPLETED

export enum TaskDelayStatus {
    ON_TIME = "on_time",
    DELAYED_START = "delayed_start",
    DELAYED_END = "delayed_end",
    DELAYED_BOTH = "delayed_both",
}

export enum TaskReviewStatus {
    PENDING = "pending",
    REJECTED = "rejected",
    APPROVED = "approved",
}

export interface TaskFilters {
    status?: TaskStatus[]
    assignees?: ResourcesIds
    reviewers?: ResourcesIds
    createdBy?: ResourcesIds
    tags?: Tag[]
    dateInterval?: {
        startDate?: string
        endDate?: string
    }
}

export interface TaskJsonInterface extends BaseModelInterface {
    wbs: string
    name: string
    description: string
    duration: number
    progress: number
    hasIssues: boolean
    startDate: Date | string
    endDate: Date | string
    actualStartDate: Date | string
    actualEndDate: Date | string
    status: TaskStatus
    statusDetail: TaskStatusDetail
    delayStatus: TaskDelayStatus
    parent: TaskJsonInterface | null
    children: TaskJsonInterface[]
    dependencies: TaskDependencyJsonInterface[]
    assignees: UserInterface[]
    groupsAssigned: PermissionGroupJsonInterface[]
    followers: UserInterface[]
    groupFollowers: PermissionGroupJsonInterface[]
    reviews: TaskReviewJsonInterface[]
    comments: TaskCommentJsonInterface[]
    files: FileJsonInterface[]
    tags: TagJson[]
    activities: TaskActivityInterface[]
    createdBy: UserInterface
    isExpanded: boolean
    isLoading: boolean
    eligibleForDelayNotifications: boolean
    articles: ArticleInterface[]
}

export interface TaskExpandedMapping {
    [key: string]: boolean
}

export interface TaskCalendarParams {
    divisions: {
        weeks: number
        days: number
        months: number
    }
    startMonth: number
    endMonth: number
    startDay: number
    endDay: number
}

export interface TaskPayload {
    name: string
    description: string
    startDate: Date
    endDate: Date
    progress: number
    parent?: string
}

export enum TaskDependenciesRelationType {
    ERROR = "ERROR",
    FINISH_TO_START = "FINNISH_TO_START",
    START_TO_START = "START_TO_START",
    FINISH_TO_FINISH = "FINNISH_TO_FINISH",
    START_TO_FINISH = "START_TO_FINISH",
}

export enum TaskDependenciesType {
    ERROR = "ERROR",
    SUCCESSOR = "SUCCESSOR",
    PREDECESSOR = "PREDECESSOR",
}

export interface TaskDependencyJsonInterface extends BaseModelInterface {
    dependencyType: TaskDependenciesType
    relationType: TaskDependenciesRelationType
    lag: number
    dependencyTask: TaskJsonInterface
}

export interface TaskDependenciesPayload {
    dependencyType: TaskDependenciesType
    relationType: TaskDependenciesRelationType
    dependencyTask: string
    lag: number
    taskId: string
}

export class TaskDependency extends BaseModel {
    public dependencyType: TaskDependenciesType
    public relationType: TaskDependenciesRelationType
    public lag: number
    public dependencyTask: Task

    constructor(public data?: TaskDependencyJsonInterface) {
        super(data)
        this.dependencyType =
            data?.dependencyType ?? TaskDependenciesType.PREDECESSOR
        this.relationType =
            data?.relationType ?? TaskDependenciesRelationType.FINISH_TO_START
        this.lag = data?.lag ?? 0
        this.dependencyTask = data?.dependencyTask
            ? new Task(data.dependencyTask)
            : new Task()
    }

    public toJson(): TaskDependencyJsonInterface {
        return {
            ...super.toJson(),
            dependencyType: this.dependencyType,
            relationType: this.relationType,
            lag: this.lag,
            dependencyTask: this.dependencyTask.toJson(),
        }
    }

    public createPayload(): TaskDependenciesPayload {
        return {
            dependencyType: this.dependencyType,
            relationType: this.relationType,
            dependencyTask: this.dependencyTask.id,
            lag: this.lag,
            taskId: this.dependencyTask.id,
        }
    }
}

export class Task extends BaseModel {
    public wbs: string
    public name: string
    public description: string
    public duration: number
    public progress: number
    public hasIssues: boolean
    public startDate: Date
    public endDate: Date
    public actualStartDate: Date
    public actualEndDate: Date
    public startDelay: number
    public endDelay: number
    public status: TaskStatus
    public statusDetail: TaskStatusDetail
    public delayStatus: TaskDelayStatus
    public parent: Task | null
    public children: Task[]
    public dependencies: TaskDependency[]
    public assignees: User[]
    public groupsAssigned: PermissionGroup[]
    public followers: User[]
    public groupFollowers: PermissionGroup[]
    public reviews: TaskReview[]
    public comments: TaskComment[]
    public files: ProjectFile[]
    public tags: Tag[]
    public activities: TaskActivity[]
    public createdBy: User
    public isExpanded: boolean
    public isLoading: boolean
    public eligibleForDelayNotifications: boolean
    public taskStatus: TaskStatus
    public articles: Article[]

    constructor(public data?: TaskJsonInterface) {
        super(data)
        this.isExpanded = data?.isExpanded ?? true
        this.isLoading = data?.isLoading ?? false
        this.eligibleForDelayNotifications =
            data?.eligibleForDelayNotifications ?? false
        this.wbs = data?.wbs ?? ""
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        this.duration = data?.duration ?? 0
        this.progress = data?.progress ?? 0
        this.hasIssues = data?.hasIssues ?? false
        this.startDate = data?.startDate ? new Date(data.startDate) : new Date()
        this.endDate = data?.endDate ? new Date(data.endDate) : new Date()
        this.actualStartDate = data?.actualStartDate
            ? new Date(data.actualStartDate)
            : new Date()
        this.actualEndDate = data?.actualEndDate
            ? new Date(data.actualEndDate)
            : new Date()
        this.startDelay =
            differenceInDays(this.actualStartDate, this.startDate) > 0
                ? differenceInDays(this.actualStartDate, this.startDate)
                : 0
        this.endDelay =
            differenceInDays(this.actualEndDate, this.endDate) > 0
                ? differenceInDays(this.actualEndDate, this.endDate)
                : 0
        this.status = data?.status ?? TaskStatus.NOT_STARTED
        this.statusDetail =
            data?.statusDetail ??
            TaskStatusDetailNameSpace.NOT_STARTED.AWAITING_START
        this.delayStatus = data?.delayStatus ?? TaskDelayStatus.ON_TIME
        this.parent = data?.parent ? new Task(data.parent) : null
        this.children = data?.children
            ? data.children.map((child) => new Task(child))
            : []
        this.dependencies = data?.dependencies
            ? data.dependencies.map(
                  (dependency) => new TaskDependency(dependency),
              )
            : []
        this.assignees = data?.assignees
            ? data.assignees.map((user) => new User(user))
            : []
        this.groupsAssigned = data?.groupsAssigned
            ? data.groupsAssigned.map((group) => new PermissionGroup(group))
            : []
        this.followers = data?.followers
            ? data.followers.map((follower) => new User(follower))
            : []
        this.groupFollowers = data?.groupFollowers
            ? data.groupFollowers.map((group) => new PermissionGroup(group))
            : []
        this.reviews = data?.reviews
            ? data.reviews.map((review) => new TaskReview(review))
            : []
        this.comments = data?.comments
            ? data.comments.map((comment) => new TaskComment(comment))
            : []
        this.files = data?.files
            ? data.files.map((file) => new ProjectFile(file))
            : []
        this.tags = data?.tags ? data.tags.map((tag) => new Tag(tag)) : []
        this.activities = data?.activities
            ? data.activities.map((activity) => new TaskActivity(activity))
            : []
        this.createdBy = new User(data?.createdBy) ?? new User()
        this.taskStatus = this.status
        this.articles = data?.articles
            ? data.articles.map((article) => new Article(article))
            : []
    }

    public getReviewsStatus(): TaskReviewStatus {
        const isRejected =
            this.reviews.length > 0 &&
            this.reviews.every(
                (review) => review.status === TaskReviewStatus.REJECTED,
            )
        const isApproved =
            this.reviews.length > 0 &&
            this.reviews.every(
                (review) => review.status === TaskReviewStatus.APPROVED,
            )
        let reviewStatus = TaskReviewStatus.PENDING
        if (isRejected) reviewStatus = TaskReviewStatus.REJECTED
        if (isApproved) reviewStatus = TaskReviewStatus.APPROVED
        return reviewStatus
    }

    getTaskCalendarParams(): TaskCalendarParams {
        const months = eachMonthOfInterval({
            start: this.startDate,
            end: this.endDate,
        }).length
        const weeks = eachWeekOfInterval({
            start: this.startDate,
            end: this.endDate,
        }).length
        const days = eachDayOfInterval({
            start: this.startDate,
            end: this.endDate,
        }).length
        const divisions = {
            days: days,
            weeks: weeks,
            months: months,
        }
        return {
            divisions: divisions,
            startMonth: this.startDate.getMonth(),
            endMonth: this.endDate.getMonth(),
            startDay: this.startDate.getDate(),
            endDay: this.endDate.getDate(),
        }
    }

    public get startDateInput(): string {
        const year = this.startDate.getFullYear()
        const month = (this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")
        const day = this.startDate.getDate().toString().padStart(2, "0")
        return `${year}-${month}-${day}`
    }

    public set startDateInput(value: string | Date) {
        if (typeof value === "string") {
            this.startDate = new Date(value)
        } else {
            this.startDate = value
        }
    }

    public get endDateInput(): string {
        const year = this.endDate.getFullYear()
        const month = (this.endDate.getMonth() + 1).toString().padStart(2, "0")
        const day = this.endDate.getDate().toString().padStart(2, "0")
        return `${year}-${month}-${day}`
    }

    public set endDateInput(value: string | Date) {
        if (typeof value === "string") {
            this.endDate = new Date(value)
        } else {
            this.endDate = value
        }
    }

    get startDateHtml(): string {
        return this.startDate.toISOString().split("T")[0]
    }

    get endDateHtml(): string {
        return this.endDate.toISOString().split("T")[0]
    }

    get hasChildren(): boolean {
        return this.children.length > 0
    }

    public toJson(): TaskJsonInterface {
        return {
            ...super.toJson(),
            wbs: this.wbs,
            name: this.name,
            description: this.description,
            duration: this.duration,
            progress: this.progress,
            hasIssues: this.hasIssues,
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
            actualStartDate: this.actualStartDate.toISOString(),
            actualEndDate: this.actualEndDate.toISOString(),
            status: this.status,
            statusDetail: this.statusDetail,
            delayStatus: this.delayStatus,
            parent: this.parent ? this.parent.toJson() : null,
            children: this.children.map((child) => child.toJson()),
            dependencies: this.dependencies.map((dependency) =>
                dependency.toJson(),
            ),
            assignees: this.assignees.map((user) => user.toJson()),
            groupsAssigned: this.groupsAssigned.map((group) => group.toJson()),
            followers: this.followers.map((follower) => follower.toJson()),
            groupFollowers: this.groupFollowers.map((group) => group.toJson()),
            reviews: this.reviews.map((review) => review.toJson()),
            comments: this.comments.map((comment) => comment.toJson()),
            files: this.files.map((file) => file.toJson()),
            tags: this.tags.map((tag) => tag.toJson()),
            activities: this.activities.map((activity) => activity.toJson()),
            createdBy: this.createdBy.toJson(),
            isExpanded: this.isExpanded,
            isLoading: this.isLoading,
            eligibleForDelayNotifications: this.eligibleForDelayNotifications,
            articles: this.articles.map((article) => article.toJson()),
        }
    }

    createUpdatePayload(parentId?: string): TaskPayload {
        return {
            name: this.name,
            description: this.description,
            startDate: this.startDate,
            endDate: this.endDate,
            progress: this.progress,
            ...(parentId ? { parent: parentId } : {}),
        }
    }

    setDependenciesPayload(
        dependencyType: TaskDependenciesType,
    ): TaskDependenciesPayload[] {
        return this.dependencies
            .filter(
                (dependency) => dependency.dependencyType === dependencyType,
            )
            .map((dependency) => {
                return {
                    dependencyType: dependency.dependencyType,
                    relationType: dependency.relationType,
                    dependencyTask: dependency.dependencyTask.id,
                    lag: dependency.lag,
                    taskId: dependency.dependencyTask.id,
                }
            })
    }
}

export enum TaskCommentType {
    COMMENT = "comment",
    REPLY = "reply",
    REVIEW_APPROVED = "review_approved",
    REVIEW_REJECTED = "review_rejected",
}

export interface TaskCommentJsonInterface extends BaseModelInterface {
    comment: string
    type: TaskCommentType
    repliesCount: number
    task: TaskJsonInterface
    user: UserInterface
    parent: TaskCommentJsonInterface | null
    replies: TaskCommentJsonInterface[]
    images: FileJsonInterface[]
}

export class TaskComment extends BaseModel {
    public comment: string
    public type: TaskCommentType
    public repliesCount: number
    public task: Task
    public user: User
    public parent: TaskComment | null
    public replies: TaskComment[]
    public images: ProjectFile[]

    constructor(public data?: TaskCommentJsonInterface) {
        super(data)
        this.comment = data?.comment ?? ""
        this.type = data?.type ?? TaskCommentType.COMMENT
        this.repliesCount = data?.repliesCount ?? 0
        this.task = new Task(data?.task) ?? new Task()
        this.user = new User(data?.user) ?? new User()
        this.parent = data?.parent ? new TaskComment(data.parent) : null
        this.replies = data?.replies
            ? data.replies.map((reply) => new TaskComment(reply))
            : []
        this.images = data?.images
            ? data.images.map((image) => new ProjectFile(image))
            : []
    }

    public toJson(): TaskCommentJsonInterface {
        return {
            ...super.toJson(),
            comment: this.comment,
            type: this.type,
            repliesCount: this.repliesCount,
            task: this.task.toJson(),
            user: this.user.toJson(),
            parent: this.parent ? this.parent.toJson() : null,
            replies: this.replies.map((reply) => reply.toJson()),
            images: this.images.map((image) => image.toJson()),
        }
    }
}

export interface TaskReviewJsonInterface extends BaseModelInterface {
    status: TaskReviewStatus
    task: TaskJsonInterface
    reviewer: UserInterface
}

export class TaskReview extends BaseModel {
    public status: TaskReviewStatus
    public task: Task
    public reviewer: User

    constructor(public data?: TaskReviewJsonInterface) {
        super(data)
        this.status = data?.status ?? TaskReviewStatus.PENDING
        this.task = new Task(data?.task) ?? new Task()
        this.reviewer = new User(data?.reviewer) ?? new User()
    }

    public toJson(): any {
        return {
            ...super.toJson(),
            status: this.status,
            task: this.task.toJson(),
            reviewers: this.reviewer.toJson(),
        }
    }
}

export interface TaskResourcesInterface {
    assignees: {
        users: User[]
        groups: PermissionGroup[]
    }
    followers: {
        users: User[]
        groups: PermissionGroup[]
    }
    reviewers: User[]
}

export interface TaskResourcesJsonInterface {
    assignees: {
        users: UserInterface[]
        groups: PermissionGroupJsonInterface[]
    }
    followers: {
        users: UserInterface[]
        groups: PermissionGroupJsonInterface[]
    }
    reviewers: UserInterface[]
}
