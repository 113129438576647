import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { Form, SubmitType } from "@/features/Form/Form"
import { PermissionType } from "@/models/Permission"
import { User } from "@/models/User"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ModulePermission } from "./ModulePermission"
import {
    clearErrors,
    getUserPermissionsInProject,
    setUserProjectPermissions,
} from "./userManagementSlice"

interface UserModulePermissionFormProps {
    user: User
    closeModal?: () => void
}

export const UserModulePermissionForm: React.FC<
    UserModulePermissionFormProps
> = ({ user, closeModal }) => {
    const { t } = useTranslation()
    const userPermissions = useAppSelector(
        (state) => state.userManagement.selectedUserPermissions,
    )
    const status = useAppSelector((state) => state.userManagement.status)

    const [permissions, setPermissions] = useState<PermissionType[]>([])
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId
    useEffect(() => {
        dispatch(getUserPermissionsInProject({ projectId, userId: user.id }))
    }, [])
    const savePermissions = () => {
        dispatch(
            setUserProjectPermissions({
                projectId,
                userId: user.id,
                permissions,
            }),
        ).then(() => {
            if (closeModal) closeModal()
        })
    }
    useEffect(() => {
        setPermissions(userPermissions)
    }, [userPermissions])
    return (
        <Form
            statuses={[status.update, status.read]}
            loadingTitle={
                status.read === SliceStatus.LOADING
                    ? t("loading_permissions")
                    : t("updating_permissions")
            }
            onSubmit={savePermissions}
            clearErrors={clearErrors}
            submitType={SubmitType.Save}
            onCancel={closeModal}
        >
            <div className="flex flex-col gap-3">
                <h2 className="text-2xl font-bold">
                    {t("adjusting_user_permissions", {
                        user: user.fullName,
                    })}
                </h2>
                <ModulePermission
                    permissions={permissions}
                    setPermissions={setPermissions}
                />
            </div>
        </Form>
    )
}
