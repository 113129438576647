import { Task, TaskStatus } from "@/models/Task"

interface CalendarDayInterface {
    date: Date
    tasks: Task[]
}

interface CalendarDayProps {
    day: CalendarDayInterface
    selected: boolean
    onClick: () => void
}

export const CalendarDay: React.FC<CalendarDayProps> = ({
    day,
    selected,
    onClick,
}) => {
    const compareDatesByDay = (date1: Date, date2: Date) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        )
    }
    const taskColorMapping = {
        [TaskStatus.NOT_STARTED]: "bg-gray-400",
        [TaskStatus.COMPLETED]: "bg-green-400",
        [TaskStatus.IN_PROGRESS]: "bg-yellow-400",
    }
    return (
        <div
            className={`w-full h-full border relative flex cursor-pointer hover:bg-primary-100 hover:text-secondary-100 transition ${
                selected ? " bg-primary-100 text-secondary-100" : ""
            }`}
            onClick={onClick}
        >
            <p
                className={`absolute text-4xl font-bold right-2 bottom-2 z-20 ${
                    selected ? "opacity-100" : "opacity-30"
                }`}
            >
                {day.date.getDate()}
            </p>
            {day.tasks.length > 0 && (
                <div className="absolute w-[101%] mt-4 z-10">
                    {day.tasks
                        .sort(
                            (a, b) =>
                                a.startDate.getTime() - b.startDate.getTime(),
                        )
                        .map((task) => {
                            if (
                                !compareDatesByDay(task.startDate, day.date) &&
                                !compareDatesByDay(task.endDate, day.date)
                            ) {
                                return (
                                    <div
                                        key={task.id}
                                        className={`w-full h-1 mt-1 opacity-75 ${
                                            taskColorMapping[task.status]
                                        }`}
                                    ></div>
                                )
                            }
                            if (
                                compareDatesByDay(task.startDate, day.date) &&
                                compareDatesByDay(task.endDate, day.date)
                            ) {
                                return (
                                    <div
                                        key={task.id}
                                        className={`w-1/2 h-1 mt-1 mx-auto opacity-75 flex items-center justify-between ${
                                            taskColorMapping[task.status]
                                        }`}
                                    >
                                        <div className="overflow-hidden  translate-y-[3px]  h-2">
                                            <div
                                                className={`w-2 h-4 rotate-[-45deg] transform origin-bottom-left -translate-y-[10px] ${
                                                    taskColorMapping[
                                                        task.status
                                                    ]
                                                }`}
                                            ></div>
                                        </div>
                                        <div className="overflow-hidden ml-auto translate-y-[4px]">
                                            <div
                                                className={`w-2 h-3 rotate-[-45deg] transform origin-top-left ${
                                                    taskColorMapping[
                                                        task.status
                                                    ]
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                )
                            }
                            if (compareDatesByDay(task.startDate, day.date)) {
                                return (
                                    <div
                                        key={task.id}
                                        className={`w-1/2 h-1 mt-1 opacity-75 flex items-center ${
                                            taskColorMapping[task.status]
                                        } ml-auto`}
                                    >
                                        <div className="overflow-hidden  translate-y-[3px]  h-2">
                                            <div
                                                className={`w-2 h-4 rotate-[-45deg] transform origin-bottom-left -translate-y-[8px] ${
                                                    taskColorMapping[
                                                        task.status
                                                    ]
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                )
                            }
                            if (compareDatesByDay(task.endDate, day.date)) {
                                return (
                                    <div
                                        key={task.id}
                                        className={`w-1/2 h-1 mt-1 opacity-75 flex items-start justify-end ${
                                            taskColorMapping[task.status]
                                        }`}
                                    >
                                        <div className="overflow-hidden   ">
                                            <div
                                                className={`w-2 h-3 rotate-[-45deg] transform origin-top-left ${
                                                    taskColorMapping[
                                                        task.status
                                                    ]
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                )
                            }
                            return <></>
                        })}
                </div>
            )}
        </div>
    )
}
