import { PieChart } from "@mui/x-charts"
import { useEffect, useRef, useState } from "react"

interface StatisticsCardProps {
    title: string
    items: {
        title: string
        percentage: number
        value: number
    }[]
}

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
    title,
    items,
}) => {
    const [chartWidth, setChartWidth] = useState(400)
    const [chartHeight, setChartHeight] = useState(200)
    const containerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (containerRef.current) {
            setChartWidth(containerRef.current.clientWidth)
            setChartHeight(containerRef.current.clientHeight)
        }
    }, [containerRef.current])
    return (
        <div className="bg-secondary-100 p-4 rounded-lg shadow-lg w-full h-full text-center">
            <h1 className="font-bold text-2xl mb-4">{title}</h1>
            <div className="flex flex-wrap gap-y-4" ref={containerRef}>
                <PieChart
                    series={[
                        {
                            data: items.map((item) => ({
                                id: item.title,
                                value: Math.round(item.percentage),
                                label: item.title,
                            })),
                        },
                    ]}
                    width={chartWidth}
                    height={chartHeight}
                />
            </div>
        </div>
    )
}
