import { BaseModel, BaseModelInterface } from "./BaseModel"
import { FileJsonInterface, ProjectFile } from "./File"
import { Project, ProjectJsonInterface } from "./Project"
import { User, UserInterface } from "./User"

export interface FolderJson extends BaseModelInterface {
    name: string
    createdBy: UserInterface
    project: ProjectJsonInterface
    parent: FolderJson | null
    children: FolderJson[]
    files: FileJsonInterface[]
    isGlobal: boolean
}

export class Folder extends BaseModel {
    name: string
    createdBy: User
    project: Project
    parent: Folder | null
    children: Folder[]
    files: ProjectFile[]
    isGlobal: boolean

    constructor(data?: FolderJson) {
        super(data)
        this.name = data?.name ?? ""
        this.createdBy = data?.createdBy ? new User(data.createdBy) : new User()
        this.project = data?.project ? new Project(data.project) : new Project()
        this.parent = data?.parent ? new Folder(data.parent) : null
        this.children = data?.children
            ? data.children.map((folder) => new Folder(folder))
            : []
        this.files = data?.files
            ? data.files.map((file) => new ProjectFile(file))
            : []
        this.isGlobal = data?.isGlobal ?? false
    }

    public toJson(): FolderJson {
        return {
            ...super.toJson(),
            name: this.name,
            createdBy: this.createdBy.toJson(),
            project: this.project.toJson(),
            parent: this.parent ? this.parent.toJson() : null,
            children: this.children.map((folder) => folder.toJson()),
            files: this.files.map((file) => file.toJson()),
            isGlobal: this.isGlobal,
        }
    }
}
