import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Task } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CalendarTaskRow } from "./CalendarTaskRow"
import { CalendarDay } from "./CalenderDay"

interface CalendarProps {
    small?: boolean
    tasks: Task[]
}

interface CalendarMapping {
    date: Date
    tasks: Task[]
}

export const Calendar: React.FC<CalendarProps> = ({ tasks, small = false }) => {
    const status = useAppSelector((state) => state.projects.status)

    const { t } = useTranslation()
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [selectedMonth, setSelectedMonth] = useState<number>(
        selectedDate.getMonth(),
    )
    const [selectedYear, setSelectedYear] = useState<number>(
        selectedDate.getFullYear(),
    )
    const [calendar, setCalendar] = useState<CalendarMapping[][]>([])
    const compareDatesByDay = (date1: Date, date2: Date) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        )
    }
    const dateInInterval = (date: Date, startDate: Date, endDate: Date) => {
        return (
            (date > startDate || compareDatesByDay(date, startDate)) &&
            (date < endDate || compareDatesByDay(date, endDate))
        )
    }
    const [selectedDay, setSelectedDay] = useState<CalendarMapping>({
        date: new Date(),
        tasks: tasks.filter((task) =>
            dateInInterval(new Date(), task.startDate, task.endDate),
        ),
    })

    useEffect(() => {
        setSelectedMonth(selectedDate.getMonth())
        setSelectedYear(selectedDate.getFullYear())
        const firstSunday = new Date(selectedDate)
        firstSunday.setDate(1)
        while (firstSunday.getDay() !== 0) {
            firstSunday.setDate(firstSunday.getDate() - 1)
        }
        const lastSunday = new Date(selectedDate)
        lastSunday.setDate(1)
        lastSunday.setMonth(lastSunday.getMonth() + 1)
        lastSunday.setDate(0)
        while (lastSunday.getDay() !== 0) {
            lastSunday.setDate(lastSunday.getDate() + 1)
        }

        const calendar: CalendarMapping[][] = []
        let currentDay = new Date(firstSunday)
        while (currentDay <= lastSunday) {
            const week: CalendarMapping[] = []
            for (let i = 0; i < 7; i++) {
                const tasksForDay = tasks.filter((task) =>
                    dateInInterval(currentDay, task.startDate, task.endDate),
                )
                week.push({
                    date: new Date(currentDay),
                    tasks: tasksForDay,
                })
                currentDay.setDate(currentDay.getDate() + 1)
            }
            calendar.push(week)
        }
        setCalendar(calendar)
    }, [selectedDate])

    const addMonthToDate = () => {
        const newDate = new Date(selectedDate)
        newDate.setMonth(newDate.getMonth() + 1)
        setSelectedDate(newDate)
    }

    const subtractMonthToDate = () => {
        const newDate = new Date(selectedDate)
        newDate.setMonth(newDate.getMonth() - 1)
        setSelectedDate(newDate)
    }
    return (
        <WithLoader
            statuses={[status.read]}
            title={t("loading_task_calendar")}
            className="h-full"
        >
            <div className="flex h-full sm:flex-col w-full">
                <Scrollable height="100%" className="h-full">
                    <div className="h-full flex flex-col flex-1">
                        <div className="flex gap-2 items-center">
                            <FontAwesomeIcon
                                icon="chevron-left"
                                onClick={subtractMonthToDate}
                                className="text-xl"
                            />
                            <h1 className="text-4xl w-72 text-center">
                                <span className="font-bold">
                                    {
                                        (
                                            t("months", {
                                                returnObjects: true,
                                            }) as string[]
                                        )[selectedMonth] as string
                                    }{" "}
                                </span>
                                {selectedYear}
                            </h1>
                            <FontAwesomeIcon
                                icon="chevron-right"
                                onClick={addMonthToDate}
                                className="text-xl"
                            />
                        </div>
                        <div className="flex gap-2">
                            {[...Array(7).keys()].map((day) => (
                                <div
                                    key={day}
                                    className="flex-1 text-center text-sm font-bold"
                                >
                                    {
                                        (
                                            t("daysMapping", {
                                                returnObjects: true,
                                            }) as string[]
                                        )[day]
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col flex-1">
                            {calendar.map((week, weekIndex) => (
                                <div key={weekIndex} className="flex flex-1">
                                    {week.map((day, dayIdx) => (
                                        <CalendarDay
                                            day={day}
                                            key={dayIdx}
                                            onClick={() => {
                                                setSelectedDay(day)
                                            }}
                                            selected={
                                                selectedDay.date.getTime() ===
                                                day.date.getTime()
                                            }
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>

                    {!small && (
                        <div className="p-4 flex flex-col h-full">
                            <h1 className="font-bold text-xl text-right">
                                {
                                    (
                                        t("daysMapping", {
                                            returnObjects: true,
                                        }) as string[]
                                    )[selectedDay.date.getDay()]
                                }{" "}
                                {selectedDay.date.getDate()},{" "}
                                {
                                    (
                                        t("months", {
                                            returnObjects: true,
                                        }) as string[]
                                    )[selectedDay.date.getMonth()]
                                }{" "}
                                {selectedDay.date.getFullYear()}
                            </h1>
                            <div className="flex flex-col gap-2 my-4">
                                {selectedDay.tasks
                                    .sort(
                                        (a, b) =>
                                            a.status.length - b.status.length,
                                    )
                                    .map((task) => (
                                        <CalendarTaskRow
                                            key={task.id}
                                            task={task}
                                        />
                                    ))}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-2">
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-yellow-500"></div>
                                        <p>{t("in_progress")}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-primary-100"></div>
                                        <p>{t("pending_review")}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-green-500"></div>
                                        <p>{t("completed")}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-red-500"></div>
                                        <p>{t("has_issues")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Scrollable>
            </div>
        </WithLoader>
    )
}
