import { Invitation, InvitationStatus } from "@/models/Auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

interface InvitationCardProps {
    invitation: Invitation
}
export const InvitationCard: React.FC<InvitationCardProps> = ({
    invitation,
}) => {
    const { t } = useTranslation()
    const icon =
        invitation.status === InvitationStatus.PENDING
            ? "clock"
            : invitation.status === InvitationStatus.REJECTED
              ? "times"
              : invitation.status === InvitationStatus.ACCEPTED
                ? "check"
                : invitation.status === InvitationStatus.EXPIRED
                  ? "times"
                  : "question"
    const color =
        invitation.status === InvitationStatus.PENDING
            ? "text-yellow-400"
            : invitation.status === InvitationStatus.REJECTED
              ? "text-red-400"
              : invitation.status === InvitationStatus.ACCEPTED
                ? "text-green-400"
                : invitation.status === InvitationStatus.EXPIRED
                  ? "text-red-800"
                  : ""

    return (
        <div className="bg-secondary-100 border-lg p-3 rounded-lg shadow-lg">
            <div className={`flex items-center gap-2 justify-center `}>
                <FontAwesomeIcon icon="envelope" />
                <p>{invitation.email}</p>
            </div>

            <div
                className={`flex items-center gap-2 text-xs justify-center opacity-55`}
            >
                <FontAwesomeIcon icon="clock" />
                {t("intlDateTime", {
                    val: invitation.createdAt,
                    formatParams: {
                        val: {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        },
                    },
                })}
            </div>
            <div className={`flex items-center gap-2 justify-center  ${color}`}>
                <FontAwesomeIcon icon={icon} />
                <p>{t(invitation.status)}</p>
            </div>
        </div>
    )
}
