import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { Form, SubmitType } from "@/features/Form/Form"
import { Project } from "@/models/Project"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { clearErrors, removeProject } from "../projectsSlice"

interface ProjectDeleteFormProps {
    project: Project
    closeModal: () => void
}

export const ProjectDeleteForm: React.FC<ProjectDeleteFormProps> = ({
    project,
    closeModal,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectStatus = useAppSelector((state) => state.projects.status)
    const adminStatus = useAppSelector((state) => state.admin.status)
    const [projectNameConfirmation, setProjectNameConfirmation] = useState("")

    return (
        <Form
            loadingTitle={t("deleting_project")}
            onCancel={closeModal}
            onSubmit={async () => {
                if (projectNameConfirmation === project.name) {
                    const { type } = await dispatch(removeProject(project.id))
                    if (type.includes("fulfilled")) {
                        closeModal()
                    }
                }
            }}
            statuses={[projectStatus.delete, adminStatus.delete]}
            clearErrors={clearErrors}
            submitType={SubmitType.Delete}
        >
            <h1 className="font-bold text-lg mb-2">
                {t("deleting_project", { project: project.name })}
            </h1>
            <p>{t("delete_project_warning", { project: project.name })}</p>
            <Input
                label={t("confirm_project_name")}
                value={projectNameConfirmation}
                onChange={(e) => setProjectNameConfirmation(e.target.value)}
                required
                name={t("confirm_project_name")}
                type="text"
            />
        </Form>
    )
}
