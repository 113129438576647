import { axiosAuthenticated } from "@/common/axios"
import { ArticleInterface } from "@/models/Article"

export const getProjectArticlesApi = (projectId: string) => {
    return new Promise<{ data: ArticleInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/finance/articles/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createArticleApi = (
    projectId: string,
    articlePayload: FormData,
) => {
    return new Promise<{ data: ArticleInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `projects/${projectId}/finance/articles/create`,
                articlePayload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateArticleApi = (
    projectId: string,
    articleId: string,
    articlePayload: FormData,
) => {
    return new Promise<{ data: ArticleInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(
                `projects/${projectId}/finance/articles/${articleId}`,
                articlePayload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteArticleApi = (projectId: string, articleId: string) => {
    return new Promise<{ data: string }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(`projects/${projectId}/finance/articles/${articleId}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getArticleApi = (projectId: string, articleId: string) => {
    return new Promise<{ data: ArticleInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/finance/articles/${articleId}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getBudgetArticlesApi = (projectId: string, budgetId: string) => {
    return new Promise<{ data: ArticleInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/finance/budget/${budgetId}/articles`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
