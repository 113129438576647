import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { BimpackLogo } from "@/components/Other/BimpackLogo"
import {
    logout,
    selectAuthUser,
    selectIsLogged,
} from "@/features/Authentication/authSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface NavigationProps {
    menu: {
        label: string
        ref: React.RefObject<HTMLDivElement>
        onClick: () => void
    }[]
    vertical?: boolean
    currentSection?: number
}

export const Navigation: React.FC<NavigationProps> = ({
    menu,
    vertical = false,
    currentSection,
}) => {
    const { t } = useTranslation()
    const isLogged = useAppSelector(selectIsLogged)
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectAuthUser)
    const [burgerMenu, setBurgerMenu] = useState<boolean>(false)
    const navRef = useRef<HTMLDivElement>(null)
    const [burgerMenuTop, setBurgerMenuTop] = useState<number>(0)
    useEffect(() => {
        if (navRef.current) {
            setBurgerMenuTop(navRef.current.clientHeight)
        }
    }, [])
    return (
        <nav
            className={`flex ${vertical ? "flex-col h-screen px-2 lg:p-0 lg:w-full lg:h-full" : "w-full border-b px-8"} items-center justify-between  py-4 relative`}
            ref={navRef}
        >
            <div
                className={`animate-pulse lg:w-1/3 lg:flex lg:justify-start ${vertical ? "lg:hidden" : ""}`}
            >
                <BimpackLogo withText={false} />
            </div>
            <div
                className={`hidden lg:flex lg:w-1/3 lg:justify-center ${vertical ? "lg:w-full lg:h-full lg:items-center lg:z-30" : ""}`}
            >
                <FontAwesomeIcon
                    icon={burgerMenu ? "times" : "bars"}
                    className={`text-xl cursor-pointer`}
                    onClick={() => setBurgerMenu(!burgerMenu)}
                />
            </div>
            {burgerMenu && (
                <div
                    className={`absolute w-screen left-0 z-20 bg-primary-100 p-4 flex flex-col justify-center items-center gap-2 bg-opacity-90 ${vertical ? "lg:fixed lg:!top-0 lg:!left-0" : ""}`}
                    style={{ top: burgerMenuTop }}
                >
                    <>
                        {menu.map((item, index) => (
                            <div
                                key={index}
                                className={`group relative cursor-pointer ${currentSection === index ? "text-highlight-100" : ""} hover:text-highlight-100 transition-all duration-200 ease flex gap-2 items-center`}
                                onClick={() => {
                                    item.onClick()
                                    setBurgerMenu(false)
                                }}
                            >
                                <p>{item.label}</p>
                                <span className="ease transition-all duration-200 absolute w-0 h-1 bottom-0 left-0 bg-highlight-100 group-hover:w-full"></span>
                            </div>
                        ))}
                        <div className="flex justify-center items-center flex-col gap-2">
                            {user.isSuperAdmin && (
                                <a href="/admin/home">
                                    <Button
                                        className=" !rounded-lg !bg-red-400  text-red-600 hover:!bg-red-600 hover:!text-white"
                                        name={t("admin")}
                                    ></Button>
                                </a>
                            )}
                            <a href="/dashboard/projects">
                                <Button
                                    className=" !rounded-lg !bg-green-400  text-green-600 hover:!bg-green-600 hover:!text-white"
                                    name={t("dashboard")}
                                ></Button>
                            </a>
                        </div>
                        <div className={vertical ? "" : "hidden"}>
                            <div
                                className={`flex ${vertical ? "flex-col" : ""} gap-3 items-center font-bold lg:flex lg:justify-end`}
                            >
                                {!isLogged ? (
                                    <div className="group relative cursor-pointer hover:text-primary-100 transition-all duration-200 ease flex gap-2 items-center">
                                        <FontAwesomeIcon icon="user" />
                                        <a href="/auth/login">{t("login")}</a>
                                        <span className="ease transition-all duration-200 absolute w-0 h-1 -bottom-[1px] left-0 bg-primary-100 group-hover:w-full"></span>
                                    </div>
                                ) : (
                                    <>
                                        {user.isSuperAdmin && (
                                            <a href="/admin/home">
                                                <Button
                                                    className=" !rounded-lg !bg-red-400  text-red-600 hover:!bg-red-600 hover:!text-white"
                                                    name={t("admin")}
                                                ></Button>
                                            </a>
                                        )}
                                        <a href="/dashboard/projects">
                                            <Button
                                                className=" !rounded-lg !bg-green-400  text-green-600 hover:!bg-green-600 hover:!text-white"
                                                name={t("dashboard")}
                                            ></Button>
                                        </a>
                                        <div className="group relative cursor-pointer hover:text-red-600 transition-all duration-200 ease flex gap-2 items-center">
                                            <FontAwesomeIcon icon="right-from-bracket" />
                                            <p
                                                onClick={() =>
                                                    dispatch(logout())
                                                }
                                            >
                                                {t("logout")}
                                            </p>
                                            <span className="ease transition-all duration-200 absolute w-0 h-1 bottom-0 left-0 bg-red-600 group-hover:w-full"></span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                </div>
            )}
            <div
                className={`flex ${vertical ? "flex-col" : ""} gap-8 items-center font-bold lg:hidden`}
            >
                {menu.map((item, index) => (
                    <div
                        key={index}
                        className={`group relative cursor-pointer ${currentSection === index ? "text-highlight-100" : ""} hover:text-highlight-100 transition-all duration-200 ease flex gap-2 items-center`}
                        onClick={() => {
                            item.onClick()
                        }}
                    >
                        <p>{item.label}</p>
                        <span className="ease transition-all duration-200 absolute w-0 h-1 bottom-0 left-0 bg-highlight-100 group-hover:w-full"></span>
                    </div>
                ))}
            </div>
            <div
                className={`flex ${vertical ? "flex-col lg:hidden" : ""} gap-3 items-center font-bold lg:w-1/3 lg:flex lg:justify-end`}
            >
                {!isLogged ? (
                    <div className="group relative cursor-pointer hover:text-primary-100 transition-all duration-200 ease flex gap-2 items-center">
                        <FontAwesomeIcon icon="user" />
                        <a href="/auth/login">{t("login")}</a>
                        <span className="ease transition-all duration-200 absolute w-0 h-1 -bottom-[1px] left-0 bg-primary-100 group-hover:w-full"></span>
                    </div>
                ) : (
                    <>
                        <div className="sm:hidden flex items-center gap-2">
                            {user.isSuperAdmin && (
                                <a href="/admin/home">
                                    <Button
                                        className=" !rounded-lg !bg-red-400  text-red-600 hover:!bg-red-600 hover:!text-white"
                                        name={t("admin")}
                                    ></Button>
                                </a>
                            )}
                            <a href="/dashboard/projects">
                                <Button
                                    className=" !rounded-lg !bg-green-400  text-green-600 hover:!bg-green-600 hover:!text-white"
                                    name={t("dashboard")}
                                ></Button>
                            </a>
                        </div>
                        <div className="group relative cursor-pointer hover:text-red-600 transition-all duration-200 ease flex gap-2 items-center">
                            <FontAwesomeIcon icon="right-from-bracket" />
                            <p onClick={() => dispatch(logout())}>
                                {t("logout")}
                            </p>
                            <span className="ease transition-all duration-200 absolute w-0 h-1 bottom-0 left-0 bg-red-600 group-hover:w-full"></span>
                        </div>
                    </>
                )}
            </div>
        </nav>
    )
}
