import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { ProjectFile } from "@/models/File"
import { IfcModel } from "@/models/Ifc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { IfcHandler } from "./IfcHandler"
import { getIfcModelFiles, selectIfcModelFiles, setIsLoading } from "./ifcSlice"

interface IfcModelFilesProps {
    ifcHandler: IfcHandler
    selectedModel: IfcModel
    selectedFile: ProjectFile
}

export const IfcModelFiles: FC<IfcModelFilesProps> = ({
    ifcHandler,
    selectedModel,
    selectedFile,
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const ifcFiles = useAppSelector(selectIfcModelFiles)
    const status = useAppSelector((state) => state.ifcViewer.status)
    const projectId = useParams<{ projectId: string }>().projectId
    const [ifcFilesState, setIfcFilesState] = useState<{
        [key: string]: {
            isShown: boolean
            isSelected: boolean
            isAdded: boolean
        }
    }>({})
    const [localLoading, setLocalLoading] = useState<SliceStatus>(
        SliceStatus.IDLE,
    )

    useEffect(() => {
        dispatch(getIfcModelFiles({ projectId, ifcModelId: selectedModel.id }))
    }, [selectedModel])
    useEffect(() => {
        ifcFiles.forEach((file) => {
            if (!ifcFilesState[file.name]) {
                setIfcFilesState((prev) => ({
                    ...prev,
                    [file.name]: {
                        isAdded:
                            file.id === selectedFile.id ||
                            ifcHandler.models.hasOwnProperty(file.name),
                        isShown:
                            file.id === selectedFile.id ||
                            (ifcHandler.models[file.name] &&
                                ifcHandler.models[file.name].isShown),
                        isSelected:
                            file.id === selectedFile.id ||
                            (ifcHandler.models[file.name] &&
                                ifcHandler.models[file.name].isSelected),
                    },
                }))
            }
        })
    }, [ifcFiles])
    const resetIfcFilesStateSelected = () => {
        const newState: {
            [key: string]: {
                isShown: boolean
                isSelected: boolean
                isAdded: boolean
            }
        } = {}
        Object.keys(ifcFilesState).forEach((key) => {
            newState[key] = {
                ...ifcFilesState[key],
                isSelected: false,
            }
        })
        setIfcFilesState(newState)
    }
    return (
        <WithLoader statuses={[status.read]} title={t("loading_ifc_files")}>
            <WithLoader statuses={[localLoading]} title={t("loading_ifc_file")}>
                <div className="w-full h-full flex flex-col gap-2">
                    {ifcFiles.map((file) => (
                        <div
                            key={file.id}
                            className="w-full rounded-lg shadow-lg px-4 py-2 bg-secondary-100 text-primary-300 flex items-center justify-between"
                        >
                            <p>{file.name}</p>
                            <div className="flex gap-1 items-center ml-4">
                                <FontAwesomeIcon
                                    icon={
                                        ifcFilesState[file.name] &&
                                        ifcFilesState[file.name].isAdded
                                            ? "minus"
                                            : "plus"
                                    }
                                    className="w-4 h-4 hover:bg-primary-100 hover:text-secondary-100 rounded-full p-1 cursor-pointer transition"
                                    onClick={() => {
                                        if (ifcHandler.models[file.name]) {
                                            ifcHandler.removeModel(file.name)
                                            setIfcFilesState((prev) => ({
                                                ...prev,
                                                [file.name]: {
                                                    ...prev[file.name],
                                                    isAdded: false,
                                                    isShown: false,
                                                },
                                            }))
                                        } else {
                                            dispatch(setIsLoading(true))
                                            setLocalLoading(SliceStatus.LOADING)
                                            ifcHandler
                                                .loadIfcFile(
                                                    file.name,
                                                    file.path,
                                                    true,
                                                )
                                                .then(() => {
                                                    dispatch(
                                                        setIsLoading(false),
                                                    )
                                                    setLocalLoading(
                                                        SliceStatus.IDLE,
                                                    )
                                                })
                                            setIfcFilesState((prev) => ({
                                                ...prev,
                                                [file.name]: {
                                                    ...prev[file.name],
                                                    isAdded: true,
                                                    isShown: true,
                                                },
                                            }))
                                        }
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon={
                                        ifcFilesState[file.name] &&
                                        ifcFilesState[file.name].isShown
                                            ? "eye-slash"
                                            : "eye"
                                    }
                                    className="w-4 h-4 hover:bg-primary-100 hover:text-secondary-100 rounded-full p-1 cursor-pointer transition"
                                    onClick={() => {
                                        ifcHandler.toggleModal(
                                            file.name,
                                            !ifcFilesState[file.name].isShown,
                                        )
                                        setIfcFilesState((prev) => ({
                                            ...prev,
                                            [file.name]: {
                                                ...prev[file.name],
                                                isShown:
                                                    !prev[file.name].isShown,
                                            },
                                        }))
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon="arrow-pointer"
                                    className={`w-4 h-4 hover:bg-primary-100 hover:text-secondary-100 rounded-full p-1 cursor-pointer transition ${
                                        ifcFilesState[file.name] &&
                                        ifcFilesState[file.name].isSelected
                                            ? "bg-primary-100 text-secondary-100"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        ifcHandler.selectModel(file.name)
                                        resetIfcFilesStateSelected()
                                        setIfcFilesState((prev) => ({
                                            ...prev,
                                            [file.name]: {
                                                ...prev[file.name],
                                                isSelected:
                                                    !prev[file.name].isSelected,
                                            },
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </WithLoader>
        </WithLoader>
    )
}
