import UserManagementFilePermissionsIllustration from "@/assets/illustrations/user_management/file_upload_permissions.png"
import UserManagementFolderPermissionsIllustration from "@/assets/illustrations/user_management/folder_permissions.png"
import UserManagementGroupPermissionsIllustration from "@/assets/illustrations/user_management/group_permissions.png"
import UserManagementIllustration from "@/assets/illustrations/user_management/user_management_illustration.jpeg"
import UserManagementUserPermissionsIllustration from "@/assets/illustrations/user_management/user_specific_permissions.png"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpringRef, useTransition } from "@react-spring/web"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface CustomPermissionsFeaturesProps {}

export const CustomPermissionsFeatures: React.FC<
    CustomPermissionsFeaturesProps
> = () => {
    const { t } = useTranslation()
    const details = [
        {
            title: t("dynamic_group_creation"),
            description: t("dynamic_group_creation_description"),
            icon: "users",
            illustration: (
                <div className="w-full h-full flex items-center">
                    <div className="w-fit h-fit border-4 rounded border-primary-100 border-t-[25px] relative">
                        <div className="absolute -top-[15px] left-4 w-fit h-fit flex gap-2">
                            <span className="rounded-full w-2 h-2 bg-red-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-yellow-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-green-600 z-20"></span>
                        </div>
                        <img
                            src={UserManagementIllustration}
                            alt="user_management_illustration"
                            className="h-full object-contain"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: t("tailored_access_controls"),
            description: t("tailored_access_controls_description"),
            icon: "user-lock",
            illustration: (
                <div className="flex items-center justify-center gap-4">
                    <img
                        src={UserManagementGroupPermissionsIllustration}
                        alt="user_management_group_permissions_illustration"
                        className="border-4 rounded-lg border-primary-100 w-[35%] inline-block"
                    />
                    <img
                        src={UserManagementUserPermissionsIllustration}
                        alt="user_management_user_permissions_illustration"
                        className="object-contain h-full border-4 rounded-lg border-primary-100 w-[40%] inline-block"
                    />
                </div>
            ),
        },
        {
            title: t("granular_permissions"),
            description: t("granular_permissions_description"),
            icon: "user-shield",
            illustration: (
                <div className="flex gap-4 items-center justify-center">
                    <img
                        src={UserManagementFilePermissionsIllustration}
                        alt="file_permissions_illustration"
                        className="border-4 rounded-lg border-primary-100 inline-block w-[35%]"
                    />
                    <img
                        src={UserManagementFolderPermissionsIllustration}
                        alt="folder_permissions_illustration"
                        className="border-4 rounded-lg border-primary-100 inline-block w-[35%]"
                    />
                </div>
            ),
        },
    ]
    const [currentDetail, setCurrentDetail] = useState(0)
    const transRef = useSpringRef()
    const [transitions, api] = useTransition(currentDetail, () => ({
        ref: transRef,
        keys: null,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
        onRest: (_a, _b, item) => {
            if (currentDetail === item) {
                setCurrentDetail((state) => (state + 1) % details.length)
            }
        },
        exitBeforeEnter: true,
    }))
    useEffect(() => {
        transRef.start()
    }, [currentDetail])
    return (
        <section className="w-full h-full flex flex-col px-8 py-4 ">
            <div className="flex gap-2 h-full ">
                <div className="w-full h-full">
                    <div className="flex flex-col h-full gap-4 items-center w-full">
                        {details.map((detail, index) => (
                            <div
                                key={index}
                                className={`flex lg:flex-col lg:p-2 gap-4 items-center p-4 rounded-lg cursor-pointer transition hover:bg-primary-100 w-full flex-1 ${
                                    currentDetail === index
                                        ? "bg-primary-100"
                                        : ""
                                }`}
                                onClick={() => setCurrentDetail(index)}
                            >
                                <FontAwesomeIcon
                                    icon={detail.icon as IconProp}
                                    className="text-3xl text-secondary-100"
                                />
                                <div className="lg:text-center">
                                    <h2 className="text-xl lg:text-lg font-bold">
                                        {detail.title}
                                    </h2>
                                    <p className="text-lg lg:text-base font-light">
                                        {detail.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full h-full lg:hidden">
                    {transitions((style, item) => (
                        <animated.div
                            className="w-full h-full flex items-center justify-center"
                            style={{ ...style }}
                        >
                            {details[item].illustration}
                        </animated.div>
                    ))}
                </div>
            </div>
        </section>
    )
}
