import { axiosAuthenticated } from "@/common/axios"
import { FileJsonInterface, ProjectFileTypeEnum } from "@/models/File"
import { ResourcePermissions } from "@/models/Permission"
import { setIfcUploadProgress } from "./ifcSlice"

export const getProjectIfcFilesApi = (projectId: string) => {
    return new Promise<{ data: FileJsonInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/file-manager/files?fileType=${ProjectFileTypeEnum.IFC}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createIfcModelApi = (
    projectId: string,
    payload: {
        name: string
        description: string
        permissions: ResourcePermissions
    },
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/ifc-manager/ifc-models/`, payload)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectIfcModelsApi = (projectId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/ifc-manager/ifc-models/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const uploadIfcFileToModelApi = (
    projectId: string,
    modelId: string,
    payload: FormData,
    dispatch: any,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `projects/${projectId}/ifc-manager/ifc-models/${modelId}/upload/`,
                payload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            const progress = Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total,
                            )
                            dispatch(setIfcUploadProgress(progress))
                        }
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getFileSignedUrlsApi = (
    projectId: string,
    ifcModelId: string,
    fileId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/ifc-manager/ifc-models/${ifcModelId}/files/${fileId}/signed-urls/`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getIfcModelFilesApi = (projectId: string, ifcModelId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/ifc-manager/ifc-models/${ifcModelId}/files/`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const attachEntitiesToTaskApi = (
    projectId: string,
    taskId: string,
    ifcEntitiesIds: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/ifc-manager/entities/attach/${taskId}`,
                { ifcEntitiesIds },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateIfcEntitiesProgressApi = (
    projectId: string,
    entitiesProgress: Record<string, number>,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/ifc-manager/entities/progress`, {
                entitiesProgress,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getIfcFileEntitiesApi = (projectId: string, ifcFileId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/ifc-manager/ifc-file/${ifcFileId}/entities`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const setIfcEntitiesArticleApi = (
    projectId: string,
    articleId: string,
    entities: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/ifc-manager/entities/article/${articleId}`,
                { entities },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteIfcModelApi = (projectId: string, ifcModelId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(
                `/projects/${projectId}/ifc-manager/ifc-models/${ifcModelId}/`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getIfcFileNotStartedEntitiesApi = (
    projectId: string,
    ifcFileId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/ifc-manager/ifc-file/${ifcFileId}/entities/not-started`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getIfcFileInProgressEntitiesApi = (
    projectId: string,
    ifcFileId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/ifc-manager/ifc-file/${ifcFileId}/entities/in-progress`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getIfcFileCompletedEntitiesApi = (
    projectId: string,
    ifcFileId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/ifc-manager/ifc-file/${ifcFileId}/entities/completed`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
