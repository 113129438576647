import Atef from "@/assets/illustrations/about_us/atef.jpg"
import Ghassen from "@/assets/illustrations/about_us/ghassen.jpg"
import { useTranslation } from "react-i18next"

interface AboutUsProps {}

export const AboutUs: React.FC<AboutUsProps> = () => {
    const { t } = useTranslation()
    return (
        <section className="w-full flex flex-col items-center min-h-screen px-32 py-8 bg-primary-200 text-secondary-100 lg:p-4 ">
            <h1 className="text-5xl font-black mb-4">{t("about_us")}</h1>
            <p className="text-xl text-center w-3/4 lg:w-full lg:mb-8">
                {t("about_us_description")}
            </p>
            <div className="w-5/6 h-full flex gap-2 justify-between lg:flex-col lg:items-center mt-12">
                <div className="flex flex-col items-center max-w-80">
                    <img
                        src={Ghassen}
                        alt="Ghassen"
                        className="rounded-full w-60 h-60 object-cover"
                    />
                    <div className="text-center flex flex-col items-center relative">
                        <h1 className="text-2xl font-bold bg-highlight-100 w-fit absolute -top-10 p-2 bg-opacity-70">
                            Ghassen Boufaden
                        </h1>
                        <p className="font-bold text-lg mt-2">
                            {t("ghassen_title")}
                        </p>
                        <p className="font-light text-sm">
                            {t("ghassen_background")}
                        </p>
                    </div>
                </div>
                <div className="w-3/4 hidden h-full p-8 lg:flex flex-col justify-evenly lg:w-full lg:p-2 lg:gap-4">
                    <div className="text-2xl w-full bg-secondary-100 text-primary-300 rounded-lg px-8 py-4 lg:text-lg">
                        {t("about_us_detail_3")}
                    </div>
                </div>
                <div className="flex flex-col items-center max-w-80">
                    <img
                        src={Atef}
                        alt="Atef"
                        className="rounded-full w-60 h-60 object-cover"
                    />
                    <div className="text-center flex flex-col items-center relative">
                        <h1 className="text-2xl font-bold bg-highlight-100 w-fit absolute -top-10 p-2 bg-opacity-70">
                            Atef Mechken
                        </h1>
                        <p className="font-bold text-lg mt-2">
                            {t("atef_title")}
                        </p>
                        <p className="font-light text-sm">
                            {t("atef_background")}
                        </p>
                    </div>
                </div>
                <div className="w-3/4 hidden h-full p-8 lg:flex flex-col justify-evenly lg:w-full lg:p-2 lg:gap-4">
                    <div className="text-2xl w-full bg-secondary-100 text-primary-300 rounded-lg px-8 py-4 lg:text-lg">
                        {t("about_us_detail_1")} 
                    </div>
                </div>
            </div>
            <div className="w-5/6 h-full flex gap-4 p-8 lg:hidden flex-col justify-evenly lg:w-full lg:p-2 lg:gap-4">
                <div className="text-2xl w-full text-center bg-secondary-100 text-primary-300 rounded-lg px-8 py-4 lg:text-lg">
                    {t("about_us_detail_1")}
                </div>
                <div className="text-2xl w-full text-center bg-highlight-100 text-secondary-300 rounded-lg px-8 py-4 lg:text-lg">
                    {t("about_us_detail_2")}
                </div>
                <div className="text-2xl w-full text-center bg-primary-100 text-secondary-300 rounded-lg px-8 py-4 lg:text-lg">
                    {t("about_us_detail_3")}
                </div>
            </div>
        </section>
    )
}
