import { axiosAuthenticated } from "@/common/axios"
import {
    BudgetInterface,
    BudgetPayload,
    GeneralBudgetInfo,
} from "@/models/Finance"

export const getBudgetsApi = (projectId: string) => {
    return new Promise<{ data: BudgetInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/finance/budget/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getBudgetsTreesApi = (projectId: string) => {
    return new Promise<{ data: BudgetInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/finance/budget/tree`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getGeneralBudgetInfoApi = (projectId: string) => {
    return new Promise<{ data: GeneralBudgetInfo[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/finance/budget/general`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createBudgetApi = (projectId: string, payload: BudgetPayload) => {
    return new Promise<{ data: BudgetInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/finance/budget`, { data: payload })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateBudgetApi = (
    projectId: string,
    budgetId: string,
    payload: BudgetPayload,
) => {
    return new Promise<{ data: BudgetInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/finance/budget/${budgetId}/`, {
                data: payload,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteBudgetApi = (projectId: string, budgetId: string) => {
    return new Promise<{ data: BudgetInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/projects/${projectId}/finance/budget/${budgetId}/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getBudgetStatisticsApi = (projectId: string, budgetId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/finance/budget/${budgetId}/statistics`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
