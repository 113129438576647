import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { CalendarZoomModes } from "@/common/types"
import { BimpackLogo } from "@/components/Other/BimpackLogo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { getProjectUsersById } from "../../projectsSlice"
import { getProjectTags } from "../../Tags/tagSlice"
import { getProjectGroups } from "../../UserManagement/userManagementSlice"
import {
    expandAllTasks,
    getPlanningTaskTree,
    selectTaskTree,
    shrinkAllTasks,
} from "../planningSlice"
import {
    CommonFilterFields,
    FilterFields,
    TaskFilterFields,
} from "./Filters/Filters"
import {
    initCalendar,
    selectGanttCalendar,
    setTableColumns,
    setZoom,
} from "./ganttSlice"
import { GanttTaskCalendar } from "./GanttTaskCalendar"
import { GanttTaskTable } from "./GanttTaskTable"

const zoomOrder = [
    CalendarZoomModes.Day,
    CalendarZoomModes.Week,
    CalendarZoomModes.Month,
    CalendarZoomModes.Quarter,
]

export const Gantt: React.FC = () => {
    const { projectId, planningId } = useParams<{
        projectId: string
        planningId: string
    }>()
    const dispatch = useAppDispatch()
    const taskTree = useAppSelector(selectTaskTree)
    const tableRef = React.useRef<HTMLDivElement>(null)
    const calendarRef = React.useRef<HTMLDivElement>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    const fullScreenRef = React.useRef<HTMLDivElement>(null)
    const positionControlRef = React.useRef<HTMLDivElement>(null)
    const toPrintRef = React.useRef<HTMLDivElement>(null)
    const tableColumns = useAppSelector((state) => state.gantt.tableColumns)
    const [showTableColumnsInput, setShowTableColumnsInput] =
        useState<boolean>(false)
    const [localTableColumns, setLocalTableColumns] =
        useState<FilterFields[]>(tableColumns)
    const [isResizing, setIsResizing] = useState<boolean>(false)
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
    const calendar = useAppSelector(selectGanttCalendar)
    useEffect(() => {
        dispatch(
            getPlanningTaskTree({
                projectId: projectId,
                planningId: planningId,
            }),
        )
        dispatch(getProjectTags(projectId))
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])
    useEffect(() => {
        dispatch(
            initCalendar({
                startDate: taskTree.startDate.toISOString(),
                endDate: taskTree.endDate.toISOString(),
            }),
        )
    }, [taskTree])
    useEffect(() => {
        setLocalTableColumns(tableColumns)
    }, [tableColumns])
    const handlePrint = useReactToPrint({
        content: () => toPrintRef.current,
    })

    const resizeTable = (e: React.DragEvent<HTMLDivElement>) => {
        if (
            tableRef.current &&
            calendarRef.current &&
            containerRef.current &&
            positionControlRef.current &&
            isResizing
        ) {
            const tableWidth = tableRef.current.offsetWidth
            const calendarWidth = calendarRef.current.offsetWidth
            const containerWidth = containerRef.current.offsetWidth
            const positionControlXPosition =
                positionControlRef.current.getBoundingClientRect().left
            const newTableWidth =
                tableWidth + (e.clientX - positionControlXPosition)
            const newCalendarWidth =
                calendarWidth - (e.clientX - positionControlXPosition)
            if (
                newTableWidth > 0 &&
                newCalendarWidth > 0 &&
                newTableWidth + newCalendarWidth < containerWidth
            ) {
                tableRef.current.style.width = newTableWidth + "px"
                calendarRef.current.style.width = newCalendarWidth + "px"
            }
        }
    }

    const zoomOutCalendar = () => {
        if (calendar.zoom !== CalendarZoomModes.Quarter) {
            dispatch(setZoom(zoomOrder[zoomOrder.indexOf(calendar.zoom) + 1]))
            dispatch(
                initCalendar({
                    startDate: taskTree.startDate.toISOString(),
                    endDate: taskTree.endDate.toISOString(),
                }),
            )
        }
    }

    const zoomInCalendar = () => {
        if (calendar.zoom !== CalendarZoomModes.Day) {
            dispatch(setZoom(zoomOrder[zoomOrder.indexOf(calendar.zoom) - 1]))
            dispatch(
                initCalendar({
                    startDate: taskTree.startDate.toISOString(),
                    endDate: taskTree.endDate.toISOString(),
                }),
            )
        }
    }

    const recalculateWidths = () => {
        if (tableRef.current && calendarRef.current && containerRef.current) {
            tableRef.current.style.width = "70%"
            calendarRef.current.style.width = "30%"
        }
    }

    const setFullscreen = async () => {
        if (fullScreenRef.current && tableRef.current && calendarRef.current) {
            await fullScreenRef.current.requestFullscreen()
            recalculateWidths()
        }
    }

    const tableWidthFullSize = () => {
        if (tableRef.current && calendarRef.current && containerRef.current) {
            tableRef.current.style.width =
                containerRef.current.offsetWidth + "px"
            calendarRef.current.style.width = "0px"
        }
    }

    const calenderWidthFullSize = () => {
        if (tableRef.current && calendarRef.current && containerRef.current) {
            tableRef.current.style.width = "0px"
            calendarRef.current.style.width =
                containerRef.current.offsetWidth + "px"
        }
    }

    useEffect(() => {
        if (isFullScreen) {
            setFullscreen()
        } else {
            //  if document is active
            if (document.fullscreenElement) {
                document.exitFullscreen()
            }
        }
        dispatch(
            initCalendar({
                startDate: taskTree.startDate.toISOString(),
                endDate: taskTree.endDate.toISOString(),
            }),
        )
    }, [isFullScreen])

    return (
        <div
            className="bg-secondary-100 w-full p-2 h-full flex flex-col gap-4 overflow-hidden"
            ref={fullScreenRef}
            onResize={recalculateWidths}
        >
            <div className="w-full shadow-lg rounded-lg bg-secondary-100 flex p-2 mt-2 gap-4 py-4 justify-center items-center">
                <div className="flex gap-2">
                    <FontAwesomeIcon
                        icon="arrow-down-short-wide"
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={() => {
                            dispatch(expandAllTasks())
                        }}
                    />
                    <FontAwesomeIcon
                        icon="arrow-up-short-wide"
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={() => {
                            dispatch(shrinkAllTasks())
                        }}
                    />
                </div>
                <div className="flex gap-2">
                    <FontAwesomeIcon
                        icon="magnifying-glass-plus"
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={zoomInCalendar}
                    />
                    <FontAwesomeIcon
                        icon="magnifying-glass-minus"
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={zoomOutCalendar}
                    />
                    <FontAwesomeIcon
                        icon={isFullScreen ? "compress" : "expand"}
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={() => setIsFullScreen(!isFullScreen)}
                    />
                </div>
                <div className="flex gap-2">
                    <div className="relative">
                        <FontAwesomeIcon
                            icon="eye"
                            className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                            onClick={() =>
                                setShowTableColumnsInput(!showTableColumnsInput)
                            }
                        />
                        {showTableColumnsInput && (
                            <div className="absolute z-50 bg-secondary-100 py-5 px-8 text-nowrap shadow-lg rounded-lg">
                                <p className="font-bold border-b border-primary-300">
                                    {t("show_columns")}:
                                </p>
                                <div className="pl-2">
                                    {[...Object.values(TaskFilterFields)]
                                        .filter(
                                            (field) =>
                                                ![
                                                    TaskFilterFields.WBS,
                                                    CommonFilterFields.Name,
                                                ].includes(field),
                                        )
                                        .map((field, index) => (
                                            <div
                                                key={field + index}
                                                className="flex items-center gap-2"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={localTableColumns.includes(
                                                        field as FilterFields,
                                                    )}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            dispatch(
                                                                setTableColumns(
                                                                    [
                                                                        ...tableColumns,
                                                                        field,
                                                                    ],
                                                                ),
                                                            )
                                                        } else {
                                                            dispatch(
                                                                setTableColumns(
                                                                    tableColumns.filter(
                                                                        (
                                                                            column,
                                                                        ) =>
                                                                            column !==
                                                                            field,
                                                                    ),
                                                                ),
                                                            )
                                                        }
                                                    }}
                                                />
                                                <p>{t(field)}</p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex gap-2">
                    <FontAwesomeIcon
                        icon="print"
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={handlePrint}
                    />
                </div>
            </div>

            <div
                className="w-full h-full overflow-hidden flex"
                ref={containerRef}
            >
                <div className="w-10/12 h-full relative" ref={tableRef}>
                    <div
                        className={`absolute w-full top-0 h-full print:h-fit ${isResizing ? "hidden" : "block"}`}
                        ref={toPrintRef}
                    >
                        <div className="print:block hidden relative min-h-36">
                            <div className="absolute left-8 top-1/2 transform -translate-y-1/2">
                                <BimpackLogo
                                    primaryColor="#052A51"
                                    secondaryColor="#083D77"
                                    logoSize="h-16 w-16"
                                    withText={true}
                                    textColor="#052A51"
                                    className="text-4xl !w-fit"
                                />
                            </div>
                            <div className="absolute right-8 top-1/2 transform -translate-y-1/2">
                                <p className="text-lg">
                                    <span className="font-bold">
                                        {t("planning")}:
                                    </span>{" "}
                                    {taskTree.name}
                                </p>
                                <p className="text-lg">
                                    <span className="font-bold">
                                        {t("print_date")}:
                                    </span>{" "}
                                    {t("intlDateTime", {
                                        val: new Date(),
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                    })}
                                </p>
                            </div>
                        </div>
                        <GanttTaskTable />
                    </div>
                    <div
                        className={`text-2xl opacity-50 gap-4 flex-col justify-center items-center w-full h-full ${isResizing ? "flex" : "hidden"}`}
                    >
                        <FontAwesomeIcon
                            icon="arrow-left"
                            className=" text-8xl"
                        />
                        <p>{t("drag_to_left")}</p>
                    </div>
                </div>
                <div
                    className="w-2 h-full bg-primary-300 opacity-20 relative cursor-move z-10 hover:opacity-100 transition"
                    draggable={true}
                    onDrag={resizeTable}
                    ref={positionControlRef}
                    onMouseDown={() => setIsResizing(true)}
                    onDragEnd={() => setIsResizing(false)}
                    onMouseUp={() => setIsResizing(false)}
                >
                    <div
                        onClick={tableWidthFullSize}
                        className="cursor-pointer w-6 h-12 bg-primary-300 absolute top-[90%] left-full -translate-y-1/2 flex items-center justify-center p-4 rounded-tr-full rounded-br-full"
                    >
                        <FontAwesomeIcon
                            icon="chevron-right"
                            className="text-secondary-100 text-2xl"
                        />
                    </div>
                    <div
                        onClick={calenderWidthFullSize}
                        className="cursor-pointer w-6 h-12 bg-primary-300 absolute top-[90%] right-full transform -translate-y-1/2 flex items-center justify-center p-4 rounded-tl-full rounded-bl-full"
                    >
                        <FontAwesomeIcon
                            icon="chevron-left"
                            className="text-secondary-100 text-2xl"
                        />
                    </div>
                </div>
                <div
                    className="w-2/12 h-full overflow-hidden relative"
                    ref={calendarRef}
                >
                    <div
                        className={`absolute top-0 left-0 h-full flex w-full ${isResizing ? "hidden" : "block"}`}
                    >
                        <GanttTaskCalendar />
                    </div>
                    <div
                        className={`text-2xl opacity-50 gap-4 flex-col justify-center items-center w-full h-full ${isResizing ? "flex" : "hidden"}`}
                    >
                        <FontAwesomeIcon
                            icon="arrow-right"
                            className=" text-8xl"
                        />
                        <p>{t("drag_to_right")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
