import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { getProjectUsersById } from "../projectsSlice"
import { getProjectGroups } from "../UserManagement/userManagementSlice"
import { getTaskAssigneesById, selectTask } from "./tasksSlice"

interface TaskAssigneesProps {}

export const TaskAssignees: React.FC<TaskAssigneesProps> = ({}) => {
    const dispatch = useAppDispatch()
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const task = useAppSelector(selectTask)
    const [queryParams] = useSearchParams()
    const [highlighted, setHighlighted] = useState<string>("")
    const highlightedRef = React.useRef<HTMLDivElement>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    useEffect(() => {
        dispatch(getTaskAssigneesById({ projectId, taskId }))
    }, [])
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])

    useEffect(() => {
        const assignee = queryParams.get("assignee")
        if (assignee) {
            setHighlighted(assignee)
            if (containerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        containerRef.current.getBoundingClientRect()
                    containerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current])
    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col h-full">
            <div className="flex items-center justify-between gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("assignees")}
                </h1>
            </div>
            <div className="w-full mt-4">
                {task.assignees.length > 0 || task.groupsAssigned.length > 0 ? (
                    <Scrollable height="400px" innerRef={containerRef}>
                        {task.assignees.length > 0 && (
                            <div className="flex flex-col gap-4">
                                <h1 className="text-lg font-bold">
                                    {t("users")}
                                </h1>
                                {task.assignees.map((assignee) => (
                                    <div
                                        key={assignee.id}
                                        className={`flex justify-between items-center bg-secondary-100 p-4 border-b border-r border-primary-100 border-opacity-25 rounded-br-lg ${
                                            highlighted === assignee.id
                                                ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                                                : ""
                                        }`}
                                        ref={
                                            highlighted === assignee.id
                                                ? highlightedRef
                                                : null
                                        }
                                        onMouseEnter={() => {
                                            if (highlighted === assignee.id) {
                                                setHighlighted("")
                                            }
                                        }}
                                    >
                                        <div className="flex items-center gap-3 ">
                                            <img
                                                src={
                                                    assignee.profilePicture.path
                                                }
                                                alt=""
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <h4 className="text-lg font-bold">
                                                {assignee.fullName}
                                            </h4>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {task.groupsAssigned.length > 0 && (
                            <div className="flex flex-col gap-4">
                                <h1 className="text-lg font-bold">
                                    {t("groups")}
                                </h1>
                                {task.groupsAssigned.map((group) => (
                                    <div
                                        key={group.id}
                                        className={`flex justify-between items-center bg-secondary-100 p-4 border-b border-r border-primary-100 border-opacity-25 rounded-br-lg ${
                                            highlighted === group.id
                                                ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                                                : ""
                                        }`}
                                        ref={
                                            highlighted === group.id
                                                ? highlightedRef
                                                : null
                                        }
                                        onMouseEnter={() => {
                                            if (highlighted === group.id) {
                                                setHighlighted("")
                                            }
                                        }}
                                    >
                                        <div className="flex justify-center items-center gap-4">
                                            <div className="flex w-8 h-8 justify-center items-center border-2 border-primary-300 rounded-full">
                                                <FontAwesomeIcon
                                                    icon="user-group"
                                                    className="text-xs"
                                                />
                                            </div>
                                            <h4 className="text-lg font-bold">
                                                {group.name}
                                            </h4>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Scrollable>
                ) : (
                    <div className="w-full h-full flex justify-center items-center opacity-20">
                        <h1 className="text-xl flex flex-col items-center">
                            <FontAwesomeIcon
                                icon="user-minus"
                                className="mr-2 text-6xl"
                            />
                            <p>{t("no_assignees_yet")}...</p>
                        </h1>
                    </div>
                )}
            </div>
        </div>
    )
}
