import { BaseModel, BaseModelInterface } from "./BaseModel"
import { User, UserInterface } from "./User"

export enum BanReason {
    SPAM = "spam",
    HARASSMENT = "harassment",
    ILLEGAL_CONTENT = "illegal_content",
    EXPLOIT = "exploit",
    OTHER = "other",
}

export interface BanInterface extends BaseModelInterface {
    reason: BanReason
    description: string
    expiresAt: Date
    user: UserInterface
}

export class Ban extends BaseModel {
    reason: BanReason
    description: string
    expiresAt: Date
    user: User

    constructor(data?: BanInterface) {
        super(data)
        this.reason = data?.reason ?? BanReason.OTHER
        this.description = data?.description ?? ""
        this.expiresAt = data?.expiresAt ? new Date(data.expiresAt) : new Date()
        this.user = data?.user ? new User(data.user) : new User()
    }

    public banPayload() {
        return {
            reason: this.reason,
            description: this.description,
            expiresAt: this.expiresAt.toISOString(),
        }
    }

    public toJson(): BanInterface {
        return {
            ...super.toJson(),
            reason: this.reason,
            description: this.description,
            expiresAt: this.expiresAt,
            user: this.user.toJson(),
        }
    }
}
