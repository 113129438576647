import { TaskDelayStatusBar } from "@/components/Other/TaskDelayStatus"
import { TaskReviewStatusBar } from "@/components/Other/TaskReviewStatusBar"
import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { TaskStatusDetailBar } from "@/components/Other/TaskStatusDetailBar"
import { UsersShow } from "@/components/Other/UsersShow"
import { TaskActivity, TaskActivityTypes } from "@/models/Activity"
import { ProjectFile } from "@/models/File"
import { NotificationType } from "@/models/Notification"
import { PermissionGroup } from "@/models/Permission"
import { Tag } from "@/models/Tag"
import {
    Task,
    TaskComment,
    TaskDelayStatus,
    TaskReviewStatus,
    TaskStatus,
    TaskStatusDetail,
} from "@/models/Task"
import { User } from "@/models/User"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"

interface TaskActivityProps {
    activity: TaskActivity
}

const ACTIVITY_TYPE_PARAM_MAPPING = {
    [TaskActivityTypes.ADD_REVIEWER]: "reviewer",
    [TaskActivityTypes.ASSIGN_ASSIGNEE]: "assignee",
    [TaskActivityTypes.ADD_FOLLOWER]: "follower",
    [TaskActivityTypes.ADD_TAG]: "tag",
    [TaskActivityTypes.ADD_DEPENDENCY]: "dependency",
    [TaskActivityTypes.ADD_ATTACHMENT]: "attachment",
}

export const TaskActivityComponent: React.FC<TaskActivityProps> = ({
    activity,
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const projectId = useParams<{ projectId: string }>().projectId
    const taskId = useParams<{ taskId: string }>().taskId
    const [isPlural, setIsPlural] = useState<boolean>(false)
    useEffect(() => {
        const plural =
            (activity.parsedData?.users?.length ?? 0) > 1 ||
            (activity.parsedData?.dependencyTasks?.length ?? 0) > 1 ||
            (activity.parsedData?.attachments?.length ?? 0) > 1 ||
            (activity.parsedData?.comments?.length ?? 0) > 1 ||
            (activity.parsedData?.tags?.length ?? 0) > 1
        const groupPlural = (activity.parsedData?.groups?.length ?? 0) > 1
        setIsPlural(plural || groupPlural)
    }, [activity])
    return (
        <div className="flex flex-wrap text-nowrap gap-1 items-center">
            {activity.createdAt && (
                <p className="text-sm mr-2 opacity-60">
                    {t("intlDateTime", {
                        val: activity.createdAt,
                        formatParams: {
                            val: {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            },
                        },
                    })}
                </p>
            )}
            {activity.doneBy.id != "-1" && (
                <div className="flex items-center gap-2">
                    <img
                        src={activity.doneBy.profilePicture.path}
                        alt={activity.doneBy.fullName}
                        className="w-8 h-8 rounded-full"
                    />

                    <p className="font-bold">{activity.doneBy.fullName}</p>
                </div>
            )}
            <Trans
                i18nKey={`task_activity.${activity.type}`}
                values={{
                    plural: isPlural ? "s" : "",
                }}
                components={[
                    <span key="type" className="font-bold">
                        {t(`activity.${activity.type}`)}
                    </span>,
                    <span key="users" className="flex items-center gap-1">
                        {activity.parsedData?.users?.map(
                            (user, index, users) => (
                                <p key={index}>
                                    <span className="text-primary-100 border-b border-b-primary-100 transition cursor-pointer hover:border-b-primary-300 hover:text-primary-300">
                                        {(user as User).fullName}
                                    </span>
                                    {index !== users.length - 1 ? ", " : ""}
                                </p>
                            ),
                        )}
                    </span>,
                    <span key="groups" className="flex items-center gap-1">
                        {activity.parsedData?.groups?.map(
                            (group, index, groups) => (
                                <p key={index}>
                                    <span className="text-primary-100 border-b border-b-primary-100 transition cursor-pointer hover:border-b-primary-300 hover:text-primary-300">
                                        {(group as PermissionGroup).name}
                                    </span>
                                    {index !== groups.length - 1 ? ", " : ""}
                                </p>
                            ),
                        )}
                    </span>,
                    <span key="comments">
                        {activity.parsedData?.comments?.map(
                            (comment, index) => (
                                <Link
                                    key={index}
                                    to={`/dashboard/projects/${projectId}/tasks/${taskId}?comment=${(comment as TaskComment).id}`}
                                    className="text-primary-100 border-b border-b-primary-100 transition cursor-pointer hover:border-b-primary-300 hover:text-primary-300"
                                >
                                    {t("comment")}
                                </Link>
                            ),
                        )}
                    </span>,
                    <span key="tags">
                        {activity.parsedData?.tags?.map((tag, index) => (
                            <span key={index}>{(tag as Tag).name}</span>
                        ))}
                    </span>,
                    <span key="dependencyTasks">
                        {activity.parsedData?.dependencyTasks?.map(
                            (task, index) => (
                                <Link
                                    key={index}
                                    to={`/dashboard/projects/${projectId}/tasks/${(task as Task).id}`}
                                    className="text-primary-100 border-b border-b-primary-100 transition cursor-pointer hover:border-b-primary-300 hover:text-primary-300"
                                >
                                    {t("task")}
                                </Link>
                            ),
                        )}
                    </span>,
                    <span key="attachments">
                        {activity.parsedData?.attachments?.map(
                            (attachment, index) => (
                                <Link
                                    key={index}
                                    to={`/dashboard/projects/${projectId}/tasks/${taskId}?attachment=${(attachment as ProjectFile).id}`}
                                    className="text-primary-100 border-b border-b-primary-100 transition cursor-pointer hover:border-b-primary-300 hover:text-primary-300"
                                >
                                    {(attachment as ProjectFile).name}
                                </Link>
                            ),
                        )}
                    </span>,
                    <div className="w-fit">
                        <p></p>
                        {activity.parsedData && activity.parsedData.status && (
                            <TaskStatusBar
                                key="status"
                                taskStatus={
                                    activity.parsedData.status[0] as TaskStatus
                                }
                            />
                        )}
                    </div>,
                    <div className="w-fit">
                        <p></p>
                        {activity.parsedData &&
                            activity.parsedData.reviewStatus && (
                                <TaskReviewStatusBar
                                    key="status"
                                    reviewStatus={
                                        activity.parsedData
                                            .reviewStatus[0] as TaskReviewStatus
                                    }
                                />
                            )}
                    </div>,
                    <div>
                        <p></p>
                        {activity.parsedData &&
                            activity.parsedData.notificationType &&
                            activity.parsedData.notificationType[0] && (
                                <p className="font-bold">
                                    {t(
                                        activity.parsedData
                                            ?.notificationType[0] as NotificationType,
                                    )}
                                </p>
                            )}
                    </div>,
                    <div>
                        <p></p>
                        {activity.data && activity.parsedData.users && (
                            <UsersShow
                                users={activity.parsedData.users as User[]}
                            />
                        )}
                    </div>,
                    <div className="w-fit">
                        <p></p>
                        {activity.parsedData && activity.parsedData.status && (
                            <TaskStatusDetailBar
                                key="status"
                                taskStatusDetail={
                                    activity.parsedData
                                        .status[0] as TaskStatusDetail
                                }
                            />
                        )}
                    </div>,
                    <div className="w-fit">
                        <p></p>
                        {activity.parsedData && activity.parsedData.status && (
                            <TaskDelayStatusBar
                                key="status"
                                taskDelayStatus={
                                    activity.parsedData
                                        .status[0] as TaskDelayStatus
                                }
                            />
                        )}
                    </div>,
                ]}
            />
        </div>
    )
}
