import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { Project, ProjectJsonInterface } from "@/models/Project"
import { Task, TaskJsonInterface } from "@/models/Task"
import { User, UserInterface } from "@/models/User"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import { TaskFilters } from "../../models/Task"
import {
    addImagesApi,
    createProjectApi,
    getAllProjectTasksByIdApi,
    getAuthenticatedUserProjects,
    getAuthenticatedUserSharedProjects,
    getProjectByIdApi,
    getProjectProgressByIdApi,
    getProjectResourcesByIdApi,
    getProjectTasksByIdApi,
    getProjectTasksFilteredApi,
    getProjectTasksTreesByIdApi,
    getProjectUsersByIdApi,
    removeImagesApi,
    removeProjectApi,
    updateProjectApi,
} from "./projectsApi"

export const getUserProjects = createAsyncThunkWithNotification(
    "projects/getUserProjects",
    async ({
        pageSize,
        pageNumber,
    }: {
        pageSize: number
        pageNumber: number
    }) => {
        const response = await getAuthenticatedUserProjects(
            pageNumber,
            pageSize,
        )
        return response
    },
)
export const getUserSharedProjects = createAsyncThunkWithNotification(
    "projects/getUserSharedProjects",
    async ({
        pageSize,
        pageNumber,
    }: {
        pageSize: number
        pageNumber: number
    }) => {
        const response = await getAuthenticatedUserSharedProjects(
            pageNumber,
            pageSize,
        )
        return response
    },
)

export const getProjectById = createAsyncThunkWithNotification(
    "projects/getProjectById",
    async (projectId: string) => {
        const response = await getProjectByIdApi(projectId)
        return response
    },
)

export const getProjectProgressById = createAsyncThunkWithNotification(
    "projects/getProjectProgressById",
    async (projectId: string) => {
        const response = await getProjectProgressByIdApi(projectId)
        return response
    },
)

export const getProjectResourcesById = createAsyncThunkWithNotification(
    "projects/getProjectResourcesById",
    async (projectId: string) => {
        const response = await getProjectResourcesByIdApi(projectId)
        return response
    },
)

export const getProjectUsersById = createAsyncThunkWithNotification(
    "projects/getProjectUsersById",
    async (projectId: string) => {
        const response = await getProjectUsersByIdApi(projectId)
        return response
    },
)

export const createProject = createAsyncThunkWithNotification(
    "projects/createProject",
    async (payload: any) => {
        const response = await createProjectApi(payload)
        return response
    },
)

export const getProjectTasksById = createAsyncThunkWithNotification(
    "projects/getProjectTasksById",
    async (projectId: string) => {
        const response = await getProjectTasksByIdApi(projectId, 2000, 1)
        return response
    },
)

export const getProjectTasksFiltered = createAsyncThunkWithNotification(
    "projects/getProjectTasksFiltered",
    async ({
        projectId,
        filters,
    }: {
        projectId: string
        filters: TaskFilters
    }) => {
        const response = await getProjectTasksFilteredApi(projectId, filters)
        return response
    },
)

export const getAllProjectTasksById = createAsyncThunkWithNotification(
    "projects/getAllProjectTasksById",
    async (projectId: string) => {
        const response = await getAllProjectTasksByIdApi(projectId)
        return response
    },
)

export const getProjectTasksTreesById = createAsyncThunkWithNotification(
    "projects/getProjectTasksTreesById",
    async (projectId: string) => {
        const response = await getProjectTasksTreesByIdApi(projectId)
        return response
    },
)

export const updateProject = createAsyncThunkWithNotification(
    "projects/updateProject",
    async ({
        projectId,
        updatePayload,
    }: {
        projectId: string
        updatePayload: any
    }) => {
        const response = await updateProjectApi(projectId, updatePayload)
        return response
    },
)

export const addImages = createAsyncThunkWithNotification(
    "projects/addImages",
    async (
        {
            projectId,
            payload,
        }: {
            projectId: string
            payload: FormData
        },
        dispatch: any,
    ) => {
        const response = await addImagesApi(projectId, payload, dispatch)
        return response
    },
)

export const deleteImages = createAsyncThunkWithNotification(
    "projects/deleteImages",
    async ({
        projectId,
        payload,
    }: {
        projectId: string
        payload: { fileIds: string[] }
    }) => {
        const response = await removeImagesApi(projectId, payload)
        return response
    },
)

export const removeProject = createAsyncThunkWithNotification(
    "projects/removeProject",
    async (projectId: string) => {
        const response = await removeProjectApi(projectId)
        return response
    },
)

export interface ProjectsState {
    projects: ProjectJsonInterface[]
    sharedProjects: ProjectJsonInterface[]
    projectsTotalCount: number
    sharedProjectsTotalCount: number
    project: ProjectJsonInterface
    progress: number
    projectUsers: UserInterface[]
    projectTasks: TaskJsonInterface[]
    projectTasksTree: TaskJsonInterface[]
    imageUploadProgress: number
    status: ReducerStatus
    errors: FormErrors
}

const initialState: ProjectsState = {
    projects: [],
    sharedProjects: [],
    projectsTotalCount: 0,
    sharedProjectsTotalCount: 0,
    project: new Project().toJson(),
    progress: 0,
    projectUsers: [],
    projectTasks: [],
    projectTasksTree: [],
    imageUploadProgress: 0,
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

export const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setImageUploadProgress: (state, action) => {
            state.imageUploadProgress = action.payload
        },
        clearErrors: (state) => {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserProjects.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserProjects.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projects = action.payload.data.data
                state.projectsTotalCount = action.payload.data.totalCount
            })
            .addCase(getUserProjects.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.projects = []
            })
            .addCase(getUserSharedProjects.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserSharedProjects.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.sharedProjects = action.payload.data.data
                state.sharedProjectsTotalCount = action.payload.data.totalCount
            })
            .addCase(getUserSharedProjects.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.sharedProjects = []
            })
            .addCase(getProjectById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.project = action.payload.data.data
            })
            .addCase(getProjectById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.project = new Project().toJson()
            })
            .addCase(getProjectUsersById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectUsersById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projectUsers = action.payload.data.data
            })
            .addCase(getProjectUsersById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.projectUsers = []
            })
            .addCase(createProject.pending, (state) => {
                state.status.create = SliceStatus.LOADING
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.status.create = SliceStatus.IDLE
                state.projects = [...state.projects, action.payload.data.data]
            })
            .addCase(createProject.rejected, (state, action) => {
                state.status.create = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(getProjectTasksById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectTasksById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projectTasks = action.payload.data.data
            })
            .addCase(getProjectTasksById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.projectTasks = []
            })
            .addCase(getProjectTasksFiltered.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectTasksFiltered.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projectTasks = action.payload.data.data
            })
            .addCase(getProjectTasksFiltered.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.projectTasks = []
            })
            .addCase(getAllProjectTasksById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getAllProjectTasksById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projectTasks = action.payload.data.data
            })
            .addCase(getAllProjectTasksById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.projectTasks = []
            })
            .addCase(getProjectTasksTreesById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectTasksTreesById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projectTasksTree = action.payload.data.data
            })
            .addCase(getProjectTasksTreesById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.projectTasksTree = []
            })
            .addCase(updateProject.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.project = action.payload.data.data
            })
            .addCase(updateProject.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
                state.project = new Project().toJson()
            })
            .addCase(addImages.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(addImages.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.project.files = [
                    ...state.project.files,
                    ...action.payload.data.data,
                ]
            })
            .addCase(addImages.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(deleteImages.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(deleteImages.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.project.files = state.project.files.filter((file) => {
                    return !action.payload.data.data.includes(file.id)
                })
            })
            .addCase(deleteImages.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(removeProject.pending, (state) => {
                state.status.delete = SliceStatus.LOADING
            })
            .addCase(removeProject.fulfilled, (state, action) => {
                state.status.delete = SliceStatus.IDLE
                state.projects = state.projects.filter(
                    (project) => project.id !== action.payload.data.data,
                )
            })
            .addCase(removeProject.rejected, (state) => {
                state.status.delete = SliceStatus.FAILED
            })
            .addCase(getProjectProgressById.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectProgressById.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.progress = action.payload.data.data
            })
            .addCase(getProjectProgressById.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.progress = 0
            })
    },
})

const selectRawProjects = (state: RootState) => state.projects.projects
const selectRawSharedProjects = (state: RootState) =>
    state.projects.sharedProjects
const selectRawProject = (state: RootState) => state.projects.project
const selectRawProjectUsers = (state: RootState) => state.projects.projectUsers
const selectRawProjectTasks = (state: RootState) => state.projects.projectTasks
const selectRawProjectTasksTree = (state: RootState) =>
    state.projects.projectTasksTree

export const selectProjects = createSelector([selectRawProjects], (projects) =>
    projects.map((project) => new Project(project)),
)
export const selectSharedProjects = createSelector(
    [selectRawSharedProjects],
    (projects) => projects.map((project) => new Project(project)),
)
export const selectProject = createSelector(
    [selectRawProject],
    (project) => new Project(project),
)

export const selectProjectUsers = createSelector(
    [selectRawProjectUsers],
    (projectUsers) => projectUsers.map((projectUser) => new User(projectUser)),
)

export const selectProjectTasks = createSelector(
    [selectRawProjectTasks],
    (projectTasks) => projectTasks.map((projectTask) => new Task(projectTask)),
)

export const selectProjectTasksTree = createSelector(
    [selectRawProjectTasksTree],
    (projectTasksTree) =>
        projectTasksTree.map((projectTask) => new Task(projectTask)),
)

export const { setImageUploadProgress, clearErrors } = projectSlice.actions

export default projectSlice.reducer
