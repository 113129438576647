import { UserNotification } from "@/models/Notification"
import {
    NotificationTypeAdditionalDataMap,
    UserNotificationType,
} from "@/types/UserNotificationsTypes"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface NotificationTaskInProgressProps {
    notification: UserNotification
    additionalData: NotificationTypeAdditionalDataMap[UserNotificationType.TASK_IN_PROGRESS]
    seen: boolean
    longFormat?: boolean
}

export const NotificationTaskInProgress: React.FC<
    NotificationTaskInProgressProps
> = ({ notification, additionalData, seen, longFormat = false }) => {
    const { t } = useTranslation()
    const redirectLink = `/projects/${additionalData.project.id}/tasks/${additionalData.task.id}`
    return (
        <Link
            className={`flex items-center justify-between  p-2 bg-secondary-100 rounded-lg  ${
                seen ? "text-primary-100" : "text-primary-300"
            }`}
            to={redirectLink}
        >
            <div className="flex items-center gap-2">
                <div>
                    {t("the_task")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.task.name}
                    </span>{" "}
                    {t("from_the_project")}{" "}
                    <span
                        className={`font-bold border-b cursor-pointer ${
                            seen
                                ? "border-b-primary-100"
                                : "border-b-primary-300"
                        }`}
                    >
                        {additionalData.project.name}
                    </span>{" "}
                    {t("is_now")}{" "}
                    <span className="text-primary-100">{t("in_progress")}</span>
                    {!longFormat && (
                        <p className="text-xs">
                            {notification.timeSinceCreation}{" "}
                        </p>
                    )}
                </div>
            </div>
            {longFormat && (
                <p className="text-xs">{notification.timeSinceCreation} </p>
            )}
        </Link>
    )
}
