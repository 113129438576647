interface BimpackLogoProps {
    className?: string
    withText?: boolean
    primaryColor?: string
    secondaryColor?: string
    textColor?: string
    logoSize?: string
    isLink?: boolean
}

export const BimpackLogo: React.FC<BimpackLogoProps> = ({
    className,
    withText = false,
    primaryColor = "#F8FCFF",
    secondaryColor = "#bababa",
    textColor = "#F8FCFF",
    logoSize = "w-10 h-10",
    isLink = true,
}) => {
    return isLink ? (
        <a href="/">
            <div
                className={`relative z-10 flex gap-2 items-center justify-center text-2xl ${className}`}
            >
                <div className={`${logoSize}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 270.97 286.22"
                    >
                        <defs>
                            <style>
                                {`.cls-1{fill:${primaryColor};}.cls-2{fill:${secondaryColor};}`}
                            </style>
                        </defs>
                        <title>Bimpack</title>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path
                                    className="cls-1"
                                    d="M270.75,104.15l-40,24.53h0l-2.45,1.5-55.74,34.17a1.75,1.75,0,0,0-.85,1.5v45.27a1.77,1.77,0,0,0,2.69,1.5l95.76-58.74a1.75,1.75,0,0,0,.84-1.5V105A1.73,1.73,0,0,0,270.75,104.15Z"
                                />
                                <path
                                    className="cls-2"
                                    d="M270.13,103.49,131,18.06,102,.26a1.76,1.76,0,0,0-2.68,1.5v45.3a1.77,1.77,0,0,0,.84,1.5l128.15,78.62h0a1.76,1.76,0,0,1,0,3l2.45-1.5,40-24.53A1.87,1.87,0,0,0,270.13,103.49Z"
                                />
                                <path
                                    className="cls-2"
                                    d="M42.69,156l55.75-34.17a1.77,1.77,0,0,0,.84-1.5V75.1a1.76,1.76,0,0,0-2.68-1.5L.84,132.34A1.74,1.74,0,0,0,0,133.5L42.69,159A1.76,1.76,0,0,1,42.69,156Z"
                                />
                                <path
                                    className="cls-1"
                                    d="M170.84,237.66,42.69,159h0L0,133.5a1.75,1.75,0,0,0,0,.33l.22,48.25a1.75,1.75,0,0,0,.62.65L140,268.16,169,286a1.76,1.76,0,0,0,2.68-1.5v-45.3A1.74,1.74,0,0,0,170.84,237.66Z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                {withText && (
                    <div className="relative">
                        <p
                            className="font-bold "
                            style={{
                                color: textColor,
                            }}
                        >
                            Bimpack
                        </p>
                        <p className="absolute top-6 right-0 text-sm self-end !m-0 text-highlight-100">
                            alpha
                        </p>
                    </div>
                )}
            </div>
        </a>
    ) : (
        <div
            className={`relative z-10 flex gap-2 items-center justify-center ${className}`}
        >
            <div className={`${logoSize}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 270.97 286.22"
                >
                    <defs>
                        <style>
                            {`.cls-1{fill:${primaryColor};}.cls-2{fill:${secondaryColor};}`}
                        </style>
                    </defs>
                    <title>Bimpack</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <path
                                className="cls-1"
                                d="M270.75,104.15l-40,24.53h0l-2.45,1.5-55.74,34.17a1.75,1.75,0,0,0-.85,1.5v45.27a1.77,1.77,0,0,0,2.69,1.5l95.76-58.74a1.75,1.75,0,0,0,.84-1.5V105A1.73,1.73,0,0,0,270.75,104.15Z"
                            />
                            <path
                                className="cls-2"
                                d="M270.13,103.49,131,18.06,102,.26a1.76,1.76,0,0,0-2.68,1.5v45.3a1.77,1.77,0,0,0,.84,1.5l128.15,78.62h0a1.76,1.76,0,0,1,0,3l2.45-1.5,40-24.53A1.87,1.87,0,0,0,270.13,103.49Z"
                            />
                            <path
                                className="cls-2"
                                d="M42.69,156l55.75-34.17a1.77,1.77,0,0,0,.84-1.5V75.1a1.76,1.76,0,0,0-2.68-1.5L.84,132.34A1.74,1.74,0,0,0,0,133.5L42.69,159A1.76,1.76,0,0,1,42.69,156Z"
                            />
                            <path
                                className="cls-1"
                                d="M170.84,237.66,42.69,159h0L0,133.5a1.75,1.75,0,0,0,0,.33l.22,48.25a1.75,1.75,0,0,0,.62.65L140,268.16,169,286a1.76,1.76,0,0,0,2.68-1.5v-45.3A1.74,1.74,0,0,0,170.84,237.66Z"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            {withText && (
                <p className="font-bold text-2xl text-secondary-100">Bimpack</p>
            )}
        </div>
    )
}
