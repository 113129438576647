import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PlanView } from "@thatopen/components-front"
import { useEffect, useState } from "react"
import { IfcHandler } from "../IfcHandler"

interface PlansListProps {
    ifcHandler: IfcHandler
}

export const PlansList: React.FC<PlansListProps> = ({ ifcHandler }) => {
    const [plansList, setPlansList] = useState<PlanView[]>(ifcHandler.plansList)
    const [selectedPlan, setSelectedPlan] = useState<number>(0)
    useEffect(() => {
        if (selectedPlan > 0 && selectedPlan < plansList.length - 1) {
            ifcHandler.setPlanView(plansList[selectedPlan].id)
        }
    }, [selectedPlan])
    return (
        <div className="flex items-center gap-2">
            {selectedPlan > 0 && (
                <FontAwesomeIcon
                    icon="chevron-left"
                    className="cursor-pointer transition hover:scale-110"
                    onClick={() => {
                        setSelectedPlan(selectedPlan - 1)
                    }}
                />
            )}
            <div
                key={plansList[selectedPlan].id}
                className="flex items-center gap-2 p-2 w-full"
            >
                <p className="text-nowrap">{plansList[selectedPlan].name}</p>
            </div>
            {selectedPlan < plansList.length - 1 && (
                <FontAwesomeIcon
                    icon="chevron-right"
                    className="cursor-pointer transition hover:scale-110"
                    onClick={() => {
                        setSelectedPlan(selectedPlan + 1)
                    }}
                />
            )}
        </div>
    )
}
