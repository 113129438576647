import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { ImageInput } from "@/components/Inputs/ImageInput"
import { Input } from "@/components/Inputs/Input"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { MODULE_PERMISSIONS } from "@/models/Permission"
import { Project } from "@/models/Project"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, SubmitType } from "../../Form/Form"
import { selectUserPermissions } from "../../User/userSlice"
import {
    addImages,
    clearErrors,
    createProject,
    deleteImages,
    updateProject,
} from "../projectsSlice"

interface ProjectFormProps {
    project?: Project
    forcePicturesUpdate?: boolean
    closeModal: () => void
}

export const ProjectForm: React.FC<ProjectFormProps> = ({
    project,
    closeModal,
    forcePicturesUpdate = false,
}) => {
    const { t } = useTranslation()
    const [localProject, setLocalProject] = useState<Project>(
        project ?? new Project(),
    )
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.projects.status)
    const [isUploading, setIsUploading] = useState(false)
    const imageUploadProgress = useAppSelector(
        (state) => state.projects.imageUploadProgress,
    )
    const errors = useAppSelector((state) => state.projects.errors)
    const [picturesToUpload, setPicturesToUpload] = useState<File[]>([])
    const [deletedPictures, setDeletedPictures] = useState<string[]>([])
    const userPermissions = useAppSelector(selectUserPermissions)
    const changeProjectAttribute = (key: string, value: any) => {
        setLocalProject(
            (prevState) => new Project({ ...prevState.toJson(), [key]: value }),
        )
    }
    const handleSubmit = async () => {
        if (project) {
            const types = []
            const { type } = await dispatch(
                updateProject({
                    projectId: project.id,
                    updatePayload: localProject.createPayload(),
                }),
            )
            types.push(type)
            if (picturesToUpload.length > 0) {
                const formData: FormData = new FormData()
                picturesToUpload.forEach((image) => {
                    formData.append("photos", image)
                })
                setIsUploading(true)
                const { type } = await dispatch(
                    addImages({
                        projectId: project.id,
                        payload: formData,
                    }),
                )
                types.push(type)
                setIsUploading(false)
            }
            if (deletedPictures.length > 0) {
                const { type } = await dispatch(
                    deleteImages({
                        projectId: project.id,
                        payload: { fileIds: deletedPictures },
                    }),
                )
                types.push(type)
            }
            if (types.every((type) => type.includes("fulfilled"))) {
                closeModal()
            }
        } else {
            const payload = localProject.createPayload()
            if (picturesToUpload.length > 0) {
                const action = await dispatch(createProject(payload))
                const formData: FormData = new FormData()
                picturesToUpload.forEach((image) => {
                    formData.append("photos", image)
                })
                await dispatch(
                    addImages({
                        projectId: action.payload.data.data.id,
                        payload: formData,
                    }),
                )
                if (action.type === createProject.fulfilled.type) {
                    closeModal()
                }
            } else {
                const { type } = await dispatch(createProject(payload))
                if (type === createProject.fulfilled.type) {
                    closeModal()
                }
            }
        }
    }

    return (
        <div className="w-full">
            <h1 className="font-bold text-lg">
                {project
                    ? t("project_edit_notice", { project: project.name })
                    : t("create_project")}
            </h1>
            <Form
                statuses={[status.create, status.update]}
                loadingTitle={
                    !isUploading
                        ? project
                            ? t("editing_project")
                            : t("creating_project")
                        : `${t("uploading_images")} ${imageUploadProgress}%`
                }
                onSubmit={handleSubmit}
                onCancel={closeModal}
                className="w-full"
                submitType={project ? SubmitType.Save : SubmitType.Create}
                clearErrors={clearErrors}
            >
                <div className="w-full">
                    <div>
                        <Input
                            label={t("name")}
                            value={localProject.name}
                            type="text"
                            name="projectName"
                            onChange={(e) =>
                                changeProjectAttribute("name", e.target.value)
                            }
                            errors={errors["name"]}
                        ></Input>
                        <TextAreaInput
                            name="description"
                            label={t("description")}
                            value={localProject.description}
                            onChange={(e) => {
                                changeProjectAttribute(
                                    "description",
                                    e.target.value,
                                )
                            }}
                            className="w-full"
                            resizable={false}
                        />
                        <Input
                            label={t("address")}
                            value={localProject.address}
                            type="text"
                            name="projectAddress"
                            onChange={(e) =>
                                changeProjectAttribute(
                                    "address",
                                    e.target.value,
                                )
                            }
                            errors={errors["address"]}
                        ></Input>
                    </div>
                    {(userPermissions.hasAllPermissions([
                        MODULE_PERMISSIONS.FileManager.Upload,
                    ]) ||
                        !project ||
                        forcePicturesUpdate) && (
                        <div className="my-4">
                            <ImageInput
                                images={project?.pictures ?? []}
                                setImagesToDelete={setDeletedPictures}
                                setImagesToUpload={setPicturesToUpload}
                                label={t("project_images")}
                            />
                        </div>
                    )}
                </div>
            </Form>
        </div>
    )
}
