import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { PlanningForm } from "./PlanningForm"
import { PlanningItem } from "./PlanningItem"
import { getPlanningsByProjectId, selectPlannings } from "./planningSlice"

export const Plannings = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const plannings = useAppSelector(selectPlannings)
    const [showCreateModal, setShowCreateModal] = React.useState(false)
    const [localPlannings, setLocalPlannings] = React.useState(plannings)
    const status = useAppSelector((state) => state.plannings.status)
    useEffect(() => {
        dispatch(getPlanningsByProjectId(projectId))
    }, [])
    useEffect(() => {
        setLocalPlannings(plannings)
    }, [plannings])
    return (
        <div className="flex flex-col overflow-hidden h-full p-2">
            <div className="flex justify-between items-center sm:flex-col sm:items-start sm:gap-4">
                <h1 className="text-4xl font-bold border-b-4 w-fit border-opacity-100 border-primary-300">
                    Plannings
                </h1>
                <Button
                    name={t("create_planning")}
                    icon="plus"
                    onClick={() => setShowCreateModal(true)}
                ></Button>
            </div>
            <div className="bg-secondary-100 rounded-lg shadow-lg p-4 mt-5 overflow-hidden h-full w-fulls">
                <Scrollable height="100%" width="100%">
                    <WithLoader
                        statuses={[status.read]}
                        title={t("loading_plannings")}
                        className="w-full"
                    >
                        {plannings.length > 0 && (
                            <div className="flex flex-col gap-4 w-full">
                                {plannings
                                    .sort((a, b) =>
                                        a.isMain === b.isMain
                                            ? 0
                                            : a.isMain
                                              ? -1
                                              : 1,
                                    )
                                    .map((planning) => (
                                        <PlanningItem
                                            key={planning.id}
                                            planning={planning}
                                        />
                                    ))}
                            </div>
                        )}
                    </WithLoader>
                </Scrollable>
            </div>
            <NewModal
                title={t("create_planning")}
                isShown={showCreateModal}
                closeModal={() => setShowCreateModal(false)}
            >
                <PlanningForm closeModal={() => setShowCreateModal(false)} />
            </NewModal>
        </div>
    )
}
