import FileManagerIllustration from "@/assets/illustrations/file_manager/file_manager_illustration.jpeg"
import FileManagerPermissionsIllustration from "@/assets/illustrations/file_manager/permissions_illustration.png"
import FileManagerQuickAccessIllustration from "@/assets/illustrations/file_manager/quick_access_illustration.png"
import FileManagerQuicNavigationIllustration from "@/assets/illustrations/file_manager/quick_navigation_illustration.png"
import FileManagerSearchIllustration from "@/assets/illustrations/file_manager/search_illustration.png"
import FileManagerTaskAttachmentsIllustration from "@/assets/illustrations/file_manager/task_attachments_illustration.png"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpringRef, useTransition } from "@react-spring/web"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface CentralizedResourceManagementFeaturesProps {}

export const CentralizedResourceManagementFeatures: React.FC<
    CentralizedResourceManagementFeaturesProps
> = () => {
    const { t } = useTranslation()
    const details = [
        {
            title: t("comprehensive_file_manager"),
            description: t("comprehensive_file_manager_description"),
            icon: "folder",
            illustration: (
                <div className="w-full h-full flex items-center">
                    <div className="w-fit h-fit border-4 rounded border-primary-100 border-t-[25px] relative">
                        <div className="absolute -top-[15px] left-4 w-fit h-fit flex gap-2">
                            <span className="rounded-full w-2 h-2 bg-red-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-yellow-600 z-20"></span>
                            <span className="rounded-full w-2 h-2 bg-green-600 z-20"></span>
                        </div>
                        <img
                            src={FileManagerIllustration}
                            alt="file_manager_illustration"
                            className="h-full object-contain"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: t("seamless_integration"),
            description: t("seamless_integration_description"),
            icon: "users",
            illustration: (
                <div className="w-full relative h-full">
                    <div className="absolute bottom-10 left-10 z-10">
                        <img
                            src={FileManagerPermissionsIllustration}
                            alt="file_manager_permissions_illustration"
                            className="border-4 rounded-lg border-primary-100 opacity-80"
                        />
                    </div>
                    <div className="absolute h-full">
                        <img
                            src={FileManagerTaskAttachmentsIllustration}
                            alt="task_header_illustration"
                            className="object-contain h-full border-4 rounded-lg border-primary-100"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: t("quick_search_and_retrieval"),
            description: t("quick_search_and_retrieval_description"),
            icon: "comments",
            illustration: (
                <div className="w-full h-full flex flex-col items-center justify-center gap-10">
                    <div className="">
                        <img
                            src={FileManagerSearchIllustration}
                            alt="file_manager_search_illustration"
                            className="border-4 rounded-lg border-primary-100"
                        />
                    </div>
                    <div className="">
                        <img
                            src={FileManagerQuicNavigationIllustration}
                            alt="file_manager_quick_navigation_illustration"
                            className="border-4 rounded-lg border-primary-100 inline-block mr-2"
                        />
                        <img
                            src={FileManagerQuickAccessIllustration}
                            alt="file_manager_quick_access_illustration"
                            className="border-4 rounded-lg border-primary-100 inline-block"
                        />
                    </div>
                </div>
            ),
        },
    ]
    const [currentDetail, setCurrentDetail] = useState(0)
    const transRef = useSpringRef()
    const [transitions, api] = useTransition(currentDetail, () => ({
        ref: transRef,
        keys: null,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
        onRest: (_a, _b, item) => {
            if (currentDetail === item) {
                setCurrentDetail((state) => (state + 1) % details.length)
            }
        },
        exitBeforeEnter: true,
    }))
    useEffect(() => {
        transRef.start()
    }, [currentDetail])
    return (
        <section className="w-full h-full flex flex-col px-8 py-4 ">
            <div className="flex gap-2 h-full lg:flex-col">
                <div className="w-full h-full">
                    <div className="flex flex-col h-full gap-4 items-center w-full">
                        {details.map((detail, index) => (
                            <div
                                key={index}
                                className={`flex lg:flex-col lg:p-2 gap-4 items-center p-4 rounded-lg cursor-pointer transition hover:bg-primary-100 w-full flex-1 ${
                                    currentDetail === index
                                        ? "bg-primary-100"
                                        : ""
                                }`}
                                onClick={() => setCurrentDetail(index)}
                            >
                                <FontAwesomeIcon
                                    icon={detail.icon as IconProp}
                                    className="text-3xl text-secondary-100"
                                />
                                <div className="lg:text-center">
                                    <h2 className="text-xl lg:text-lg font-bold">
                                        {detail.title}
                                    </h2>
                                    <p className="text-lg lg:text-base font-light">
                                        {detail.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full h-full lg:hidden">
                    {transitions((style, item) => (
                        <animated.div
                            className="w-full h-full"
                            style={{ ...style }}
                        >
                            {details[item].illustration}
                        </animated.div>
                    ))}
                </div>
            </div>
        </section>
    )
}
