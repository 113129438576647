import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { ProjectDeleteForm } from "@/features/Projects/Forms/ProjectDeleteForm"
import { ProjectForm } from "@/features/Projects/Forms/ProjectForm"
import { Project } from "@/models/Project"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getLatestProjects, selectLatestProjects } from "../adminSlice"
import { FreezeProjectForm } from "./Form/FreezeProjectForm"
import { UnfreezeProjectForm } from "./Form/UnFreezeProjectForm"
import { LatestProjectsActions } from "./LatestProjectsActions"

export interface ProjectStats {
    project: Project
    projectUserCount: number
    storageUsage: number
}

interface LatestProjectsProps {
    minimized?: boolean
}

export const LatestProjects: React.FC<LatestProjectsProps> = ({
    minimized = false,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const latestProjects = useAppSelector(selectLatestProjects)
    const status = useAppSelector((state) => state.admin.status)
    const [selectedProject, setSelectedProject] = useState<ProjectStats | null>(
        null,
    )
    const [editProjectModal, setEditProjectModal] = useState(false)
    const [deleteProjectModal, setDeleteProjectModal] = useState(false)
    const [freezeProjectModal, setFreezeProjectModal] = useState(false)
    const [unfreezeProjectModal, setUnfreezeProjectModal] = useState(false)

    const columns = useMemo<MRT_ColumnDef<ProjectStats>[]>(
        () => [
            {
                accessorKey: "project.name",
                header: t("project"),
            },
            {
                accessorKey: "project.createdBy.fullName",
                header: t("created_by"),
            },
            ...(!minimized
                ? [
                      {
                          accessorKey: "projectUserCount",
                          header: t("users"),
                      },
                      {
                          accessorKey: "storageUsage",
                          header: t("storage"),
                          Cell: ({
                              renderedCellValue,
                              row,
                          }: {
                              renderedCellValue: any
                              row: any
                          }) => (
                              <div>
                                  {row.original.storageUsage.toFixed(2)} GB
                              </div>
                          ),
                      },
                      {
                          accessorKey: "project.progress",
                          header: t("progress"),
                          Cell: ({
                              renderedCellValue,
                              row,
                          }: {
                              renderedCellValue: any
                              row: any
                          }) => (
                              <div>
                                  {Math.round(row.original.project.progress)}%
                              </div>
                          ),
                      },
                      {
                          accessorKey: "project.isFrozen",
                          header: t("is_frozen"),
                          Cell: ({
                              renderedCellValue,
                              row,
                          }: {
                              renderedCellValue: any
                              row: any
                          }) => (
                              <div>
                                  {row.original.project.isFrozen
                                      ? t("yes")
                                      : t("no")}
                              </div>
                          ),
                      },
                  ]
                : []),
            {
                accessorKey: "project.createdAt",
                header: t("created_at"),
                filterVariant: "date-range",
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {t("intlDateTime", {
                            val: row.original.project.createdAt,
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </div>
                ),
            },
            {
                id: "actions",
                header: "",
                enableColumnActions: false,
                enableColumnFilters: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({ row }) => (
                    <LatestProjectsActions
                        project={row.original}
                        setSelectedProject={setSelectedProject}
                        setEditProjectModal={setEditProjectModal}
                        setDeleteProjectModal={setDeleteProjectModal}
                        setFreezeProjectModal={setFreezeProjectModal}
                        setUnfreezeProjectModal={setUnfreezeProjectModal}
                    />
                ),
            },
        ],
        [],
    )
    const table = useMantineReactTable<ProjectStats>({
        columns,
        data: latestProjects,
        enableColumnActions: !minimized,
        enableColumnFilters: !minimized,
        enablePagination: !minimized,
        enableSorting: !minimized,
        enableFullScreenToggle: true,
        initialState: {
            columnOrder: [
                "project.name",
                "project.createdBy.fullName",
                ...(minimized
                    ? []
                    : [
                          "projectUserCount",
                          "storageUsage",
                          "project.progress",
                          "project.isFrozen",
                      ]),
                "project.createdAt",
                "actions",
            ],
        },
        positionPagination: "top",
    })

    useEffect(() => {
        dispatch(getLatestProjects({}))
    }, [])

    return (
        <div className="flex flex-col w-full h-full">
            <WithLoader
                title={t("loading_projects")}
                statuses={[status.read]}
                className="w-full h-full"
            >
                <Scrollable>
                    <MantineReactTable table={table} />
                </Scrollable>
            </WithLoader>
            {selectedProject && (
                <div>
                    <NewModal
                        title={t("edit_project")}
                        isShown={editProjectModal}
                        closeModal={() => setEditProjectModal(false)}
                    >
                        <ProjectForm
                            project={selectedProject?.project}
                            closeModal={() => setEditProjectModal(false)}
                            forcePicturesUpdate
                        />
                    </NewModal>
                    <NewModal
                        title={t("delete_project")}
                        isShown={deleteProjectModal}
                        closeModal={() => setDeleteProjectModal(false)}
                    >
                        <ProjectDeleteForm
                            project={selectedProject.project}
                            closeModal={() => setDeleteProjectModal(false)}
                        />
                    </NewModal>
                    <NewModal
                        title={t("freeze_project")}
                        isShown={freezeProjectModal}
                        closeModal={() => setFreezeProjectModal(false)}
                    >
                        <FreezeProjectForm
                            project={selectedProject.project}
                            closeModal={() => setFreezeProjectModal(false)}
                        />
                    </NewModal>
                    <NewModal
                        title={t("unfreeze_project")}
                        isShown={unfreezeProjectModal}
                        closeModal={() => setUnfreezeProjectModal(false)}
                    >
                        <UnfreezeProjectForm
                            project={selectedProject.project}
                            closeModal={() => setUnfreezeProjectModal(false)}
                        />
                    </NewModal>
                </div>
            )}
        </div>
    )
}
