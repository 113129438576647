import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { Spoiler } from "@/components/Layouts/Spoiler"
import { Form, SubmitType } from "@/features/Form/Form"
import { IfcModel } from "@/models/Ifc"
import { ResourcePermissions } from "@/models/Permission"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { FileManagerPermissionInputs } from "../../FileManager/Forms/FileManagerPermissionInputs"
import { clearErrors, createIfcModel } from "../ifcSlice"

interface IfcModelFormProps {
    edit?: boolean
    ifcModel?: IfcModel
    closeModal: () => void
}

export const IfcModelForm: React.FC<IfcModelFormProps> = ({
    edit = false,
    ifcModel,
    closeModal,
}) => {
    const [localIfcModel, setLocalIfcModel] = useState<IfcModel>(
        edit && ifcModel ? ifcModel : new IfcModel(),
    )
    const errors = useAppSelector((state) => state.ifcViewer.errors)
    const [localResourcePermissions, setLocalResourcePermissions] =
        useState<ResourcePermissions>({
            read: { users: [], groups: [] },
            write: { users: [], groups: [] },
            notifications: { users: [], groups: [] },
        })
    const projectId = useParams<{ projectId: string }>().projectId
    const status = useAppSelector((state) => state.ifcViewer.status)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const handleSubmit = async () => {
        if (!edit) {
            const { type } = await dispatch(
                createIfcModel({
                    projectId,
                    name: localIfcModel.name,
                    description: localIfcModel.description,
                    permissions: localResourcePermissions,
                }),
            )
            if (type === "ifcViewer/createIfcModel/fulfilled") {
                closeModal()
            }
        }
    }
    return (
        <Form
            onSubmit={handleSubmit}
            statuses={[status.create, status.update]}
            loadingTitle={
                edit ? t("editing_new_model") : t("creating_new_model")
            }
            submitType={edit ? SubmitType.Save : SubmitType.Create}
            clearErrors={clearErrors}
        >
            <h1 className="text-xl font-bold">
                {edit ? t("editing_new_model") : t("creating_new_model")}
            </h1>
            <Input
                label={t("name")}
                name={t("name")}
                type="text"
                value={localIfcModel.name}
                className="w-full"
                onChange={(e) =>
                    setLocalIfcModel(
                        new IfcModel({
                            ...localIfcModel.toJson(),
                            name: e.target.value,
                        }),
                    )
                }
                errors={errors.name}
            />
            <Input
                label={t("description")}
                name={t("description")}
                type="text"
                value={localIfcModel.description}
                onChange={(e) =>
                    setLocalIfcModel(
                        new IfcModel({
                            ...localIfcModel.toJson(),
                            description: e.target.value,
                        }),
                    )
                }
                className="w-full"
                errors={errors.description}
            />
            <div className="mt-4 w-full">
                <Spoiler title={t("permissions")} icon="user-lock">
                    <FileManagerPermissionInputs
                        value={localResourcePermissions}
                        setValue={setLocalResourcePermissions}
                    />
                </Spoiler>
            </div>
        </Form>
    )
}
