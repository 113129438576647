import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { UserNotification } from "@/models/Notification"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    getUserAllNotifications,
    selectUserAllNotifications,
} from "../User/userSlice"

interface UserNotificationsProps {}

export const UserNotifications: React.FC<UserNotificationsProps> = () => {
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(selectUserAllNotifications)
    const { t } = useTranslation()
    const status = useAppSelector((state) => state.users.status)
    const [localNotifications, setLocalNotifications] =
        useState<UserNotification[]>(notifications)
    const [search, setSearch] = useState<string>("")
    const [filter, setFilter] = useState<"all" | "read" | "unread">("all")
    const [sort, setSort] = useState<"seen_date" | "receipt_date">(
        "receipt_date",
    )
    const [seen, setSeen] = useState<string[]>([])
    useEffect(() => {
        dispatch(getUserAllNotifications({}))
    }, [dispatch])
    useEffect(() => {
        setLocalNotifications(notifications)
    }, [notifications])
    useEffect(() => {
        let newNotifications = [...notifications]
        if (filter === "all") {
            newNotifications = [...notifications]
        } else if (filter === "read") {
            newNotifications = notifications.filter(
                (notification) => notification.seen,
            )
        } else if (filter === "unread") {
            newNotifications = notifications.filter(
                (notification) => !notification.seen,
            )
        }
        if (sort === "receipt_date") {
            newNotifications = newNotifications.sort((a, b) => {
                if (a.createdAt > b.createdAt) return -1
                if (a.createdAt < b.createdAt) return 1
                return 0
            })
        } else if (sort === "seen_date") {
            newNotifications = newNotifications.sort((a, b) => {
                if (a.seenOn > b.seenOn) return -1
                if (a.seenOn < b.seenOn) return 1
                return 0
            })
        }
        setLocalNotifications(newNotifications)
    }, [filter, notifications, sort])

    const addSeen = (id: string) => {
        if (seen.includes(id)) return
        setSeen([...seen, id])
    }
    return (
        <div className="h-full flex flex-col">
            <div className="flex justify-between items-center shadow-lg mb-5 p-4 rounded-lg border bg-secondary-100 lg:flex-wrap lg:gap-3">
                <div className="flex items-center gap-2 sm:flex-wrap">
                    <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon="search" />
                        <Input
                            value={search}
                            name="search"
                            type="text"
                            placeholder={t("search")}
                            onChange={(e) => setSearch(e.target.value)}
                            className="!mt-0"
                        />
                    </div>
                    <div className="flex gap-2 items-center">
                        <div
                            className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                filter === "all"
                                    ? "bg-primary-300 text-secondary-100"
                                    : "bg-secondary-100 text-primary-300 border border-primary-300"
                            }`}
                            onClick={() => setFilter("all")}
                        >
                            <FontAwesomeIcon icon="list" />
                            <p>{t("all")}</p>
                        </div>
                        <div
                            className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                filter === "unread"
                                    ? "bg-primary-300 text-secondary-100"
                                    : "bg-secondary-100 text-primary-300 border border-primary-300"
                            }`}
                            onClick={() => setFilter("unread")}
                        >
                            <FontAwesomeIcon icon="eye-low-vision" />
                            <p>{t("unread")}</p>
                        </div>
                        <div
                            className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                filter === "read"
                                    ? "bg-primary-300 text-secondary-100"
                                    : "bg-secondary-100 text-primary-300 border border-primary-300"
                            }`}
                            onClick={() => setFilter("read")}
                        >
                            <FontAwesomeIcon icon="eye" />
                            <p>{t("read")}</p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-4 sm:flex-col sm:items-start">
                    <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon="sort" />
                        <p className="font-bold">{t("sort_by")}:</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div
                            className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                sort === "receipt_date"
                                    ? "bg-primary-300 text-secondary-100"
                                    : "bg-secondary-100 text-primary-300 border border-primary-300"
                            }`}
                            onClick={() => setSort("receipt_date")}
                        >
                            <FontAwesomeIcon icon="eye" />
                            <p>{t("receipt_date")}</p>
                        </div>
                        <div
                            className={`flex justify-center items-center  px-3 py-2 gap-2 rounded-lg cursor-pointer transition hover:bg-primary-300 hover:text-secondary-100 ${
                                sort === "seen_date"
                                    ? "bg-primary-300 text-secondary-100"
                                    : "bg-secondary-100 text-primary-300 border border-primary-300"
                            }`}
                            onClick={() => setSort("seen_date")}
                        >
                            <FontAwesomeIcon icon="eye" />
                            <p>{t("seen_date")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow-lg bg-secondary-100 p-5 rounded-lg h-full overflow-hidden">
                <Scrollable height="100%" className="w-full">
                    <WithLoader
                        statuses={[status.read]}
                        title={t("loading_notifications")}
                    >
                        {localNotifications.length > 0 &&
                            localNotifications.map((notification) => {
                                return (
                                    <div
                                        key={notification.id}
                                        onMouseEnter={() =>
                                            addSeen(notification.id)
                                        }
                                    >
                                        {notification.getBody(
                                            notification.seen,
                                            true,
                                        )}
                                    </div>
                                )
                            })}
                    </WithLoader>
                </Scrollable>
            </div>
        </div>
    )
}
