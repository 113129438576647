import { useTranslation } from "react-i18next"

interface BudgetTrackingFeaturesProps {}

export const BudgetTrackingFeatures: React.FC<
    BudgetTrackingFeaturesProps
> = () => {
    const { t } = useTranslation()
    return (
        <section className="w-full h-full flex flex-col items-center justify-center px-14 py-8 lg:p-2">
            <h1 className="text-4xl font-bold uppercase text-center">
                {t("coming_soon")}!
            </h1>
        </section>
    )
}
