import { createAsyncThunkWithNotification } from "@/app/common"
import { SliceStatus } from "@/common/types"
import {
    PermissionGroup,
    PermissionGroupJsonInterface,
} from "@/models/Permission"
import {
    GeneralDelayStatistics,
    GeneralQualityStatistics,
    GeneralStatistics,
    TASK_STATISTICS_TYPES,
    TaskPerGroupStatistics,
    TaskPerUserStatistics,
    TaskStatistics,
} from "@/models/Statistics"
import { Task, TaskJsonInterface } from "@/models/Task"
import { User, UserInterface } from "@/models/User"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    getPermissionGroupInfoApi,
    getPermissionGroupsTasksStatisticsInProjectApi,
    getPermissionGroupStatisticsInProjectApi,
    getProjectGeneralDelayStatisticsApi,
    getProjectGeneralQualityStatisticsApi,
    getProjectGeneralStatisticsApi,
    getProjectTaskStatisticsPerGroupApi,
    getProjectTaskStatisticsPerUserApi,
    getUserInfoApi,
    getUsersTasksStatisticsInProjectApi,
    getUserStatisticsInProjectApi,
} from "./statisticsApi"

export const getProjectGeneralStatistics = createAsyncThunkWithNotification(
    "statistics/getProjectGeneralStatistics",
    async (projectId: string) => {
        const response = await getProjectGeneralStatisticsApi(projectId)
        return response
    },
)

export const getProjectGeneralDelayStatistics =
    createAsyncThunkWithNotification(
        "statistics/getProjectGeneralDelayStatistics",
        async (projectId: string) => {
            const response =
                await getProjectGeneralDelayStatisticsApi(projectId)
            return response
        },
    )

export const getProjectGeneralQualityStatistics =
    createAsyncThunkWithNotification(
        "statistics/getProjectGeneralQualityStatistics",
        async (projectId: string) => {
            const response =
                await getProjectGeneralQualityStatisticsApi(projectId)
            return response
        },
    )

export const getProjectTaskStatisticsPerUser = createAsyncThunkWithNotification(
    "statistics/getProjectTaskStatisticsPerUser",
    async (projectId: string) => {
        const response = await getProjectTaskStatisticsPerUserApi(projectId)
        return response
    },
)

export const getProjectTaskStatisticsPerGroup =
    createAsyncThunkWithNotification(
        "statistics/getProjectTaskStatisticsPerGroup",
        async (projectId: string) => {
            const response =
                await getProjectTaskStatisticsPerGroupApi(projectId)
            return response
        },
    )

export const getUserInfo = createAsyncThunkWithNotification(
    "statistics/getUserInfo",
    async ({ projectId, userId }: { projectId: string; userId: string }) => {
        const response = await getUserInfoApi(projectId, userId)
        return response
    },
)

export const getUserStatisticsInProject = createAsyncThunkWithNotification(
    "statistics/getUserStatisticsInProject",
    async ({ projectId, userId }: { projectId: string; userId: string }) => {
        const response = await getUserStatisticsInProjectApi(projectId, userId)
        return response
    },
)

export const getUsersTasksStatisticsInProject =
    createAsyncThunkWithNotification(
        "statistics/getUsersTasksStatisticsInProject",
        async ({
            projectId,
            userId,
            type,
        }: {
            projectId: string
            userId: string
            type: TASK_STATISTICS_TYPES
        }) => {
            const response = await getUsersTasksStatisticsInProjectApi(
                projectId,
                userId,
                type,
            )
            return response
        },
    )

export const getPermissionGroupInfo = createAsyncThunkWithNotification(
    "statistics/getPermissionGroupInfo",
    async ({ projectId, groupId }: { projectId: string; groupId: string }) => {
        const response = await getPermissionGroupInfoApi(projectId, groupId)
        return response
    },
)

export const getPermissionGroupStatisticsInProject =
    createAsyncThunkWithNotification(
        "statistics/getPermissionGroupStatisticsInProject",
        async ({
            projectId,
            groupId,
        }: {
            projectId: string
            groupId: string
        }) => {
            const response = await getPermissionGroupStatisticsInProjectApi(
                projectId,
                groupId,
            )
            return response
        },
    )

export const getPermissionGroupsTasksStatisticsInProject =
    createAsyncThunkWithNotification(
        "statistics/getPermissionGroupsTasksStatisticsInProject",
        async ({
            projectId,
            groupId,
            type,
        }: {
            projectId: string
            groupId: string
            type: TASK_STATISTICS_TYPES
        }) => {
            const response =
                await getPermissionGroupsTasksStatisticsInProjectApi(
                    projectId,
                    groupId,
                    type,
                )
            return response
        },
    )

interface StatisticsState {
    general: GeneralStatistics
    generalDelay: GeneralDelayStatistics
    generalQuality: GeneralQualityStatistics
    tasksPerUser: TaskPerUserStatistics[]
    tasksPerGroup: TaskPerGroupStatistics[]
    userInfo: UserInterface
    permissionGroupInfo: PermissionGroupJsonInterface
    statistics: TaskStatistics
    tasks: TaskJsonInterface[]
    status: {
        read: SliceStatus
        write: SliceStatus
    }
}

const initialState: StatisticsState = {
    general: {
        totalTasks: 0,
        projectProgress: 0,
    },
    generalDelay: {
        tasksOnTime: 0,
        tasksDelayedStart: 0,
        tasksDelayedEnd: 0,
        tasksDelayedBoth: 0,
    },
    generalQuality: {
        tasksApproved: 0,
        tasksWithIssues: 0,
        tasksApprovedWithIssues: 0,
        tasksApprovedWithoutIssues: 0,
    },
    tasksPerUser: [],
    tasksPerGroup: [],
    userInfo: new User().toJson(),
    statistics: {
        tasksCount: 0,
        tasksApproved: 0,
        tasksWithIssues: 0,
        tasksApprovedWithIssues: 0,
        tasksApprovedWithoutIssues: 0,
        tasksOnTime: 0,
        tasksDelayedStart: 0,
        tasksDelayedEnd: 0,
        tasksDelayedBoth: 0,
    },
    permissionGroupInfo: new PermissionGroup().toJson(),
    tasks: [],
    status: {
        read: SliceStatus.IDLE,
        write: SliceStatus.IDLE,
    },
}

const statisticsSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProjectGeneralStatistics.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectGeneralStatistics.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.general = action.payload.data.data
            })
            .addCase(getProjectGeneralStatistics.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getProjectGeneralDelayStatistics.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getProjectGeneralDelayStatistics.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.generalDelay = action.payload.data.data
                },
            )
            .addCase(getProjectGeneralDelayStatistics.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getProjectGeneralQualityStatistics.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getProjectGeneralQualityStatistics.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.generalQuality = action.payload.data.data
                },
            )
            .addCase(getProjectGeneralQualityStatistics.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getProjectTaskStatisticsPerUser.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getProjectTaskStatisticsPerUser.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.tasksPerUser = action.payload.data.data
                },
            )
            .addCase(getProjectTaskStatisticsPerUser.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getProjectTaskStatisticsPerGroup.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getProjectTaskStatisticsPerGroup.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.tasksPerGroup = action.payload.data.data
                },
            )
            .addCase(getProjectTaskStatisticsPerGroup.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getUserInfo.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.userInfo = action.payload.data.data
            })
            .addCase(getUserInfo.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getUserStatisticsInProject.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserStatisticsInProject.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.statistics = action.payload.data.data
            })
            .addCase(getUserStatisticsInProject.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getUsersTasksStatisticsInProject.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getUsersTasksStatisticsInProject.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.tasks = action.payload.data.data
                },
            )
            .addCase(getUsersTasksStatisticsInProject.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getPermissionGroupInfo.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getPermissionGroupInfo.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.permissionGroupInfo = action.payload.data.data
            })
            .addCase(getPermissionGroupInfo.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(getPermissionGroupStatisticsInProject.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getPermissionGroupStatisticsInProject.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.statistics = action.payload.data.data
                },
            )
            .addCase(
                getPermissionGroupStatisticsInProject.rejected,
                (state) => {
                    state.status.read = SliceStatus.FAILED
                },
            )
            .addCase(
                getPermissionGroupsTasksStatisticsInProject.pending,
                (state) => {
                    state.status.read = SliceStatus.LOADING
                },
            )
            .addCase(
                getPermissionGroupsTasksStatisticsInProject.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.tasks = action.payload.data.data
                },
            )
            .addCase(
                getPermissionGroupsTasksStatisticsInProject.rejected,
                (state) => {
                    state.status.read = SliceStatus.FAILED
                },
            )
    },
})

const selectUserInfoRaw = (state: { statistics: StatisticsState }) =>
    state.statistics.userInfo
export const selectUserInfo = createSelector(
    [selectUserInfoRaw],
    (userInfo) => new User(userInfo),
)

const selectPermissionGroupInfoRaw = (state: { statistics: StatisticsState }) =>
    state.statistics.permissionGroupInfo
export const selectPermissionGroupInfo = createSelector(
    [selectPermissionGroupInfoRaw],
    (permissionGroupInfo) => new PermissionGroup(permissionGroupInfo),
)

const selectStatisticsTasksRaw = (state: { statistics: StatisticsState }) =>
    state.statistics.tasks
export const selectStatisticsTasks = createSelector(
    [selectStatisticsTasksRaw],
    (tasks) => tasks.map((task) => new Task(task)),
)

export default statisticsSlice.reducer
