import { TaskStatus } from "../models/Task"

export enum UserNotificationType {
    TASK_UPDATED = "task_updated",
    TASK_DELETED = "task_deleted",

    TASK_ASSIGNED = "task_assigned",
    TASK_UNASSIGNED = "task_unassigned",
    TASK_REVIEWER_ASSIGNED = "task_reviewer_assigned",
    TASK_REVIEWER_REMOVED = "task_reviewer_removed",
    TASK_ASK_REVIEW = "task_ask_review",

    TASK_COMPLETED = "task_completed",
    TASK_HAS_ISSUE = "task_has_issue",
    TASK_PENDING_REVIEW = "task_pending_review",
    TASK_IN_PROGRESS = "task_in_progress",

    TASK_REVIEW_APPROVED = "task_review_approved",
    TASK_REVIEW_REJECTED = "task_review_rejected",

    TASK_ADD_COMMENT = "task_add_comment",
    TASK_REPLY_COMMENT = "task_reply_comment",

    TASK_SET_DEPENDENCY = "task_set_dependency",
    TASK_ADD_ATTACHMENT = "task_add_attachment",

    TASK_DELAYED_MANAGER = "task_delayed_manager",
    TASK_DELAYED_START = "task_delayed_start",
    TASK_DELAYED_END = "task_delayed_end",

    FOLDER_CREATED = "folder_created",
    FOLDER_DELETED = "folder_deleted",
    FOLDER_RENAMED = "folder_renamed",

    FILE_UPLOADED = "file_uploaded",
    FILE_DELETED = "file_deleted",
    FILE_RENAMED = "file_renamed",
}

export namespace AdditionalDataInterface {
    interface WithId {
        id: string
    }

    interface WithIdAndName extends WithId {
        name: string
    }
    interface ActionTaker extends WithId {
        firstName: string
        lastName: string
        profilePicture: {
            path: string
        }
    }
    interface WithProject {
        project: WithIdAndName
    }

    interface WithTask extends WithProject {
        task: WithIdAndName
    }

    interface WithTaskAndStatus extends WithTask {
        task: {
            id: string
            name: string
            oldStatus: TaskStatus
            newStatus: TaskStatus
        }
    }

    interface withFolder extends WithProject {
        folder: WithIdAndName
        parent: WithIdAndName | null
    }

    interface withFile extends WithProject {
        file: WithIdAndName
        parent: WithIdAndName | null
    }
    interface TaskData extends WithTask {
        assigner: ActionTaker
    }
    export interface TaskAssigned extends TaskData {}

    export interface TaskReviewerDesignated extends TaskData {}

    export interface TaskUnassigned extends TaskData {}

    export interface TaskReviewerRemoved extends TaskData {}

    export interface TaskDeleted extends WithTask {
        deletedBy: ActionTaker
    }

    export interface TaskUpdated extends WithTask {
        updatedBy: ActionTaker
    }

    export interface TaskAskedForReview extends WithTask {
        assignee: ActionTaker
    }

    export interface TaskCompleted extends WithTaskAndStatus {}

    export interface TaskHasIssue extends WithTaskAndStatus {}

    export interface TaskPendingReview extends WithTaskAndStatus {}

    export interface TaskInProgress extends WithTaskAndStatus {}

    export interface TaskReviewApproved extends WithTask {
        reviewer: ActionTaker
    }

    export interface TaskReviewRejected extends WithTask {
        reviewer: ActionTaker
    }

    export interface TaskAddComment extends WithTask {
        commenter: ActionTaker
        comment: {
            id: string
        }
    }

    export interface TaskRepliedComment extends WithTask {
        commenter: ActionTaker
        comment: {
            id: string
        }
        reply: {
            id: string
        }
    }

    export interface TaskDependencySet extends WithTask {
        setBy: ActionTaker
        dependency: {
            id: string
            name: string
        }
    }

    export interface TaskAttachmentAdded extends WithTask {
        attachment: {
            id: string
            name: string
        }
        uploadedBy: ActionTaker
    }

    export interface TaskDelayed extends WithTask {}

    export interface FolderCreated extends withFolder {
        createdBy: ActionTaker
    }

    export interface FileUploaded extends withFile {
        uploadedBy: ActionTaker
    }

    export interface FileDeleted extends withFile {
        deletedBy: ActionTaker
    }
    export interface FileRenamed extends withFile {
        renamedBy: ActionTaker
    }

    export interface FolderDeleted extends withFolder {
        deletedBy: ActionTaker
    }

    export interface FolderRenamed extends withFolder {
        renamedBy: ActionTaker
        folder: {
            id: string
            name: string
            oldName: string
        }
    }
}

export interface NotificationTypeAdditionalDataMap {
    [UserNotificationType.TASK_UPDATED]: AdditionalDataInterface.TaskUpdated
    [UserNotificationType.TASK_DELETED]: AdditionalDataInterface.TaskDeleted

    [UserNotificationType.TASK_ASSIGNED]: AdditionalDataInterface.TaskAssigned
    [UserNotificationType.TASK_REVIEWER_ASSIGNED]: AdditionalDataInterface.TaskReviewerDesignated
    [UserNotificationType.TASK_UNASSIGNED]: AdditionalDataInterface.TaskUnassigned
    [UserNotificationType.TASK_REVIEWER_REMOVED]: AdditionalDataInterface.TaskReviewerRemoved
    [UserNotificationType.TASK_ASK_REVIEW]: AdditionalDataInterface.TaskAskedForReview

    [UserNotificationType.TASK_COMPLETED]: AdditionalDataInterface.TaskCompleted
    [UserNotificationType.TASK_HAS_ISSUE]: AdditionalDataInterface.TaskHasIssue
    [UserNotificationType.TASK_PENDING_REVIEW]: AdditionalDataInterface.TaskPendingReview
    [UserNotificationType.TASK_IN_PROGRESS]: AdditionalDataInterface.TaskInProgress

    [UserNotificationType.TASK_REVIEW_APPROVED]: AdditionalDataInterface.TaskReviewApproved
    [UserNotificationType.TASK_REVIEW_REJECTED]: AdditionalDataInterface.TaskReviewRejected

    [UserNotificationType.TASK_ADD_COMMENT]: AdditionalDataInterface.TaskAddComment
    [UserNotificationType.TASK_REPLY_COMMENT]: AdditionalDataInterface.TaskRepliedComment

    [UserNotificationType.TASK_SET_DEPENDENCY]: AdditionalDataInterface.TaskDependencySet
    [UserNotificationType.TASK_ADD_ATTACHMENT]: AdditionalDataInterface.TaskAttachmentAdded

    [UserNotificationType.TASK_DELAYED_MANAGER]: AdditionalDataInterface.TaskDelayed // FIXME: implement this
    [UserNotificationType.TASK_DELAYED_START]: AdditionalDataInterface.TaskDelayed // FIXME: implement this
    [UserNotificationType.TASK_DELAYED_END]: AdditionalDataInterface.TaskDelayed // FIXME: implement this

    [UserNotificationType.FOLDER_CREATED]: AdditionalDataInterface.FolderCreated
    [UserNotificationType.FOLDER_DELETED]: AdditionalDataInterface.FolderDeleted
    [UserNotificationType.FOLDER_RENAMED]: AdditionalDataInterface.FolderRenamed
    [UserNotificationType.FILE_UPLOADED]: AdditionalDataInterface.FileUploaded
    [UserNotificationType.FILE_DELETED]: AdditionalDataInterface.FileDeleted
    [UserNotificationType.FILE_RENAMED]: AdditionalDataInterface.FileRenamed
}
