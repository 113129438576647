import { useAppDispatch } from "@/app/hooks"
import {
    Notification as NotificationModel,
    NotificationType,
} from "@/models/Notification"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpring } from "@react-spring/web"
import { useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { removeNotification } from "./AppNotificationsSlice"

interface NotificationMapping {
    [key: string]: { color: string; icon: IconProp }
}

const notificationMapping: NotificationMapping = {
    [NotificationType.SUCCESS]: {
        color: "rgb(34, 197, 94)",
        icon: "check-circle",
    },
    [NotificationType.WARNING]: {
        color: "rgb(234, 179, 8)",
        icon: "exclamation-circle",
    },
    [NotificationType.ERROR]: {
        color: "rgb(239, 68, 68)",
        icon: "times-circle",
    },
    [NotificationType.INFO]: { color: "rgb(68 142 239)", icon: "info-circle" },
    [NotificationType.INVALID]: { color: "rgb(0, 0, 0)", icon: "anchor-lock" },
}

const Notification = ({
    notification,
}: {
    notification: NotificationModel
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { color, icon } =
        notificationMapping[notification?.type ?? NotificationType.INVALID]
    const [containerSprings, containerApi] = useSpring(() => ({
        from: { x: 0, opacity: 1 },
    }))
    const closeNotification = () => {
        containerApi.start({
            opacity: 0,
            x: 400,
            onRest: () => {
                dispatch(removeNotification(notification.id))
            },
        })
    }
    const [loadingSpring, loadingApi] = useSpring(() => ({
        from: { width: "0%" },
        to: { width: "100%" },
        config: { duration: 3000 },
        onRest: closeNotification,
    }))
    const popupSpring = useSpring({
        from: { x: 400, opacity: 0 },
        to: { x: 0, opacity: 1 },
    })
    useLayoutEffect(() => {
        loadingApi.start()
    })
    return (
        <animated.div style={popupSpring}>
            <animated.div
                className={`min-w-[350px] h-fit bg-secondary-100 p-4 rounded-lg border-2 mt-4 relative`}
                style={{ borderColor: color, ...containerSprings }}
                onMouseEnter={() => loadingApi.pause()}
                onMouseLeave={() => loadingApi.resume()}
            >
                <span
                    className="absolute right-0 top-0 mr-2 mt-1 transition hover:scale-125 cursor-pointer"
                    onClick={closeNotification}
                >
                    <FontAwesomeIcon icon="close" size="1x" color={color} />
                </span>
                <span className="flex items-center">
                    <FontAwesomeIcon
                        icon={icon}
                        size="1x"
                        className="mr-2"
                        color={color}
                    />
                    <h3
                        className={`text-2xl font-primary-300 border-b-2 w-fit`}
                        style={{ borderColor: color, color: color }}
                    >
                        {t(notification.title)}
                    </h3>
                </span>
                <p className="mt-2 text-primary-300">{t(notification.body)}</p>
                {notification.errors && (
                    <ul>
                        {Object.values(notification.errors).map(
                            (error, index) => (
                                <li
                                    className="text-sm mt-1 text-primary-300"
                                    key={index}
                                >
                                    -{" "}
                                    {Object.values(error).map((err) => t(err))}
                                </li>
                            ),
                        )}
                    </ul>
                )}
                <animated.div
                    className="h-1 mt-2 rounded-3xl"
                    style={{ ...loadingSpring, backgroundColor: color }}
                ></animated.div>
            </animated.div>
        </animated.div>
    )
}

export default Notification
