import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { SliceStatus } from "../common/types"
import { WithLoader } from "../components/Loaders/WithLoader"
import { verifyEmail } from "../features/Authentication/authSlice"

interface VerifyEmailProps {}

export const VerifyEmail: React.FC<VerifyEmailProps> = () => {
    const [queryParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.auth.status)
    const { t } = useTranslation()
    useEffect(() => {
        const token = queryParams.get("token")
        if (token) {
            dispatch(verifyEmail(token))
        }
    }, [queryParams, dispatch])
    return (
        <div>
            <div className="relative bg-white text-primary-300 rounded-lg shadow-lg z-40 w-[400px] ">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6">
                    <div className="max-w-3xl mx-auto text-center">
                        <WithLoader
                            statuses={[status.read]}
                            title={t("verifying_your_email")}
                        >
                            <p className="mt-4 text-lg text-gray-500">
                                {status.read === SliceStatus.IDLE
                                    ? t("your_email_has_been_verified")
                                    : status.read === SliceStatus.FAILED
                                      ? t("failed_to_verify_your_email")
                                      : ""}
                            </p>
                        </WithLoader>
                    </div>
                </div>
            </div>
        </div>
    )
}
