import { RootState } from "@/app/store"
import { Notification, NotificationInterface } from "@/models/Notification"
import { createSelector, createSlice } from "@reduxjs/toolkit"

export interface NotificationsState {
    notifications: NotificationInterface[]
}

const initialState: NotificationsState = {
    notifications: [],
}

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotification: (state, action) => {
            const newNotification = new Notification({
                ...action.payload,
                id: state.notifications.length + new Date().getTime(),
            })
            state.notifications.push(newNotification.toJson())
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload,
            )
        },
    },
})

const selectRawNotifications = (state: RootState) =>
    state.notifications.notifications

export const selectNotifications = createSelector(
    [selectRawNotifications],
    (notifications) =>
        notifications.map((notification) => new Notification(notification)),
)

export default notificationsSlice.reducer
export const { addNotification, removeNotification } =
    notificationsSlice.actions
