import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { FileIcon } from "../FileIcon"
import {
    getProjectRootFolders,
    resetFolder,
    selectFolderTree,
} from "../fileManagerSlice"
import { FolderIcon } from "../FolderIcon"
import { QuickAccess } from "../QuickAccess"

interface BrowserDefaultProps {}

export const BrowserDefault: React.FC<BrowserDefaultProps> = () => {
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const status = useAppSelector((state) => state.fileManager.manyStatus)
    const folderTree = useAppSelector(selectFolderTree)
    const browserContainerRef = useRef<HTMLDivElement>(null)
    const highlightedRef = useRef<HTMLDivElement>(null)
    const [queryParams] = useSearchParams()
    const [highlightedResource, setHighlightedResource] = useState<string>("")
    useEffect(() => {
        dispatch(getProjectRootFolders(projectId))
        dispatch(resetFolder())
    }, [])
    useEffect(() => {
        const resource = queryParams.get("resource")
        if (resource) {
            setHighlightedResource(resource)
            if (browserContainerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        browserContainerRef.current.getBoundingClientRect()
                    browserContainerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current])
    return (
        <Scrollable height="100%" innerRef={browserContainerRef}>
            <QuickAccess />
            <div>
                <div className="p-2">
                    <h1 className="text-2xl font-bold">{t("files")}:</h1>
                </div>
                <WithLoader
                    statuses={[status.read]}
                    title={t("loading_files")}
                    className="w-full h-full flex flex-wrap overflow-hidden"
                >
                    <div className="flex flex-wrap gap-2 sm:justify-evenly w-full h-full">
                        {folderTree.children.map((folder) => (
                            <FolderIcon
                                key={folder.id}
                                folder={folder}
                                highlighted={highlightedResource === folder.id}
                                innerRef={
                                    highlightedResource === folder.id
                                        ? highlightedRef
                                        : null
                                }
                            />
                        ))}
                        {folderTree.files.map((file) => (
                            <FileIcon
                                key={file.id}
                                file={file}
                                highlighted={highlightedResource === file.id}
                                innerRef={
                                    highlightedResource === file.id
                                        ? highlightedRef
                                        : null
                                }
                            />
                        ))}
                    </div>

                    {folderTree.files.length === 0 &&
                        folderTree.children.length === 0 && (
                            <div className="p-2 opacity-60 text-primary-300 w-full h-full flex flex-col gap-3 justify-center items-center">
                                <FontAwesomeIcon icon="search" size="3x" />
                                {t("no_items_found")}
                            </div>
                        )}
                </WithLoader>
            </div>
        </Scrollable>
    )
}
