import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { UsersOrGroupsInput } from "@/components/Inputs/UsersOrGroupsInput"
import { ResourcePermissions } from "@/models/Permission"
import { t } from "i18next"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getProjectUsersById, selectProjectUsers } from "../../projectsSlice"
import {
    getProjectGroups,
    selectProjectGroups,
} from "../../UserManagement/userManagementSlice"

interface FileManagerPermissionInputsProps {
    value: ResourcePermissions
    setValue: Dispatch<SetStateAction<ResourcePermissions>>
}

export const FileManagerPermissionInputs: React.FC<
    FileManagerPermissionInputsProps
> = ({ value, setValue }) => {
    const dispatch = useAppDispatch()
    const projectUsers = useAppSelector(selectProjectUsers)
    const projectGroups = useAppSelector(selectProjectGroups)
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])
    return (
        <div className="flex flex-col gap-3">
            <div>
                <p className="font-bold">{t("who_can_read")}: </p>
                <UsersOrGroupsInput
                    value={value.read}
                    setValue={(value) =>
                        setValue((prev: ResourcePermissions) => {
                            return {
                                ...prev,
                                read: value,
                            }
                        })
                    }
                    users={projectUsers}
                    groups={projectGroups}
                    zIndex={"z-40"}
                />
            </div>
            <div>
                <p className="font-bold">{t("who_can_write")}: </p>
                <UsersOrGroupsInput
                    value={value.write}
                    setValue={(value) => {
                        setValue((prev: ResourcePermissions) => {
                            return {
                                ...prev,
                                write: value,
                            }
                        })
                    }}
                    users={projectUsers}
                    groups={projectGroups}
                    zIndex={"z-30"}
                />
            </div>
            <div>
                <p className="font-bold">{t("who_receives_notifications")}: </p>
                <UsersOrGroupsInput
                    value={value.notifications}
                    setValue={(value) => {
                        setValue((prev: ResourcePermissions) => {
                            return {
                                ...prev,
                                notifications: value,
                            }
                        })
                    }}
                    users={projectUsers}
                    groups={projectGroups}
                    zIndex={"z-20"}
                />
            </div>
        </div>
    )
}
