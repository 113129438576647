import { axiosAuthenticated } from "@/common/axios"
import {
    PermissionGroupCreatePayload,
    PermissionGroupJsonInterface,
    PermissionType,
} from "@/models/Permission"
import { ProjectJsonInterface } from "@/models/Project"

export const getProjectGroupsApi = (projectId: string) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/groups`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectGroupByIdAndProjectIdApi = (
    projectId: string,
    groupId: string,
) => {
    return new Promise<{ data: PermissionGroupJsonInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .get(`/projects/${projectId}/groups/${groupId}`)
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const createPermissionGroupApi = (
    projectId: string,
    createPayload: PermissionGroupCreatePayload,
) => {
    return new Promise<{ data: PermissionGroupJsonInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .post(`/projects/${projectId}/groups/create`, createPayload)
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const updatePermissionGroupApi = (
    projectId: string,
    groupId: string,
    createPayload: PermissionGroupCreatePayload,
) => {
    return new Promise<{ data: PermissionGroupJsonInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .put(`/projects/${projectId}/groups/${groupId}`, createPayload)
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const addUsersToPermissionGroupApi = (
    projectId: string,
    groupId: string,
    userEmails: string[],
    specialPermissions: PermissionType[] = [],
) => {
    return new Promise<{ data: PermissionGroupJsonInterface }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .post(`/projects/${projectId}/groups/${groupId}/add`, {
                    userEmails,
                    specialPermissions,
                })
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const deletePermissionGroupApi = (
    projectId: string,
    groupId: string,
) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/projects/${projectId}/groups/${groupId}/delete`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeUserFromPermissionGroupApi = (
    projectId: string,
    groupId: string,
    userId: string,
) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .delete(
                `/projects/${projectId}/groups/${groupId}/users/${userId}/remove`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const inviteUsersToProjectApi = (
    projectId: string,
    userEmails: string[],
) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/users/invite`, { userEmails })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeUserFromProjectApi = (projectId: string, userId: string) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/projects/${projectId}/users/${userId}/remove`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const setUserProjectPermissionsApi = (
    projectId: string,
    userId: string,
    permissions: PermissionType[],
) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/users/${userId}/permissions`, {
                permissions,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectInvitationsApi = (projectId: string) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/invitations`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserPermissionsInProjectApi = (
    projectId: string,
    userId: string,
) => {
    return new Promise((resolve, reject) => {
        axiosAuthenticated()
            .get(`/project/${projectId}/permissions/user/${userId}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
