import { BaseModel, BaseModelInterface } from "./BaseModel"
import { CURRENCY } from "./Finance"
import { User, UserInterface } from "./User"

export interface PricingPlanUpdatePayload {
    name?: string
    price?: number
    features?: string[]
    currency?: CURRENCY
    numberOfUsers?: number
    numberOfProjects?: number
    numberOfEmails?: number
    isActive?: boolean
    isDefault?: boolean
}

export interface PricingPlanInterface extends BaseModelInterface {
    name: string
    price: number
    features: string[]
    currency: CURRENCY
    numberOfUsers: number
    numberOfProjects: number
    numberOfEmails: number
    storage: number
    isDefault: boolean
    users: UserInterface[]
}

export class PricingPlan extends BaseModel {
    name: string
    price: number
    features: string[]
    currency: CURRENCY
    numberOfUsers: number
    numberOfProjects: number
    numberOfEmails: number
    storage: number
    isDefault: boolean
    users: User[]

    constructor(data?: PricingPlanInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.price = data?.price ?? 0
        this.features = data?.features ?? []
        this.currency = data?.currency ?? CURRENCY.USD
        this.numberOfUsers = data?.numberOfUsers ?? 0
        this.numberOfProjects = data?.numberOfProjects ?? 0
        this.numberOfEmails = data?.numberOfEmails ?? 0
        this.storage = data?.storage ?? 0
        this.isDefault = data?.isDefault ?? false
        this.users = data?.users ? data.users.map((user) => new User(user)) : []
    }

    createPayload() {
        return {
            name: this.name,
            price: this.price,
            features: this.features,
            currency: this.currency,
            numberOfUsers: this.numberOfUsers,
            numberOfProjects: this.numberOfProjects,
            numberOfEmails: this.numberOfEmails,
            storage: this.storage,
        }
    }

    updatePayload() {
        return {
            name: this.name,
            price: this.price,
            features: this.features,
            currency: this.currency,
            numberOfUsers: this.numberOfUsers,
            numberOfProjects: this.numberOfProjects,
            numberOfEmails: this.numberOfEmails,
            storage: this.storage,
            isDefault: this.isDefault,
        }
    }

    toJson(): PricingPlanInterface {
        return {
            ...super.toJson(),
            name: this.name,
            price: this.price,
            features: this.features,
            currency: this.currency,
            numberOfUsers: this.numberOfUsers,
            numberOfProjects: this.numberOfProjects,
            numberOfEmails: this.numberOfEmails,
            storage: this.storage,
            isDefault: this.isDefault,
            users: this.users.map((user) => user.toJson()),
        }
    }
}

export interface UserPricingPlanInterface extends BaseModelInterface {
    user: UserInterface
    pricingPlan: PricingPlanInterface
    expiresAt: string
    numberOfUsers: number
    numberOfProjects: number
    numberOfEmails: number
    storage: number
    isActive: boolean
}

export class UserPricingPlan extends BaseModel {
    user: User
    pricingPlan: PricingPlan
    expiresAt: Date
    numberOfUsers: number
    numberOfProjects: number
    numberOfEmails: number
    storage: number
    isActive: boolean

    constructor(data?: UserPricingPlanInterface) {
        super(data)
        this.user = data?.user ? new User(data.user) : new User()
        this.pricingPlan = data?.pricingPlan
            ? new PricingPlan(data.pricingPlan)
            : new PricingPlan()
        this.expiresAt = data?.expiresAt ? new Date(data.expiresAt) : new Date()
        this.numberOfUsers = data?.numberOfUsers ?? 0
        this.numberOfProjects = data?.numberOfProjects ?? 0
        this.numberOfEmails = data?.numberOfEmails ?? 0
        this.storage = data?.storage ?? 0
        this.isActive = data?.isActive ?? false
    }

    createPayload() {
        return {
            user: this.user.id,
            pricingPlan: this.pricingPlan.id,
            expiresAt: this.expiresAt,
            numberOfUsers: this.numberOfUsers,
            numberOfProjects: this.numberOfProjects,
            numberOfEmails: this.numberOfEmails,
            storage: this.storage,
        }
    }

    toJson(): UserPricingPlanInterface {
        return {
            ...super.toJson(),
            user: this.user.toJson(),
            pricingPlan: this.pricingPlan.toJson(),
            expiresAt: this.expiresAt.toISOString(),
            numberOfUsers: this.numberOfUsers,
            numberOfProjects: this.numberOfProjects,
            numberOfEmails: this.numberOfEmails,
            storage: this.storage,
            isActive: this.isActive,
        }
    }
}
