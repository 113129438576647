import { axiosAuthenticated } from "@/common/axios"
import { PlanningJsonInterface } from "@/models/Planning"
import {
    TaskDependenciesPayload,
    TaskJsonInterface,
    TaskPayload,
} from "@/models/Task"

export const getPlanningsByProjectIdApi = (projectId: string) => {
    return new Promise<{ data: PlanningJsonInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/plannings`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deletePlanningApi = (projectId: string, planningId: string) => {
    return new Promise<{ data: PlanningJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/projects/${projectId}/plannings/${planningId}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const editPlanningApi = (
    projectId: string,
    planningId: string,
    updatePayload: any,
) => {
    return new Promise<{ data: PlanningJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(
                `/projects/${projectId}/plannings/${planningId}`,
                updatePayload,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getPlanningTaskTreeApi = (
    projectId: string,
    planningId: string,
) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/plannings/${planningId}/task-tree`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskByIdApi = (
    projectId: string,
    planningId: string,
    taskId: string,
) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/plannings/${planningId}/tasks/task/${taskId}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskSiblingsByIdApi = (
    projectId: string,
    planningId: string,
    taskId: string,
) => {
    return new Promise<{ data: TaskJsonInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/plannings/${planningId}/tasks/${taskId}/siblings`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateTaskByIdApi = (
    projectId: string,
    taskId: string,
    updatePayload: TaskPayload,
) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/tasks/${taskId}`, updatePayload)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const setTaskDependenciesApi = (
    projectId: string,
    planningId: string,
    taskId: string,
    updatePayload: TaskDependenciesPayload[],
) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(
                `/projects/${projectId}/plannings/${planningId}/tasks/${taskId}/dependencies`,
                updatePayload,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createPlanningApi = (
    createPlanningPayload: FormData,
    projectId: string,
) => {
    return new Promise<{ data: PlanningJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/plannings/create`,
                createPlanningPayload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const setPlanningAsMainApi = (projectId: string, planningId: string) => {
    return new Promise<{ data: PlanningJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/plannings/${planningId}/set-main`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
