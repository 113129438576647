import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "./Input"

interface ArrayInputProps {
    label: string
    value: string[]
    onChange: (value: string[]) => void
    required?: boolean
    name: string
    type: string
    className?: string
}

export const ArrayInput: React.FC<ArrayInputProps> = ({
    label,
    value,
    onChange,
    required,
    name,
    type,
    className,
}) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                {value.length < 1 && (
                    <label
                        className="font-medium text-sm text-primary-300"
                        htmlFor={name}
                    >
                        {label}
                    </label>
                )}
                <div className="flex gap-2 items-end flex-wrap">
                    {value.map((item, index) => (
                        <div className="flex items-end gap-1" key={index}>
                            <Input
                                key={index}
                                className={`${className}`}
                                label={label}
                                value={item}
                                onChange={(e) => {
                                    const newValue = [...value]
                                    newValue[index] = e.target.value
                                    onChange(newValue)
                                }}
                                required={required}
                                name={name}
                                type={type}
                            />
                            <div
                                className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                                onClick={() => {
                                    const newValue = [...value]
                                    newValue.splice(index, 1)
                                    onChange(newValue)
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="minus"
                                    className="cursor-pointer"
                                />
                            </div>
                        </div>
                    ))}
                    <div
                        className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                        onClick={() => onChange([...value, ""])}
                    >
                        <FontAwesomeIcon
                            icon="plus"
                            className="cursor-pointer"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
