import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { TaskActivityComponent } from "./TaskActivity"
import { getTaskActivities, selectTask } from "./tasksSlice"

interface TaskActivityProps {}

export const TaskActivity: React.FC<TaskActivityProps> = () => {
    const { t } = useTranslation()
    const task = useAppSelector(selectTask)
    const status = useAppSelector((state) => state.tasks.status)
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    useEffect(() => {
        dispatch(getTaskActivities({ projectId, taskId }))
    }, [status.update])
    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col">
            <h1 className="text-2xl font-bold text-nowrap">{t("activity")}</h1>
            <WithLoader
                statuses={[status.read]}
                title={t("loading_activities")}
            >
                {task.activities.length > 0 ? (
                    <Scrollable height="200px" className="flex flex-col gap-2">
                        {task.activities.map((activity) => (
                            <TaskActivityComponent
                                key={activity.id}
                                activity={activity}
                            />
                        ))}
                    </Scrollable>
                ) : (
                    <div className="w-full h-full flex justify-center items-center opacity-20 p-5">
                        <h1 className="text-xl flex flex-col items-center">
                            <FontAwesomeIcon
                                icon="list-check"
                                className="text-6xl"
                            />
                            <p>{t("no_activities_yet")}...</p>
                        </h1>
                    </div>
                )}
            </WithLoader>
        </div>
    )
}
