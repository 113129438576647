import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Form, SubmitType } from "@/features/Form/Form"
import {
    getCurrentUserPermissionsInProject,
    selectUserPermissions,
} from "@/features/User/userSlice"
import {
    MODULE_PERMISSIONS,
    PermissionGroup,
    PermissionType,
} from "@/models/Permission"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { GroupCard } from "./GroupCard"
import { ModulePermission } from "./ModulePermission"
import {
    clearErrors,
    createPermissionGroup,
    getProjectGroups,
    selectProjectGroups,
} from "./userManagementSlice"

interface GroupsProps {}

export const Groups: React.FC<GroupsProps> = () => {
    const status = useAppSelector((state) => state.projects.status)
    const userManagementStatus = useAppSelector(
        (state) => state.userManagement.status,
    )
    const [showCreate, setShowCreate] = useState<boolean>(false)
    const [localGroup, setLocalGroup] = useState<PermissionGroup>(
        new PermissionGroup(),
    )
    const [groupPermissions, setGroupPermissions] = useState<PermissionType[]>(
        [],
    )
    const groups = useAppSelector(selectProjectGroups)
    const userPermissions = useAppSelector(selectUserPermissions)
    const errors = useAppSelector((state) => state.userManagement.errors)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    useEffect(() => {
        dispatch(getProjectGroups(projectId))
        dispatch(getCurrentUserPermissionsInProject(projectId))
    }, [])
    useEffect(() => {
        setLocalGroup(
            new PermissionGroup({
                ...localGroup.toJson(),
                permissions: groupPermissions,
            }),
        )
    }, [groupPermissions])
    const createGroup = async () => {
        const { type } = await dispatch(
            createPermissionGroup({
                projectId,
                createPayload: localGroup.createPayload(),
            }),
        )
        if (type === createPermissionGroup.fulfilled.type) {
            setLocalGroup(new PermissionGroup())
            setShowCreate(false)
        }
    }
    return (
        userPermissions.hasOneOfPermissions([
            MODULE_PERMISSIONS.UserManager.GroupCreate,
            MODULE_PERMISSIONS.UserManager.GroupDelete,
            MODULE_PERMISSIONS.UserManager.GroupEdit,
            MODULE_PERMISSIONS.UserManager.GroupRead,
        ]) && (
            <div className="bg-secondary-100 rounded-lg shadow-lg p-4 flex flex-col h-full gap-2">
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="flex justify-between items-center">
                        <h2 className="font-bold text-2xl">{t("groups")}:</h2>
                        {userPermissions.hasAllPermissions([
                            MODULE_PERMISSIONS.UserManager.GroupCreate,
                        ]) && (
                            <Button
                                name={t("create_group")}
                                icon="user-group"
                                onClick={() => setShowCreate(true)}
                            />
                        )}
                    </div>
                    <Scrollable height="100%">
                        <WithLoader
                            statuses={[status.read]}
                            title={t("loading_groups")}
                            className="!w-full !h-full"
                        >
                            <div className="flex flex-wrap gap-4 justify-normal pb-5 w-full">
                                {groups.length > 0 &&
                                    groups.map((group) => (
                                        <GroupCard
                                            projectGroup={group}
                                            key={group.id}
                                        />
                                    ))}
                                {groups.length === 0 && (
                                    <div className="w-full h-full flex justify-center items-center gap-2 opacity-50">
                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                        <p>{t("no_groups_found")}</p>
                                    </div>
                                )}
                            </div>
                        </WithLoader>
                    </Scrollable>
                </div>
                <NewModal
                    title={t("create_new_group")}
                    isShown={showCreate}
                    closeModal={() => setShowCreate(false)}
                >
                    <Form
                        statuses={[userManagementStatus.create]}
                        loadingTitle={t("creating_group")}
                        onSubmit={createGroup}
                        clearErrors={clearErrors}
                        submitType={SubmitType.Create}
                        onCancel={() => setShowCreate(false)}
                    >
                        <div className="flex flex-col gap-4 p-4 w-[400px]">
                            <h2 className="text-2xl font-bold">
                                {t("create_new_group")}
                            </h2>
                            <div className="flex flex-col gap-5">
                                <Input
                                    label={t("name")}
                                    name={t("name")}
                                    type="text"
                                    placeholder={t("enter_group_name") + "..."}
                                    value={localGroup.name}
                                    onChange={(e) =>
                                        setLocalGroup(
                                            new PermissionGroup({
                                                ...localGroup.toJson(),
                                                name: e.target.value,
                                            }),
                                        )
                                    }
                                    className="!m-0"
                                    errors={errors.name}
                                />
                                <Input
                                    label={t("description")}
                                    name={t("description")}
                                    type="text"
                                    placeholder={
                                        t("enter_group_description") + "..."
                                    }
                                    value={localGroup.description}
                                    onChange={(e) =>
                                        setLocalGroup(
                                            new PermissionGroup({
                                                ...localGroup.toJson(),
                                                description: e.target.value,
                                            }),
                                        )
                                    }
                                    className="!m-0"
                                    errors={errors.description}
                                />
                                <div>
                                    <p className="font-bold">
                                        {t("permissions")}:
                                    </p>
                                    <ModulePermission
                                        permissions={groupPermissions}
                                        setPermissions={setGroupPermissions}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </NewModal>
            </div>
        )
    )
}
