import { useEffect, useRef } from "react"
import { Scrollable } from "../Layouts/Scrollable"

export enum POPUP_TYPES {
    RIGHT_CLICK = "right-click",
    LEFT_CLICK = "left-click",
    HOVER = "hover",
}

interface PopupProps {
    parentRef: React.RefObject<HTMLDivElement>
    children: React.ReactNode
    type: POPUP_TYPES
    isShown: boolean
    closePopup: () => void
    className?: string
}

export const Popup: React.FC<PopupProps> = ({
    parentRef,
    children,
    type,
    isShown,
    closePopup,
    className,
}) => {
    const popupRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isShown) {
            const parent = parentRef.current
            const popup = popupRef.current
            if (parent && popup) {
                const parentRect = parent.getBoundingClientRect()
                const popupRect = popup.getBoundingClientRect()
                let top = 0
                let left = 0
                switch (type) {
                    case POPUP_TYPES.RIGHT_CLICK:
                        top = parentRect.top + parentRect.height
                        left = parentRect.left + parentRect.width
                        break
                    case POPUP_TYPES.LEFT_CLICK:
                        top = parentRect.top + parentRect.height
                        left = parentRect.left
                        break
                    case POPUP_TYPES.HOVER:
                        top = parentRect.top + parentRect.height
                        left = parentRect.left
                        break
                }
                // handle screen overflow
                if (top + popupRect.height > window.innerHeight) {
                    top = window.innerHeight - popupRect.height
                }
                if (left + popupRect.width > window.innerWidth) {
                    left = window.innerWidth - popupRect.width
                }
                popup.style.top = `${top}px`
                popup.style.left = `${left}px`
            }
        }
    }, [isShown, parentRef, type])

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(e.target as Node)
            ) {
                closePopup()
            }
        }
        document.addEventListener("mousedown", handleClick)
        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, [closePopup])

    return (
        <div
            ref={popupRef}
            className={`fixed bg-white shadow-lg rounded-lg border border-gray-200 z-50 ${
                isShown ? "block" : "hidden"
            } ${className ? ` ${className}` : ""}`}
        >
            <Scrollable height="300px" className="p-2">
                {children}
            </Scrollable>
        </div>
    )
}
