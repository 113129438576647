import { Button } from "@/components/Inputs/Button"
import { DropDown } from "@/components/Inputs/DropDown"
import { IfcFilterSet } from "@/models/Ifc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IfcHandler } from "../IfcHandler"
import { IfcFiltersRule } from "./IfcFiltersRule"

interface IfcFiltersSetProps {
    ifcHandler: IfcHandler
    ifcFilterSet: IfcFilterSet
    setIfcFilterSet: (set: IfcFilterSet) => void
    canDelete?: boolean
    deleteSet?: () => void
}

export const IfcFiltersSet: React.FC<IfcFiltersSetProps> = ({
    ifcHandler,
    ifcFilterSet,
    setIfcFilterSet,
    canDelete = false,
    deleteSet,
}) => {
    const { t } = useTranslation()
    const [localFilterSet, setLocalFilterSet] =
        useState<IfcFilterSet>(ifcFilterSet)
    useEffect(() => {
        setIfcFilterSet(localFilterSet)
    }, [localFilterSet])
    return (
        <div
            className={`bg-secondary-100 text-primary-300 p-4 rounded-lg border border-l-8 ${localFilterSet.inclusive ? "border-green-600" : "border-blue-600"}`}
        >
            <div className="flex items-center gap-2 sm:flex-wrap">
                <DropDown
                    name={t("filter_type")}
                    value={localFilterSet.inclusive}
                    onChange={(e) => {
                        setLocalFilterSet({
                            ...localFilterSet,
                            inclusive: e.target.value as any as boolean,
                        })
                    }}
                    options={[
                        { label: t("inclusive_notice"), value: true },
                        { label: t("exclusive_notice"), value: false },
                    ]}
                    className="min-w-[300px] sm:min-w-[200px]"
                />
                <Button
                    name={t("add_rule")}
                    onClick={() => {
                        setLocalFilterSet({
                            ...localFilterSet,
                            rules: [
                                ...localFilterSet.rules,
                                {
                                    type: "property",
                                    name: "",
                                    value: "",
                                },
                            ],
                        })
                    }}
                />
                <Button
                    name={t("add_set")}
                    onClick={() => {
                        setLocalFilterSet({
                            ...localFilterSet,
                            sets: [
                                ...localFilterSet.sets,
                                {
                                    inclusive: true,
                                    rules: [],
                                    sets: [],
                                },
                            ],
                        })
                    }}
                />
                {canDelete && deleteSet && (
                    <FontAwesomeIcon
                        icon="trash"
                        className="text-red-400 cursor-pointer hover:text-red-600 transition"
                        onClick={deleteSet}
                    />
                )}
            </div>
            <div className="flex flex-col gap-4">
                {localFilterSet.rules.map((rule, index) => (
                    <IfcFiltersRule
                        key={index}
                        rule={rule}
                        setRule={(rule) => {
                            const rules = [...localFilterSet.rules]
                            rules[index] = rule
                            setLocalFilterSet({ ...localFilterSet, rules })
                        }}
                        deleteRule={() => {
                            const rules = [...localFilterSet.rules]
                            rules.splice(index, 1)
                            setLocalFilterSet({ ...localFilterSet, rules })
                        }}
                    />
                ))}
                {localFilterSet.sets.map((set, index) => (
                    <IfcFiltersSet
                        key={index}
                        ifcHandler={ifcHandler}
                        ifcFilterSet={set}
                        setIfcFilterSet={(set) => {
                            const sets = [...localFilterSet.sets]
                            sets[index] = set
                            setLocalFilterSet({ ...localFilterSet, sets })
                        }}
                        canDelete
                        deleteSet={() => {
                            const sets = [...localFilterSet.sets]
                            sets.splice(index, 1)
                            setLocalFilterSet({ ...localFilterSet, sets })
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
