import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Amount } from "@/components/Other/Amount"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ArticleForm } from "../../Articles/ArticleForm"
import { getBudgetStatistics, selectBudgetStatistics } from "../../financeSlice"
import { BudgetPerArticleTable } from "./BudgetPerArticleTable"

interface BudgetProps {}

export const Budget: React.FC<BudgetProps> = () => {
    const budgetStatistics = useAppSelector(selectBudgetStatistics)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const budgetId = useParams<{ budgetId: string }>().budgetId ?? "-1"
    const [showCreateArticle, setShowCreateArticle] = useState<boolean>(false)
    useEffect(() => {
        dispatch(
            getBudgetStatistics({
                projectId,
                budgetId,
            }),
        )
    }, [])
    return (
        <Scrollable>
            <div className="w-full h-full flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <h1 className="text-4xl font-bold border-b-4 border-b-primary-300 w-fit">
                        {budgetStatistics.name}
                    </h1>
                    <p>{budgetStatistics.description}</p>
                    <div className="flex gap-1 items-center">
                        <p>
                            <span className="font-bold">{t("total")}:</span>{" "}
                        </p>
                        <Amount
                            amount={budgetStatistics.total}
                            currency={budgetStatistics.currency}
                        />
                    </div>
                    <div className="flex gap-1 items-center">
                        <p>
                            <span className="font-bold">{t("engaged")}:</span>{" "}
                        </p>
                        <Amount
                            amount={budgetStatistics.engaged}
                            currency={budgetStatistics.currency}
                        />
                    </div>
                    <div className="flex gap-1 items-center">
                        <p>
                            <span className="font-bold">{t("remaining")}:</span>{" "}
                        </p>
                        <Amount
                            amount={budgetStatistics.remaining}
                            currency={budgetStatistics.currency}
                        />
                    </div>
                </div>
                <div>
                    <div className="flex item-center justify-between my-4">
                        <h3 className="mb-4 text-2xl">
                            {t("budget_articles")}
                        </h3>
                        <Button
                            name={t("create_new_article")}
                            onClick={() => setShowCreateArticle(true)}
                            icon={"plus"}
                        />
                    </div>
                    <BudgetPerArticleTable budget={budgetStatistics} />
                </div>
            </div>
            <NewModal
                title={t("create_new_article")}
                isShown={showCreateArticle}
                closeModal={() => setShowCreateArticle(false)}
            >
                <ArticleForm closeModal={() => setShowCreateArticle(false)} />
            </NewModal>
        </Scrollable>
    )
}
