import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

interface NoImageProps {
    size: SizeProp
    className?: string
}

export const NoImage: React.FC<NoImageProps> = ({ size, className }) => {
    const { t } = useTranslation()
    return (
        <div className={"opacity-40 text-center " + className}>
            <p className="text-2xl">{t("no_image")}</p>
            <div className="relative">
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden rounded-lg">
                    <div className="w-2 h-[140%] bg-primary-300 border-x-4 rotate-45 box-content" />
                </div>
                <FontAwesomeIcon icon="image" size={size} />
            </div>
        </div>
    )
}
