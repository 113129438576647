import { useAppDispatch } from "@/app/hooks"
import { Folder } from "@/models/Folder"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { expandFolderTree } from "./fileManagerSlice"

interface QuickNavigationFolderProps {
    folder: Folder
}

export const QuickNavigationFolder: React.FC<QuickNavigationFolderProps> = ({
    folder,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"

    const dispatch = useAppDispatch()
    const handleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
        if (expanded === false) {
            dispatch(expandFolderTree({ projectId, folderId: folder.id })).then(
                () => setExpanded(true),
            )
        }
        setExpanded(!expanded)
    }
    return (
        <div className="w-full">
            <div className="flex items-center gap-1  border-b border-l">
                <div className="w-4">
                    {folder.children.length > 0 && (
                        <div onClick={handleExpand}>
                            <FontAwesomeIcon
                                icon={
                                    !expanded ? "chevron-right" : "chevron-down"
                                }
                                size="sm"
                            />
                        </div>
                    )}
                </div>
                <Link
                    className="flex items-center gap-1"
                    to={`/dashboard/projects/${projectId}/file-manager/${folder.id}`}
                >
                    <FontAwesomeIcon
                        icon="folder"
                        size="sm"
                        className="text-yellow-500"
                    />
                    <p className="text-nowrap">{folder.name}</p>
                </Link>
            </div>
            <div className="w-full">
                {expanded && (
                    <div className="pl-4 flex w-full">
                        <div className="w-full">
                            {folder.children.map((child) => (
                                <QuickNavigationFolder
                                    key={child.id}
                                    folder={child}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
