import { useAppDispatch } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { getCurrentUserPermissionsInProject } from "@/features/User/userSlice"
import { useEffect, useRef, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { TaskActivity } from "./TaskActivities"
import { TaskAssignees } from "./TaskAssignees"
import { TaskAttachments } from "./TaskAttachments"
import { TaskComments } from "./TaskComments"
import { TaskDependencies } from "./TaskDependencies"
import { TaskDetails } from "./TaskDetails"
import { TaskFollowers } from "./TaskFollowers"
import { TaskReviews } from "./TaskReviews"

interface TaskProps {}

export const Task: React.FC<TaskProps> = () => {
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const containerRef = useRef<HTMLDivElement>(null)
    const reviewsRef = useRef<HTMLDivElement>(null)
    const assigneesRef = useRef<HTMLDivElement>(null)
    const commentsRef = useRef<HTMLDivElement>(null)
    const dependenciesRef = useRef<HTMLDivElement>(null)
    const attachmentsRef = useRef<HTMLDivElement>(null)
    const [queryParams] = useSearchParams()
    const [scrollTo, setScrollTo] = useState<string>("")
    const location = useLocation()
    useEffect(() => {
        dispatch(getCurrentUserPermissionsInProject(projectId))
    }, [])
    useEffect(() => {
        if (queryParams.has("reviewer")) {
            if (reviewsRef.current) {
                setScrollTo("reviews")
            }
        } else if (queryParams.has("assignee")) {
            if (assigneesRef.current) {
                setScrollTo("assignees")
            }
        } else if (queryParams.has("comment")) {
            if (commentsRef.current) {
                setScrollTo("comments")
            }
        } else if (queryParams.has("dependency")) {
            if (dependenciesRef.current) {
                setScrollTo("dependencies")
            }
        } else if (queryParams.has("attachment")) {
            if (attachmentsRef.current) {
                setScrollTo("attachments")
            }
        }
    }, [
        commentsRef,
        dependenciesRef,
        reviewsRef,
        assigneesRef,
        attachmentsRef,
        location,
    ])

    useEffect(() => {
        const scrollToMapping: any = {
            reviews: reviewsRef,
            assignees: assigneesRef,
            comments: commentsRef,
            dependencies: dependenciesRef,
            attachments: attachmentsRef,
        }
        if (scrollTo) {
            if (
                scrollToMapping[scrollTo] &&
                scrollToMapping[scrollTo].current
            ) {
                setTimeout(() => {
                    scrollToMapping[scrollTo].current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    })
                }, 2000)
            }
        }
    }, [scrollTo, location])

    return (
        <Scrollable height="100%" innerRef={containerRef}>
            <div className="flex flex-col gap-4 w-full">
                <TaskDetails />
                <div className="flex w-full gap-4 lg:flex-col">
                    <div className="w-full" ref={reviewsRef}>
                        <TaskReviews />
                    </div>
                    <div className="w-full" ref={assigneesRef}>
                        <TaskAssignees />
                    </div>
                    <div className="w-full">
                        <TaskFollowers />
                    </div>
                </div>
                <div className="flex w-full gap-4 lg:flex-col">
                    <div className="w-full" ref={dependenciesRef}>
                        <TaskDependencies />
                    </div>
                    <div className="w-full" ref={attachmentsRef}>
                        <TaskAttachments />
                    </div>
                    <div className="w-full" ref={commentsRef}>
                        <TaskComments />
                    </div>
                </div>
                <div className="w-full">
                    <TaskActivity />
                </div>
            </div>
        </Scrollable>
    )
}
