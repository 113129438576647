import { ResourcesIds } from "@/app/common"
import { BaseModel, BaseModelInterface } from "./BaseModel"
import { Project, ProjectJsonInterface } from "./Project"
import { User, UserInterface } from "./User"

export namespace MODULE_PERMISSIONS {
    export enum UserManager {
        Invite = "UserManager.invite",
        Remove = "UserManager.remove",
        Edit = "UserManager.edit",
        GroupCreate = "UserManager.groupCreate",
        GroupEdit = "UserManager.groupEdit",
        GroupDelete = "UserManager.groupDelete",
        GroupRead = "UserManager.groupRead",
    }
    export enum FileManager {
        View = "FileManager.view",
        Upload = "FileManager.upload",
    }
    export enum Planning {
        Read = "Planning.read",
        Write = "Planning.write",
        Delete = "Planning.delete",
    }
    export enum ModelViewer {
        Read = "ModelViewer.read",
        Write = "ModelViewer.write",
    }
    export enum Project {
        Edit = "Project.edit",
        Delete = "Project.delete",
    }
    export enum Finance {
        Read = "Finance.read",
        Write = "Finance.write",
    }

    export enum Statistics {
        Read = "Statistics.read",
    }

    export enum All {
        All = "*",
    }
}

export interface PermissionGroupJsonInterface extends BaseModelInterface {
    name: string
    description: string
    permissions: PermissionType[]
    project: ProjectJsonInterface
    users: UserInterface[]
}

export interface UserProjectPermissionsJsonInterface
    extends BaseModelInterface {
    user: UserInterface
    project: ProjectJsonInterface
    permissions: PermissionType[]
}

export interface PermissionGroupCreatePayload {
    name: string
    description: string
    permissions: PermissionType[]
}

export class PermissionGroup extends BaseModel {
    public name: string
    public description: string
    public permissions: PermissionType[]
    public project: Project
    public users: User[]

    constructor(data?: PermissionGroupJsonInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        this.permissions = data?.permissions ?? []
        this.project = new Project(data?.project) ?? new Project()
        this.users = data?.users?.map((user) => new User(user)) ?? []
    }

    public toJson(): PermissionGroupJsonInterface {
        return {
            ...super.toJson(),
            name: this.name,
            description: this.description,
            permissions: this.permissions,
            project: this.project.toJson(),
            users: this.users.map((user) => user.toJson()),
        }
    }

    public createPayload(users?: string[]): PermissionGroupCreatePayload {
        return {
            name: this.name,
            description: this.description,
            permissions: this.permissions,
            ...(users && { users }),
        }
    }
}

export class UserProjectPermissions extends BaseModel {
    public user: User
    public project: Project
    public permissions: PermissionType[]

    constructor(data?: UserProjectPermissionsJsonInterface) {
        super(data)
        this.user = new User(data?.user) ?? new User()
        this.project = new Project(data?.project) ?? new Project()
        this.permissions = data?.permissions ?? []
    }

    public toJson(): UserProjectPermissionsJsonInterface {
        return {
            ...super.toJson(),
            user: this.user.toJson(),
            project: this.project.toJson(),
            permissions: this.permissions,
        }
    }
}

export interface ResourcePermissions {
    read: ResourcesIds
    write: ResourcesIds
    notifications: ResourcesIds
}

export enum RESOURCE_PERMISSIONS {
    READ = "read",
    WRITE = "write",
    NOTIFICATIONS = "notifications",
    ALL = "*",
}

export enum RESOURCES {
    FILE = "file",
    FOLDER = "folder",
    TASK = "task",
    PROJECT = "project",
    USER = "user",
    PERMISSION_GROUP = "permissionGroup",
    IFC_MODEL = "ifcModel",
    BUDGET = "budget",
}

export enum TASK_PERMISSIONS {
    ASSIGNEE = "assignee",
    REVIEWER = "reviewer",
    MANAGER = "manager",
    FOLLOWER = "follower",
}

export type ResourceParam = `${RESOURCES}Id`

export type ResourcePermissionsType =
    `${RESOURCES}.${string}.${RESOURCE_PERMISSIONS}`

export type TaskPermissionsType = `${RESOURCES}.${string}.${TASK_PERMISSIONS}`

export type ModulePermissionType =
    | MODULE_PERMISSIONS.FileManager
    | MODULE_PERMISSIONS.Planning
    | MODULE_PERMISSIONS.UserManager
    | MODULE_PERMISSIONS.ModelViewer
    | MODULE_PERMISSIONS.Project
    | MODULE_PERMISSIONS.Finance
    | MODULE_PERMISSIONS.All

export type PermissionType =
    | ModulePermissionType
    | ResourcePermissionsType
    | TaskPermissionsType

export class UserPermissions {
    public permissions: PermissionType[]

    constructor(permissions?: PermissionType[]) {
        this.permissions = permissions ?? []
    }

    hasAllPermissions(permissions: PermissionType[]): boolean {
        if (this.permissions.includes(MODULE_PERMISSIONS.All.All)) return true
        return permissions.every((permission) =>
            this.permissions.includes(permission),
        )
    }

    hasOneOfPermissions(permissions: PermissionType[]): boolean {
        if (this.permissions.includes(MODULE_PERMISSIONS.All.All)) return true
        return permissions.some((permission) =>
            this.permissions.includes(permission),
        )
    }

    toJson(): PermissionType[] {
        return this.permissions
    }
}
