import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Form, SubmitType } from "@/features/Form/Form"
import { Task } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    clearErrors,
    getTaskById,
    getTaskReviewsById,
    resetTaskStatus,
} from "../tasksSlice"

interface TaskAskForReviewFormProps {
    task: Task
    closeModal: () => void
}

export const TaskAskForReviewForm: React.FC<TaskAskForReviewFormProps> = ({
    task,
    closeModal,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const status = useAppSelector((state) => state.tasks.status)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    return (
        <Form
            statuses={[status.update, status.read]}
            loadingTitle={t("updating_task")}
            submitType={SubmitType.Submit}
            clearErrors={clearErrors}
            onCancel={closeModal}
            onSubmit={async () => {
                let close = true
                const { type } = await dispatch(
                    resetTaskStatus({ projectId, taskId }),
                )
                if (type !== resetTaskStatus.fulfilled.type) {
                    close = false
                }
                const { type: getTaskType } = await dispatch(
                    getTaskById({ projectId, taskId }),
                )
                if (getTaskType !== getTaskById.fulfilled.type) {
                    close = false
                }
                const { type: getTaskReviewsType } = await dispatch(
                    getTaskReviewsById({ projectId, taskId }),
                )
                if (getTaskReviewsType !== getTaskReviewsById.fulfilled.type) {
                    close = false
                }
                if (close) {
                    closeModal()
                }
            }}
        >
            <h1 className="font-bold text-xl mb-4">
                {t("ask_for_review_notice")}
            </h1>

            <p>
                <span className="font-bold">{t("task_name")}:</span> {task.name}
            </p>
            <p>
                <span className="font-bold">{t("start_date")}:</span>{" "}
                {t("intlDateTime", {
                    val: task.startDate,
                    formatParams: {
                        val: {
                            weekday: "short",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        },
                    },
                })}
            </p>
            <p>
                <span className="font-bold">{t("endDate")}:</span>{" "}
                {t("intlDateTime", {
                    val: task.endDate,
                    formatParams: {
                        val: {
                            weekday: "short",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        },
                    },
                })}
            </p>
            <p className="text-red-500 mt-4">
                <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
                {t("ask_for_review_consequence")}
            </p>
        </Form>
    )
}
