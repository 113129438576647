export class IndexedDbUtil {
    private dbName: string = "IFCFilesDB"
    private storeName: string = "IFCFiles"

    // Open IndexedDB
    private openDb(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, 1)

            request.onupgradeneeded = (event) => {
                const db = request.result
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, {
                        keyPath: "fileName",
                    })
                }
            }

            request.onsuccess = () => resolve(request.result)
            request.onerror = (event) => reject(event)
        })
    }

    // Save IFC file data
    public async saveFile(fileName: string, data: ArrayBuffer): Promise<void> {
        const db = await this.openDb()
        const transaction = db.transaction(this.storeName, "readwrite")
        const store = transaction.objectStore(this.storeName)

        store.put({ fileName, data })
    }

    // Retrieve IFC file data
    public async getFile(fileName: string): Promise<ArrayBuffer | null> {
        const db = await this.openDb()
        const transaction = db.transaction(this.storeName, "readonly")
        const store = transaction.objectStore(this.storeName)

        return new Promise((resolve, reject) => {
            const request = store.get(fileName)

            request.onsuccess = () => {
                const result = request.result
                resolve(result ? result.data : null)
            }

            request.onerror = (event) => reject(event)
        })
    }
}
