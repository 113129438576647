import { ProjectFile } from "@/models/File"
import { IfcModel } from "@/models/Ifc"
import { FC } from "react"
import { IfcViewer } from "./IfcViewer"

interface IfcContainerProps {
    fullScreen: boolean
    selectedFile: ProjectFile | null
    selectedModel: IfcModel | null
}

export const IfcContainer: FC<IfcContainerProps> = ({
    fullScreen,
    selectedFile,
    selectedModel,
}) => {
    return (
        <div className="w-full h-full">
            <IfcViewer
                selectedFile={selectedFile}
                selectedModel={selectedModel}
            />
        </div>
    )
}
