import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { ProjectFile } from "@/models/File"
import { PermissionType } from "@/models/Permission"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { FileIcon } from "../FileManager/FileIcon"
import {
    clearErrors,
    deleteAttachment,
    getTaskAttachmentsById,
    selectTask,
    uploadAttachment,
} from "./tasksSlice"

interface TaskAttachmentsProps {
    innerRef?: React.RefObject<HTMLDivElement>
}

export const TaskAttachments: React.FC<TaskAttachmentsProps> = ({
    innerRef = null,
}) => {
    const attachmentInputRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const dispatch = useAppDispatch()
    const task = useAppSelector(selectTask)
    const status = useAppSelector((state) => state.tasks.status)
    const userPermissions = useAppSelector(selectUserPermissions)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showUploadingModal, setShowUploadingModal] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<ProjectFile>(
        new ProjectFile(),
    )
    const [queryParams] = useSearchParams()
    const [highlighted, setHighlighted] = useState<string>("")
    const containerRef = useRef<HTMLDivElement>(null)
    const highlightedRef = useRef<HTMLDivElement>(null)
    const location = useLocation()
    const requiredPermissions: PermissionType[] = [
        `task.${task.id}.manager`,
        `task.${task.id}.reviewer`,
        `task.${task.id}.assignee`,
    ]
    useEffect(() => {
        dispatch(getTaskAttachmentsById({ projectId, taskId }))
    }, [])
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setShowUploadingModal(true)
            const payload = new FormData()
            Array.from(e.target.files).forEach((file) => {
                payload.append("attachments", file)
            })
            const { type } = await dispatch(
                uploadAttachment({ projectId, taskId, payload }),
            )
            if (type === uploadAttachment.fulfilled.type) {
                e.target.files = null
                setShowUploadingModal(false)
            }
        }
    }
    useEffect(() => {
        const attachment = queryParams.get("attachment")
        if (attachment) {
            setHighlighted(attachment)
            if (containerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        containerRef.current.getBoundingClientRect()
                    containerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current, location])
    return (
        <div
            className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col h-full"
            ref={innerRef}
        >
            <div className="flex justify-between items-center gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("attachments")}
                </h1>
            </div>
            <div className="flex flex-col gap-4 w-full mt-4 h-full">
                <Scrollable height="400px" innerRef={containerRef}>
                    <div className="flex gap-2 flex-wrap">
                        {task.files.map((file) => (
                            <div className="relative" key={file.id}>
                                <FileIcon
                                    file={file}
                                    highlighted={highlighted === file.id}
                                    innerRef={
                                        highlighted === file.id
                                            ? highlightedRef
                                            : null
                                    }
                                />
                                {userPermissions.hasAllPermissions([
                                    `task.${task.id}.manager`,
                                ]) && (
                                    <FontAwesomeIcon
                                        icon="times"
                                        className="bg-red-400 transition hover:bg-red-600 rounded-full w-4 h-4 text-xs text-secondary-100 absolute top-1 right-1 cursor-pointer z-10"
                                        onClick={() => {
                                            setSelectedFile(file)
                                            setShowDeleteModal(true)
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                        <div>
                            {userPermissions.hasOneOfPermissions(
                                requiredPermissions,
                            ) && (
                                <div
                                    className="w-28 h-28 bg-secondary-200 text-secondary-400 rounded-lg flex justify-center items-center cursor-pointer hover:bg-secondary-400 hover:text-secondary-100 transition"
                                    onClick={() =>
                                        attachmentInputRef.current?.click()
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon="plus"
                                        className="text-6xl"
                                    />
                                    <input
                                        type="file"
                                        className="hidden"
                                        ref={attachmentInputRef}
                                        onChange={handleChange}
                                        multiple={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Scrollable>
            </div>
            <NewModal
                title={t("delete_attachment", {
                    attachment: selectedFile.name,
                })}
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <Form
                    statuses={[status.update]}
                    loadingTitle={t("deleting_attachment")}
                    className="flex flex-col gap-4 w-full"
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            deleteAttachment({
                                projectId,
                                taskId,
                                fileId: selectedFile.id,
                            }),
                        )
                        if (type === deleteAttachment.fulfilled.type) {
                            setShowDeleteModal(false)
                        }
                    }}
                    clearErrors={clearErrors}
                    onCancel={() => setShowDeleteModal(false)}
                    submitType={SubmitType.Delete}
                >
                    <h1 className="text-2xl font-bold">
                        {t("delete_attachment", {
                            attachment: selectedFile.name,
                        })}
                    </h1>
                    <p>
                        {t("delete_attachment_notice", {
                            attachment: selectedFile.name,
                        })}
                    </p>
                </Form>
            </NewModal>
            <NewModal
                title={t("uploading_attachments")}
                isShown={showUploadingModal}
                closeModal={() => setShowUploadingModal(false)}
            >
                <WithLoader
                    statuses={[status.update]}
                    title={t("uploading_attachments")}
                >
                    <p></p>
                </WithLoader>
            </NewModal>
        </div>
    )
}
