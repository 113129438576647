import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useParams } from "react-router-dom"
import { Groups } from "./Groups"
import { Users } from "./Users"
import { getProjectGroups, selectProjectGroups } from "./userManagementSlice"

export const UserManagement = () => {
    const dispatch = useAppDispatch()
    const projectGroups = useAppSelector(selectProjectGroups)
    const { t } = useTranslation()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"

    useEffect(() => {
        dispatch(getProjectGroups(projectId))
    }, [dispatch, projectId])

    return (
        <div className="w-full flex flex-col gap-4 overflow-hidden p-4">
            <h1 className="text-4xl font-bold border-b-4 w-fit border-opacity-100 border-primary-300">
                {t("user_management")}
            </h1>
            <div className="h-full flex flex-col overflow-hidden">
                <Scrollable height="100%" className="flex flex-col gap-4">
                    <Users />
                    <Groups />
                </Scrollable>
            </div>
            <Outlet />
        </div>
    )
}
