import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { DropDown } from "@/components/Inputs/DropDown"
import { Input } from "@/components/Inputs/Input"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { TagComponent } from "@/components/Other/TagComponent"
import {
    selectProjectTasks,
    selectProjectUsers,
} from "@/features/Projects/projectsSlice"
import { selectTags } from "@/features/Projects/Tags/tagSlice"
import { selectProjectGroups } from "@/features/Projects/UserManagement/userManagementSlice"
import { BIMFileNominationFields, BIMFileStatus } from "@/models/File"
import { PermissionGroup } from "@/models/Permission"
import {
    TaskDelayStatus,
    TaskStatus,
    TaskStatusDetailNameSpace,
} from "@/models/Task"
import { User } from "@/models/User"
import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import { isNull } from "lodash"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { setFilters } from "../ganttSlice"
import {
    BimFilesFilterFields,
    FilterFields,
    FilterOperators,
    FilterValuesType,
    TaskFilterFields,
} from "./Filters"

interface ArrayFilterProps {
    filters: FilterValuesType<FilterFields>
    field: FilterFields
}

export const ArrayFilter: React.FC<ArrayFilterProps> = ({ filters, field }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const FilterComponentMapping = {
        [TaskFilterFields.Tags]: (
            <TaskTagsFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [TaskFilterFields.TaskStatus]: (
            <TaskStatusFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [TaskFilterFields.Assignees]: (
            <ResourceIdFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [TaskFilterFields.Reviewers]: (
            <ResourceIdFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [TaskFilterFields.StatusDetail]: (
            <TaskStatusDetailFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [TaskFilterFields.DelayStatus]: (
            <TaskDelayStatusFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Project]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Originator]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.FunctionalBreakdown]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.SpatialBreakdown]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Form]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Discipline]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Number]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Phase]: (
            <BimNominationFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Assignee]: (
            <ResourceIdFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Reviewers]: (
            <ResourceIdFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.BimFileStatus]: (
            <BimFileStatusFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
        [BimFilesFilterFields.Task]: (
            <BimFileTaskFilterInput
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
        ),
    }

    return (
        <div className="w-full h-full ">
            <div className="flex items-center flex-wrap gap-2">
                <p>{t("operator")}:</p>
                <DropDown
                    value={filters[field].operator}
                    name={t("operator")}
                    options={Object.values(FilterOperators.ArrayOperators).map(
                        (operator) => ({
                            value: operator,
                            label: t(operator),
                        }),
                    )}
                    onChange={(e) => {
                        dispatch(
                            setFilters({
                                ...filters,
                                [field]: {
                                    ...filters[field],
                                    operator: e.target.value,
                                },
                            }),
                        )
                    }}
                />
            </div>
            <div className="flex items-center flex-wrap gap-2">
                <p>{t("value")}:</p>
                {(FilterComponentMapping as any)[field]}
            </div>
        </div>
    )
}

interface FilterInputProps {
    filters: FilterValuesType<FilterFields>
    setFilters: ActionCreatorWithPayload<FilterValuesType<FilterFields>, any>
    field: FilterFields
}

const ResourceIdFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const projectUsers = useAppSelector(selectProjectUsers)
    const projectGroups = useAppSelector(selectProjectGroups)
    const dispatch = useAppDispatch()
    const [search, setSearch] = useState("")
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            <Input
                name="search"
                type="text"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            {[...projectUsers, ...projectGroups]
                .filter((userOrGroup) => {
                    if (userOrGroup instanceof User) {
                        return (userOrGroup as User).fullName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    } else {
                        return (userOrGroup as PermissionGroup).name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    }
                })
                .map((userOrGroup) => (
                    <div
                        key={userOrGroup.id}
                        className="flex items-center gap-2 text-wrap"
                    >
                        <input
                            type="checkbox"
                            checked={
                                (!isNull(filters[field]?.value) &&
                                    (
                                        filters[field]?.value as string[]
                                    ).includes(userOrGroup.id)) ??
                                false
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch(
                                        setFilters({
                                            ...filters,
                                            [field]: {
                                                ...filters[field],
                                                value: [
                                                    ...(!isNull(
                                                        filters[field]?.value,
                                                    )
                                                        ? (filters[field]
                                                              .value as string[])
                                                        : []),
                                                    userOrGroup.id,
                                                ],
                                            },
                                        }),
                                    )
                                } else {
                                    dispatch(
                                        setFilters({
                                            ...filters,
                                            [field]: {
                                                ...filters[field],
                                                value: (
                                                    filters[field]
                                                        .value as string[]
                                                ).filter(
                                                    (id) =>
                                                        id !== userOrGroup.id,
                                                ),
                                            },
                                        }),
                                    )
                                }
                            }}
                        />
                        <p className="text-wrap break-words">
                            {Object.hasOwn(userOrGroup, "email")
                                ? (userOrGroup as User).fullName
                                : (userOrGroup as PermissionGroup).name}
                        </p>
                    </div>
                ))}
        </Scrollable>
    )
}

const TaskTagsFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const projectTags = useAppSelector(selectTags)
    const dispatch = useAppDispatch()
    const [search, setSearch] = useState("")
    return (
        <Scrollable height="200px" className="flex flex-wrap gap-1">
            <Input
                name={t("search")}
                type="text"
                placeholder={t("search")}
                className="!m-0"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            {projectTags
                .filter((tag) =>
                    tag.name.toLowerCase().includes(search.toLowerCase()),
                )
                .map((tag) => (
                    <div key={tag.id} className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={
                                (!isNull(filters[field]?.value) &&
                                    (
                                        filters[field]?.value as string[]
                                    ).includes(tag.id)) ??
                                false
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch(
                                        setFilters({
                                            ...filters,
                                            [field]: {
                                                ...filters[field],
                                                value: [
                                                    ...(!isNull(
                                                        filters[field]?.value,
                                                    )
                                                        ? (filters[field]
                                                              .value as string[])
                                                        : []),
                                                    tag.id,
                                                ],
                                            },
                                        }),
                                    )
                                } else {
                                    dispatch(
                                        setFilters({
                                            ...filters,
                                            [field]: {
                                                ...filters[field],
                                                value: (
                                                    filters[field]
                                                        .value as string[]
                                                ).filter((id) => id !== tag.id),
                                            },
                                        }),
                                    )
                                }
                            }}
                        />
                        <TagComponent tag={tag} />
                    </div>
                ))}
        </Scrollable>
    )
}

const TaskStatusFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            {Object.values(TaskStatus).map((status) => (
                <div key={status} className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={
                            (!isNull(filters[field]?.value) &&
                                (filters[field]?.value as string[]).includes(
                                    status,
                                )) ??
                            false
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: [
                                                ...(!isNull(
                                                    filters[field]?.value,
                                                )
                                                    ? (filters[field]
                                                          .value as string[])
                                                    : []),
                                                status,
                                            ],
                                        },
                                    }),
                                )
                            } else {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: (
                                                filters[field].value as string[]
                                            ).filter(
                                                (filterStatus) =>
                                                    filterStatus !== status,
                                            ),
                                        },
                                    }),
                                )
                            }
                        }}
                    />
                    <p>{t(status)}</p>
                </div>
            ))}
        </Scrollable>
    )
}

const TaskStatusDetailFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            {Object.values([
                ...Object.values(TaskStatusDetailNameSpace.NOT_STARTED),
                ...Object.values(TaskStatusDetailNameSpace.IN_PROGRESS),
                ...Object.values(TaskStatusDetailNameSpace.COMPLETED),
            ]).map((status) => (
                <div key={status} className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={
                            (!isNull(filters[field]?.value) &&
                                (filters[field]?.value as string[]).includes(
                                    status,
                                )) ??
                            false
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: [
                                                ...(!isNull(
                                                    filters[field]?.value,
                                                )
                                                    ? (filters[field]
                                                          .value as string[])
                                                    : []),
                                                status,
                                            ],
                                        },
                                    }),
                                )
                            } else {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: (
                                                filters[field].value as string[]
                                            ).filter(
                                                (filterStatus) =>
                                                    filterStatus !== status,
                                            ),
                                        },
                                    }),
                                )
                            }
                        }}
                    />
                    <p>{t(status)}</p>
                </div>
            ))}
        </Scrollable>
    )
}

const TaskDelayStatusFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            {Object.values(TaskDelayStatus).map((status) => (
                <div key={status} className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={
                            (!isNull(filters[field]?.value) &&
                                (filters[field]?.value as string[]).includes(
                                    status,
                                )) ??
                            false
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: [
                                                ...(!isNull(
                                                    filters[field]?.value,
                                                )
                                                    ? (filters[field]
                                                          .value as string[])
                                                    : []),
                                                status,
                                            ],
                                        },
                                    }),
                                )
                            } else {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: (
                                                filters[field].value as string[]
                                            ).filter(
                                                (filterStatus) =>
                                                    filterStatus !== status,
                                            ),
                                        },
                                    }),
                                )
                            }
                        }}
                    />
                    <p>{t(status)}</p>
                </div>
            ))}
        </Scrollable>
    )
}

const BimNominationFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const bimNomination = useAppSelector(
        (state) => state.fileManager.filesBIMNominations,
    )
    const [nominationOptions, setNominationOptions] = useState<string[]>([])
    useEffect(() => {
        setNominationOptions(
            bimNomination[field as unknown as BIMFileNominationFields] ?? [],
        )
    }, [bimNomination])
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            {nominationOptions.map((option) => (
                <div key={option} className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={
                            (!isNull(filters[field]?.value) &&
                                (filters[field]?.value as string[]).includes(
                                    option,
                                )) ??
                            false
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: [
                                                ...(!isNull(
                                                    filters[field]?.value,
                                                )
                                                    ? (filters[field]
                                                          .value as string[])
                                                    : []),
                                                option,
                                            ],
                                        },
                                    }),
                                )
                            } else {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: (
                                                filters[field].value as string[]
                                            ).filter(
                                                (filterStatus) =>
                                                    filterStatus !== option,
                                            ),
                                        },
                                    }),
                                )
                            }
                        }}
                    />
                    <p>{option}</p>
                </div>
            ))}
        </Scrollable>
    )
}

const BimFileStatusFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            {Object.values(BIMFileStatus).map((status) => (
                <div key={status} className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={
                            (!isNull(filters[field]?.value) &&
                                (filters[field]?.value as string[]).includes(
                                    status,
                                )) ??
                            false
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: [
                                                ...(!isNull(
                                                    filters[field]?.value,
                                                )
                                                    ? (filters[field]
                                                          .value as string[])
                                                    : []),
                                                status,
                                            ],
                                        },
                                    }),
                                )
                            } else {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: (
                                                filters[field].value as string[]
                                            ).filter(
                                                (filterStatus) =>
                                                    filterStatus !== status,
                                            ),
                                        },
                                    }),
                                )
                            }
                        }}
                    />
                    <p>{t(status)}</p>
                </div>
            ))}
        </Scrollable>
    )
}

export const BimFileTaskFilterInput: React.FC<FilterInputProps> = ({
    filters,
    setFilters,
    field,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const tasks = useAppSelector(selectProjectTasks)
    return (
        <Scrollable height="200px" className="flex flex-col gap-2">
            {tasks.map((task) => (
                <div key={task.id} className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={
                            (!isNull(filters[field]?.value) &&
                                (filters[field]?.value as string[]).includes(
                                    task.id,
                                )) ??
                            false
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: [
                                                ...(!isNull(
                                                    filters[field]?.value,
                                                )
                                                    ? (filters[field]
                                                          .value as string[])
                                                    : []),
                                                task.id,
                                            ],
                                        },
                                    }),
                                )
                            } else {
                                dispatch(
                                    setFilters({
                                        ...filters,
                                        [field]: {
                                            ...filters[field],
                                            value: (
                                                filters[field].value as string[]
                                            ).filter(
                                                (filterStatus) =>
                                                    filterStatus !== task.id,
                                            ),
                                        },
                                    }),
                                )
                            }
                        }}
                    />
                    <p>{task.name}</p>
                </div>
            ))}
        </Scrollable>
    )
}
