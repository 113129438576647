import { createAsyncThunkWithNotification } from "@/app/common"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import {
    UserNotification,
    UserNotificationInterface,
} from "@/models/Notification"
import { PermissionType, UserPermissions } from "@/models/Permission"
import { ProjectModules, UserProjectModules } from "@/models/Project"
import { Task, TaskJsonInterface } from "@/models/Task"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    getCurrentUserPermissionsInProjectApi,
    getUserAllNotificationsApi,
    getUserNewNotificationsApi,
    getUserProjectModulesApi,
    getUserProjectTasksApi,
    markNotificationsAsSeenApi,
} from "./userApi"

export const getUserProjectTasks = createAsyncThunkWithNotification(
    "users/getUserProjectTasks",
    async (projectId: string) => {
        const response = await getUserProjectTasksApi(projectId)
        return response
    },
)

export const getUserNewNotifications = createAsyncThunkWithNotification(
    "users/getUserNewNotifications",
    async () => {
        const response = await getUserNewNotificationsApi()
        return response
    },
)

export const getUserAllNotifications = createAsyncThunkWithNotification(
    "users/getUserAllNotifications",
    async () => {
        const response = await getUserAllNotificationsApi()
        return response
    },
)

export const markNotificationsAsSeen = createAsyncThunkWithNotification(
    "users/markNotificationsAsSeen",
    async (notificationsIds: number[]) => {
        const response = await markNotificationsAsSeenApi(notificationsIds)
        return response
    },
)

export const getCurrentUserPermissionsInProject =
    createAsyncThunkWithNotification(
        "users/getCurrentUserPermissionsInProject",
        async (projectId: string) => {
            const response =
                await getCurrentUserPermissionsInProjectApi(projectId)
            return response
        },
    )

export const getUserProjectModules = createAsyncThunkWithNotification(
    "users/getUserProjectModules",
    async (projectId: string) => {
        const response = await getUserProjectModulesApi(projectId)
        return response
    },
)

export interface UserState {
    users: TaskJsonInterface[]
    newNotifications: UserNotificationInterface[]
    notifications: UserNotificationInterface[]
    userProjectPermissions: PermissionType[]
    userProjectModules: ProjectModules[]
    status: ReducerStatus
    errors: FormErrors
}

const initialState: UserState = {
    users: [],
    newNotifications: [],
    notifications: [],
    userProjectPermissions: [],
    userProjectModules: [],
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

export const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserProjectTasks.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserProjectTasks.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.users = action.payload.data.data
            })
            .addCase(getUserProjectTasks.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.users = []
            })
            .addCase(getUserNewNotifications.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserNewNotifications.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.newNotifications = action.payload.data.data
            })
            .addCase(getUserNewNotifications.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.newNotifications = []
            })
            .addCase(getUserAllNotifications.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserAllNotifications.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.notifications = action.payload.data.data
            })
            .addCase(getUserAllNotifications.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.notifications = []
            })
            .addCase(markNotificationsAsSeen.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(markNotificationsAsSeen.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.newNotifications = [...state.newNotifications].filter(
                    (notification) => {
                        return !action.payload.data.data.includes(
                            notification.id,
                        )
                    },
                )
            })
            .addCase(markNotificationsAsSeen.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(getCurrentUserPermissionsInProject.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getCurrentUserPermissionsInProject.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.userProjectPermissions = action.payload.data.data
                },
            )
            .addCase(getCurrentUserPermissionsInProject.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.userProjectPermissions = []
            })
            .addCase(getUserProjectModules.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserProjectModules.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.userProjectModules = action.payload.data.data
            })
            .addCase(getUserProjectModules.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.userProjectModules = []
            })
    },
})

const selectUserProjectModulesRaw = (state: { users: UserState }) =>
    state.users.userProjectModules

export const selectUserProjectModules = createSelector(
    [selectUserProjectModulesRaw],
    (modules) => new UserProjectModules(modules),
)

const selectUserPermissionsRaw = (state: { users: UserState }) =>
    state.users.userProjectPermissions

export const selectUserPermissions = createSelector(
    [selectUserPermissionsRaw],
    (permissions) => new UserPermissions(permissions),
)

const selectUserTasksRaw = (state: { users: UserState }) => state.users.users

export const selectUserTasks = createSelector([selectUserTasksRaw], (users) =>
    users.map((task) => new Task(task)),
)

const selectUserNewNotificationsRaw = (state: { users: UserState }) =>
    state.users.newNotifications

const selectUserAllNotificationsRaw = (state: { users: UserState }) =>
    state.users.notifications

export const selectUserNewNotifications = createSelector(
    [selectUserNewNotificationsRaw],
    (newNotifications) =>
        newNotifications.map(
            (newNotification) => new UserNotification(newNotification),
        ),
)

export const selectUserAllNotifications = createSelector(
    [selectUserAllNotificationsRaw],
    (allNotifications) =>
        allNotifications.map(
            (allNotification) => new UserNotification(allNotification),
        ),
)

export const { clearErrors } = userSlice.actions

export default userSlice.reducer
