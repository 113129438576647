import { axiosAuthenticated } from "@/common/axios"
import { TagJson } from "@/models/Tag"

export const createTagApi = (projectId: string, payload: TagJson) => {
    return new Promise<{ data: TagJson }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tags/create`, { payload })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectTagsApi = (projectId: string) => {
    return new Promise<{ data: TagJson[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tags`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
