import { createSlice } from "@reduxjs/toolkit"

export interface AppSettingsSlice {
    language: string
}

const initialState: AppSettingsSlice = {
    language: "en",
}

export const appSettingsSlice = createSlice({
    name: "appSettings",
    initialState,
    reducers: {
        initAppLanguage: (state) => {
            const language = localStorage.getItem("language")
            if (language) {
                state.language = language
            }
        },
        setAppLanguage: (state, action) => {
            state.language = action.payload
            localStorage.setItem("language", action.payload)
        },
    },
})

export const { initAppLanguage, setAppLanguage } = appSettingsSlice.actions

export default appSettingsSlice.reducer
