import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { DropDown } from "@/components/Inputs/DropDown"
import { Input } from "@/components/Inputs/Input"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Budget, CURRENCY } from "@/models/Finance"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    createBudget,
    getGeneralBudgetInfo,
    selectBudgets,
    updateBudget,
} from "../../financeSlice"

interface BudgetFromProps {
    budget?: Budget
    parentId?: string
    closeModal: () => void
}

export const BudgetForm: FC<BudgetFromProps> = ({
    budget,
    closeModal,
    parentId,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId
    const status = useAppSelector((state) => state.finance.status)
    const budgets = useAppSelector(selectBudgets)
    const [localBudget, setLocalBudget] = useState<Budget>(
        budget ? budget : new Budget(),
    )
    const [parentBudgetId, setParentBudgetId] = useState<string>(
        parentId ? parentId : "",
    )

    useEffect(() => {
        const budget = budgets.find((budget) => budget.id === parentBudgetId)
        if (budget) {
            setLocalBudget(
                new Budget({
                    ...localBudget.toJson(),
                    parent: budget.toJson(),
                    currency: budget.currency,
                }),
            )
        }
    }, [parentBudgetId])

    const handleCreate = () => {
        dispatch(
            createBudget({
                projectId,
                payload: localBudget.createPayload(parentBudgetId),
            }),
        ).then(() => {
            dispatch(getGeneralBudgetInfo(projectId))
            closeModal()
        })
    }

    const handleEdit = () => {
        dispatch(
            updateBudget({
                projectId,
                budgetId: budget!.id,
                payload: localBudget.createPayload(parentBudgetId),
            }),
        ).then(() => {
            dispatch(getGeneralBudgetInfo(projectId))
            closeModal()
        })
    }

    return (
        <WithLoader
            statuses={[status.write]}
            title={
                budget
                    ? parentId
                        ? t("adding_sub_budget", { budget: budget.name })
                        : t("editing_budget", { budget: budget.name })
                    : t("creating_budget")
            }
        >
            <div className="w-full">
                <h1 className="text-xl font-bold">
                    {budget
                        ? parentId
                            ? t("adding_sub_budget", { budget: budget.name })
                            : t("editing_budget", { budget: budget.name })
                        : t("creating_new_budget")}
                </h1>
                <div className="mt-5">
                    <div className="flex items-center gap-4">
                        <Input
                            type="text"
                            name={t("name")}
                            label={t("name")}
                            value={localBudget.name}
                            className="!m-0 w-3/5"
                            onChange={(e) => {
                                setLocalBudget(
                                    new Budget({
                                        ...localBudget.toJson(),
                                        name: e.target.value,
                                    }),
                                )
                            }}
                        />
                        <div>
                            <p className="font-bold">
                                {" "}
                                {t("budget_total_amount")}
                            </p>
                            <div className="flex items-center gap-2">
                                <Input
                                    type="number"
                                    name={t("budget_total_amount")}
                                    value={localBudget.total}
                                    className="!m-0"
                                    onChange={(e) => {
                                        setLocalBudget(
                                            new Budget({
                                                ...localBudget.toJson(),
                                                total: parseFloat(
                                                    e.target.value,
                                                ),
                                            }),
                                        )
                                    }}
                                />
                                <DropDown
                                    value={localBudget.currency}
                                    name={t("currency")}
                                    options={Object.keys(CURRENCY).map(
                                        (currency) => ({
                                            value: CURRENCY[
                                                currency as keyof typeof CURRENCY
                                            ],
                                            label: t(currency),
                                        }),
                                    )}
                                    onChange={(e) => {
                                        setLocalBudget(
                                            new Budget({
                                                ...localBudget.toJson(),
                                                currency: e.target
                                                    .value as CURRENCY,
                                            }),
                                        )
                                    }}
                                    disabled={parentBudgetId !== ""}
                                />
                            </div>
                        </div>
                    </div>

                    <TextAreaInput
                        label={t("description")}
                        name="description"
                        value={localBudget.description}
                        onChange={(e) => {
                            setLocalBudget(
                                new Budget({
                                    ...localBudget.toJson(),
                                    description: e.target.value,
                                }),
                            )
                        }}
                        className="w-full"
                    />
                    {budgets.length > 0 && (
                        <div className="mt-2">
                            <p className="font-bold">{t("parent_budget")}</p>
                            <DropDown
                                value={parentBudgetId}
                                name={t("parent_budget")}
                                options={[
                                    ...budgets.map((budget) => ({
                                        value: budget.id,
                                        label: budget.name,
                                    })),
                                    {
                                        value: "",
                                        label: t("no_parent"),
                                    },
                                ]}
                                onChange={(e) => {
                                    setParentBudgetId(e.target.value)
                                }}
                                disabled={parentId ? true : false}
                            />
                        </div>
                    )}
                    <div className="flex items-center justify-end gap-4 mt-6">
                        <Button
                            name={t("cancel")}
                            icon="times"
                            className=""
                            onClick={() => closeModal()}
                        ></Button>
                        <Button
                            name={
                                budget
                                    ? parentId
                                        ? t("add")
                                        : t("edit")
                                    : t("create")
                            }
                            className="!bg-green-400 hover:!bg-green-600 !border-green-400 hover:border-green-600 text-white hover:text-white transition"
                            icon={parentId ? "plus" : "edit"}
                            onClick={budget ? handleEdit : handleCreate}
                        ></Button>
                    </div>
                </div>
            </div>
        </WithLoader>
    )
}
