import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { Tag, TagJson } from "@/models/Tag"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import { createTagApi, getProjectTagsApi } from "./tagApi"

export const createTag = createAsyncThunkWithNotification(
    "projects/createTag",
    async ({ projectId, payload }: { projectId: string; payload: TagJson }) => {
        const response = await createTagApi(projectId, payload)
        return response
    },
)

export const getProjectTags = createAsyncThunkWithNotification(
    "projects/getProjectTags",
    async (projectId: string) => {
        const response = await getProjectTagsApi(projectId)
        return response
    },
)

interface TagState {
    tags: TagJson[]
    status: ReducerStatus
    errors: FormErrors
}

const initialState: TagState = {
    tags: [],
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

const tagSlice = createSlice({
    name: "tag",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectTags.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getProjectTags.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.tags = action.payload.data.data
        })
        builder.addCase(getProjectTags.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.tags = []
        })
        builder.addCase(createTag.pending, (state) => {
            state.status.create = SliceStatus.LOADING
        })
        builder.addCase(createTag.fulfilled, (state, action) => {
            state.status.create = SliceStatus.IDLE
            state.tags.push(action.payload.data.data)
        })
        builder.addCase(createTag.rejected, (state, action) => {
            state.status.create = SliceStatus.FAILED
            state.tags = []
            state.errors = (action.payload as any).data
        })
    },
})

const selectTagsRaw = (state: RootState) => state.tags.tags
export const selectTags = createSelector([selectTagsRaw], (tags) =>
    tags.map((tag) => new Tag(tag)),
)

export const { clearErrors } = tagSlice.actions

export default tagSlice.reducer
