import { Outlet } from "react-router-dom"
import { Gantt } from "./Gantt/Index"

export const Planning = () => {
    return (
        <div className="h-full overflow-hidden">
            <Gantt />
            <Outlet></Outlet>
        </div>
    )
}
