import { axiosPublic } from "@/common/axios"
import { InvitationResponse } from "."

export const validateInvitationTokenApi = (token: string) => {
    return new Promise<{ data: any }>((resolve, reject) =>
        axiosPublic
            .post("/auth/validate-invitation", { token })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}

export const updateInvitationApi = (
    token: string,
    response: InvitationResponse,
) => {
    return new Promise<{ data: any }>((resolve, reject) =>
        axiosPublic
            .put("/auth/update-invitation", { token, response })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}
