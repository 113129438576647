import { Task } from "@/models/Task"
import {
    FilterFields,
    FilterGroup,
    FilterValuesType,
    TaskFilterFields,
} from "./Filters/Filters"

export const applyFilterToTasksAndChildren: (
    task: Task,
    filters: FilterValuesType<TaskFilterFields>,
    filterGroup: FilterGroup,
) => boolean = (task, filters, filterGroup) => {
    let valid = false
    valid = filterGroup.applyFilters(
        task,
        filters as FilterValuesType<FilterFields>,
    )
    return (
        valid ||
        task.children.some((child) =>
            applyFilterToTasksAndChildren(child, filters, filterGroup),
        )
    )
}

export const applyFilterToTasks: (
    task: Task[],
    filters: FilterValuesType<TaskFilterFields>,
    filterGroup: FilterGroup,
) => Task[] = (tasks, filters, filterGroup) => {
    return tasks.filter((task) =>
        filterGroup.applyFilters(
            task,
            filters as FilterValuesType<FilterFields>,
        ),
    )
}
