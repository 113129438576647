import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { TaskDelayStatusBar } from "@/components/Other/TaskDelayStatus"
import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { TaskStatusDetailBar } from "@/components/Other/TaskStatusDetailBar"
import { TASK_STATISTICS_TYPES, TaskStatistics } from "@/models/Statistics"
import { Task, TaskStatus } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
    getPermissionGroupInfo,
    getPermissionGroupsTasksStatisticsInProject,
    getPermissionGroupStatisticsInProject,
    getUserInfo,
    getUsersTasksStatisticsInProject,
    getUserStatisticsInProject,
    selectPermissionGroupInfo,
    selectStatisticsTasks,
    selectUserInfo,
} from "./statisticsSlice"

interface StatisticsIndividualProps {
    group: boolean
}

export const StatisticsIndividual: React.FC<StatisticsIndividualProps> = ({
    group,
}) => {
    const userId = useParams<{ userId: string }>().userId
    const groupId = useParams<{ groupId: string }>().groupId
    const projectId = useParams<{ projectId: string }>().projectId
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const userInfo = useAppSelector(selectUserInfo)
    const groupInfo = useAppSelector(selectPermissionGroupInfo)
    const tasks = useAppSelector(selectStatisticsTasks)
    const status = useAppSelector((state) => state.statistics.status)
    const [overAllScore, setOverAllScore] = useState<number>(0)
    const [timeScore, setTimeScore] = useState<number>(0)
    const [qualityScore, setQualityScore] = useState<number>(0)
    const [selectedStatistics, setSelectedStatistics] =
        useState<keyof TaskStatistics>("tasksApproved")
    const statistics = useAppSelector((state) => state.statistics.statistics)
    useEffect(() => {
        if (group) {
            dispatch(getPermissionGroupInfo({ projectId, groupId }))
            dispatch(
                getPermissionGroupStatisticsInProject({ projectId, groupId }),
            )
        } else {
            dispatch(getUserInfo({ projectId, userId }))
            dispatch(getUserStatisticsInProject({ projectId, userId }))
        }
    }, [])
    useEffect(() => {
        const timeScore = (statistics.tasksOnTime / statistics.tasksCount) * 100
        const qualityScore =
            100 - (statistics.tasksWithIssues / statistics.tasksCount) * 100
        setTimeScore(timeScore)
        setQualityScore(qualityScore)
        setOverAllScore((timeScore + qualityScore) / 2)
    }, [statistics])
    useEffect(() => {
        const statisticsMapping: Record<string, TASK_STATISTICS_TYPES> = {
            tasksApproved: TASK_STATISTICS_TYPES.APPROVED,
            tasksWithIssues: TASK_STATISTICS_TYPES.WITH_ISSUES,
            tasksApprovedWithIssues: TASK_STATISTICS_TYPES.APPROVED_WITH_ISSUES,
            tasksApprovedWithoutIssues:
                TASK_STATISTICS_TYPES.APPROVED_WITHOUT_ISSUES,
            tasksOnTime: TASK_STATISTICS_TYPES.ON_TIME,
            tasksDelayedStart: TASK_STATISTICS_TYPES.DELAYED_START,
            tasksDelayedEnd: TASK_STATISTICS_TYPES.DELAYED_END,
            tasksDelayedBoth: TASK_STATISTICS_TYPES.DELAYED_BOTH,
        }
        const type = statisticsMapping[selectedStatistics]
        if (group) {
            dispatch(
                getPermissionGroupsTasksStatisticsInProject({
                    projectId,
                    groupId,
                    type,
                }),
            )
        } else {
            dispatch(
                getUsersTasksStatisticsInProject({
                    projectId,
                    userId,
                    type,
                }),
            )
        }
    }, [selectedStatistics])
    const columns = useMemo<MRT_ColumnDef<Task>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("task"),
                size: 300,
            },
            {
                accessorKey: "description",
                header: t("description"),
                size: 500,
            },
            {
                id: "startDate",
                header: t("start_date"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {t("intlDateTime", {
                            val: new Date(row.original.startDate),
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </p>
                ),
            },
            {
                id: "endDate",
                header: t("end_date"),
                Cell: ({ renderedCellValue, row }) => (
                    <p>
                        {t("intlDateTime", {
                            val: new Date(row.original.endDate),
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </p>
                ),
            },
            {
                accessorKey: "status",
                header: t("status"),
                Cell: ({ renderedCellValue, row }) => (
                    <TaskStatusBar taskStatus={row.original.status} />
                ),
            },
            {
                accessorKey: "statusDetail",
                header: t("status_detail"),
                Cell: ({ renderedCellValue, row }) => (
                    <TaskStatusDetailBar
                        taskStatusDetail={row.original.statusDetail}
                    />
                ),
            },
            {
                accessorKey: "delayStatus",
                header: t("delay_status"),
                Cell: ({ renderedCellValue, row }) =>
                    row.original.status !== TaskStatus.NOT_STARTED ? (
                        <TaskDelayStatusBar
                            taskDelayStatus={row.original.delayStatus}
                        />
                    ) : (
                        <p></p>
                    ),
            },
        ],
        [],
    )
    const table = useMantineReactTable<Task>({
        columns,
        data: tasks,
        positionPagination: "top",
        state: { isLoading: status.read === SliceStatus.LOADING },
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                navigate(
                    `/dashboard/projects/${projectId}/tasks/${row.original.id}/`,
                )
            },
            sx: {
                cursor: "pointer", //you might want to change the cursor too when adding an onClick
            },
        }),
        initialState: {
            columnVisibility: {
                description: false,
            },
        },
    })
    return (
        <div className="p-5 flex flex-col">
            <h1
                className="flex items-center justify-start font-bold text-xl cursor-pointer hover:text-primary-100 transition my-2"
                onClick={() => history.back()}
            >
                <FontAwesomeIcon icon="arrow-left" className="mr-2" />
                <p>{t("back")}</p>
            </h1>
            <Scrollable>
                <div className="flex items-center justify-center">
                    <WithLoader
                        statuses={[status.read]}
                        title={t("loading_profile")}
                        childClassName="flex items-center justify-center"
                    >
                        <div className="flex items-center gap-4 justify-center sm:flex-wrap">
                            <div className="bg-secondary-100 rounded-lg shadow-lg w-fit p-5 flex flex-col gap-4">
                                <div className="text-center w-60 flex flex-col items-center justify-center">
                                    {group ? (
                                        <div className="w-40 h-40 flex items-center justify-center rounded-full border-4 border-primary-300">
                                            <FontAwesomeIcon
                                                icon="users"
                                                className="text-8xl"
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={userInfo.profilePicture.path}
                                            alt={userInfo.fullName}
                                            className="w-40 h-40 rounded-full mx-auto mt-4"
                                        />
                                    )}
                                    <p className="font-bold text-xl">
                                        {group
                                            ? groupInfo.name
                                            : userInfo.fullName}
                                    </p>
                                    <p className="text-xs">
                                        {group
                                            ? groupInfo.description
                                            : userInfo.email}
                                    </p>
                                    <p className="text-xs">
                                        <FontAwesomeIcon
                                            icon="calendar"
                                            className="mr-2"
                                        />
                                        {group
                                            ? t("created_on")
                                            : t("joined_on")}
                                        :{" "}
                                        {t("intlDateTime", {
                                            val: group
                                                ? new Date(groupInfo.createdAt)
                                                : new Date(userInfo.createdAt),
                                            formatParams: {
                                                val: {
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "numeric",
                                                },
                                            },
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-2">
                                <div className="bg-secondary-100 rounded-lg shadow-lg h-full p-5 gap-4 text-center font-bold">
                                    <h3>
                                        <FontAwesomeIcon
                                            icon="chart-line"
                                            className="mr-2"
                                        />
                                        {t("overall_score")}
                                    </h3>
                                    <p>{overAllScore.toFixed(2)}%</p>
                                </div>
                                <div className="bg-secondary-100 rounded-lg shadow-lg h-full p-5 gap-4 text-center">
                                    <h2>
                                        <FontAwesomeIcon
                                            icon="clock"
                                            className="mr-2"
                                        />
                                        {t("time_score")}
                                    </h2>
                                    <p>{timeScore.toFixed(2)}%</p>
                                </div>
                                <div className="bg-secondary-100 rounded-lg shadow-lg h-full p-5 gap-4 text-center">
                                    <h2>
                                        <FontAwesomeIcon
                                            icon="check"
                                            className="mr-2"
                                        />
                                        {t("quality_score")}
                                    </h2>
                                    <p>{qualityScore.toFixed(2)}%</p>
                                </div>
                            </div>
                        </div>
                    </WithLoader>
                </div>
                <div className="flex justify-between mt-4 flex-wrap gap-y-4">
                    <WithLoader
                        statuses={[status.read]}
                        title={t("loading_statistics")}
                        childClassName="!h-fit"
                    >
                        <div className="flex justify-between mt-4 flex-wrap gap-y-4 w-full sm:flex-wrap">
                            {Object.entries(statistics)
                                .filter(([key, value]) => key !== "tasksCount")
                                .map(([key, value]) => (
                                    <div
                                        key={key}
                                        className="w-1/4 sm:w-full lg:w-1/2 h-full"
                                        onClick={() =>
                                            setSelectedStatistics(
                                                key as keyof TaskStatistics,
                                            )
                                        }
                                    >
                                        <div
                                            className={`p-3 bg-secondary-100 rounded-lg shadow-lg h-full w-11/12 cursor-pointer ${
                                                selectedStatistics === key
                                                    ? "!bg-primary-100 text-secondary-100"
                                                    : ""
                                            } hover:bg-primary-100 hover:text-secondary-100 transition`}
                                        >
                                            <p className="font-bold">
                                                {t(key)}
                                            </p>
                                            <p>
                                                {(
                                                    (value /
                                                        statistics.tasksCount) *
                                                    100
                                                ).toFixed(2)}
                                                % ({value})
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </WithLoader>
                </div>
                <div className="h-full my-8">
                    <MantineReactTable table={table} />
                </div>
            </Scrollable>
        </div>
    )
}
