import { Tag } from "@/models/Tag"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface TagComponentProps {
    tag: Tag
    short?: boolean
}

export const TagComponent: React.FC<TagComponentProps> = ({
    tag,
    short = false,
}) => {
    return (
        <div
            className={`shadow-lg w-fit flex items-center gap-1 px-2 py-1 ${short ? "rounded-full" : "rounded-lg"}`}
            style={{
                backgroundColor: tag.backgroundColor !== "" ? tag.backgroundColor : "#000000",
                color: tag.color !== "" ? tag.color : "#ffffff",
            }}
        >
            <FontAwesomeIcon
                icon={tag.icon !== "" ? tag.icon  as IconProp : "question"}
            />
            {tag.name !== "" && <span>{tag.name}</span>}
        </div>
    )
}
