import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { Invitation, InvitationInterface } from "@/models/Auth"
import {
    PermissionGroup,
    PermissionGroupCreatePayload,
    PermissionGroupJsonInterface,
    PermissionType,
} from "@/models/Permission"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    addUsersToPermissionGroupApi,
    createPermissionGroupApi,
    deletePermissionGroupApi,
    getProjectGroupByIdAndProjectIdApi,
    getProjectGroupsApi,
    getProjectInvitationsApi,
    getUserPermissionsInProjectApi,
    inviteUsersToProjectApi,
    removeUserFromPermissionGroupApi,
    removeUserFromProjectApi,
    setUserProjectPermissionsApi,
    updatePermissionGroupApi,
} from "./userManagementApi"

export const getProjectGroups = createAsyncThunkWithNotification(
    "userManagement/getProjectGroups",
    async (projectId: string) => {
        const response = await getProjectGroupsApi(projectId)
        return response
    },
)

export const getProjectGroupByIdAndProjectId = createAsyncThunkWithNotification(
    "userManagement/getProjectGroupByIdAndProjectId",
    async ({ projectId, groupId }: { projectId: string; groupId: string }) => {
        const response = await getProjectGroupByIdAndProjectIdApi(
            projectId,
            groupId,
        )
        return response
    },
)

export const createPermissionGroup = createAsyncThunkWithNotification(
    "userManagement/createPermissionGroup",
    async ({
        projectId,
        createPayload,
    }: {
        projectId: string
        createPayload: PermissionGroupCreatePayload
    }) => {
        const response = await createPermissionGroupApi(
            projectId,
            createPayload,
        )
        return response
    },
)

export const updatePermissionGroup = createAsyncThunkWithNotification(
    "userManagement/updatePermissionGroup",
    async ({
        projectId,
        groupId,
        createPayload,
    }: {
        projectId: string
        groupId: string
        createPayload: PermissionGroupCreatePayload
    }) => {
        const response = await updatePermissionGroupApi(
            projectId,
            groupId,
            createPayload,
        )
        return response
    },
)

export const addUserToPermissionGroup = createAsyncThunkWithNotification(
    "userManagement/addUserToPermissionGroup",
    async ({
        projectId,
        groupId,
        userEmails,
        specialPermissions = [],
    }: {
        projectId: string
        groupId: string
        userEmails: string[]
        specialPermissions?: PermissionType[]
    }) => {
        const response = await addUsersToPermissionGroupApi(
            projectId,
            groupId,
            userEmails,
            specialPermissions,
        )
        return response
    },
)

export const deletePermissionGroup = createAsyncThunkWithNotification(
    "userManagement/deletePermissionGroup",
    async ({ projectId, groupId }: { projectId: string; groupId: string }) => {
        const response = await deletePermissionGroupApi(projectId, groupId)
        return response
    },
)

export const removeUserFromPermissionGroup = createAsyncThunkWithNotification(
    "userManagement/removeUserFromPermissionGroup",
    async ({
        projectId,
        groupId,
        userId,
    }: {
        projectId: string
        groupId: string
        userId: string
    }) => {
        const response = await removeUserFromPermissionGroupApi(
            projectId,
            groupId,
            userId,
        )
        return response
    },
)

export const inviteUsersToProject = createAsyncThunkWithNotification(
    "userManagement/inviteUsersToProject",
    async ({ projectId, emails }: { projectId: string; emails: string[] }) => {
        const response = await inviteUsersToProjectApi(projectId, emails)
        return response
    },
)

export const removeUserFromProject = createAsyncThunkWithNotification(
    "userManagement/removeUserFromProject",
    async ({ projectId, userId }: { projectId: string; userId: string }) => {
        const response = await removeUserFromProjectApi(projectId, userId)
        return response
    },
)

export const setUserProjectPermissions = createAsyncThunkWithNotification(
    "userManagement/setUserProjectPermissions",
    async ({
        projectId,
        userId,
        permissions,
    }: {
        projectId: string
        userId: string
        permissions: PermissionType[]
    }) => {
        const response = await setUserProjectPermissionsApi(
            projectId,
            userId,
            permissions,
        )
        return response
    },
)

export const getProjectInvitations = createAsyncThunkWithNotification(
    "userManagement/getProjectInvitations",
    async (projectId: string) => {
        const response = await getProjectInvitationsApi(projectId)
        return response
    },
)

export const getUserPermissionsInProject = createAsyncThunkWithNotification(
    "userManagement/getUserPermissionsInProject",
    async ({ projectId, userId }: { projectId: string; userId: string }) => {
        const response = await getUserPermissionsInProjectApi(projectId, userId)
        return response
    },
)

export interface UserManagementState {
    invitations: InvitationInterface[]
    groups: PermissionGroupJsonInterface[]
    group: PermissionGroupJsonInterface
    selectedUserPermissions: PermissionType[]
    status: ReducerStatus
    errors: FormErrors
}

const initialState: UserManagementState = {
    invitations: [],
    groups: [],
    group: new PermissionGroup().toJson(),
    selectedUserPermissions: [],
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

const userManagementSlice = createSlice({
    name: "userManagement",
    initialState: initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPermissionGroup.pending, (state) => {
                state.status.create = SliceStatus.LOADING
            })
            .addCase(createPermissionGroup.fulfilled, (state, action) => {
                state.status.create = SliceStatus.IDLE
                state.groups = [...state.groups, action.payload.data.data]
            })
            .addCase(createPermissionGroup.rejected, (state, action) => {
                state.status.create = SliceStatus.FAILED
                state.groups = []
                state.errors = (action.payload as any).data
            })
            .addCase(updatePermissionGroup.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(updatePermissionGroup.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.group = action.payload.data.data
                state.groups = state.groups.map((group) =>
                    group.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : group,
                )
            })
            .addCase(updatePermissionGroup.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.group = new PermissionGroup().toJson()
                state.errors = (action.payload as any).data
            })
            .addCase(getProjectGroups.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectGroups.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.groups = action.payload.data.data
            })
            .addCase(getProjectGroups.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.groups = []
            })
            .addCase(getProjectGroupByIdAndProjectId.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(
                getProjectGroupByIdAndProjectId.fulfilled,
                (state, action) => {
                    state.status.read = SliceStatus.IDLE
                    state.group = action.payload.data.data
                },
            )
            .addCase(getProjectGroupByIdAndProjectId.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.group = new PermissionGroup().toJson()
            })
            .addCase(addUserToPermissionGroup.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(addUserToPermissionGroup.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.group = action.payload.data.data
            })
            .addCase(addUserToPermissionGroup.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
                state.group = new PermissionGroup().toJson()
            })
            .addCase(deletePermissionGroup.pending, (state) => {
                state.status.delete = SliceStatus.LOADING
            })
            .addCase(deletePermissionGroup.fulfilled, (state, action) => {
                state.status.delete = SliceStatus.IDLE
                state.groups = state.groups.filter(
                    (group) => group.id !== action.payload.data.data,
                )
            })
            .addCase(deletePermissionGroup.rejected, (state) => {
                state.status.delete = SliceStatus.FAILED
            })
            .addCase(removeUserFromPermissionGroup.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(
                removeUserFromPermissionGroup.fulfilled,
                (state, action) => {
                    state.status.update = SliceStatus.IDLE
                },
            )
            .addCase(removeUserFromPermissionGroup.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
            })

            .addCase(inviteUsersToProject.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(inviteUsersToProject.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.invitations = [
                    ...state.invitations,
                    ...action.payload.data.data.invitations,
                ]
            })
            .addCase(inviteUsersToProject.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
                state.invitations = []
            })

            .addCase(removeUserFromProject.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(removeUserFromProject.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
            })
            .addCase(removeUserFromProject.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(setUserProjectPermissions.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(setUserProjectPermissions.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
            })
            .addCase(setUserProjectPermissions.rejected, (state) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(getProjectInvitations.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectInvitations.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.invitations = action.payload.data.data
            })
            .addCase(getProjectInvitations.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.invitations = []
            })
            .addCase(getUserPermissionsInProject.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUserPermissionsInProject.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.selectedUserPermissions = action.payload.data.data
            })
            .addCase(getUserPermissionsInProject.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.selectedUserPermissions = []
            })
    },
})

const selectRawProjectGroups = (state: RootState) => state.userManagement.groups

const selectRawProjectGroup = (state: RootState) => state.userManagement.group

const selectRawInvitations = (state: RootState) =>
    state.userManagement.invitations

export const selectProjectInvitations = createSelector(
    [selectRawInvitations],
    (invitations) =>
        invitations.map((invitation) => new Invitation(invitation)),
)

export const selectProjectGroups = createSelector(
    [selectRawProjectGroups],
    (groups) => groups.map((group) => new PermissionGroup(group)),
)

export const selectProjectGroup = createSelector(
    [selectRawProjectGroup],
    (group) => new PermissionGroup(group),
)

export const { clearErrors } = userManagementSlice.actions

export default userManagementSlice.reducer
