import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { eachWeekOfInterval } from "date-fns"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import {
    getProjectStatsInInterval,
    getUserStatsInInterval,
} from "../adminSlice"

interface AdminStatsPropsProps {}

export const AdminStats: React.FC<AdminStatsPropsProps> = () => {
    const { t } = useTranslation()
    const [projectData, setProjectData] = useState<any[]>([])
    const [usersData, setUsersData] = useState<any[]>([])
    const [dateInterval, setDateInterval] = useState<Date[]>([])
    const dispatch = useAppDispatch()
    const userStats = useAppSelector((state) => state.admin.userStats)
    const projectStats = useAppSelector((state) => state.admin.projectStats)
    useEffect(() => {
        const today = new Date()
        const todayMinusMonth = new Date(today)
        todayMinusMonth.setDate(today.getDate() - 15)
        const todayPlusMonth = new Date(today)
        todayPlusMonth.setDate(today.getDate() + 15)
        const interval = eachWeekOfInterval({
            start: todayMinusMonth,
            end: todayPlusMonth,
        })
        setDateInterval(interval)
    }, [])
    useEffect(() => {
        dispatch(
            getUserStatsInInterval({
                startDate: dateInterval[0],
                endDate: dateInterval[dateInterval.length - 1],
            }),
        )
        dispatch(
            getProjectStatsInInterval({
                startDate: dateInterval[0],
                endDate: dateInterval[dateInterval.length - 1],
            }),
        )
    }, [dateInterval])
    useEffect(() => {
        const projectData = Object.keys(projectStats).map((key) => {
            return {
                name: key,
                [t("active")]: projectStats[key].active,
                [t("archived")]: projectStats[key].archived,
                [t("total")]:
                    projectStats[key].active + projectStats[key].archived,
            }
        })
        setProjectData(projectData)
    }, [projectStats])
    useEffect(() => {
        const usersData = Object.keys(userStats).map((key) => {
            return {
                name: key,
                [t("active")]: userStats[key].active,
                [t("archived")]: userStats[key].archived,
                [t("total")]: userStats[key].active + userStats[key].archived,
            }
        })
        setUsersData(usersData)
    }, [userStats])

    return (
        <div className="w-full">
            <div className="flex gap-2 justify-between h-[400px]">
                <div className="bg-secondary-100 rounded-lg shadow-lg p-5 flex justify-center items-center flex-col w-5/12 h-full">
                    <div className="flex gap-2 justify-evenly items-center py-2 text-2xl">
                        <FontAwesomeIcon icon="folder" />
                        <h1 className="font-bold text-lg">{t("projects")}</h1>
                    </div>
                    <div className="w-full h-full p-4 flex justify-center items-center">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={projectData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey={t("active")}
                                    stroke="#2b75d4"
                                    activeDot={{ r: 8 }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey={t("archived")}
                                    stroke="#82ca9d"
                                />
                                <Line
                                    type="monotone"
                                    dataKey={t("total")}
                                    stroke="#4ade80"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="bg-secondary-100 rounded-lg shadow-lg p-5 flex justify-center items-center flex-col w-7/12 h-full">
                    <div className="flex gap-2 justify-evenly items-center py-2 text-2xl">
                        <FontAwesomeIcon icon="users" />
                        <h1 className="font-bold text-lg">{t("users")}</h1>
                    </div>
                    <div className="w-full h-full p-4 flex justify-center items-center">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={usersData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey={t("active")}
                                    stroke="#2b75d4"
                                    activeDot={{ r: 8 }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey={t("archived")}
                                    stroke="#82ca9d"
                                />
                                <Line
                                    type="monotone"
                                    dataKey={t("total")}
                                    stroke="#4ade80"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}
