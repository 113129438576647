import { CURRENCY } from "@/models/Finance"
import { useEffect, useState } from "react"

interface CurrencyProps {
    currency: CURRENCY
    classnames?: string
}

export const Currency: React.FC<CurrencyProps> = ({ currency, classnames }) => {
    const currencyMapping: {
        [key in CURRENCY]: string
    } = {
        [CURRENCY.USD]: "$",
        [CURRENCY.EUR]: "€",
        [CURRENCY.TND]: "TND",
        [CURRENCY.GBP]: "£",
    }
    const [localCurrency, setLocalCurrency] = useState<string>("")
    useEffect(() => {
        setLocalCurrency(currencyMapping[currency])
    }, [currency])

    return <div className={classnames}>{localCurrency}</div>
}
