import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { FileIcon } from "../FileIcon"
import {
    getFolderContentFiles,
    getFolderContentFolders,
    selectFolder,
} from "../fileManagerSlice"
import { FolderIcon } from "../FolderIcon"

interface BrowserFolderProps {}

export const BrowserFolder: React.FC<BrowserFolderProps> = () => {
    const folderId = useParams<{ folderId: string }>()?.folderId ?? ""
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const browserContainerRef = useRef<HTMLDivElement>(null)
    const highlightedRef = useRef<HTMLDivElement>(null)
    const [queryParams] = useSearchParams()
    const [highlightedResource, setHighlightedResource] = useState<string>("")
    const folder = useAppSelector(selectFolder)
    const status = useAppSelector((state) => state.fileManager.manyStatus)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getFolderContentFolders({ projectId, folderId }))
        dispatch(getFolderContentFiles({ projectId, folderId }))
    }, [folderId])
    useEffect(() => {
        const resource = queryParams.get("resource")
        if (resource) {
            setHighlightedResource(resource)
            if (browserContainerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        browserContainerRef.current.getBoundingClientRect()
                    browserContainerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current])
    return (
        <div className="w-full flex flex-col overflow-hidden h-full">
            <div className="p-2 flex justify-between items-center w-full">
                <h1 className="text-2xl font-bold">{t("files")}:</h1>
                <Link
                    className="flex items-center justify-center gap-1 font-bold text-lg hover:text-primary-100 transition"
                    to={
                        folder.parent
                            ? `/dashboard/projects/${projectId}/file-manager/${folder.parent.id}`
                            : `/dashboard/projects/${projectId}/file-manager`
                    }
                >
                    <FontAwesomeIcon icon="arrow-left" />
                    <p>{t("back")}</p>
                </Link>
            </div>
            <WithLoader
                statuses={[status.read]}
                title={t("loading_files")}
                className="w-full h-full flex flex-wrap overflow-hidden"
            >
                <Scrollable height="100%" innerRef={browserContainerRef}>
                    <div className="flex flex-wrap gap-2 sm:justify-evenly">
                        {folder.children.map((folder) => (
                            <FolderIcon
                                key={folder.id}
                                folder={folder}
                                highlighted={highlightedResource === folder.id}
                                innerRef={
                                    highlightedResource === folder.id
                                        ? highlightedRef
                                        : null
                                }
                            />
                        ))}
                        {folder.files.map((file) => (
                            <FileIcon
                                key={file.id}
                                file={file}
                                highlighted={highlightedResource === file.id}
                                innerRef={
                                    highlightedResource === file.id
                                        ? highlightedRef
                                        : null
                                }
                            />
                        ))}
                    </div>
                </Scrollable>

                {folder.files.length === 0 && folder.children.length === 0 && (
                    <div className="p-2 opacity-60 text-primary-300 w-full h-full flex flex-col gap-3 justify-center items-center">
                        <FontAwesomeIcon icon="search" size="3x" />
                        {t("no_items_found")}
                    </div>
                )}
            </WithLoader>
        </div>
    )
}
