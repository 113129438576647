import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { InputLabel } from "./InputLabel"

interface InputProps {
    value: string | number
    type: string
    name: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label?: string
    inputClassName?: string
    className?: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    customValidator?: (value: string | number) => {
        isValid: boolean
        error: string
    }
    setValid?: (key: string, value: boolean) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    errors?: string[]
}

const emailValidator = (value: string | number) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.toString())
    return { isValid, error: "invalid_email" }
}

const passwordValidator = (value: string | number) => {
    let isValid = value.toString().length >= 8
    if (!isValid) return { isValid, error: "password_atleast_8_characters" }
    isValid = isValid && /[A-Z]/.test(value.toString())
    if (!isValid) return { isValid, error: "password_atleast_1_uppercase" }
    isValid = isValid && /[a-z]/.test(value.toString())
    if (!isValid) return { isValid, error: "password_atleast_1_lowercase" }
    isValid = isValid && /[0-9]/.test(value.toString())
    if (!isValid) return { isValid, error: "password_atleast_1_number" }
    isValid =
        isValid && /[!@#$%^&*()_+=[{\]};:'",<.>/?\\|]+/.test(value.toString())
    if (!isValid)
        return { isValid, error: "password_atleast_1_special_character" }
    return { isValid, error: "" }
}

export const Input: React.FC<InputProps> = ({
    type,
    inputClassName = "",
    className = "",
    customValidator = () => ({ isValid: true, error: "" }),
    required,
    disabled = false,
    placeholder,
    label,
    value,
    onChange,
    name,
    setValid,
    onBlur,
    onKeyDown,
    errors,
}) => {
    const { t } = useTranslation()
    const [localErrors, setLocalErrors] = React.useState<string[]>(errors ?? [])
    let validator = customValidator
    if (type === "email") validator = emailValidator
    if (type === "password") validator = passwordValidator
    useEffect(() => {
        if (setValid) {
            const timeout = setTimeout(() => {
                if (value === "") return
                const { isValid, error } = validator(value)
                if (!isValid) {
                    setLocalErrors([t(error)])
                    setValid(name, false)
                } else {
                    setLocalErrors([])
                    setValid(name, true)
                }
            }, 500)
            return () => clearTimeout(timeout)
        } else {
            setLocalErrors([])
        }
    }, [value])

    useEffect(() => {
        setLocalErrors(errors ?? [])
    }, [errors])

    return (
        <div className={`mt-2 ${className} ${disabled ? "opacity-40" : ""}`}>
            <InputLabel label={label} required={required} />
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                required={required}
                value={value ?? ""}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className={`outline-none p-1  pl-4 w-full rounded-lg ${localErrors.length > 0 ? "border-red-600" : "border-secondary-400"} border transition ${
                    disabled
                        ? "opacity-40"
                        : localErrors.length > 0
                          ? ""
                          : "hover:border-primary-100 focus:border-primary-100"
                } ${inputClassName}`}
            ></input>
            {localErrors.length > 0 &&
                localErrors.length > 0 &&
                localErrors.map((error, index) => (
                    <p key={index} className="text-red-600 text-xs">
                        {t(error)}
                    </p>
                ))}
        </div>
    )
}
