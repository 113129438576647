import { BaseModel, BaseModelInterface } from "./BaseModel"
import { FileJsonInterface, ProjectFile } from "./File"
import { NotificationType } from "./Notification"
import { PermissionGroup, PermissionGroupJsonInterface } from "./Permission"
import { Tag, TagJson } from "./Tag"
import {
    Task,
    TaskComment,
    TaskCommentJsonInterface,
    TaskDelayStatus,
    TaskJsonInterface,
    TaskReviewStatus,
    TaskStatus,
    TaskStatusDetail,
} from "./Task"
import { User, UserInterface } from "./User"

export enum TaskActivityTypes {
    ASSIGN_ASSIGNEE = "assign_assignee",
    UNASSIGN_ASSIGNEE = "unassign_assignee",
    ADD_REVIEWER = "add_reviewer",
    REMOVE_REVIEWER = "remove_reviewer",
    ADD_FOLLOWER = "add_follower",
    REMOVE_FOLLOWER = "remove_follower",
    CHANGE_STATUS = "change_status",
    ADD_COMMENT = "add_comment",
    REMOVE_COMMENT = "remove_comment",
    ADD_ATTACHMENT = "add_attachment",
    REMOVE_ATTACHMENT = "remove_attachment",
    ADD_TAG = "add_tag",
    REMOVE_TAG = "remove_tag",
    ADD_DEPENDENCY = "add_dependency",
    REMOVE_DEPENDENCY = "remove_dependency",
    UPDATED_REVIEW = "updated_review",
    ASKED_FOR_REVIEW = "asked_for_review",
    SENT_NOTIFICATION = "sent_notification",
}

export type TaskActivityDataKeys =
    | "commentIds"
    | "attachmentIds"
    | "tagIds"
    | "status"
    | "dependencyTaskIds"
    | "userIds"
    | "groupsIds"
    | "reviewStatus"
    | "notificationType"

export type TaskActivityParsedDataKeys =
    | "comments"
    | "attachments"
    | "tags"
    | "status"
    | "dependencyTasks"
    | "users"
    | "groups"
    | "reviewStatus"
    | "notificationType"

export type TaskActivityParsedDataTypes =
    | User
    | TaskComment
    | ProjectFile
    | Tag
    | Task
    | PermissionGroup
    | TaskStatus
    | TaskReviewStatus
    | NotificationType
    | TaskStatusDetail
    | TaskDelayStatus

export type TaskActivityParsedDataTypesInterface =
    | UserInterface
    | TaskCommentJsonInterface
    | FileJsonInterface
    | TagJson
    | TaskJsonInterface
    | PermissionGroupJsonInterface
    | TaskStatus
    | TaskReviewStatus
    | NotificationType
    | TaskStatusDetail
    | TaskDelayStatus

export interface TaskActivityInterface extends BaseModelInterface {
    type: TaskActivityTypes
    data: { [key in TaskActivityDataKeys]?: (string | TaskStatus)[] }
    parsedData: {
        [key in TaskActivityParsedDataKeys]?: TaskActivityParsedDataTypesInterface[]
    }
    doneBy: UserInterface
}

export class TaskActivity extends BaseModel {
    public type: TaskActivityTypes
    public data: { [key in TaskActivityDataKeys]?: (string | TaskStatus)[] }
    public parsedData: {
        [key in TaskActivityParsedDataKeys]?: TaskActivityParsedDataTypes[]
    }
    public doneBy: User

    constructor(data?: TaskActivityInterface) {
        super(data)
        this.type = data?.type ?? TaskActivityTypes.CHANGE_STATUS
        this.data = data?.data ?? {}
        this.doneBy = data?.doneBy ? new User(data.doneBy) : new User()
        this.parsedData = data?.parsedData
            ? this.parseData(data.parsedData)
            : {}
    }

    private parseData(data: {
        [key in TaskActivityParsedDataKeys]?: TaskActivityParsedDataTypesInterface[]
    }) {
        const parsedData: {
            [key in TaskActivityParsedDataKeys]?: TaskActivityParsedDataTypes[]
        } = {}
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                parsedData[key as TaskActivityParsedDataKeys] =
                    this.parsedDataTransformerMapping[
                        key as TaskActivityParsedDataKeys
                    ](
                        data[
                            key as TaskActivityParsedDataKeys
                        ] as TaskActivityParsedDataTypesInterface[],
                    )
            }
        }
        return parsedData
    }

    private parsedDataTransformerMapping: {
        [key in TaskActivityParsedDataKeys]: (
            data: TaskActivityParsedDataTypesInterface[],
        ) => TaskActivityParsedDataTypes[]
    } = {
        comments: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map(
                (comment) =>
                    new TaskComment(comment as TaskCommentJsonInterface),
            ) ?? [],
        attachments: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map(
                (attachment) =>
                    new ProjectFile(attachment as FileJsonInterface),
            ) ?? [],
        tags: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map((tag) => new Tag(tag as TagJson)) ?? [],
        status: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map((status) => status as TaskStatus) ?? [],
        dependencyTasks: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map((task) => new Task(task as TaskJsonInterface)) ?? [],
        users: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map((assignee) => new User(assignee as UserInterface)) ?? [],
        groups: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map(
                (group) =>
                    new PermissionGroup(group as PermissionGroupJsonInterface),
            ) ?? [],
        reviewStatus: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map((status) => status as TaskReviewStatus) ?? [],
        notificationType: (data: TaskActivityParsedDataTypesInterface[]) =>
            data?.map((type) => type as NotificationType) ?? [],
    }

    public toJson(): TaskActivityInterface {
        return {
            ...super.toJson(),
            type: this.type,
            data: this.data,
            doneBy: this.doneBy.toJson(),
            parsedData: {
                comments: this.parsedData.comments?.map((comment) =>
                    (comment as TaskComment).toJson(),
                ),
                attachments: this.parsedData.attachments?.map((attachment) =>
                    (attachment as ProjectFile).toJson(),
                ),
                tags: this.parsedData.tags?.map((tag) => (tag as Tag).toJson()),
                status: this.parsedData.status?.map(
                    (status) => status as TaskStatus,
                ),
                dependencyTasks: this.parsedData.dependencyTasks?.map((task) =>
                    (task as Task).toJson(),
                ),
                users: this.parsedData.users?.map((user) =>
                    (user as User).toJson(),
                ),
                groups: this.parsedData.groups?.map((group) =>
                    (group as PermissionGroup).toJson(),
                ),
                reviewStatus: this.parsedData.reviewStatus?.map(
                    (status) => status as TaskReviewStatus,
                ),
                notificationType: this.parsedData.notificationType?.map(
                    (type) => type as NotificationType,
                ),
            },
        }
    }
}
