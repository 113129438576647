import { TagComponent } from "@/components/Other/TagComponent"
import { Task, TaskStatus } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

interface TaskCardProps {
    task: Task
}

export const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const { t } = useTranslation()

    const cutText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return `${text.slice(0, maxLength)}...`
        }
        return text
    }

    const taskStatusClassesMap = {
        [TaskStatus.NOT_STARTED]: "bg-gray-400 text-secondary-100",
        [TaskStatus.IN_PROGRESS]: "bg-secondary-300 text-primary-100",
        [TaskStatus.COMPLETED]: "bg-green-400 text-secondary-100",
    }

    return (
        <Link
            to={`/dashboard/projects/${projectId}/tasks/${task.id}`}
            className="w-full"
        >
            <div className="rounded-lg shadow-lg bg-secondary-100 cursor-pointer w-full  h-full">
                <h1
                    className={`font-bold w-full px-3 py-2 flex flex-wrap justify-between ${
                        taskStatusClassesMap[task.status]
                    }`}
                >
                    {task.name}
                </h1>
                <div className="p-3 flex flex-col gap-1">
                    {task.parent && (
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon="project-diagram"
                                className="text-primary-300 text-xs"
                            />
                            <p className="!m-0 font-bold text-sm self text-primary-300">
                                {task.parent.name}
                            </p>
                        </div>
                    )}
                    <p className="text-sm text-primary-100">
                        {cutText(task.description, 80)}
                    </p>
                    <div className="flex justify-between">
                        <div className="text-xs flex items-center gap-1 opacity-50 text-primary-100">
                            <p>
                                {t("intlDateTime", {
                                    val: task.startDate,
                                    formatParams: {
                                        val: {
                                            weekday: "short",
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        },
                                    },
                                })}
                            </p>
                        </div>
                        <FontAwesomeIcon
                            icon="arrow-right"
                            className="text-primary-100"
                        />
                        <div className="text-xs flex items-center gap-1 opacity-50 text-primary-100">
                            <p>
                                {t("intlDateTime", {
                                    val: task.endDate,
                                    formatParams: {
                                        val: {
                                            weekday: "short",
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        },
                                    },
                                })}
                            </p>
                        </div>
                    </div>
                    <div className="flex gap-2 flex-wrap">
                        {task.tags.map((tag) => (
                            <TagComponent key={tag.id} tag={tag} />
                        ))}
                    </div>
                </div>
            </div>
        </Link>
    )
}
