import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { Form, SubmitType } from "@/features/Form/Form"
import { Project } from "@/models/Project"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { clearErrors, unfreezeProject } from "../../adminSlice"

interface UnfreezeProjectFormProps {
    project: Project
    closeModal: () => void
}

export const UnfreezeProjectForm: React.FC<UnfreezeProjectFormProps> = ({
    project,
    closeModal,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.admin.status)
    const [projectNameConfirmation, setProjectNameConfirmation] = useState("")
    return (
        <Form
            loadingTitle={t("unfreezing_project")}
            onSubmit={async () => {
                if (projectNameConfirmation === project.name) {
                    const { type } = await dispatch(unfreezeProject(project.id))
                    if (type === unfreezeProject.fulfilled.type) {
                        closeModal()
                    }
                }
            }}
            clearErrors={clearErrors}
            statuses={[status.update]}
            submitType={SubmitType.Submit}
            onCancel={closeModal}
        >
            <h1 className="font-bold text-lg mb-2">
                {t("unfreezing_project", { project: project.name })}
            </h1>
            <p>{t("unfreeze_project_warning", { project: project.name })}</p>
            <Input
                label={t("confirm_project_name")}
                value={projectNameConfirmation}
                onChange={(e) => setProjectNameConfirmation(e.target.value)}
                required
                name={t("confirm_project_name")}
                type="text"
            />
        </Form>
    )
}
