import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Amount } from "@/components/Other/Amount"
import { ArticleStatusBar } from "@/components/Other/ArticleStatusBar"
import { Article } from "@/models/Article"
import { Budget } from "@/models/Finance"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    getArticle,
    getBudgetArticles,
    selectArticles,
} from "../../Articles/articlesSlice"
import { BudgetArticleTableActions } from "./BudgetArticleTableActions"

interface BudgetPerArticleTableProps {
    budget: Budget
}

export const BudgetPerArticleTable: React.FC<BudgetPerArticleTableProps> = ({
    budget,
}) => {
    const { t } = useTranslation()
    const articles = useAppSelector(selectArticles)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const budgetId = useParams<{ budgetId: string }>().budgetId ?? "-1"

    useEffect(() => {
        dispatch(getBudgetArticles({ projectId, budgetId }))
    }, [])
    useEffect(() => {
        console.log(budget.currency)
    }, [budget])

    const columns = useMemo<MRT_ColumnDef<Article>[]>(
        () => [
            {
                id: "isUpdating",
                header: t("is_updating"),
                Cell: ({ row }) => {
                    const article = row.original
                    return article.isUpdating ? (
                        <div className="flex items-center gap-1 group cursor-pointer transition hover:bg-primary-100 px-2 py-1 rounded-lg hover:text-secondary-100 w-fit">
                            <span className="group-hover:hidden transition flex items-center gap-1">
                                {t("updating")}
                                <div className="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                            </span>
                            <span
                                className="hidden group-hover:flex transition items-center gap-1"
                                onClick={() => {
                                    dispatch(
                                        getArticle({
                                            projectId,
                                            articleId: article.id,
                                        }),
                                    )
                                }}
                            >
                                {t("refresh")}
                                <div className="w-4 h-4 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
                            </span>
                        </div>
                    ) : (
                        <div className="flex items-center gap-2">
                            <p>{t("is_up_to_date")}</p>
                            <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>
                        </div>
                    )
                },
            },
            {
                accessorKey: "name",
                header: t("article"),
            },
            {
                id: "description",
                accessorKey: "description",
                header: t("description"),
                size: 800,
            },
            {
                accessorKey: "unitPrice",
                header: t("unit_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex gap-1 items-center">
                            <Amount
                                amount={article.unitPrice}
                                currency={budget.currency}
                            />
                            {" / "}
                            {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "engagedQuantity",
                header: t("engaged_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const engagedQuantity = (
                        parseInt(article.engagedPrice) / article.unitPrice
                    ).toFixed(2)
                    return (
                        <span>
                            {engagedQuantity} {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "remainingQuantity",
                header: t("remaining_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const remainingQuantity = (
                        article.remainingPrice / article.unitPrice
                    ).toFixed(2)
                    return (
                        <span>
                            {remainingQuantity} {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "totalQuantity",
                header: t("total_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const totalQuantity = (
                        parseInt(article.totalPrice) / article.unitPrice
                    ).toFixed(2)
                    return (
                        <span>
                            {totalQuantity} {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },

            {
                accessorKey: "engagedPrice",
                header: t("engaged_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <Amount
                            amount={parseInt(article.engagedPrice)}
                            currency={budget.currency}
                        />
                    )
                },
            },
            {
                accessorKey: "remainingPrice",
                header: t("remaining_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <Amount
                            amount={article.remainingPrice}
                            currency={budget.currency}
                        />
                    )
                },
            },
            {
                accessorKey: "totalPrice",
                header: t("total_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <Amount
                            amount={parseInt(article.totalPrice)}
                            currency={budget.currency}
                        />
                    )
                },
            },
            {
                id: "marketQuantity",
                header: t("market_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const marketQuantity = Math.round(
                        article.marketBudget / article.unitPrice,
                    )
                    return (
                        <span className="flex items-center gap-1">
                            {marketQuantity.toLocaleString()}{" "}
                            {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "marketBudget",
                accessorKey: "marketBudget",
                header: t("market_budget"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <Amount
                            amount={article.marketBudget}
                            currency={budget.currency}
                        />
                    )
                },
            },
            {
                id: "status",
                accessorKey: "status",
                header: t("status"),
                Cell: ({ row }) => {
                    const article = row.original
                    return <ArticleStatusBar articleStatus={article.status} />
                },
            },
            {
                id: "items",
                header: t("items"),
                Cell: ({ row }) => {
                    const article = row.original
                    return <span>{article.ifcEntities.length}</span>
                },
            },
            {
                header: "",
                id: "actions",
                enableColumnActions: false,
                enableColumnFilters: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({ row }) => {
                    return <BudgetArticleTableActions article={row.original} />
                },
            },
        ],
        [],
    )

    const table = useMantineReactTable<Article>({
        columns,
        data: articles,
        positionPagination: "top",
        initialState: {
            columnVisibility: {
                items: false,
                description: false,
                marketBudget: false,
                marketQuantity: false,
            },
        },
    })

    return <MantineReactTable table={table} />
}
