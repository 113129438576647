import { DropDown } from "@/components/Inputs/DropDown"
import { Input } from "@/components/Inputs/Input"
import { IfcFilterRule } from "@/models/Ifc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { useTranslation } from "react-i18next"

interface IfcFiltersRuleProps {
    rule: IfcFilterRule
    setRule: (rule: IfcFilterRule) => void
    deleteRule: () => void
}

export const IfcFiltersRule: FC<IfcFiltersRuleProps> = ({
    rule,
    setRule,
    deleteRule,
}) => {
    const { t } = useTranslation()
    return (
        <div className="flex items-end gap-2">
            <DropDown
                name={t("rule_type")}
                value={rule.type}
                onChange={(e) => {
                    setRule({
                        ...rule,
                        type: e.target.value,
                    })
                }}
                options={[
                    { label: t("property"), value: "property" },
                    { label: t("operator"), value: "operator" },
                ]}
            />
            {rule.type === "operator" && (
                <div>
                    <p></p>
                    <DropDown
                        name={t("operator")}
                        value={rule.operator ?? ""}
                        onChange={(e) => {
                            setRule({
                                ...rule,
                                operator: e.target.value as
                                    | "<"
                                    | ">"
                                    | "="
                                    | "<="
                                    | ">=",
                            })
                        }}
                        options={[
                            // "<" | ">" | "=" | "<=" | ">="
                            { label: "<", value: "<" },
                            { label: ">", value: ">" },
                            { label: "=", value: "=" },
                            { label: "<=", value: "<=" },
                            { label: ">=", value: ">=" },
                        ]}
                    />
                </div>
            )}
            <Input
                value={rule.name.toString()}
                onChange={(e) => {
                    setRule({
                        ...rule,
                        name: e.target.value,
                    })
                }}
                label={t("name")}
                name={t("name")}
                type="text"
            />

            <Input
                label={t("value")}
                value={rule.value.toString()}
                onChange={(e) => {
                    setRule({
                        ...rule,
                        value: e.target.value,
                    })
                }}
                name={t("value")}
                type="text"
            />
            <FontAwesomeIcon
                icon="trash"
                className="text-red-400 cursor-pointer hover:text-red-600 transition relative bottom-2"
                onClick={() => {
                    deleteRule()
                }}
            />
        </div>
    )
}
