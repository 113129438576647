import { useAppDispatch } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { Button } from "@/components/Inputs/Button"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import { t } from "i18next"
import React, { useEffect } from "react"

export enum SubmitType {
    Create = "create",
    Save = "save",
    Delete = "delete",
    Submit = "submit",
}

interface FromProps {
    children: React.ReactNode
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
    statuses: SliceStatus[]
    loadingTitle: string
    submitType: SubmitType
    clearErrors: ActionCreatorWithoutPayload
    onCancel?: () => void
    className?: string
    customSubmitButton?: React.ReactNode
}

export const Form: React.FC<FromProps> = ({
    children,
    onSubmit,
    statuses,
    loadingTitle,
    submitType,
    clearErrors,
    onCancel,
    className = "",
    customSubmitButton,
}) => {
    const dispatch = useAppDispatch()
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onSubmit(event)
    }
    const submitButtonMapping = {
        [SubmitType.Create]: (
            <Button
                name={t(SubmitType.Create)}
                className="!bg-green-400 hover:!bg-green-600 !border-green-400 hover:border-green-600 text-white hover:text-white transition"
                icon="plus"
                type="submit"
            ></Button>
        ),
        [SubmitType.Save]: (
            <Button
                name={t(SubmitType.Save)}
                className="!bg-primary-100 hover:!bg-primary-300 !border-primary-100 hover:border-primary-300 text-white hover:text-white transition"
                icon="save"
                type="submit"
            ></Button>
        ),
        [SubmitType.Delete]: (
            <Button
                name={t(SubmitType.Delete)}
                className="!bg-red-400 hover:!bg-red-600 !border-red-400 hover:border-red-600 text-white hover:text-white transition"
                icon="trash"
                type="submit"
            ></Button>
        ),
        [SubmitType.Submit]: (
            <Button
                name={t(SubmitType.Submit)}
                className="!bg-primary-100 hover:!bg-primary-300 !border-primary-100 hover:border-primary-300 text-white hover:text-white transition"
                icon="check"
                type="submit"
            ></Button>
        ),
    }
    useEffect(() => {
        dispatch(clearErrors())
    }, [])
    return (
        <form className={"w-full " + className} onSubmit={handleSubmit}>
            <WithLoader statuses={statuses} title={loadingTitle}>
                {children}

                <div className="flex items-center justify-end gap-4 p-3 pr-6 bg w-full">
                    {onCancel && (
                        <Button
                            name={t("cancel")}
                            icon="times"
                            className="!bg-transparent-0 !text-primary-100 !border !border-primary-100 hover:!bg-primary-100 hover:!text-secondary-100 hover:!border-primary-100 transition"
                            onClick={onCancel}
                            type="button"
                        ></Button>
                    )}
                    {customSubmitButton
                        ? customSubmitButton
                        : submitButtonMapping[submitType]}
                </div>
            </WithLoader>
        </form>
    )
}
