import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { clearErrors, resetPassword } from "@/features/Authentication/authSlice"
import { Form, SubmitType } from "@/features/Form/Form"
import { t } from "i18next"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

interface ResetPasswordProps {}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
    const [queryParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const token = queryParams.get("token")
    const [password, setPassword] = React.useState<string>("")
    const [confirmPassword, setConfirmPassword] = React.useState<string>("")
    const status = useAppSelector((state) => state.auth.status)
    const navigate = useNavigate()

    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="bg-secondary-100 text-primary-300 p-5 rounded-lg">
                <h1 className="font-bold text-lg">{t("resetting_password")}</h1>
                <Form
                    onSubmit={(e) => {
                        dispatch(
                            resetPassword({ token: token || "", password }),
                        ).then((res) => {
                            if (res.type === resetPassword.fulfilled.type) {
                                navigate("/auth/login")
                            }
                        })
                    }}
                    submitType={SubmitType.Submit}
                    loadingTitle={t("resetting_password")}
                    statuses={[status.update]}
                    customSubmitButton={
                        <Button
                            name={t("login")}
                            className="mt-2 w-full"
                            isDisabled={!(password === confirmPassword)}
                            type="submit"
                        ></Button>
                    }
                    clearErrors={clearErrors}
                >
                    <Input
                        name={t("password")}
                        label={t("password")}
                        type="password"
                        value={password}
                        className="w-full"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Input
                        name={t("confirm_password")}
                        label={t("confirm_password")}
                        type="password"
                        className="w-full"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </Form>
            </div>
        </div>
    )
}
