// import HeroAnimation from "@/assets/animations/hero_animation.json"
import { Button } from "@/components/Inputs/Button"
import { BimpackLogo } from "@/components/Other/BimpackLogo"
import { animated, useSpring } from "@react-spring/web"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface HeroProps {
    actionClick: () => void
}

export const Hero: React.FC<HeroProps> = ({ actionClick }) => {
    const { t } = useTranslation()
    const [animationData, setAnimationData] = useState<any>(null)
    const floatSpring = useSpring({
        from: { x: -20 },
        to: [{ x: 20 }, { x: -20 }],
        loop: true,
        config: {
            duration: 3000,
            mass: 5,
            friction: 120,
            tension: 120,
        },
    })
    return (
        <section className="w-full relative h-full flex items-center px-8 overflow-hidden min-h-screen lg:p-2">
            <div className="flex flex-col w-full items-center">
                <h1 className="text-8xl font-black mb-1 lg:text-6xl">
                    Bimpack
                </h1>
                <h2 className="text-4xl font-bold text-center lg:text-2xl">
                    {t("hero_headline")}!
                </h2>
                <p className="text-lg text-center">{t("sub_hero_headline")}.</p>
                <Button
                    name={t("try_for_free")}
                    className="mt-4 w-40 !bg-green-400 hover:!bg-green-600 !text-white hover:scale-110"
                    onClick={() => {
                        actionClick()
                    }}
                ></Button>
            </div>
            <animated.div
                className="opacity-15 absolute -top-[10%] -left-[40%] w-[60%] h-full z-0 lg:top-0 lg:-left-[44%] "
                style={{ ...floatSpring }}
            >
                <BimpackLogo
                    logoSize="w-full h-full "
                    withText={false}
                    isLink={false}
                />
            </animated.div>

            <div className="opacity-80 !absolute top-[50%] right-0 transform -translate-y-1/2 w-1/2 z-0">
                {/* {!isNull(animationData) ? (
                    <Lottie
                        animationData={animationData}
                        style={{ width: "100%", height: "100%" }}
                    />
                ) : (
                    <>loading</>
                )} */}
            </div>
        </section>
    )
}
