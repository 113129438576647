import { useAppSelector } from "@/app/hooks"
import { CalendarZoomModes } from "@/common/types"
import { Task, TaskDependency } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { selectTaskTree } from "../planningSlice"
import { selectGanttCalendar } from "./ganttSlice"

interface GanttTaskCalendarRowDependencyProps {
    dependency: TaskDependency
    mainTask: Task
}

export const GanttTaskCalendarRowDependency: React.FC<
    GanttTaskCalendarRowDependencyProps
> = ({ dependency, mainTask }) => {
    const calendar = useAppSelector(selectGanttCalendar)
    const fullTaskMapping = useAppSelector(
        (state) => state.plannings.taskMapping,
    )
    const taskTree = useAppSelector(selectTaskTree)
    const [width, setWidth] = useState<number>(0)
    const getTaskPositionInTaskTree = (
        taskTree: Task,
        task: Task,
        position: { current: number },
    ): number => {
        // Check if the current task is the one we are looking for
        if (taskTree.id === task.id) {
            return position.current
        }

        // Increment the current position as we visit this task
        position.current += 1

        // Ensure taskMapping is defined and expanded
        const taskMapping = fullTaskMapping[taskTree.id]

        if (taskTree.children.length > 0 && taskMapping.isExpanded) {
            // Loop through children to find the task
            for (let i = 0; i < taskTree.children.length; i++) {
                const result = getTaskPositionInTaskTree(
                    taskTree.children[i],
                    task,
                    position,
                )
                if (result !== -1) {
                    return result
                }
            }
        }

        // Return -1 if the task is not found
        return -1
    }
    const dependencyTaskPosition = getTaskPositionInTaskTree(
        taskTree,
        dependency.dependencyTask,
        { current: 0 },
    )
    const mainTaskPosition = getTaskPositionInTaskTree(taskTree, mainTask, {
        current: 0,
    })
    const differenceInPosition = dependencyTaskPosition - mainTaskPosition
    const height = Math.abs(differenceInPosition * 45)
    useEffect(() => {
        if (
            calendar.zoom === CalendarZoomModes.Day ||
            calendar.zoom === CalendarZoomModes.Week
        ) {
            setWidth(dependency.lag * calendar.unit + calendar.unit / 2)
        } else if (calendar.zoom === CalendarZoomModes.Month) {
            setWidth(dependency.lag / 30)
        } else if (calendar.zoom === CalendarZoomModes.Quarter) {
            setWidth(dependency.lag / 90)
        }
    }, [calendar.zoom])
    return (
        <span key={dependency.id}>
            <div
                className="absolute top-6 left-full bg-primary-100"
                style={{
                    width: width + "px",
                    height: "2px",
                }}
            >
                <div
                    className="absolute left-full bg-primary-100"
                    style={{
                        width: "2px",
                        height:
                            (calendar.zoom === CalendarZoomModes.Month ||
                                calendar.zoom === CalendarZoomModes.Quarter) &&
                            differenceInPosition < 0
                                ? height - 7 + "px"
                                : height + "px",
                        ...(differenceInPosition < 0 && {
                            bottom: "0%",
                        }),
                    }}
                >
                    <div>
                        <FontAwesomeIcon
                            icon={
                                differenceInPosition > 0
                                    ? "caret-down"
                                    : "caret-up"
                            }
                            className="text-primary-100 absolute bottom-0 left-1/2 transform -translate-x-1/2"
                            style={
                                differenceInPosition < 0
                                    ? { top: "-5px" }
                                    : { bottom: "0px" }
                            }
                        />
                    </div>
                </div>
            </div>
        </span>
    )
}
