import { useEffect, useState } from "react"
import { IfcHandler } from "../IfcHandler"
import { SpatialStructureElementsRow } from "./SpatialStructureElementsRow"

interface SpatialStructureElementsRowsProps {
    ifcHandler: IfcHandler
    value: { [key: string]: Set<number> }
    search?: string
}

interface element {
    uuid: string
    expressId: number
}

export const SpatialStructureElementsRows: React.FC<
    SpatialStructureElementsRowsProps
> = ({ ifcHandler, value, search }) => {
    const [elements, setElements] = useState<element[]>([])
    useEffect(() => {
        const elements: element[] = []
        Object.keys(value).forEach((key) => {
            value[key].forEach((id) => {
                elements.push({ uuid: key, expressId: id })
            })
        })
        setElements(elements)
    }, [])
    return (
        <div>
            {elements.map((element) => (
                <SpatialStructureElementsRow
                    key={element.uuid + element.expressId}
                    ifcHandler={ifcHandler}
                    element={element}
                    search={search}
                />
            ))}
        </div>
    )
}
