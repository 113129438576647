import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Form, SubmitType } from "@/features/Form/Form"
import { User } from "@/models/User"
import { differenceInDays } from "date-fns"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { clearErrors, getUserBanStatus, unbanUser } from "../../adminSlice"

interface UnbanFormProps {
    user: User
    closeModal: () => void
}

export const UnbanForm: React.FC<UnbanFormProps> = ({ user, closeModal }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.admin.status)
    const banStatus = useAppSelector((state) => state.admin.userBanStatus)

    const handleUnban = () => {
        dispatch(unbanUser({ userId: user.id })).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                dispatch(getUserBanStatus({ userId: user.id }))
                closeModal()
            }
        })
    }

    useEffect(() => {
        if (user) {
            dispatch(getUserBanStatus({ userId: user.id }))
        }
    }, [user])

    return banStatus.isBanned ? (
        <Form
            onSubmit={() => handleUnban()}
            statuses={[status.update]}
            loadingTitle={t("unbanning_user")}
            onCancel={() => closeModal()}
            className={""}
            submitType={SubmitType.Submit}
            clearErrors={clearErrors}
        >
            <h1 className="font-bold text-lg my-2">
                {t("unbanning_user", { user: user.fullName })}
            </h1>
            <p className="text-lg">
                {t("unban_user_warning", { user: user.fullName })}
            </p>
            <p>
                <span className="font-bold">{t("user_ban_expires_at")}:</span>{" "}
                {t("intlDateTime", {
                    val: new Date(banStatus.expiresAt!),
                    formatParams: {
                        val: {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        },
                    },
                })}
            </p>
            <p>
                <span className="font-bold">{t("ban_expires_in")}:</span>{" "}
                {differenceInDays(banStatus.expiresAt!, new Date())}{" "}
                {differenceInDays(banStatus.expiresAt!, new Date()) > 1
                    ? t("days")
                    : t("day")}
            </p>
        </Form>
    ) : (
        <div>
            <h1 className="my-4 text-lg">
                {t("user_not_banned", { user: user.fullName })}
            </h1>
            <Button
                name={t("close")}
                onClick={() => closeModal()}
                className={"bg-primary-500 text-white ml-auto"}
            />
        </div>
    )
}
