import { useAppDispatch } from "@/app/hooks"
import { RootState } from "@/app/store"
import { StatisticsCard } from "@/components/Cards/StatisticsCard"
import { Button } from "@/components/Inputs/Button"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { selectUserPermissions } from "@/features/User/userSlice"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
    getProjectGeneralDelayStatistics,
    getProjectGeneralQualityStatistics,
    getProjectGeneralStatistics,
} from "./statisticsSlice"
import { StatisticsTaskPerGroupTable } from "./StatisticsTaskPerGroup"
import { StatisticsTaskPerUserTable } from "./StatsiticsTaskPerUserTable"

interface StatisticsProps {}

export const Statistics: React.FC<StatisticsProps> = () => {
    const projectId = useParams<{ projectId: string }>().projectId
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [taskAssigneesMode, setTaskAssigneesMode] = useState<
        "user" | "group"
    >("user")
    const status = useSelector((state: RootState) => state.statistics.status)
    const generalStatistics = useSelector(
        (state: RootState) => state.statistics.general,
    )
    const generalDelayStatistics = useSelector(
        (state: RootState) => state.statistics.generalDelay,
    )
    const generalQualityStatistics = useSelector(
        (state: RootState) => state.statistics.generalQuality,
    )
    const userPermissions = useSelector(selectUserPermissions)
    useEffect(() => {
        dispatch(getProjectGeneralStatistics(projectId))
        dispatch(getProjectGeneralDelayStatistics(projectId))
        dispatch(getProjectGeneralQualityStatistics(projectId))
    }, [])
    return (
        <Scrollable>
            <div className="p-5 flex flex-col gap-4">
                <div className="flex gap-4 w-full">
                    <WithLoader
                        statuses={[status.read]}
                        title={t("loading_statistics")}
                        className="w-full"
                        childClassName="!w-full !flex"
                    >
                        <div className="flex gap-4 w-full sm:flex-wrap lg:flex-wrap">
                            <div className="bg-secondary-100 shadow-lg rounded-lg w-1/2 sm:w-full lg:w-full">
                                <div className="p-4 flex items-center text-primary-300 gap-2 h-full ">
                                    <h1 className="text-4xl font-bold">
                                        {t("project_total_tasks")}:
                                    </h1>
                                    <p className="text-4xl">
                                        {generalStatistics.totalTasks}
                                    </p>
                                </div>
                            </div>
                            <div className="bg-secondary-100 shadow-lg rounded-lg w-1/2 sm:w-full lg:w-full">
                                <div className="p-4 flex items-center text-primary-300 gap-2 h-full ">
                                    <h1 className="text-4xl font-bold">
                                        {t("project_progress")}:
                                    </h1>
                                    <p className="text-4xl">
                                        {generalStatistics.projectProgress}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </WithLoader>
                </div>
                <WithLoader
                    statuses={[status.read]}
                    title={t("loading_statistics")}
                >
                    <div className="flex gap-4 w-full sm:flex-wrap lg:flex-wrap">
                        <div className="w-1/2 sm:w-full lg:w-full">
                            <StatisticsCard
                                title={t("project_delay_statistics")}
                                items={[
                                    {
                                        title: t("tasks_on_time"),
                                        percentage:
                                            (generalDelayStatistics.tasksOnTime /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalDelayStatistics.tasksOnTime,
                                    },
                                    {
                                        title: t("tasks_delayed_start"),
                                        percentage:
                                            (generalDelayStatistics.tasksDelayedStart /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalDelayStatistics.tasksDelayedStart,
                                    },
                                    {
                                        title: t("tasks_delayed_end"),
                                        percentage:
                                            (generalDelayStatistics.tasksDelayedEnd /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalDelayStatistics.tasksDelayedEnd,
                                    },
                                    {
                                        title: t("tasks_delayed_both"),
                                        percentage:
                                            (generalDelayStatistics.tasksDelayedBoth /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalDelayStatistics.tasksDelayedBoth,
                                    },
                                ]}
                            />
                        </div>
                        <div className="w-1/2 sm:w-full lg:w-full">
                            <StatisticsCard
                                title={t("project_quality_statistics")}
                                items={[
                                    {
                                        title: t("other"),
                                        percentage:
                                            100 -
                                            (generalQualityStatistics.tasksApproved /
                                                generalStatistics.totalTasks) *
                                                100 -
                                            (generalQualityStatistics.tasksWithIssues /
                                                generalStatistics.totalTasks) *
                                                100 -
                                            (generalQualityStatistics.tasksApprovedWithIssues /
                                                generalStatistics.totalTasks) *
                                                100 -
                                            (generalQualityStatistics.tasksApprovedWithoutIssues /
                                                generalStatistics.totalTasks) *
                                                100,
                                        value:
                                            generalStatistics.totalTasks -
                                            generalQualityStatistics.tasksApproved -
                                            generalQualityStatistics.tasksWithIssues -
                                            generalQualityStatistics.tasksApprovedWithIssues -
                                            generalQualityStatistics.tasksApprovedWithoutIssues,
                                    },
                                    {
                                        title: t("tasks_approved"),
                                        percentage:
                                            (generalQualityStatistics.tasksApproved /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalQualityStatistics.tasksApproved,
                                    },
                                    {
                                        title: t("tasks_with_issues"),
                                        percentage:
                                            (generalQualityStatistics.tasksWithIssues /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalQualityStatistics.tasksWithIssues,
                                    },
                                    {
                                        title: t("tasks_approved_with_issues"),
                                        percentage:
                                            (generalQualityStatistics.tasksApprovedWithIssues /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalQualityStatistics.tasksApprovedWithIssues,
                                    },
                                    {
                                        title: t(
                                            "tasks_approved_without_issues",
                                        ),
                                        percentage:
                                            (generalQualityStatistics.tasksApprovedWithoutIssues /
                                                generalStatistics.totalTasks) *
                                            100,
                                        value: generalQualityStatistics.tasksApprovedWithoutIssues,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </WithLoader>
                <div className="bg-secondary-100">
                    <div className="p-5 flex justify-between items-center sm:flex-wrap">
                        <h1 className="font-bold text-2xl ">
                            {t("task_assignees_statistics")}
                        </h1>
                        <div className="flex items-center gap-4">
                            <Button
                                name={t("users")}
                                onClick={() => setTaskAssigneesMode("user")}
                                icon="user"
                                className={
                                    !(taskAssigneesMode === "user")
                                        ? "!bg-secondary-300 !text-primary-300 !border-primary-300 border"
                                        : ""
                                }
                            />
                            <Button
                                name={t("groups")}
                                onClick={() => setTaskAssigneesMode("group")}
                                icon="users"
                                className={
                                    !(taskAssigneesMode === "group")
                                        ? "!bg-secondary-300 !text-primary-300 !border-primary-300 border"
                                        : ""
                                }
                            />
                        </div>
                    </div>

                    {taskAssigneesMode === "user" && (
                        <div className="flex gap-4 w-full h-[500px]">
                            <Scrollable>
                                <StatisticsTaskPerUserTable />
                            </Scrollable>
                        </div>
                    )}
                    {taskAssigneesMode === "group" && (
                        <div className="flex gap-4 w-full h-[500px]">
                            <Scrollable>
                                <StatisticsTaskPerGroupTable />
                            </Scrollable>
                        </div>
                    )}
                </div>
            </div>
        </Scrollable>
    )
}
