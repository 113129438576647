import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { ArrayFilter } from "../Plannings/Gantt/Filters/ArrayFilter"
import { DateFilter } from "../Plannings/Gantt/Filters/DateFilter"
import {
    CommonFilterFields,
    FilterFields,
    FilterValuesType,
    TaskFilterFields,
} from "../Plannings/Gantt/Filters/Filters"
import { NumberFilter } from "../Plannings/Gantt/Filters/NumberFilter"
import { StringFilter } from "../Plannings/Gantt/Filters/StringFilter"
import { resetFilters, setFilters } from "../Plannings/Gantt/ganttSlice"
import { getProjectUsersById } from "../projectsSlice"
import { getProjectGroups } from "../UserManagement/userManagementSlice"

interface TaskAdvancedFiltersProps {
    setShowAdvancedFiltersModal: (show: boolean) => void
}

const FilterFieldMapping = {
    [TaskFilterFields.WBS]: StringFilter,
    [CommonFilterFields.Name]: StringFilter,
    [TaskFilterFields.StartDate]: DateFilter,
    [TaskFilterFields.EndDate]: DateFilter,
    [TaskFilterFields.TaskStatus]: ArrayFilter,
    [TaskFilterFields.Duration]: NumberFilter,
    [TaskFilterFields.Progress]: NumberFilter,
    [TaskFilterFields.Tags]: ArrayFilter,
    [TaskFilterFields.Assignees]: ArrayFilter,
    [TaskFilterFields.Reviewers]: ArrayFilter,
    [TaskFilterFields.DelayStatus]: ArrayFilter,
    [TaskFilterFields.StatusDetail]: ArrayFilter,
    [TaskFilterFields.StartDelay]: NumberFilter,
    [TaskFilterFields.EndDelay]: NumberFilter,
}

export const TaskAdvancedFilters: React.FC<TaskAdvancedFiltersProps> = ({
    setShowAdvancedFiltersModal,
}) => {
    const projectId = useParams<{ projectId: string }>().projectId
    const dispatch = useAppDispatch()
    const filters = useAppSelector((state) => state.gantt.filters)
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])
    return (
        <div>
            <div>
                <div className="flex flex-wrap justify-between">
                    {Object.values(TaskFilterFields)
                        .filter(
                            (field) =>
                                ![
                                    TaskFilterFields.TaskStatus,
                                    TaskFilterFields.DelayStatus,
                                    TaskFilterFields.StatusDetail,
                                ].includes(field),
                        )
                        .map((field, idx) => {
                            const FilterComponent = FilterFieldMapping[field]
                            return (
                                <div className="w-1/2 p-8 border-b" key={idx}>
                                    <div className="flex gap-2 items-center">
                                        <p className="font-bold">{t(field)}:</p>
                                        {filters[field].value && (
                                            <FontAwesomeIcon
                                                icon="filter-circle-xmark"
                                                className="ml-1 text-red-400 hover:text-red-600 transition cursor-pointer"
                                                onClick={() => {
                                                    dispatch(
                                                        setFilters({
                                                            ...filters,
                                                            [field]: {
                                                                ...filters[
                                                                    field
                                                                ],
                                                                value: "",
                                                            },
                                                        }),
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                    <FilterComponent
                                        filters={
                                            filters as FilterValuesType<FilterFields>
                                        }
                                        field={field}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
            <div className="flex gap-2 justify-end w-full p-2">
                <Button
                    name={t("cancel")}
                    icon="times"
                    className="!bg-transparent-0 !text-primary-100 !border !border-primary-100 hover:!bg-primary-100 hover:!text-secondary-100 hover:!border-primary-100 transition"
                    onClick={() => setShowAdvancedFiltersModal(false)}
                    type="button"
                ></Button>
                <Button
                    name={t("clear_all")}
                    className="!bg-red-400 hover:!bg-red-600 !border-red-400 hover:border-red-600 text-white hover:text-white transition"
                    onClick={() => {
                        dispatch(resetFilters({}))
                    }}
                    icon={"filter-circle-xmark"}
                ></Button>
                <Button
                    name={t("apply")}
                    onClick={() => {}}
                    className="!bg-green-400 hover:!bg-green-600 !border-green-400 hover:border-green-600 text-white hover:text-white transition"
                    icon="filter"
                />
            </div>
        </div>
    )
}
