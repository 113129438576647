import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"

interface FinanceProps {}

enum FinanceTabs {
    BUDGETS = "budgets",
    ARTICLES = "articles",
    REPORTS = "reports",
    STATISTICS = "statistics",
}

const tabsIconMapping: {
    [key: string]: IconProp
} = {
    budgets: "money-bill-wave",
    articles: "file-invoice-dollar",
    reports: "file-invoice",
    statistics: "chart-line",
}

export const Finance: FC<FinanceProps> = () => {
    const { t } = useTranslation()
    const projectId = useParams<{ projectId: string }>().projectId
    const [activeTab, setActiveTab] = useState<FinanceTabs>(FinanceTabs.BUDGETS)
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        const path = location.pathname.split("/").pop()
        if (path) {
            setActiveTab(path as FinanceTabs)
        }
        if (path === "finance") {
            navigate(`/dashboard/projects/${projectId}/finance/budgets`)
        }
    }, [location])
    return (
        <div className="flex flex-col overflow-hidden h-full p-2">
            <div className="w-full h-full flex">
                <div
                    className="bg-secondary-100 w-full h-full rounded-lg p-4 shadow-lg z-0"
                    style={{
                        borderTopLeftRadius: "0",
                    }}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
