import { axiosAuthenticated } from "@/common/axios"
import {
    GeneralDelayStatistics,
    GeneralQualityStatistics,
    GeneralStatistics,
    TASK_STATISTICS_TYPES,
} from "@/models/Statistics"

export const getProjectGeneralStatisticsApi = (projectId: string) => {
    return new Promise<{ data: GeneralStatistics }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/statistics/general/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectGeneralDelayStatisticsApi = (projectId: string) => {
    return new Promise<{ data: GeneralDelayStatistics }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/statistics/general-delay/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectGeneralQualityStatisticsApi = (projectId: string) => {
    return new Promise<{ data: GeneralQualityStatistics }>(
        (resolve, reject) => {
            axiosAuthenticated()
                .get(`/projects/${projectId}/statistics/general-quality/`)
                .then((response) => {
                    resolve({ data: response.data })
                })
                .catch((error) => {
                    reject({ data: error.response.data })
                })
        },
    )
}

export const getProjectTaskStatisticsPerUserApi = (projectId: string) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/statistics/task-per-user/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectTaskStatisticsPerGroupApi = (projectId: string) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/statistics/task-per-group/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserInfoApi = (projectId: string, userId: string) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/statistics/users/${userId}/info`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserStatisticsInProjectApi = (
    projectId: string,
    userId: string,
) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/statistics/users/${userId}/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUsersTasksStatisticsInProjectApi = (
    projectId: string,
    userId: string,
    type: TASK_STATISTICS_TYPES,
) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/statistics/users/${userId}/tasks/${type}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getPermissionGroupInfoApi = (
    projectId: string,
    permissionGroupId: string,
) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/statistics/groups/${permissionGroupId}/info`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getPermissionGroupStatisticsInProjectApi = (
    projectId: string,
    permissionGroupId: string,
) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/statistics/groups/${permissionGroupId}/`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getPermissionGroupsTasksStatisticsInProjectApi = (
    projectId: string,
    permissionGroupId: string,
    type: TASK_STATISTICS_TYPES,
) => {
    return new Promise<any>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/statistics/groups/${permissionGroupId}/tasks/${type}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
