import NoDataSVG from "@/assets/no-data.svg"

interface NoDataProps {
    message: string
    className?: string
}

export const NoData: React.FC<NoDataProps> = ({ message, className = "" }) => {
    return (
        <div
            className={`flex flex-col justify-center items-center text-center ${className}`}
        >
            <p>{message}</p>
            <img src={NoDataSVG} alt="No data" className="w-full mt-2" />
        </div>
    )
}
