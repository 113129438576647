import {
    PermissionGroup,
    PermissionGroupJsonInterface,
} from "@/models/Permission"
import { User, UserInterface } from "@/models/User"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { t } from "i18next"
import { addNotification } from "../features/AppNotification/AppNotificationsSlice"
import {
    NotificationInterface,
    NotificationLevel,
} from "../models/Notification"

const DEBUG = import.meta.env.VITE_DEBUG === "true"

export interface ResponseWithNotification {
    [key: string]: any
    notification: NotificationInterface
}

export interface Resources {
    users: User[]
    groups: PermissionGroup[]
}

export interface ResourcesJson {
    users: UserInterface[]
    groups: PermissionGroupJsonInterface[]
}

export interface ResourcesIds {
    users: string[]
    groups: string[]
}

export interface UsersManager {
    value: ResourcesIds
    add: ResourcesIds
    remove: ResourcesIds
}

export const createAsyncThunkWithNotification = (
    actionType: string,
    apiCall: any,
) => {
    return createAsyncThunk(
        actionType,
        async (
            payload: any = undefined,
            { dispatch, rejectWithValue, fulfillWithValue },
        ) => {
            let anyResponse
            try {
                let response
                if (!payload) response = await apiCall()
                else response = await apiCall(payload, dispatch)
                anyResponse = response
                return fulfillWithValue(response)
            } catch (error: any) {
                anyResponse = error
                const errors = error.data?.notification?.errors ?? []
                return rejectWithValue({ data: errors })
            } finally {
                if (!anyResponse.data && !anyResponse.data.notification)
                    throw new Error(t("malformed_response"))
                if (DEBUG) {
                    dispatch(addNotification(anyResponse.data.notification))
                } else {
                    if (
                        anyResponse.data.notification.level !==
                        NotificationLevel.DEBUG
                    ) {
                        dispatch(addNotification(anyResponse.data.notification))
                    }
                }
            }
        },
    )
}

export const isEmail = (value: string): boolean => {
    return /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.toString())
}

export const makeId = (length: number) => {
    let result = ""
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        )
        counter += 1
    }
    return result
}

export const SupportedLanguages = [
    {
        code: "en",
        name: "english",
    },
    {
        code: "ar",
        name: "arabic",
    },
    // {
    //     code: "es",
    //     name: "spanish",
    // },
    {
        code: "fr",
        name: "french",
    },
]
