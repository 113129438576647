import { useAppDispatch } from "@/app/hooks"
import { markNotificationsAsSeen } from "@/features/User/userSlice"
import { UserNotification } from "@/models/Notification"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Scrollable } from "../Layouts/Scrollable"
import { NotificationItem } from "./NotificationItem"

interface NotificationContainerProps {
    newNotifications: UserNotification[]
    oldNotifications: UserNotification[]
    setShowContainer: React.Dispatch<React.SetStateAction<boolean>>
}

const useIsMounted = () => {
    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])
    return () => isMounted.current
}

export const NotificationContainer: React.FC<NotificationContainerProps> = ({
    newNotifications,
    oldNotifications,
    setShowContainer,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [seenNotifications, setSeenNotifications] = useState<string[]>([])
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const isMounted = useIsMounted()
    const addSeenNotification = (id: string) => {
        if (seenNotifications.includes(id)) return
        setSeenNotifications([...seenNotifications, id])
    }
    useEffect(() => {
        const handleClickOutside = (e: any) => {
            if (e.target !== ref.current && !ref.current?.contains(e.target)) {
                setShowContainer(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, setShowContainer])
    useEffect(() => {
        return () => {
            if (!isMounted()) {
                if (seenNotifications.length === 0) return
                dispatch(markNotificationsAsSeen(seenNotifications))
            }
        }
    }, [seenNotifications, dispatch, isMounted])

    return (
        <>
            <div className="hidden sm:fixed sm:block w-screen h-screen opacity-50 z-10 top-0 left-0 bg-black"></div>
            <div
                className="absolute top-6 right-[20%] bg-primary-100 w-fit z-20 p-3  rounded-lg shadow-lg sm:top-0 sm:left-0 sm:fixed"
                ref={ref}
            >
                <Scrollable height="400px">
                    <div className="border-b py-4">
                        <h1 className="w-full text-center font-bold mb-2">
                            {t("recent_notifications")}
                        </h1>
                        <ul className="flex flex-col gap-2">
                            {[
                                newNotifications.map((notification) => (
                                    <li
                                        key={notification.id}
                                        className="w-[400px] shadow-lg rounded-lg"
                                    >
                                        <NotificationItem
                                            notification={notification}
                                            addSeen={() =>
                                                addSeenNotification(
                                                    notification.id,
                                                )
                                            }
                                        />
                                    </li>
                                )),
                            ]}
                            {newNotifications.length === 0 && (
                                <li className="text-center text-secondary-100 w-[400px]">
                                    {t("no_new_notifications")}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="border-b py-4">
                        <h1 className="w-full text-center font-bold mb-2">
                            {t("old_notifications")}
                        </h1>
                        <ul className="flex flex-col gap-2">
                            {[
                                oldNotifications.map((notification) => (
                                    <li
                                        key={notification.id}
                                        className="w-[400px] shadow-lg rounded-lg"
                                    >
                                        <NotificationItem
                                            notification={notification}
                                        />
                                    </li>
                                )),
                            ]}
                            {oldNotifications.length === 0 && (
                                <li className="text-center text-secondary-100 w-[400px]">
                                    {t("no_old_notifications")}
                                </li>
                            )}
                        </ul>
                    </div>
                </Scrollable>
                <Link to="/dashboard/notifications">
                    <div className="mt-2 text-primary-100 rounded-lg border bg-secondary-100  border-primary-100 p-2 text-center hover:bg-primary-100 hover:text-secondary-100 hover:border-secondary-100 transition cursor-pointer">
                        <p>{t("check_all_notifications")}</p>
                    </div>
                </Link>
            </div>
        </>
    )
}
