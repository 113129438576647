import { animated, useSpring } from "@react-spring/web"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { AboutUs } from "./AboutUs"
import { ContactUs } from "./ContactUs"
import { Features } from "./Features"
import { Hero } from "./Hero"
import { Navigation } from "./Navigation"
import { Pricing } from "./Pricing"

interface LandingPageProps {}

export const LandingPage: React.FC<LandingPageProps> = () => {
    const heroRef = useRef<HTMLDivElement>(null)
    const featuresRef = useRef<HTMLDivElement>(null)
    const pricingRef = useRef<HTMLDivElement>(null)
    const aboutUsRef = useRef<HTMLDivElement>(null)
    const contactUsRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const [currentSection, setCurrentSection] = useState<number>(0)
    const [menuSection, setMenuSection] = useState<number>(0)
    const { t } = useTranslation()
    const [hideHeader, setHideHeader] = useState<boolean>(false)
    const [displayHeader, setDisplayHeader] = useState<boolean>(false)
    const menu = [
        {
            label: t("home"),
            ref: heroRef,
            onClick: () => {
                setCurrentSection(0)
                setMenuSection(0)
            },
        },
        {
            label: t("features"),
            ref: featuresRef,
            onClick: () => {
                setCurrentSection(1)
                setMenuSection(1)
            },
        },
        {
            label: t("pricing"),
            ref: pricingRef,
            onClick: () => {
                setCurrentSection(2)
                setMenuSection(2)
            },
        },
        {
            label: t("about_us"),
            ref: aboutUsRef,
            onClick: () => {
                setCurrentSection(3)
                setMenuSection(3)
            },
        },
        {
            label: t("contact_us"),
            ref: contactUsRef,
            onClick: () => {
                setCurrentSection(4)
                setMenuSection(4)
            },
        },
    ]
    useEffect(() => {
        const section = menu[currentSection]
        if (section) {
            section.ref.current?.scrollIntoView({
                behavior: "smooth",
            })
        }
    }, [currentSection])
    const [opacitySpring, api] = useSpring(() => ({
        from: { display: "none", opacity: 0 },
        to: { display: "block", opacity: 1 },
    }))
    useEffect(() => {
        api.start({
            opacity: hideHeader ? 1 : 0,
            ...(hideHeader && { display: "block" }),
            onRest: () => {
                if (!hideHeader) {
                    api.start({ display: "none" })
                    setDisplayHeader(true)
                }
            },
        })
    }, [hideHeader])

    return (
        <div
            className="w-screen h-screen py-2 bg-primary-300 flex flex-col no-scrollbar overflow-y-scroll"
            ref={containerRef}
            onScroll={(e) => {
                const scrollPosition = containerRef.current?.scrollTop || 0
                if (heroRef.current) {
                    const heroRect = heroRef.current.getBoundingClientRect()
                    if (
                        scrollPosition >
                        heroRect.height - Math.round(heroRect.height / 2)
                    ) {
                        setHideHeader(true)
                    } else {
                        setHideHeader(false)
                    }
                    if (heroRect.top > -100 && heroRect.top < 100) {
                        setMenuSection(0)
                    }
                }
                if (featuresRef.current) {
                    const featuresRect =
                        featuresRef.current.getBoundingClientRect()
                    if (featuresRect.top > -100 && featuresRect.top < 100) {
                        setMenuSection(1)
                    }
                }
                if (pricingRef.current) {
                    const pricingRect =
                        pricingRef.current.getBoundingClientRect()
                    if (pricingRect.top > -100 && pricingRect.top < 100) {
                        setMenuSection(2)
                    }
                }
                if (aboutUsRef.current) {
                    const aboutUsRect =
                        aboutUsRef.current.getBoundingClientRect()
                    if (aboutUsRect.top > -100 && aboutUsRect.top < 100) {
                        setMenuSection(3)
                    }
                }
                if (contactUsRef.current) {
                    const contactUsRect =
                        contactUsRef.current.getBoundingClientRect()
                    if (contactUsRect.top > -100 && contactUsRect.top < 100) {
                        setMenuSection(4)
                    }
                }
            }}
        >
            <div ref={heroRef} className="h-screen">
                <header className="px-14 lg:p-0" ref={headerRef}>
                    <Navigation menu={menu} />
                </header>
                <Hero actionClick={() => setCurrentSection(2)} />
            </div>
            {displayHeader && (
                <div className="">
                    <animated.div
                        className="fixed top-0 right-0 lg:left-2 lg:top-2 z-50 bg-primary-300 bg-opacity-25 lg:w-8 lg:h-8 lg:rounded-full lg:flex lg:items-center lg:justify-center"
                        style={{ ...opacitySpring }}
                    >
                        <Navigation
                            menu={menu}
                            vertical={true}
                            currentSection={menuSection}
                        />
                    </animated.div>
                </div>
            )}
            <div ref={featuresRef}>
                <Features />
            </div>
            <div ref={pricingRef}>
                <Pricing />
            </div>
            <div ref={aboutUsRef}>
                <AboutUs />
            </div>
            <div ref={contactUsRef}>
                <ContactUs />
            </div>
            <div className="w-full h-12 my-4">
                <p className="text-center text-xs">
                    {t("copy_right")} {new Date().getFullYear()}
                </p>
            </div>
        </div>
    )
}
