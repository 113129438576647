import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { DropDownMultiInput } from "@/components/Inputs/DropDownMultiInput"
import { Input } from "@/components/Inputs/Input"
import { InputLabel } from "@/components/Inputs/InputLabel"
import { Form, SubmitType } from "@/features/Form/Form"
import { PricingPlan } from "@/models/Pricing"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getLatestUsers, selectLatestUsers } from "../../adminSlice"
import { assignUsersToPricingPlan, clearErrors } from "../pricingSlice"

interface AddUserToPricingPlanProps {
    pricingPlan: PricingPlan
    onCancel: () => void
}

export const AddUserToPricingPlan: React.FC<AddUserToPricingPlanProps> = ({
    pricingPlan,
    onCancel,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.pricing.status)
    const [selectedUsers, setSelectedUsers] = useState<string[]>([])
    const [numberOfMonths, setNumberOfMonths] = useState<number>(1)
    const users = useAppSelector(selectLatestUsers)

    useEffect(() => {
        dispatch(getLatestUsers({}))
    }, [])
    return (
        <Form
            loadingTitle={t("adding_user_to_pricing_plan")}
            onSubmit={async () => {
                const expiresAt = new Date()
                expiresAt.setMonth(expiresAt.getMonth() + numberOfMonths)
                const { type } = await dispatch(
                    assignUsersToPricingPlan({
                        userIds: selectedUsers,
                        pricingPlanId: pricingPlan.id,
                        expiresAt,
                    }),
                )
                if (type === assignUsersToPricingPlan.fulfilled.type) {
                    onCancel()
                }
            }}
            clearErrors={clearErrors}
            statuses={[status.update]}
            submitType={SubmitType.Submit}
            onCancel={onCancel}
        >
            <div className="flex flex-col gap-4">
                <h1 className="text-lg font-bold">
                    {t("adding_user_to_pricing_plan", {
                        pricingPlan: pricingPlan.name,
                    })}
                </h1>
                <div>
                    <InputLabel label={t("select_users")} required />
                    <DropDownMultiInput
                        options={
                            users.map((user) => ({
                                label: user.email,
                                value: user.id,
                            })) as { label: string; value: string }[]
                        }
                        type="text"
                        onChange={(value) => {
                            setSelectedUsers(value as string[])
                        }}
                        value={selectedUsers}
                        placeHolder={t("select_users")}
                    />
                </div>
                <Input
                    label={t("number_of_months")}
                    required
                    name={t("number_of_months")}
                    type="number"
                    value={numberOfMonths}
                    onChange={(e) => {
                        const value = parseInt(e.target.value)
                        if (value > 0) setNumberOfMonths(value)
                    }}
                />
            </div>
        </Form>
    )
}
