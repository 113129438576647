import { createAsyncThunkWithNotification } from "@/app/common"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { BanReason } from "@/models/Admin"
import { Project, ProjectJsonInterface } from "@/models/Project"
import { User, UserInterface } from "@/models/User"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import { removeProject, updateProject } from "../Projects/projectsSlice"
import {
    banUserApi,
    changeUserProfilePictureApi,
    freezeProjectApi,
    getGeneralStatsApi,
    getLatestProjectsApi,
    getLatestUsersApi,
    getProjectStatsInIntervalApi,
    getUserBanStatusApi,
    getUserStatsInIntervalApi,
    unbanUserApi,
    unfreezeProjectApi,
    updateUserApi,
} from "./adminApi"

export const getLatestUsers = createAsyncThunkWithNotification(
    "admin/getLatestUsers",
    async () => {
        const response = await getLatestUsersApi()
        return response
    },
)

export const getLatestProjects = createAsyncThunkWithNotification(
    "admin/getLatestProjects",
    async () => {
        const response = await getLatestProjectsApi()
        return response
    },
)

export const getGeneralStats = createAsyncThunkWithNotification(
    "admin/getGeneralStats",
    async () => {
        const response = await getGeneralStatsApi()
        return response
    },
)

export const getUserStatsInInterval = createAsyncThunkWithNotification(
    "admin/getUserStatsInInterval",
    async ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
        const response = await getUserStatsInIntervalApi(
            startDate.toISOString().split("T")[0],
            endDate.toISOString().split("T")[0],
        )
        return response
    },
)

export const getProjectStatsInInterval = createAsyncThunkWithNotification(
    "admin/getProjectStatsInInterval",
    async ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
        const response = await getProjectStatsInIntervalApi(
            startDate.toISOString().split("T")[0],
            endDate.toISOString().split("T")[0],
        )
        return response
    },
)

export const banUser = createAsyncThunkWithNotification(
    "admin/banUser",
    async ({
        userId,
        payload,
    }: {
        userId: string
        payload: {
            reason: BanReason
            description: string
            expiresAt: Date
        }
    }) => {
        const response = await banUserApi(userId, payload)
        return response
    },
)

export const unbanUser = createAsyncThunkWithNotification(
    "admin/unbanUser",
    async ({ userId }: { userId: string }) => {
        const response = await unbanUserApi(userId)
        return response
    },
)

export const getUserBanStatus = createAsyncThunkWithNotification(
    "admin/getUserBanStatus",
    async ({ userId }: { userId: string }) => {
        const response = await getUserBanStatusApi(userId)
        return response
    },
)

export const changeUserProfilePicture = createAsyncThunkWithNotification(
    "auth/changeUserProfilePicture",
    async ({ userId, payload }: { userId: string; payload: FormData }) => {
        const response = await changeUserProfilePictureApi(userId, payload)
        return response
    },
)

export const updateUser = createAsyncThunkWithNotification(
    "auth/updateUser",
    async ({ userId, user }: { userId: string; user: UserInterface }) => {
        const response = await updateUserApi(userId, user)
        return response
    },
)

export const freezeProject = createAsyncThunkWithNotification(
    "admin/freezeProject",
    async (projectId: string) => {
        const response = await freezeProjectApi(projectId)
        return response
    },
)

export const unfreezeProject = createAsyncThunkWithNotification(
    "admin/unfreezeProject",
    async (projectId: string) => {
        const response = await unfreezeProjectApi(projectId)
        return response
    },
)

interface DateObjectStats {
    [key: string]: { active: number; archived: number }
}

interface AdminState {
    userBanStatus: {
        isBanned: boolean
        expiresAt: Date | null | string
    }
    latestUsers: UserInterface[]
    latestProjects: {
        project: ProjectJsonInterface
        projectUserCount: number
        storageUsage: number
    }[]
    generalStats: { userCount: number; projectCount: number }
    userStats: DateObjectStats
    projectStats: DateObjectStats
    status: ReducerStatus
    errors: FormErrors
}

const initialState: AdminState = {
    userBanStatus: { isBanned: false, expiresAt: null },
    latestUsers: [],
    latestProjects: [],
    generalStats: { userCount: 0, projectCount: 0 },
    userStats: {},
    projectStats: {},
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLatestUsers.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getLatestUsers.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.latestUsers = action.payload.data.data
        })
        builder.addCase(getLatestUsers.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.latestUsers = []
        })
        builder.addCase(getUserStatsInInterval.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getUserStatsInInterval.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.userStats = action.payload.data.data
        })
        builder.addCase(getUserStatsInInterval.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.userStats = {}
        })
        builder.addCase(getProjectStatsInInterval.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(
            getProjectStatsInInterval.fulfilled,
            (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.projectStats = action.payload.data.data
            },
        )
        builder.addCase(getProjectStatsInInterval.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.projectStats = {}
        })
        builder.addCase(getGeneralStats.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getGeneralStats.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.generalStats = action.payload.data.data
        })
        builder.addCase(getGeneralStats.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.generalStats = { userCount: 0, projectCount: 0 }
        })
        builder.addCase(getLatestProjects.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getLatestProjects.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.latestProjects = action.payload.data.data
        })
        builder.addCase(getLatestProjects.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.latestProjects = []
        })
        builder.addCase(banUser.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(banUser.fulfilled, (state) => {
            state.status.update = SliceStatus.IDLE
        })
        builder.addCase(banUser.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
        builder.addCase(unbanUser.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(unbanUser.fulfilled, (state) => {
            state.status.update = SliceStatus.IDLE
        })
        builder.addCase(unbanUser.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(getUserBanStatus.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getUserBanStatus.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.userBanStatus = action.payload.data.data
        })
        builder.addCase(getUserBanStatus.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.userBanStatus = { isBanned: false, expiresAt: null }
        })
        builder.addCase(updateUser.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.latestUsers = state.latestUsers.map((user) => {
                if (user.id === action.payload.data.data.id) {
                    return action.payload.data.data
                }
                return user
            })
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
        builder.addCase(changeUserProfilePicture.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(changeUserProfilePicture.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.latestUsers = state.latestUsers.map((user) => {
                if (user.id === action.payload.data.data.id) {
                    return action.payload.data.data
                }
                return user
            })
        })
        builder.addCase(changeUserProfilePicture.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
        builder.addCase(updateProject.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(updateProject.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.latestProjects = state.latestProjects.map((project) => {
                if (project.project.id === action.payload.data.data.id) {
                    return { ...project, project: action.payload.data.data }
                }
                return project
            })
        })
        builder.addCase(updateProject.rejected, (state, action) => {
            state.status.delete = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
        builder.addCase(removeProject.pending, (state) => {
            state.status.delete = SliceStatus.LOADING
        })
        builder.addCase(removeProject.fulfilled, (state, action) => {
            state.status.delete = SliceStatus.IDLE
            state.latestProjects = state.latestProjects.filter(
                (project) => project.project.id !== action.payload.data.data,
            )
        })
        builder.addCase(removeProject.rejected, (state, action) => {
            state.status.delete = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
        builder.addCase(freezeProject.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(freezeProject.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.latestProjects = state.latestProjects.map((project) => {
                if (project.project.id === action.payload.data.data.id) {
                    return { ...project, project: action.payload.data.data }
                }
                return project
            })
        })
        builder.addCase(freezeProject.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
        builder.addCase(unfreezeProject.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(unfreezeProject.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.latestProjects = state.latestProjects.map((project) => {
                if (project.project.id === action.payload.data.data.id) {
                    return { ...project, project: action.payload.data.data }
                }
                return project
            })
        })
        builder.addCase(unfreezeProject.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            // state.errors = action.payload.data.errors
        })
    },
})

const selectLatestUsersRaw = (state: { admin: AdminState }) =>
    state.admin.latestUsers
export const selectLatestUsers = createSelector(
    [selectLatestUsersRaw],
    (users) => users.map((user) => new User(user)),
)

const selectLatestProjectsRaw = (state: { admin: AdminState }) =>
    state.admin.latestProjects
export const selectLatestProjects = createSelector(
    [selectLatestProjectsRaw],
    (projects) =>
        projects.map((project) => ({
            ...project,
            project: new Project(project.project),
        })),
)

export const { clearErrors } = adminSlice.actions

export default adminSlice.reducer
