import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import arAR from "./arAR.json"
import enUS from "./enUS.json"
import frFR from "./frFR.json"

i18next.use(initReactI18next).init({
    lng: "en",
    debug: false,
    resources: {
        en: {
            translation: enUS,
        },
        fr: {
            translation: frFR,
        },
        ar: {
            translation: arAR,
        },
    },
})
