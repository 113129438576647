import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animated, useSpring } from "@react-spring/web"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BudgetTrackingFeatures } from "./Features/BudgetTrackingFeatures"
import { CentralizedResourceManagementFeatures } from "./Features/CentralizedResourceManagementFeatures"
import { CustomPermissionsFeatures } from "./Features/CustomPermissionsFeatures"
import { FeatureCard } from "./Features/FeatureCard"
import { ProjectManagementFeatures } from "./Features/ProjectManagementFeatures"
import { ThreeDVisualizationFeatures } from "./Features/ThreeDVisualizationFeatures"

interface FeaturesProps {}

export const Features: React.FC<FeaturesProps> = () => {
    const { t } = useTranslation()
    const features = [
        {
            title: t("centralized_resource_management"),
            description: t("centralized_resource_management_description"),
            icon: "folder-tree",
            details: <CentralizedResourceManagementFeatures />,
        },
        {
            title: t("custom_permissions"),
            description: t("custom_permissions_description"),
            icon: "user-lock",
            details: <CustomPermissionsFeatures />,
        },
        {
            title: t("integrated_project_management"),
            description: t("integrated_project_management_description"),
            icon: "tasks",
            details: <ProjectManagementFeatures />,
        },
        {
            title: t("3d_visualization_and_bim"),
            description: t("3d_visualization_and_bim_description"),
            icon: "cube",
            details: <ThreeDVisualizationFeatures />,
        },
        {
            title: t("precise_budget_tracking"),
            description: t("precise_budget_tracking_description"),
            icon: "file-invoice-dollar",
            details: <BudgetTrackingFeatures />,
        },
    ]
    const [carrouselSpring, carrouselSpringApi] = useSpring(() => ({
        from: { x: "0px" },
    }))
    const [currentFeature, setCurrentFeature] = useState(2)
    useEffect(() => {
        const featureCardWidth = 23
        const offset = Math.floor((features.length - 0.1) / 2) - currentFeature
        carrouselSpringApi.start({
            x: `${offset * featureCardWidth}rem`,
        })
    }, [currentFeature])
    return (
        <section className="w-full flex flex-col items-center px-32 py-8 lg:p-2 min-h-screen">
            <h1 className="text-5xl font-black mb-4">{t("features")}</h1>
            <p className="text-xl text-center w-3/4 lg:w-full">
                {t("features_description")}
            </p>
            <div
                className={`relative w-full h-64 p-14 lg:p-0 flex flex-col items-center justify-center mt-4 lg:mt-0 overflow-hidden
            before:content-[''] before:absolute before:top-0 before:left-0 before:w-[50%] before:lg:content-none before:h-full before:bg-gradient-to-r 
            before:from-transparent-100 before:via-transparent-80 before:to-transparent-0 before:pointer-events-none
            after:content-[''] after:absolute after:top-0 after:right-0 after:w-[50%] after:lg:content-none after:h-full after:bg-gradient-to-r 
            after:from-transparent-0 after:via-transparent-80 after:to-transparent-100 after:pointer-events-none after:z-10 before:z-10`}
            >
                {currentFeature !== 0 && (
                    <FontAwesomeIcon
                        icon="chevron-left"
                        className="text-6xl text-primary-secondary absolute top-1/2 left-20 lg:left-3 lg:text-4xl transform -translate-y-1/2 z-20 opacity-10 hover:opacity-100 transition hover:scale-110 cursor-pointer"
                        onClick={() => {
                            if (currentFeature - 1 >= 0) {
                                setCurrentFeature(currentFeature - 1)
                            }
                        }}
                    />
                )}
                {currentFeature !== features.length - 1 && (
                    <FontAwesomeIcon
                        icon="chevron-right"
                        className="text-6xl text-primary-secondary absolute top-1/2 right-20 lg:right-3 lg:text-4xl transform -translate-y-1/2 z-20 opacity-10 hover:opacity-100 transition hover:scale-110 cursor-pointer"
                        onClick={() => {
                            if (currentFeature + 1 <= features.length - 1) {
                                setCurrentFeature(currentFeature + 1)
                            }
                        }}
                    />
                )}
                <animated.div
                    className="flex gap-12 "
                    style={{ ...carrouselSpring }}
                >
                    {features.map((feature, index) => (
                        <FeatureCard
                            key={index}
                            title={feature.title}
                            description={feature.description}
                            icon={feature.icon as IconProp}
                            onClick={() => setCurrentFeature(index)}
                        />
                    ))}
                </animated.div>
            </div>
            <div className="h-full w-full rounded-lg mt-4 lg:mt-2">
                {features[currentFeature].details}
            </div>
        </section>
    )
}
