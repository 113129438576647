import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Form, SubmitType } from "@/features/Form/Form"
import { Task } from "@/models/Task"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { clearErrors, deleteTask } from "../tasksSlice"

interface TaskDeleteFormProps {
    task: Task
    callback: (taskId: string) => void
}

export const TaskDeleteForm: React.FC<TaskDeleteFormProps> = ({
    task,
    callback,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const taskId = useParams<{ taskId: string }>().taskId ?? task.id
    const status = useAppSelector((state) => state.tasks.status)
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const onClick = async () => {
        const { type } = await dispatch(deleteTask({ projectId, taskId }))
        if (type === deleteTask.fulfilled.type) {
            await callback(taskId)
        }
    }
    return (
        <div>
            <Form
                statuses={[status.delete]}
                loadingTitle={t("deleting_task")}
                clearErrors={clearErrors}
                onSubmit={onClick}
                submitType={SubmitType.Delete}
            >
                <h1 className="font-bold text-xl mb-4">
                    {t("task_delete_notice")}
                </h1>
                <p className="text-center text-red-600">{task.name}</p>
            </Form>
        </div>
    )
}
