import { IconProp } from "@fortawesome/fontawesome-svg-core"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { SideBarItem } from "./SideBarItem"

interface SidebarItem {
    name: string
    icon: IconProp
    route: string
}

interface SidebarProps {
    items: SidebarItem[][]
    minimized?: boolean
}

export const Sidebar: React.FC<SidebarProps> = ({
    items,
    minimized = true,
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [isMinimized, setIsMinimized] = React.useState<boolean>(minimized)

    return (
        <nav className="h-full flex flex-col justify-between flex-grow transition sm:hidden bg-primary-100">
            <ul>
                {items.map((groups, idx) => (
                    <li
                        key={idx}
                        className="border-b border-secondary-100 border-opacity-25 overflow-hidden"
                    >
                        <ul>
                            {groups.map((item, idx) => (
                                <li key={idx}>
                                    <Link to={item.route} key={idx}>
                                        <SideBarItem
                                            icon={item.icon}
                                            name={t(item.name)}
                                            isActive={location.pathname.includes(
                                                item.route,
                                            )}
                                            onClick={() => {}}
                                            expanded={!isMinimized}
                                        />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
            <div className="w-fit">
                <SideBarItem
                    name={t("minimize_sidebar")}
                    icon={"arrow-right"}
                    onClick={() => setIsMinimized(!isMinimized)}
                    isActive={false}
                    expanded={!isMinimized}
                    rotateIcon={true}
                />
            </div>
        </nav>
    )
}
