import appSettingsReducer from "@/features/AppSettings/appSettingsSlice"
import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import PricingReducer from "../features/Admin/Pricing/pricingSlice"
import AdminReducer from "../features/Admin/adminSlice"
import notificationsReducer from "../features/AppNotification/AppNotificationsSlice"
import authReducer from "../features/Authentication/authSlice"
import fileManagerReducer from "../features/Projects/FileManager/fileManagerSlice"
import articleReducer from "../features/Projects/Finance/Articles/articlesSlice"
import financeReducer from "../features/Projects/Finance/financeSlice"
import ifcViewerReducer from "../features/Projects/Ifc/ifcSlice"
import ganttReducer from "../features/Projects/Plannings/Gantt/ganttSlice"
import planningReducer from "../features/Projects/Plannings/planningSlice"
import statisticsReducer from "../features/Projects/Statistics/statisticsSlice"
import tagsReducer from "../features/Projects/Tags/tagSlice"
import tasksReducer from "../features/Projects/Tasks/tasksSlice"
import userManagementReducer from "../features/Projects/UserManagement/userManagementSlice"
import projectsReducer from "../features/Projects/projectsSlice"
import userReducer from "../features/User/userSlice"
import invitationReducer from "../pages/Invitation/invitationSlice"

const persistConfig = {
    key: "root",
    storage,
}

const persistedAuthReducer = persistReducer(
    {
        ...persistConfig,
        blacklist: ["errors", "status"],
    },
    authReducer,
)

const persistedAppSettingsReducer = persistReducer(
    {
        ...persistConfig,
        key: "appSettings",
    },
    appSettingsReducer,
)

export const store = configureStore({
    reducer: {
        appSettings: persistedAppSettingsReducer,
        auth: persistedAuthReducer,
        invitation: invitationReducer,
        projects: projectsReducer,
        plannings: planningReducer,
        gantt: ganttReducer,
        notifications: notificationsReducer,
        userManagement: userManagementReducer,
        tasks: tasksReducer,
        users: userReducer,
        article: articleReducer,
        ifcViewer: ifcViewerReducer,
        fileManager: fileManagerReducer,
        tags: tagsReducer,
        finance: financeReducer,
        admin: AdminReducer,
        pricing: PricingReducer,
        statistics: statisticsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const persistor = persistStore(store)
