import { UserNotification } from "@/models/Notification"
import { useState } from "react"

interface NotificationItemProps {
    notification: UserNotification
    addSeen?: (id: string) => void
    longFormat?: boolean
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    addSeen,
    longFormat = false,
}) => {
    const [localSeen, setLocalSeen] = useState<boolean>(notification.seen)
    const handleHover = () => {
        if (addSeen) {
            addSeen(notification.id)
            setLocalSeen(true)
        }
    }

    return (
        <div onMouseEnter={handleHover} className="w-full">
            {notification.getBody(localSeen, longFormat) ?? ""}
        </div>
    )
}
