import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
    TabbedWindow,
    TabbedWindowInterface,
} from "@/components/Layouts/TabbedWindow"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
    getCurrentUserPermissionsInProject,
    getUserProjectModules,
    selectUserProjectModules,
} from "../User/userSlice"

export const Project = () => {
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const dispatch = useAppDispatch()
    const userProjectModules = useAppSelector(selectUserProjectModules)
    const [tabs, setTabs] = useState<TabbedWindowInterface[]>([])
    useEffect(() => {
        dispatch(getCurrentUserPermissionsInProject(projectId))
        dispatch(getUserProjectModules(projectId))
    }, [])
    useEffect(() => {
        setTabs(userProjectModules.getTabs(projectId))
    }, [userProjectModules])
    return <TabbedWindow tabs={tabs}></TabbedWindow>
}
