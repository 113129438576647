import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { UsersOrGroupsInput } from "@/components/Inputs/UsersOrGroupsInput"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Form, SubmitType } from "@/features/Form/Form"
import { t } from "i18next"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { getPlanningTaskTree } from "../../Plannings/planningSlice"
import { getProjectUsersById, selectProjectUsers } from "../../projectsSlice"
import {
    getProjectGroups,
    selectProjectGroups,
} from "../../UserManagement/userManagementSlice"
import {
    addTaskAssigneesById,
    addTaskFollowersById,
    addTaskReviewsById,
    clearErrors,
    getTaskResources,
    removeTaskAssigneesById,
    removeTaskFollowersById,
    removeTaskReviewsById,
    selectResources,
} from "../tasksSlice"

interface TaskResourcesFormProps {}

export const TaskResourcesForm: React.FC<TaskResourcesFormProps> = () => {
    const taskId = useParams<{ taskId: string }>().taskId ?? ""
    const projectId = useParams<{ projectId: string }>().projectId
    const planningId = useParams<{ planningId: string }>().planningId
    const dispatch = useAppDispatch()
    const projectUsers = useAppSelector(selectProjectUsers)
    const projectGroups = useAppSelector(selectProjectGroups)
    const status = useAppSelector((state) => state.tasks.status)
    const projectStatus = useAppSelector((state) => state.projects.status)
    const context = useOutletContext<{
        setShowModal: Dispatch<SetStateAction<boolean>>
    }>()
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [localResources, setLocalResources] = useState<{
        assignees: {
            users: string[]
            groups: string[]
        }
        followers: {
            users: string[]
            groups: string[]
        }
        reviewers: {
            users: string[]
            groups: string[]
        }
    }>({
        assignees: {
            users: [],
            groups: [],
        },
        followers: {
            users: [],
            groups: [],
        },
        reviewers: {
            users: [],
            groups: [],
        },
    })
    const resources = useAppSelector(selectResources)
    useEffect(() => {
        if (taskId) {
            setIsEditMode(true)
        }
    }, [taskId])
    useEffect(() => {
        if (isEditMode) {
            dispatch(getTaskResources({ projectId, taskId }))
        }
    }, [isEditMode])
    useEffect(() => {
        if (isEditMode) {
            const newLocalResources = {
                assignees: {
                    users: resources.assignees.users.map(
                        (assignee) => assignee.id,
                    ),
                    groups: resources.assignees.groups.map(
                        (groupAssigned) => groupAssigned.id,
                    ),
                },
                followers: {
                    users: resources.followers.users.map(
                        (follower) => follower.id,
                    ),
                    groups: resources.followers.groups.map(
                        (groupFollowed) => groupFollowed.id,
                    ),
                },
                reviewers: {
                    users: resources.reviewers.map((reviewer) => reviewer.id),
                    groups: [],
                },
            }
            setLocalResources(newLocalResources)
        }
    }, [resources, projectGroups, projectUsers])
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])

    const handleSubmit = async () => {
        const assigneesToAdd = {
            users: localResources.assignees.users.filter(
                (user) =>
                    !resources.assignees.users
                        .map((assignee) => assignee.id)
                        .includes(user),
            ),
            groups: localResources.assignees.groups.filter(
                (group) =>
                    !resources.assignees.groups
                        .map((groupAssigned) => groupAssigned.id)
                        .includes(group),
            ),
        }
        const reviewersToAdd = {
            users: localResources.reviewers.users.filter(
                (user) =>
                    !resources.reviewers
                        .map((review) => review.id)
                        .includes(user),
            ),
            groups: [],
        }
        const followersToAdd = {
            users: localResources.followers.users.filter(
                (user) =>
                    !resources.followers.users
                        .map((follower) => follower.id)
                        .includes(user),
            ),
            groups: localResources.followers.groups.filter(
                (group) =>
                    !resources.followers.groups
                        .map((group) => group.id)
                        .includes(group),
            ),
        }
        await Promise.all([
            ...(assigneesToAdd.users.length > 0 ||
            assigneesToAdd.groups.length > 0
                ? [
                      dispatch(
                          addTaskAssigneesById({
                              projectId,
                              taskId,
                              assignees: assigneesToAdd.users,
                              groups: assigneesToAdd.groups,
                          }),
                      ),
                  ]
                : []),
            ...(reviewersToAdd.users.length > 0
                ? [
                      dispatch(
                          addTaskReviewsById({
                              projectId,
                              taskId,
                              reviewers: reviewersToAdd.users,
                              groups: reviewersToAdd.groups,
                          }),
                      ),
                  ]
                : []),
            ...(followersToAdd.users.length > 0 ||
            followersToAdd.groups.length > 0
                ? [
                      dispatch(
                          addTaskFollowersById({
                              projectId,
                              taskId,
                              followers: followersToAdd.users,
                              groupFollowers: followersToAdd.groups,
                          }),
                      ),
                  ]
                : []),
        ])
        const assigneesToRemove = {
            users: resources.assignees.users
                .map((assignee) => assignee.id)
                .filter(
                    (user) => !localResources.assignees.users.includes(user),
                ),
            groups: resources.assignees.groups
                .map((groupAssigned) => groupAssigned.id)
                .filter(
                    (group) => !localResources.assignees.groups.includes(group),
                ),
        }
        const reviewersToRemove = {
            users: resources.reviewers
                .map((review) => review.id)
                .filter(
                    (user) => !localResources.reviewers.users.includes(user),
                ),
            groups: [],
        }
        const followersToRemove = {
            users: resources.followers.users
                .map((follower) => follower.id)
                .filter(
                    (user) => !localResources.followers.users.includes(user),
                ),
            groups: resources.followers.groups
                .map((group) => group.id)
                .filter(
                    (group) => !localResources.followers.groups.includes(group),
                ),
        }
        await Promise.all([
            ...(assigneesToRemove.users.length > 0 ||
            assigneesToRemove.groups.length > 0
                ? [
                      dispatch(
                          removeTaskAssigneesById({
                              projectId,
                              taskId,
                              assignees: assigneesToRemove.users,
                              groups: assigneesToRemove.groups,
                          }),
                      ),
                  ]
                : []),
            ...(reviewersToRemove.users.length > 0
                ? [
                      dispatch(
                          removeTaskReviewsById({
                              projectId,
                              taskId,
                              reviewers: reviewersToRemove.users,
                              groups: reviewersToRemove.groups,
                          }),
                      ),
                  ]
                : []),
            ...(followersToRemove.users.length > 0 ||
            followersToRemove.groups.length > 0
                ? [
                      dispatch(
                          removeTaskFollowersById({
                              projectId,
                              taskId,
                              followers: followersToRemove.users,
                              groupFollowers: followersToRemove.groups,
                          }),
                      ),
                  ]
                : []),
        ])
        if (planningId) {
            await dispatch(getPlanningTaskTree({ planningId, projectId }))
        }
        if (context && context.setShowModal) {
            context.setShowModal(false)
        }
    }

    return (
        <Form
            loadingTitle={
                projectStatus.read === SliceStatus.LOADING ||
                status.read === SliceStatus.LOADING
                    ? t("loading_task_resources")
                    : isEditMode
                      ? t("updating_task_resources")
                      : t("creating_task_resources")
            }
            clearErrors={clearErrors}
            statuses={[
                projectStatus.read,
                status.read,
                status.update,
                status.create,
            ]}
            submitType={isEditMode ? SubmitType.Save : SubmitType.Create}
            onSubmit={handleSubmit}
            className="p-5"
        >
            <Scrollable height="80%" className="flex flex-col gap-4  w-full">
                <div className="flex  gap-2">
                    <h1 className="font-bold  text-lg">{t("assignees")}:</h1>
                    <div className="flex flex-col gap-4 ">
                        <UsersOrGroupsInput
                            value={localResources.assignees}
                            setValue={(value) =>
                                setLocalResources({
                                    ...localResources,
                                    assignees: value,
                                })
                            }
                            users={projectUsers}
                            groups={projectGroups}
                        />
                    </div>
                </div>
                <div className="flex  gap-2">
                    <h1 className="font-bold  text-lg">{t("reviewers")}:</h1>
                    <div className="flex flex-col gap-4 ">
                        <UsersOrGroupsInput
                            value={{
                                users: localResources.reviewers.users,
                                groups: [],
                            }}
                            setValue={(value) =>
                                setLocalResources({
                                    ...localResources,
                                    reviewers: value,
                                })
                            }
                            users={projectUsers}
                            groups={projectGroups}
                            disableGroups
                        />
                    </div>
                </div>
                <div className="flex  gap-2">
                    <h1 className="font-bold  text-lg">{t("followers")}:</h1>
                    <div className="flex flex-col gap-4 ">
                        <UsersOrGroupsInput
                            value={localResources.followers}
                            setValue={(value) =>
                                setLocalResources({
                                    ...localResources,
                                    followers: value,
                                })
                            }
                            users={projectUsers}
                            groups={projectGroups}
                        />
                    </div>
                </div>
            </Scrollable>
        </Form>
    )
}
