import { UserNotification } from "@/models/Notification"
import {
    NotificationTypeAdditionalDataMap,
    UserNotificationType,
} from "@/types/UserNotificationsTypes"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface NotificationTaskDelayedProps {
    notification: UserNotification
    additionalData: NotificationTypeAdditionalDataMap[UserNotificationType.TASK_DELAYED_MANAGER]
    seen: boolean
    longFormat?: boolean
}

export const NotificationTaskDelayed: React.FC<
    NotificationTaskDelayedProps
> = ({ notification, additionalData, seen, longFormat = false }) => {
    const { t } = useTranslation()
    const redirectLink = ``
    return (
        <Link
            className={`flex items-center justify-between  p-2 bg-secondary-100 rounded-lg  ${
                seen ? "text-primary-100" : "text-primary-300"
            }`}
            to={redirectLink}
        >
            FIXME
        </Link>
    )
}
