import { createAsyncThunkWithNotification } from "@/app/common"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { Invitation, InvitationInterface } from "@/models/Auth"
import { createSlice } from "@reduxjs/toolkit"
import { InvitationResponse } from "."
import {
    updateInvitationApi,
    validateInvitationTokenApi,
} from "./invitationApi"

export const validateInvitationToken = createAsyncThunkWithNotification(
    "invitation/validateInvitationToken",
    async (token: string) => {
        const response = await validateInvitationTokenApi(token)
        return response
    },
)

export const updateInvitation = createAsyncThunkWithNotification(
    "invitation/updateInvitation",
    async ({
        token,
        response,
    }: {
        token: string
        response: InvitationResponse
    }) => {
        const res = await updateInvitationApi(token, response)
        return res
    },
)

export interface InvitationState {
    invitation: InvitationInterface
    status: ReducerStatus
    errors: FormErrors
}

const initialState: InvitationState = {
    invitation: new Invitation().toJson(),
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

export const invitationSlice = createSlice({
    name: "invitation",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateInvitationToken.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(validateInvitationToken.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.invitation = action.payload.data.data
            })
            .addCase(validateInvitationToken.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(updateInvitation.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(updateInvitation.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.invitation = action.payload.data.data
            })
            .addCase(updateInvitation.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
    },
})

export const { clearErrors } = invitationSlice.actions

export default invitationSlice.reducer
