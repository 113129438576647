import { InputLabel } from "./InputLabel"

interface TextAreaInputProps {
    value: string
    label: string
    name: string
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    resizable?: boolean
    className?: string
    required?: boolean
}

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
    value,
    label,
    name,
    onChange,
    className = "",
    resizable = true,
    required = false,
}) => {
    return (
        <div className="mt-2 w-full flex flex-col">
            <InputLabel label={label} required={required} />
            <textarea
                className={`outline-none border border-secondary-400 hover:border-primary-100 focus:border-primary-100 rounded-md p-2 ${className}`}
                name={name}
                value={value}
                onChange={onChange}
                style={{ resize: resizable ? "vertical" : "none" }}
            />
        </div>
    )
}
